/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ALERT_ORDER,
  ALERT_ORDER_ERROR,
  ALERT_ORDER_SUCCESS,
  LOAD_ORDER,
  LOAD_ORDER_ERROR,
  LOAD_ORDER_SUCCESS,
  ORDER_RESOLVED_ERROR,
  ORDER_RESOLVED_SUCCESS,
  PlaceOrderActionType,
  PlaceOrderState,
  PLACE_ORDER,
  PLACE_ORDER_ERROR,
  PLACE_ORDER_SUCCESS,
  PURCHASE_WITH_COUPON,
  PURCHASE_WITH_COUPON_ERROR,
  PURCHASE_WITH_COUPON_SUCCESS,
  RESET_ORDER_STATE,
  WAIT_ORDER_RESOLVED,
  WAIT_STATUS,
  CLEAR_PLACED_ORDER,
} from "../types/placeOrderTypes";

const defaultState: PlaceOrderState = {
  error: "",
  fetchStatus: "",
  data: {},
};

export const placeOrderReducer = (
  state: PlaceOrderState = defaultState,
  action: PlaceOrderActionType
): PlaceOrderState => {
  switch (action.type) {
    case PLACE_ORDER:
      return { data: {}, error: "", fetchStatus: "fetching" };
    case PLACE_ORDER_SUCCESS:
      return {
        // @ts-ignore - Project Upgrade
        data: action.payload.placedOrder,
        error: "",
        fetchStatus: "success",
      };
    case PLACE_ORDER_ERROR:
    case PURCHASE_WITH_COUPON_ERROR:
    case LOAD_ORDER_ERROR:
    case ALERT_ORDER_ERROR:
      return { ...state, error: action.payload.error, fetchStatus: "failed" };
    case ORDER_RESOLVED_ERROR:
      return {
        ...state,
        data: { ...state.data },
        error: action.payload.error,
        fetchStatus: "failed",
      };
    case PURCHASE_WITH_COUPON:
      return { ...state, fetchStatus: "fetching" };
    case PURCHASE_WITH_COUPON_SUCCESS:
      return updateOrder(state, action.payload.order);
    case LOAD_ORDER:
    case WAIT_ORDER_RESOLVED:
    case WAIT_STATUS:
    case ALERT_ORDER:
      return { ...state, fetchStatus: "fetching" };
    case LOAD_ORDER_SUCCESS:
    case ORDER_RESOLVED_SUCCESS:
    case ALERT_ORDER_SUCCESS:
      return updateOrder(state, action.payload.order);
    case RESET_ORDER_STATE:
      return defaultState;
    // Clears only the placed order (Pending Order) cache, this is different thant the reset_order_state action which will delete
    // all order data as well.
    case CLEAR_PLACED_ORDER:
      return defaultState;
    default:
      return state;
  }
};

function updateOrder(state: PlaceOrderState, order: any): PlaceOrderState {
  return {
    ...state,
    data: {
      ...state.data,
      ...order,
      secsToServiceEstimate: Math.max(0, order.secsToServiceEstimate),
    },
    error: "",
    fetchStatus: "success",
  };
}
