import main from "./main";
import "./static/images/processing-icon.png";

declare global {
  interface Window {
    ApplePaySession: any;
  }
}

export default main;
