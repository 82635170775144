import React from "react";

import { Link } from "react-router-dom";
import { env, routes } from "../../app-constants";
import dateTimeFormatService from "../../services/dateTimeFormatService";
import vendorService from "../../services/vendorService";
import { Vendor } from "../../types";
import Spinner from "../Spinner/Spinner";

export interface IVendorLinkProps {
  vendor: Vendor;
  queryParams: string;
  delivery: boolean;
  /** True if the user has entered their address for delivery (or we loaded a stored one). */
  haveDeliveryAddress: boolean;
  loading: boolean;
  deliveryTime?: number;
  onVendorClick: () => void;
}

/**
 * Calculates the vendors status and handles vendor selection.
 */
const VendorLink = ({
  vendor,
  queryParams,
  haveDeliveryAddress,
  delivery,
  loading,
  deliveryTime,
  onVendorClick,
}: IVendorLinkProps) => {
  const now = () => new Date().valueOf();

  const remainingTimeUntilStoreOpen = (vendor: Vendor) => {
    return vendor.nextServiceTime
      ? dateTimeFormatService.formatRemainingTime(
          new Date(vendor.nextServiceTime).getTime() - now()
        )
      : "Unknown";
  };

  const renderPickupTxt = (vendor: Vendor) => {
    return (
      vendor.tts > 60 * 1000 && (
        <>
          {env.REACT_APP_SHOW_TIME_TO_SERVICE ? (
            <div className="btn-tag">
              Pickup ~
              <span>
                {dateTimeFormatService.formatRemainingTime(vendor.tts)}
              </span>
            </div>
          ) : (
            <div className="btn-tag">Pickup</div>
          )}
        </>
      )
    );
  };

  const renderTextChoice = (delivery: boolean, vendor: Vendor) => {
    if (delivery) {
      return renderDeliverTxt(vendor);
    }
    return renderPickupTxt(vendor);
  };

  const renderDeliverTxt = (vendor: Vendor) => {
    let remainingTime = vendor.tts;
    if (deliveryTime) {
      remainingTime += deliveryTime;
    }
    return (
      vendor.tts > 60 * 1000 && (
        <>
          {env.REACT_APP_SHOW_TIME_TO_SERVICE ? (
            <div className="btn-tag">
              Deliver ~
              <span>
                {dateTimeFormatService.formatRemainingTime(remainingTime)}
              </span>
            </div>
          ) : (
            <div className="btn-tag">Deliver</div>
          )}
        </>
      )
    );
  };

  const renderOpeningTxt = (vendor: Vendor) => {
    return (
      vendor.tts > 60 * 1000 && (
        <>
          {env.REACT_APP_SHOW_TIME_TO_SERVICE ? (
            <div className="btn-tag">
              Opening ~<span>{remainingTimeUntilStoreOpen(vendor)}</span>
            </div>
          ) : (
            <div className="btn-tag">Opening soon</div>
          )}
        </>
      )
    );
  };

  const renderNoWaitingTxt = (vendor: Vendor) => {
    return (
      vendor.tts <= 60 * 1000 && (
        <div className="btn-tag">
          <span>No waiting</span>
        </div>
      )
    );
  };

  return (
    <div className="order-column">
      {loading ? (
        <div>
          <button className="option-link disabled">
            <Spinner />
          </button>
        </div>
      ) : (
        <div>
          {!vendor.isPaused ? (
            <div>
              {vendorService.isDigitalStoreOpen(vendor) ? (
                <div>
                  {delivery && !haveDeliveryAddress ? (
                    <div className="option-link disabled">
                      <h6 className="text-primary-bold">
                        Enter Delivery Address
                      </h6>
                    </div>
                  ) : (
                    <Link
                      to={{
                        pathname: routes.ORDER_FLOW.PLACE_ORDER.PATH,
                        search: queryParams,
                      }}
                      className="option-link"
                      onClick={onVendorClick}
                    >
                      <h6 className="text-primary-bold">Order Now</h6>
                      {renderTextChoice(delivery, vendor)}
                      {renderNoWaitingTxt(vendor)}
                    </Link>
                  )}
                </div>
              ) : (
                <div className="option-link disabled">
                  <h6 className="text-primary-bold">Closed</h6>
                  {renderOpeningTxt(vendor)}
                </div>
              )}
            </div>
          ) : (
            <button className="option-link disabled">
              <h6 className="text-primary-bold">Back Soon</h6>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default VendorLink;
