import { all, call, put, takeEvery } from "redux-saga/effects";
import couponService from "../../services/couponService";
import {
  COUPON_LOADED_ERROR,
  COUPON_LOADED_SUCCESS,
  LoadCouponAction,
  LOAD_COUPON,
} from "../types/couponTypes";

export function* loadCoupon({ payload }: LoadCouponAction) {
  try {
    const { result } = yield call(couponService.getCoupon, payload.orderId);
    yield put({
      type: COUPON_LOADED_SUCCESS,
      payload: { coupon: result },
    });
  } catch (error) {
    yield put({
      type: COUPON_LOADED_ERROR,
      payload: { error },
    });
  }
}

export function* couponRootSaga() {
  yield all([takeEvery(LOAD_COUPON, loadCoupon)]);
}
