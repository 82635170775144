import { cleanEnv, num, str, bool } from "envalid";

// 30 minutes default order session expiry.
const defaultOrderSessionExpiryTimeInMillis = 1800000;

export const env = cleanEnv(process.env, {
  REACT_APP_VENDOR_ID: num({ devDefault: 1 }),
  REACT_APP_CLIENT_ID: num({ devDefault: 1 }),
  REACT_APP_VENUE_ID: num({ devDefault: 1 }),
  REACT_APP_VENUE_NAME: str({ devDefault: "Test Venue" }),
  REACT_APP_MULTI_VENDOR_SUPPORT: bool({ default: false }),
  REACT_APP_ENTITY_NAME: str({ devDefault: "Test Entity Name" }),
  REACT_APP_ABN: str({ devDefault: "test-abn" }),
  REACT_APP_GOOGLE_API_KEY: str({ default: undefined }),
  REACT_APP_SPECIAL_ORDER_NOTE: str({ default: undefined }),
  REACT_APP_DIETARY_NOTE: bool({ default: false }),
  REACT_APP_SHOW_TIME_TO_SERVICE: bool({ default: false }),
  REACT_APP_ORDER_SESSION_EXPIRY_TIME_MILLIS: num({
    default: defaultOrderSessionExpiryTimeInMillis,
  }),
  REACT_APP_SERVER_URL: str({ devDefault: "http://mocked-url.com" }),
  REACT_APP_API_VERSION: str({ devDefault: "/v1" }),
  REACT_APP_DISCOVER_VENUE_ID: num({ default: undefined }),
  REACT_APP_GPAY_PRODUCTION: bool({ devDefault: false }),
  REACT_APP_GPAY_GATEWAY_MERCHANT_ID: str({
    devDefault: "TESTqhopper-cc1b2535fdca15fa",
  }),
  REACT_APP_GPAY_MERCHANT_ID: str({ devDefault: "12345678901234567890" }),
});
