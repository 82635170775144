import { OrderState } from "../../state/types/orderTypes";
import { Vendor } from "../../types";
import { env, routes } from "../../app-constants";
import vendorService from "../../services/vendorService";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import React from "react";
import { DeliveryDetails } from "./Delivery";

/**
 * The "Proceed to Order" button, or a message that explains why we're not showing it yet. Only
 * supported for single-vendor apps.
 *
 * @param props.orderState Used to check whether the vendor is accepting online orders.
 * @param props.vendorsTakingDeliveryOrders The vendors currently accepting orders for delivery.
 * @param props.deliveryDetailsByVendorId Contains the `DeliveryDetails` for the vendor.
 * @param props.onClick Called when the button is clicked.
 * @param props.queryParams The query string from the current URL.
 */
export const PlaceOrderButton = (props: {
  orderState: OrderState;
  vendorsTakingDeliveryOrders: readonly Vendor[];
  deliveryDetailsByVendorId: Record<number, DeliveryDetails> | undefined;
  onClick: () => void;
  queryParams: string;
}): JSX.Element => {
  const vendor =
    props.vendorsTakingDeliveryOrders &&
    props.vendorsTakingDeliveryOrders.find(
      (vendor: Vendor) => vendor.id === env.REACT_APP_VENDOR_ID
    );

  const isDigitalStoreOpen = vendorService.isDigitalStoreOpen(
    vendor,
    props.orderState
  );

  const deliveryDetails =
    vendor && props.deliveryDetailsByVendorId?.[vendor.id];

  const vendorWillAcceptOrderToDeliveryAddress =
    deliveryDetails?.deliveryPossible;

  const deliveryDetailsErrorMessage = deliveryDetails?.error ? (
    <div className="error-notice">
      <span>{deliveryDetails?.error}</span>
    </div>
  ) : (
    <></>
  );

  // Shown if the user is allowed to proceed.
  const placeOrderButton = (
    <div className="mt-auto">
      <Link
        className="btn btn-primary"
        onClick={props.onClick}
        to={{
          pathname: routes.ORDER_FLOW.PLACE_ORDER.PATH,
          search: props.queryParams,
        }}
      >
        Proceed to Order
      </Link>
    </div>
  );

  // Shown if the user hasn't entered their address yet or if they're outside of the vendor's
  // delivery range.
  const enterAddressMessage = (
    <div className="mt-auto">
      <button className="btn btn-primary" disabled>
        Enter Address Above
      </button>
    </div>
  );

  // Shown if the store is closed.
  const closedMessage = (
    <div className="mt-auto">
      <button className="btn btn-primary" disabled>
        We are closed
      </button>
    </div>
  );

  return (
    <div className="fixed-btn-row">
      {!isDigitalStoreOpen ? (
        closedMessage
      ) : !vendorWillAcceptOrderToDeliveryAddress ? (
        <>
          {deliveryDetailsErrorMessage}
          {enterAddressMessage}
        </>
      ) : (
        placeOrderButton
      )}
      <Footer className="mt-auto" />
    </div>
  );
};
