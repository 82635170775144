import React from "react";

export interface ICreditCardiconProps {
  creditCardNumber: string;
}

export default class CreditCardicon extends React.Component<
  ICreditCardiconProps,
  any
> {
  readonly creditCardTypes = [
    {
      classStyle: "visa",
      regex: /^4[0-9]{0,12}(?:[0-9]{0,3})$/,
    },
    {
      classStyle: "mastercard",
      regex: /^5[1-5][0-9]{0,14}$/,
    },
    {
      classStyle: "amex",
      regex: /^3[47][0-9]{0,13}$/,
    },
    {
      classStyle: "diners-club",
      regex: /^3(?:0[0-5]|[68][0-9])[0-9]{0,11}$/,
    },
    {
      classStyle: "jcb",
      regex: /^(?:2131|1800|35\d{0,3})\d{0,11}$/,
    },
    {
      classStyle: "generic",
      regex: /.*/,
    },
  ];

  getCreditCardIconClass(creditCardNumber: string) {
    if (creditCardNumber) {
      for (const type of this.creditCardTypes) {
        if (type.regex.test(creditCardNumber)) {
          return type.classStyle;
        }
      }
    }
    return "";
  }

  render() {
    const { creditCardNumber } = this.props;
    const classStyle = this.getCreditCardIconClass(this.props.creditCardNumber);
    return creditCardNumber ? (
      <span className={`left-icon credit-card-icon ${classStyle}`}></span>
    ) : null;
  }
}
