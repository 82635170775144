import React from "react";
import { ICardFormData } from "./PaymentCardForm";

export interface ISavedCardFormProps {
  savedCardData: any;
  isFormValid: (formData: ISavedCardFormState, isValid: boolean) => void;
}

export type ISavedCardFormState = ICardFormData;

export default class SavedCardForm extends React.Component<
  ISavedCardFormProps,
  ISavedCardFormState
> {
  constructor(props: ISavedCardFormProps) {
    super(props);
    this.onCvnChanged = this.onCvnChanged.bind(this);
    this.state = {
      isRememberCardChecked: true,
      nameOncard: this.props.savedCardData.cardName,
      cardNumber: this.props.savedCardData.maskedCreditCardNumber,
      cvn: "",
      expiryMonth: this.props.savedCardData.expiryMonth,
      expiryYear: this.props.savedCardData.expiryYear,
    };
    this.props.isFormValid(this.state, false);
  }

  onCvnChanged(event: any) {
    const cvnValue = event.currentTarget.value;
    if (cvnValue.length <= 4) {
      this.setState({ cvn: event.currentTarget.value }, () => {
        if (/^\d{3,}$/.test(cvnValue)) {
          this.props.isFormValid(this.state, true);
        } else {
          this.props.isFormValid(this.state, false);
        }
      });
    }
  }

  render() {
    const { cardName, maskedCreditCardNumber } = this.props.savedCardData;
    return (
      <React.Fragment>
        <div className="card-notice">
          To pay with this <strong>saved credit card</strong> enter its 3 or 4
          digit CVN/CCV below
        </div>
        <div className="saved-details">
          <div className="card-holder">{cardName}</div>
          <div className="card-number">{maskedCreditCardNumber}</div>
        </div>
        <div className="field field-code credit-card-cvn">
          <input
            type="tel"
            value={this.state.cvn}
            onChange={this.onCvnChanged}
            name="creditCardCvn"
            className="form-control"
            id="creditCardCvn"
            autoComplete="cc-csc"
            x-autocompletetype="cc-csc"
            maxLength={4}
            placeholder="CCV#"
            required={true}
          />
        </div>
      </React.Fragment>
    );
  }
}
