import React from "react";
import { env } from "../../app-constants";
import Spinner from "../Spinner/Spinner";

export interface IAssetProps {
  thumbnailAssetId: number;
  transform?: string;
}

export interface IAssetState {
  loading: boolean;
  assetError: boolean;
}

export default class Asset extends React.Component<IAssetProps, IAssetState> {
  readonly defaultTransform = "350x";

  constructor(props: IAssetProps) {
    super(props);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.state = {
      loading: true,
      assetError: false,
    };
  }

  handleImageLoaded(e: any) {
    this.setState({
      loading: false,
    });
  }

  handleImageError(e: any) {
    this.setState({
      loading: false,
      assetError: true,
    });
  }

  render() {
    const { thumbnailAssetId, transform = this.defaultTransform } = this.props;
    const assetUrl =
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}` +
      `/displayassets/${thumbnailAssetId}/transformed/${transform}`;
    return (
      <React.Fragment>
        {this.state.loading && <Spinner />}
        {!this.state.assetError && (
          <img
            alt="thumbnail"
            onLoad={this.handleImageLoaded}
            onError={this.handleImageError}
            src={assetUrl}
          />
        )}
      </React.Fragment>
    );
  }
}
