import React from "react";
import FollowingBalls from "../LoadingIndicator/FollowingBalls";
import Modal from "./Modal";

export interface IVendorStatusModalProps {
  text: string;
  isOpen: boolean;
}

export default (props: IVendorStatusModalProps) => (
  <Modal
    className="PaymentLoaderModal"
    isOpen={props.isOpen}
    // @ts-ignore - Project Upgrade
    title={null}
    content={
      <React.Fragment>
        <div className="processing-icon"></div>
        <div className="processing-text">{props.text}</div>
        <FollowingBalls />
      </React.Fragment>
    }
    actions={null}
  ></Modal>
);
