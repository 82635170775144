/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { env, routes } from "../../app-constants";
import Footer from "../../components/Footer/Footer";
import OrderSummaryInfo from "../../components/Order/OrderSummaryInfo";
import OrderSummaryList from "../../components/Order/OrderSummaryList";
import { RootState } from "../../state/store/store";
import Nav from "../../components/Nav/Nav";
import withData from "../../highOrderComponents/withData";
import { useDispatch, useSelector } from "react-redux";
import { calculateOrderAmount } from "../../state/actions/orderActions";

const ConfirmOrder = () => {
  const dispatch = useDispatch();

  const order = useSelector((state: RootState) => state.order);

  const selectedVendor = useSelector(
    (state: RootState) => state.selectedVendor
  );

  useEffect(() => {
    dispatch(calculateOrderAmount(selectedVendor.data.id, order));
  }, []);

  const paymentSummary = order.orderAmount.paymentSummary;

  return (
    <div className="ConfirmOrder col-md-8 offset-md-2 d-flex flex-column">
      <Nav
        className="nav-header"
        goTo={routes.ORDER_FLOW.PLACE_ORDER.PATH}
        title="Please Check Your Order"
        subtitle={`Ordering from ${
          selectedVendor
            ? selectedVendor.data.storeName
            : env.REACT_APP_VENUE_NAME
        }`}
      />
      <div className="summary-container d-flex flex-column">
        <OrderSummaryList
          showAddRemoveItemsBtn={true}
          // @ts-ignore - Project Upgrade
          items={paymentSummary.items.filter(
            // @ts-ignore - Project Upgrade
            (item) => item.itemType !== "ServiceCharge"
          )}
          // @ts-ignore - Project Upgrade
          totalPayment={paymentSummary.total}
        />
        <OrderSummaryInfo
          // @ts-ignore - Project Upgrade
          chargeItems={paymentSummary.items.filter(
            // @ts-ignore - Project Upgrade
            (item) => item.itemType === "ServiceCharge"
          )}
          // @ts-ignore - Project Upgrade
          totalPayment={paymentSummary.total}
          // @ts-ignore - Project Upgrade
          subtotal={paymentSummary.subTotals}
        />

        {env.REACT_APP_DIETARY_NOTE && selectedVendor ? (
          <div className="special-order-note">
            For specific dietary requests including all allergies please contact
            the restaurant directly on&nbsp;
            <a href={`tel:${selectedVendor.data.phoneNumber}`}>
              {selectedVendor.data.phoneNumber}
            </a>
          </div>
        ) : (
          <></>
        )}

        {env.REACT_APP_SPECIAL_ORDER_NOTE ? (
          <div className="special-order-note">
            {env.REACT_APP_SPECIAL_ORDER_NOTE}
          </div>
        ) : (
          <></>
        )}

        <div className="fixed-btn-row">
          <div className="mt-auto">
            <Link
              className="btn btn-primary btn-arrow"
              to={routes.ORDER_FLOW.CONFIRM_CONTACT.PATH}
            >
              Proceed to Pay
            </Link>
          </div>
          <Footer className="mt-auto" />
        </div>
      </div>
    </div>
  );
};

export default withData()(ConfirmOrder);
