import Utils from "../../services/utils";
import React from "react";
import OrderSteps from "../OrderSteps/OrderSteps";
import SubRoutes from "../Router/SubRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store/store";
import { IRoutItem } from "../Router/Router";
import { Redirect } from "react-router";
import { routes } from "../../app-constants";

export default (props: any) => {
  const selectedServiceMode = useSelector(
    (state: RootState) => state.order.orderConfiguration.selectedServiceMode
  );

  const currRoute = Utils.getCurrentRouteStep(
    props.location.pathname,
    props.routes
  );

  const allRouteNames = props.routes.map((r: IRoutItem) => r.path);

  // If the route does not match any of the defined route, return to start order page.
  if (allRouteNames.indexOf(props.location.pathname) < 0) {
    return <Redirect push to={routes.START.PATH} />;
  }

  return (
    <div className="OrderFlow row">
      <OrderSteps
        className="fixed-top"
        currStepIndex={currRoute}
        overrideSteps={[
          "Order",
          "Pay",
          "Track",
          selectedServiceMode === "HomeDelivery" ? "Deliver" : "Collect",
        ]}
      >
        <div className="nav-logo"></div>
      </OrderSteps>

      {
        //prettier-ignore
        // @ts-ignore - Project Upgrade
        props.routes.map((route, i) => (
        <SubRoutes
          key={i}
          {...Object.assign(
            {
              nextRoute:
                i < props.routes.length - 1 ? props.routes[i + 1] : null
            },
            route
          )}
        />
      ))
      }
    </div>
  );
};
