/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from "axios";
import { HmacSHA1, enc } from "crypto-js";
import {
  Store,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";
import createSagaMiddleware from "redux-saga";
import { env } from "../../app-constants";
import {
  networkConnected,
  networkDisconnected,
} from "../actions/networkStatusActions";
import { consumerReducer } from "../reducers/consumerReducer";
import { couponReducer } from "../reducers/couponReducer";
import { menuReducer } from "../reducers/menuReducer";
import { networkStatusReducer } from "../reducers/networkStatusReducer";
import { orderReducer } from "../reducers/orderReducer";
import { paymentReducer } from "../reducers/paymentReducer";
import { placeOrderReducer } from "../reducers/placeOrderReducer";
import {
  pickUpTimeSlotsReducer,
  selectedVendorReducer,
  vendorsByVenueListReducer,
} from "../reducers/vendorReducer";
import { venuesReducer } from "../reducers/venueReducer";
import rootSaga from "../sagas/rootSaga";
import utils from "../../services/utils";

declare global {
  interface Window {
    // See <https://github.com/zalmoxisus/redux-devtools-extension>.
    readonly __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R;
  }
}

export const rootReducer = combineReducers({
  networkStatus: networkStatusReducer,
  venues: venuesReducer,
  vendorsByVenue: vendorsByVenueListReducer,
  selectedVendor: selectedVendorReducer,
  pickupTimeSlotsByVendor: pickUpTimeSlotsReducer,
  menu: menuReducer,
  order: orderReducer,
  consumer: consumerReducer,
  placedOrder: placeOrderReducer,
  coupon: couponReducer,
  payment: paymentReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface IStoreFactory {
  getStore: () => Store<any>;
  createStore: (initialStore: any) => IStoreFactory;
}

// Setup for the Redux DevTools Extension. See
// <https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup>.
const composeEnhancers = utils.isNil(
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
)
  ? compose
  : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const storeFactory = (): IStoreFactory => {
  let store: Store<any>;
  return {
    getStore: (): Store<any> => store,
    createStore(initialStore: any): IStoreFactory {
      const sagaMiddleware = createSagaMiddleware();
      store = createStore(
        rootReducer,
        initialStore,
        composeEnhancers && composeEnhancers(applyMiddleware(sagaMiddleware))
      );
      sagaMiddleware.run(rootSaga);
      return this;
    },
  };
};

const store = storeFactory();

function crypto() {
  return {
    encodeHmac(content: any, key: any) {
      try {
        return enc.Base64.stringify(HmacSHA1(content, key));
      } catch (e) {
        return "";
      }
    },
  };
}

function getContentType(config: any) {
  // Overrides the Content-Type for GET requests, this is needed to create a proper hmac
  return config.method.toUpperCase() === "POST"
    ? "Content-Type: " + (config.headers["Content-Type"] || "")
    : "Content-Type: ";
}

function synchronisedTime() {
  let timeDelta = 0;

  return {
    getCurrentTime() {
      return new Date().getTime() - timeDelta;
    },
    updateFromServerTime(serverTime: number) {
      timeDelta = new Date().getTime() - serverTime;
    },
  };
}

function hmacValue(parts: any, key: any) {
  // @ts-ignore
  const toHash = parts.reduce((acc, curr) => {
    return acc + (curr || "");
  }, "");
  return crypto().encodeHmac(toHash, key);
}

axios.interceptors.request.use(
  // TODO introduce runtime validation
  async (config) => {
    // @ts-ignore
    if (config.headers.Authorisation === "") {
      const { data: consumerData } = store.getStore().getState().consumer;

      // @ts-ignore
      config.headers["Timestamp"] = synchronisedTime().getCurrentTime();
      const hmac = hmacValue(
        [
          config.url?.replace(env.REACT_APP_SERVER_URL || "", "").split("?")[0],
          config.method?.toUpperCase(),
          JSON.stringify(config.data),
          getContentType(config),
          // @ts-ignore
          "Timestamp: " + config.headers["Timestamp"],
        ],
        consumerData.hmacKey
      );
      // @ts-ignore
      config.headers["Authorisation"] = consumerData.id + ":" + hmac;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  // @ts-ignore - Project Upgrade
  (data) => {
    errorService.resetNetworkError();
    return data;
  },
  (error: any) => {
    errorService.handleRestResponseError(error);
    return Promise.reject(error);
  }
);

export interface IErrorServiceService {
  handleRestResponseError: (error: any) => void;
  resetNetworkError: () => void;
}

export const errorService: IErrorServiceService = {
  handleRestResponseError(error: any) {
    if (error.message === "Network Error") {
      //@ts-ignore - Project Upgrade
      store.getStore().dispatch(networkDisconnected(error));
    } else {
      this.resetNetworkError();
    }
  },

  resetNetworkError() {
    store.getStore().dispatch(networkConnected());
  },
};

export default store;
