/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { env, routes } from "../../app-constants";
import { orderFinishedStatuses } from "../../app-constants/order";
import ConfirmOrderButton from "../../components/Button/ConfirmOrderButton/ConfirmOrderButton";
import Footer from "../../components/Footer/Footer";
import MenuListWithOptions from "../../components/MenuListWithOptions/MenuListWithOptions";
import Nav from "../../components/Nav/Nav";
import { isSessionExpired } from "../../components/OrderSession/OrderSession";
import withData from "../../highOrderComponents/withData";
import menuService from "../../services/menuService";
import utils from "../../services/utils";
import vendorService from "../../services/vendorService";
import {
  orderActions,
  resetOrderAction,
  vendorActions,
} from "../../state/actions";
import {
  discoveredVendors,
  selectedVendor,
} from "../../state/selectors/vendor";
import { RootState } from "../../state/store/store";
import { Menu, MenuState } from "../../state/types/menuTypes";
import { Vendor } from "../../types";
import { SelectedVendorState } from "../../state/types/vendorTypes";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { confirmVendor } from "../../state/actions/orderActions";
import Asset from "../../components/Asset/Asset";

const ConfirmVendorModal = ({
  show,
  hide,
  selectedVendor,
}: {
  show: boolean;
  hide: () => void;
  selectedVendor: SelectedVendorState;
}) => (
  <Modal isOpen={show}>
    <ModalHeader style={{ display: "block", textAlign: "center" }}>
      {selectedVendor.data.displayAssets?.thumbnailAssetId !== undefined ? (
        <Asset
          thumbnailAssetId={selectedVendor.data.displayAssets?.thumbnailAssetId}
        />
      ) : (
        <></>
      )}
      <p style={{ marginTop: "10px" }}>
        Ordering from
        <br />
        <span className="highlighted">{`${
          selectedVendor
            ? selectedVendor.data.storeName
            : env.REACT_APP_VENUE_NAME
        }`}</span>
      </p>
    </ModalHeader>
    <ModalBody>
      <p>
        You are ordering from our{" "}
        <span className="highlighted">{`${
          selectedVendor
            ? selectedVendor.data.storeName
            : env.REACT_APP_VENUE_NAME
        }`}</span>{" "}
        store which is located at{" "}
        <span className="highlighted">{`${selectedVendor.data.location}`}</span>
        .
      </p>
    </ModalBody>
    <ModalFooter>
      <Link
        className="btn btn-primary btn-secondary"
        to={routes.ORDER_FLOW.DISCOVER_VENDOR.PATH}
      >
        Choose another store
      </Link>
      <button className="btn btn-primary" id="confirm-store" onClick={hide}>
        Confirm Store
      </button>
    </ModalFooter>
  </Modal>
);

/**
 * Responsible rendering the menu list (place order page).
 */
const PlaceOrder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [autoSelectedVendor, setAutoSelectedVendor] = useState(false);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const store = useSelector((state: RootState) => state);
  const selectedVendorState = useSelector(
    (state: RootState) => state.selectedVendor
  );
  const order = useSelector((state: RootState) => state.order);
  const placedOrder = useSelector((state: RootState) => state.placedOrder);
  const menuState = useSelector((state: RootState) => state.menu);
  const vendorsByVenue = useSelector(
    (state: RootState) => state.vendorsByVenue
  );
  const venues = useSelector((state: RootState) => state.venues.list);
  const queryParams = useLocation().search;
  const vendors = discoveredVendors(venues, vendorsByVenue.vendors).filter(
    (f) => !!f && (f.nextServiceTime !== undefined || f.isOpen === true)
  );
  const parsedQueryParams = queryString.parse(queryParams) as {
    vendorId: string;
  };
  const selectedVenue = useSelector(
    (state: RootState) => state.selectedVendor.data.venueId
  );

  useEffect(() => {
    dispatch(vendorActions.loadRequiredVenuesAndVendors(selectedVenue));
  }, [dispatch, selectedVenue]);

  useEffect(() => {
    if (
      parsedQueryParams.vendorId !== undefined &&
      env.REACT_APP_MULTI_VENDOR_SUPPORT
    ) {
      const vendorToLoad = vendors.find(
        (vendor: Vendor) =>
          vendor.id === parseInt(parsedQueryParams.vendorId, 10)
      );

      if (vendorToLoad !== undefined && !autoSelectedVendor) {
        setAutoSelectedVendor(true);
        if (
          isSessionExpired(order) ||
          // @ts-ignore - Project Upgrade
          orderFinishedStatuses.includes(placedOrder.data.orderStatus)
        ) {
          dispatch(resetOrderAction(true));
        }
        dispatch(
          vendorActions.selectVendor(
            vendorToLoad?.id,
            vendorToLoad?.venueId,
            vendorToLoad?.storeName,
            vendorToLoad?.phoneNumber,
            vendorToLoad?.locationDescription,
            vendorToLoad?.vendorDisplayAssets
          )
        );
      }
    }
  }, [
    dispatch,
    parsedQueryParams,
    vendors,
    autoSelectedVendor,
    order,
    placedOrder,
  ]);

  useEffect(() => {
    if (selectedVendorState.data.id !== 0 && !menuLoaded) {
      setMenuLoaded(true);
      dispatch(
        vendorActions.loadVendorAndMenu(
          selectedVendorState.data.venueId,
          selectedVendorState.data.id
        )
      );

      // Clean up vendor id in query parameter since it only used for QR code scan and direct patron to the right vendor menu.
      const updatedQueryParams = utils.removeQueryParameter(
        "vendorId",
        queryParams
      );

      history.push({ search: updatedQueryParams });
    }

    // Records the order session start if it is not set, so that we can direct patron back to start page if session
    // expired in order to prevent unexpected error due to invalid menu item or scheduled pickup time.
    if (utils.isNil(order?.orderSessionStartTime)) {
      dispatch(
        orderActions.startOrderSession(
          moment().valueOf(),
          selectedVendorState.data.id
        )
      );
    }
  }, [
    dispatch,
    selectedVendorState,
    order,
    menuLoaded,
    queryParams,
    history,
    parsedQueryParams,
  ]);

  const getGoupedMenuItems = (menu: Menu) => {
    if (menu) {
      const menuItems = menuService.retrieveMenuItems(menu.items);
      return (
        menuService
          .groupAndMapMenuItems(menuItems)
          // @ts-ignore - Project Upgrade
          .filter((item) => item.visible)
      );
    }
    return [];
  };

  const renderMenuList = (menu: MenuState) => {
    const groupedMenuItems = getGoupedMenuItems(
      menuState.menuByVendor[selectedVendorState.data.id]
    );
    return (
      <MenuListWithOptions
        store={store}
        dispatchAction={dispatch}
        menu={menu.menuByVendor[selectedVendorState.data.id]}
        groupedMenuItems={groupedMenuItems}
        userOrder={order.userOrder}
      />
    );
  };

  const vendor = selectedVendor(vendorsByVenue, selectedVendorState);

  const isDigitalStoreOpen = vendorService.isDigitalStoreOpen(vendor, order);

  const selectedServiceMode = order.orderConfiguration.selectedServiceMode;

  const deliveryAddress = order.deliveryAddress;

  const totalOrderAmount = Object.values(order.userOrder).reduce(
    (sum: number, item: any) => {
      // Each item is either constructed as { default: {...} } or { "itemId--modifierId": {} }
      const itemValues: any[] = Object.values(item);

      const itemTotalPrice = itemValues.reduce(
        (valueSum: number, itemValue) => {
          const modifierPriceTotal = itemValue.modifiers
            ? itemValue.modifiers.reduce(
                (modifierSum: number, modifier: any) => {
                  return modifierSum + modifier.price.amount * itemValue.count;
                },
                0
              )
            : 0;

          return (
            valueSum +
            itemValue.price.amount * itemValue.count +
            modifierPriceTotal
          );
        },
        0
      );

      return sum + itemTotalPrice;
    },
    0
  );

  const pickedTime = order.pickedTime;

  const requireMinOrderAmount = (currentVendor?: Vendor) => {
    if (
      currentVendor &&
      currentVendor.vendorOrderConfiguration?.deliveryMinAmount
    ) {
      return (
        selectedServiceMode === "HomeDelivery" &&
        totalOrderAmount <
          currentVendor.vendorOrderConfiguration?.deliveryMinAmount.amount
      );
    }

    return false;
  };

  return (
    <div className="PlaceOrder col-md-8 offset-md-2">
      <ConfirmVendorModal
        show={!order.vendorConfirmed}
        hide={() => dispatch(confirmVendor())}
        selectedVendor={selectedVendorState}
      />
      {selectedServiceMode === "HomeDelivery" ? (
        <Nav
          className="nav-header"
          goTo={routes.ORDER_FLOW.DELIVERY.PATH}
          title={env.REACT_APP_VENUE_NAME}
        />
      ) : (
        <Nav
          className="nav-header"
          goTo={
            env.REACT_APP_MULTI_VENDOR_SUPPORT
              ? routes.ORDER_FLOW.DISCOVER_VENDOR.PATH
              : routes.START.PATH
          }
          title={vendor ? vendor.storeName : env.REACT_APP_VENUE_NAME}
        />
      )}
      {deliveryAddress && selectedServiceMode === "HomeDelivery" ? (
        <div className="delivery-address-section">
          Deliver To: {deliveryAddress.addressDescription}
        </div>
      ) : (
        <></>
      )}
      {pickedTime ? (
        <div className="delivery-address-section">
          Preferred{" "}
          {selectedServiceMode === "HomeDelivery" ? "Deliver" : "Pickup"} Time{" "}
          {moment(pickedTime).format("h:mm a dddd")}
        </div>
      ) : (
        <></>
      )}
      <div className="menu-list-container">{renderMenuList(menuState)}</div>
      <div className="fixed-btn-row">
        <div className="confirm-order">
          {requireMinOrderAmount(vendor) &&
          vendor?.vendorOrderConfiguration?.deliveryMinAmount ? (
            <button className="btn btn-primary btn-note" disabled>
              Minimum Delivery Order Amount $
              {vendor?.vendorOrderConfiguration?.deliveryMinAmount.amount.toFixed(
                2
              )}
            </button>
          ) : (
            <ConfirmOrderButton
              confirmText="Review Order"
              goTo={routes.ORDER_FLOW.CONFIRM_ORDER.PATH}
              isDigitalStoreOpen={isDigitalStoreOpen}
              hasSelectedItems={Object.keys(order.userOrder).length}
              vendor={vendor}
            />
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default withData()(PlaceOrder);
