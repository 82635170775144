import React from "react";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";

export interface IRefundDataPolicyProps {
  className?: string;
  rejectRedirectTo: string;
  content: React.ComponentType<any>;
}

export interface IRefundDataPolicyState {
  showModal: boolean;
}

export default class RefundDataPolicy extends React.Component<
  IRefundDataPolicyProps,
  IRefundDataPolicyState
> {
  constructor(props: any) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = { showModal: false };
  }

  toggleModal() {
    this.setState((prevState: IRefundDataPolicyState) => ({
      showModal: !prevState.showModal,
    }));
  }

  getModalActions() {
    return (
      <>
        <Link
          to={this.props.rejectRedirectTo}
          className="btn btn-secondary"
          onClick={this.toggleModal}
        >
          Reject
        </Link>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.toggleModal}
        >
          <i className="far fa-thumbs-up"></i> Accept
        </button>
      </>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        Please review the&nbsp;
        <button
          className="btn btn-link"
          type="button"
          onClick={this.toggleModal}
        >
          refund and data policy
        </button>
        &nbsp;before payment
        <Modal
          isOpen={this.state.showModal}
          className="refund"
          title="Refund and Data Policy"
          content={<this.props.content />}
          actions={this.getModalActions()}
          // @ts-ignore - Project Upgrade
          container={document.getElementById("modal-container")}
        />
      </div>
    );
  }
}
