import React from "react";
import { RootState } from "../../state/store/store";
import CouponModal from "./CouponModal";
import NewCardForm, { INewCardFormState } from "./NewCardForm";
import SavedCardForm from "./SavedCardForm";

export interface ICardFormData {
  isRememberCardChecked: boolean;
  nameOncard: string;
  cardNumber: string;
  cvn: string;
  expiryMonth: string;
  expiryYear: string;
}

export interface IPayCardFormProps {
  store: RootState;
  isFormValid: (formData: INewCardFormState, isValid: boolean) => void;
  initiatedPayment: any;
  validateCoupon?: (couponCode: string) => void;
  enableCoupon?: boolean;
}

export interface IPaymentCarFormState {
  showNewCardForm: boolean;
  showCouponModal: boolean;
  hasCouponCode: boolean;
}

export default class PaymentCarForm extends React.Component<
  IPayCardFormProps,
  IPaymentCarFormState
> {
  constructor(props: any) {
    super(props);
    this.toggleCardForm = this.toggleCardForm.bind(this);
    this.toggleCouponModal = this.toggleCouponModal.bind(this);
    this.clearCoupon = this.clearCoupon.bind(this);
    this.validateCoupon = this.validateCoupon.bind(this);
    this.state = {
      showNewCardForm: true,
      showCouponModal: false,
      hasCouponCode: false,
    };
  }

  get isPaymentInitiated(): boolean {
    return Object.keys(this.props.initiatedPayment).length > 0;
  }

  componentWillReceiveProps(nextProps: IPayCardFormProps) {
    if (
      nextProps.initiatedPayment.savedPaymentMethod &&
      !this.isPaymentInitiated
    ) {
      this.setState({ showNewCardForm: false });
    }
  }

  toggleCardForm() {
    this.setState((prevState: IPaymentCarFormState) => {
      return {
        showNewCardForm: !prevState.showNewCardForm,
      };
    });
  }

  toggleCouponModal() {
    this.setState((prev: IPaymentCarFormState) => ({
      showCouponModal: !prev.showCouponModal,
    }));
  }

  renderCouponButton() {
    if (this.props.store && this.props.store.order.appliedCoupon) {
      return (
        <button
          onClick={this.clearCoupon}
          className="btn btn-default toggle-coupon-modal remove"
        >
          Remove Coupon
        </button>
      );
    }

    return (
      <button
        onClick={this.toggleCouponModal}
        className="btn btn-default toggle-coupon-modal"
      >
        Enter Coupon Code
      </button>
    );
  }

  clearCoupon() {
    // @ts-ignore - Project Upgrade
    this.props.validateCoupon("");
  }

  validateCoupon(couponCode: string) {
    // @ts-ignore - Project Upgrade
    this.props.validateCoupon(couponCode);
    this.setState({ hasCouponCode: couponCode !== "" });
  }

  render() {
    const { initiatedPayment, validateCoupon } = this.props;
    if (!Object.keys(initiatedPayment).length) {
      return null;
    }
    return (
      <React.Fragment>
        {this.props.enableCoupon && this.renderCouponButton()}
        {!this.state.showNewCardForm ? (
          <SavedCardForm
            isFormValid={this.props.isFormValid}
            savedCardData={initiatedPayment.savedPaymentMethod}
          />
        ) : (
          <NewCardForm isFormValid={this.props.isFormValid} />
        )}
        {validateCoupon && (
          <>
            <CouponModal
              showModal={this.state.showCouponModal}
              toggleModal={this.toggleCouponModal}
              validateCoupon={this.validateCoupon}
            />
          </>
        )}
        {initiatedPayment.savedPaymentMethod && (
          <button
            onClick={this.toggleCardForm}
            className="btn border-primary toggle-card"
          >
            {this.state.showNewCardForm
              ? "Or, use saved card"
              : "Or, change card"}
          </button>
        )}
      </React.Fragment>
    );
  }
}
