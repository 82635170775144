/* eslint-disable @typescript-eslint/ban-types */
import { FETCH_STATUS } from "../actions";
import { OrderAttributes, OrderState } from "./orderTypes";

export const PLACE_ORDER = "PLACE_ORDER";
export const PLACE_ORDER_ERROR = "PLACE_ORDER_ERROR";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PURCHASE_WITH_COUPON = "PURCHASE_WITH_COUPON";
export const PURCHASE_WITH_COUPON_SUCCESS = "PURCHASE_WITH_COUPON_SUCCESS";
export const PURCHASE_WITH_COUPON_ERROR = "PURCHASE_WITH_COUPON_ERROR";
export const LOAD_ORDER = "LOAD_ORDER";
export const LOAD_ORDER_SUCCESS = "LOAD_ORDER_SUCCESS";
export const LOAD_ORDER_ERROR = "LOAD_ORDER_ERROR";
export const WAIT_ORDER_RESOLVED = "WAIT_ORDER_RESOLVED";
export const ORDER_RESOLVED_SUCCESS = "ORDER_RESOLVED_SUCCESS";
export const ORDER_RESOLVED_ERROR = "ORDER_RESOLVED_ERROR";
export const WAIT_STATUS = "WAIT_STATUS";
export const ALERT_ORDER = "ALERT_ORDER";
export const ALERT_ORDER_SUCCESS = "ALERT_ORDER_SUCCESS";
export const ALERT_ORDER_ERROR = "ALERT_ORDER_ERROR";
export const RESET_ORDER_STATE = "RESET_ORDER_STATE";
export const PLACE_ORDER_WITH_APPLE_PAY = "PLACE_ORDER_WITH_APPLE_PAY";
export const CONFIRM_VENDOR = "CONFIRM_VENDOR";
export const CLEAR_PLACED_ORDER = "CLEAR_PLACED_ORDER";

export interface PlaceOrderAction {
  type: typeof PLACE_ORDER;
  payload: {
    order: OrderState;
    vendorId: number;
    menuId: number;
    attributes: OrderAttributes;
  };
}

export interface PlaceOrderErrorAction {
  type: typeof PLACE_ORDER_ERROR;
  payload: { error: unknown };
}

export interface PlaceOrderSuccessAction {
  type: typeof PLACE_ORDER_SUCCESS;
  payload: { placedOrder: unknown };
}

export interface PurchaseWithCouponAction {
  type: typeof PURCHASE_WITH_COUPON;
  payload: {
    verification: string;
    orderId: number;
    vendorId: number;
    couponData: unknown;
  };
}

export interface PurchaseWithCouponSuccessAction {
  type: typeof PURCHASE_WITH_COUPON_SUCCESS;
  payload: {
    order: unknown;
  };
}

export interface PurchaseWithCouponErrorAction {
  type: typeof PURCHASE_WITH_COUPON_ERROR;
  payload: { error: unknown };
}

export interface LoadOrderAction {
  type: typeof LOAD_ORDER;
  payload: { vendorId: number; orderId: number };
}

export interface LoadOrderSuccessAction {
  type: typeof LOAD_ORDER_SUCCESS;
  payload: { order: unknown };
}

export interface LoadOrderErrorAction {
  type: typeof LOAD_ORDER_ERROR;
  payload: { error: unknown };
}

export interface WaitOrderResolvedAction {
  type: typeof WAIT_ORDER_RESOLVED;
  payload: {
    vendorId: number;
    orderId: number;
  };
}

export interface OrderResolvedSuccessAction {
  type: typeof ORDER_RESOLVED_SUCCESS;
  payload: { order: unknown };
}

export interface OrderResolvedErrorAction {
  type: typeof ORDER_RESOLVED_ERROR;
  payload: { error: unknown };
}

export interface WaitStatusAction {
  type: typeof WAIT_STATUS;
  payload: {
    vendorId: number;
    orderId: number;
    status: ReadonlyArray<string>;
  };
}

export interface AlertOrderAction {
  type: typeof ALERT_ORDER;
  payload: {
    orderId: number;
    vendorId: number;
  };
}

export interface AlertOrderSuccessAction {
  type: typeof ALERT_ORDER_SUCCESS;
  payload: { order: unknown };
}

export interface AlertOrderErrorAction {
  type: typeof ALERT_ORDER_ERROR;
  payload: { error: unknown };
}

export interface ResetStateAction {
  type: typeof RESET_ORDER_STATE;
  payload: {};
}

export interface PlaceOrderWithApplePayAction {
  type: typeof PLACE_ORDER_WITH_APPLE_PAY;
  payload: {
    order: OrderState;
    vendorId: number;
    menuId: number;
    attributes: OrderAttributes;
  };
}

export interface ConfirmVendorAction {
  type: typeof CONFIRM_VENDOR;
  payload: {};
}

/**
 * Action to clear the placed order in the cache.
 * This is used whenever we need to bust the cached placed order and recreate a Pending order.
 */
export interface ClearPlacedOrderAction {
  type: typeof CLEAR_PLACED_ORDER;
  payload: {};
}

export type PlaceOrderActionType =
  | PlaceOrderAction
  | PlaceOrderErrorAction
  | PlaceOrderSuccessAction
  | PurchaseWithCouponAction
  | PurchaseWithCouponSuccessAction
  | PurchaseWithCouponErrorAction
  | LoadOrderAction
  | LoadOrderSuccessAction
  | LoadOrderErrorAction
  | WaitOrderResolvedAction
  | OrderResolvedSuccessAction
  | OrderResolvedErrorAction
  | WaitStatusAction
  | AlertOrderAction
  | AlertOrderSuccessAction
  | AlertOrderErrorAction
  | ResetStateAction
  | PlaceOrderWithApplePayAction
  | ConfirmVendorAction
  | ClearPlacedOrderAction;

export interface PlaceOrderState {
  // TODO add runtime types
  data: object;
  fetchStatus: FETCH_STATUS;
  error: unknown;
}
