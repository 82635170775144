/* eslint-disable @typescript-eslint/ban-ts-comment */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import couponService from "../../services/couponService";
import orderService from "../../services/orderService";
import { RootState } from "../store/store";
import {
  APPLYING_COUPON,
  CALCULATE_ORDER_AMOUNT,
  FetchingOrderAmountAction,
  FETCHING_ORDER_AMOUNT,
  FETCH_ORDER_AMOUNT_ERROR,
  FETCH_ORDER_AMOUNT_SUCCESS,
  ORDER_COUPON_APPLY_FAILED,
  ORDER_COUPON_APPLY_SUCCESS,
  ReorderLastOrderAction,
  REORDER_LAST_ORDER,
  REORDER_SUCCESS,
  SetOrderCouponAction,
  SET_ORDER_COUPON,
} from "../types/orderTypes";
import { RESET_ORDER_STATE } from "../types/placeOrderTypes";

export function* getOrderAmount({ payload }: FetchingOrderAmountAction) {
  try {
    // @ts-ignore
    const selectedServiceMode = yield select(
      (state: RootState) => state.order.orderConfiguration.selectedServiceMode
    );

    yield put({ type: FETCHING_ORDER_AMOUNT });
    const { result } = yield call(
      //@ts-ignore - Project Upgrade
      orderService.calculateOrderAmount,
      payload.vendorId,
      payload.order,
      undefined,
      selectedServiceMode
    );

    yield put({
      type: FETCH_ORDER_AMOUNT_SUCCESS,
      payload: { paymentSummary: result },
    });
  } catch (error) {
    yield put({
      type: FETCH_ORDER_AMOUNT_ERROR,
      payload: error,
    });
  }
}

export function* reorder({ payload }: ReorderLastOrderAction) {
  try {
    const { items, modifiers, modifierGroups } = yield select(
      (state: RootState) => state.menu.menuByVendor[payload.vendorId]
    );
    // @ts-ignore
    const lastOrder = yield select((state: RootState) => state.order.lastOrder);

    const availableItemIds = items
      // @ts-ignore
      .filter((i) => i["visible"] && i["currentInventory"] > 0)
      // @ts-ignore
      .map((i) => i["id"]);

    const availableModifierIds = modifiers
      // @ts-ignore - Project Upgrade
      .filter((i) => i["visible"])
      // @ts-ignore - Project Upgrade
      .map((i) => i["id"]);

    const availableModifierGroupIds = modifierGroups
      // @ts-ignore - Project Upgrade
      .filter((i) => i["visible"])
      // @ts-ignore - Project Upgrade
      .map((i) => i["id"]);

    const availableLastOrder = Object.keys(lastOrder).reduce(
      (acc, itemName) => {
        const oi = lastOrder[itemName];

        // Order item is in format of { [itemName]: {[modifier-ids]: item } }.
        // Use the first key to determine the id of the menu item.
        const orderItemMenuItemId = oi[Object.keys(oi)[0]].id;

        if (availableItemIds.includes(orderItemMenuItemId)) {
          return {
            ...acc,
            [itemName]: oi,
          };
        }

        return acc;
      },
      {}
    );

    const orderToReorder = {};

    Object.keys(availableLastOrder).forEach((itemName: string) => {
      // @ts-ignore
      const orderItem = availableLastOrder[itemName];
      const validOrderItem = {};

      Object.keys(orderItem).forEach((k: string) => {
        const modifiers = orderItem[k]["modifiers"];
        const availableModifiers = modifiers.filter(
          // @ts-ignore
          (m) =>
            // @ts-ignore - Project Upgrade
            availableModifierIds.includes(m["id"]) &&
            // @ts-ignore - Project Upgrade
            availableModifierGroupIds.includes(m["groupId"])
        );
        // @ts-ignore - Project Upgrade
        validOrderItem[k] = { ...orderItem[k], modifiers: availableModifiers };
      });

      // @ts-ignore - Project Upgrade
      orderToReorder[itemName] = validOrderItem;
    });

    yield put({
      type: REORDER_SUCCESS,
      payload: { lastOrder: orderToReorder },
    });
  } catch (error) {
    // In case of unexpected error during re-order, reset saved last order and entire app state to start over clean.
    yield put({ type: RESET_ORDER_STATE, payload: { resetLastOrder: true } });
  }
}

export function* setOrderCoupon({ payload }: SetOrderCouponAction) {
  try {
    yield put({
      type: APPLYING_COUPON,
      payload: { couponCode: payload.couponCode },
    });
    const { result: updatedOrder } = yield call(
      couponService.setOrderCoupon,
      payload.orderId,
      payload.couponCode
    );

    const { result: updatedInvoice } = yield call(
      couponService.generateOrderInvoice,
      payload.orderId
    );

    if ((updatedOrder.maybeCouponCode || "") === payload.couponCode) {
      yield put({
        type: ORDER_COUPON_APPLY_SUCCESS,
        payload: {
          updatedInvoice,
          appliedCoupon: updatedOrder.maybeCouponCode,
        },
      });
    } else {
      yield put({
        type: ORDER_COUPON_APPLY_FAILED,
        payload: "Invalid coupon code.",
      });
    }
  } catch (error) {
    // TODO: User does not have understanding that the failure is not due to them
    yield put({
      type: ORDER_COUPON_APPLY_FAILED,
      payload: "Invalid coupon code.",
    });
  }
}

export function* orderRootSaga() {
  yield all([
    takeEvery(CALCULATE_ORDER_AMOUNT, getOrderAmount),
    takeEvery(SET_ORDER_COUPON, setOrderCoupon),
    takeEvery(REORDER_LAST_ORDER, reorder),
  ]);
}
