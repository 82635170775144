import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import React from "react";
import { DeliveryAddress, GoogleAddressPrediction } from "../../types";
import googleLogo from "../../static/images/powered_by_google_on_white.png";

const addressPredictionBiasingRadiusInMeters = 100 * 1000;

// This is used for Google address auto complete biasing base, currently set to centre of Canberra.
const addressPredictionBiasCenterLocation = {
  lat: -35.2813043,
  lng: 149.1204446,
};

const autoCompletionRequest = {
  componentRestrictions: {
    country: "au",
  },
  types: ["address"] as [string],
  location: addressPredictionBiasCenterLocation,
  radius: addressPredictionBiasingRadiusInMeters,
};

/**
 * The text field for the user to enter their delivery address. Autocompletes the address using the
 * Google Maps API.
 *
 * @param addressDescription The user's stored address.
 * @param handleAddressSelected Called when the user selects their address from the autocomplete
 *                              dropdown.
 * @param clearAddress Called when the user clicks the button to clear the address.
 * @param selectedDeliveryAddress The currently selected address, if any.
 */
export const AddressInput = ({
  addressDescription,
  handleAddressSelected,
  clearAddress,
  selectedDeliveryAddress,
}: {
  addressDescription: string;
  handleAddressSelected: (
    addressPrediction: GoogleAddressPrediction
  ) => Promise<void>;
  clearAddress: () => void;
  selectedDeliveryAddress: DeliveryAddress | undefined;
}): JSX.Element => (
  <GooglePlacesAutocomplete
    initialValue={addressDescription}
    debounce={1000}
    withSessionToken={true}
    placeholder="Enter Delivery Address"
    required={true}
    onSelect={handleAddressSelected}
    autocompletionRequest={autoCompletionRequest}
    renderInput={(props: any) => (
      <div className="address-input">
        <button className="btn btn-link" onClick={clearAddress}>
          X Clear
        </button>
        {selectedDeliveryAddress ? (
          <textarea className="form-control" {...props} disabled />
        ) : (
          <textarea className="form-control" {...props} />
        )}
      </div>
    )}
    renderSuggestions={(_active, suggestions, onSelectSuggestion) => (
      <div className="google-places-autocomplete__suggestions-container">
        {suggestions.map((suggestion) => (
          <div
            className="google-places-autocomplete__suggestion"
            key={suggestion.place_id}
            onClick={(event) => onSelectSuggestion(suggestion, event)}
          >
            {suggestion.description}
          </div>
        ))}
        {suggestions.length > 0 ? (
          <div className="google-logo">
            <img src={googleLogo} alt="Powered By Google" />
          </div>
        ) : (
          <></>
        )}
      </div>
    )}
  />
);
