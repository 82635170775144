import React from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";

export interface IDiscussOrderModalProps {
  showModal: boolean;
}

export default (props: IDiscussOrderModalProps) => (
  // @ts-ignore - Project Upgrade
  <Modal
    className="alert-modal order-cancelled"
    isOpen={props.showModal}
    title="Temporary problem!"
    content={
      <React.Fragment>
        <p>We need to discuss your order with you at the shop.</p>
        <p>Press 'Get Help' to see directions to the order pickup point.</p>
      </React.Fragment>
    }
    actions={
      <Link to={"/order-flow/alert-order"} className="btn btn-primary">
        <span>Get Help</span>
      </Link>
    }
  ></Modal>
);
