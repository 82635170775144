/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { IMenuItemRowProps } from "../components/MenuListWithOptions/MenuItemRow";
import MenuMaxItemsAlertModal from "../components/Modal/MenuMaxItemsAlertModal";
import MenuRestrictedItemsModal from "../components/Modal/MenuRestrictedItemsModal";
import MenuSoldOutModal from "../components/Modal/MenuSoldOutModal";
import CONSTANTS from "../constants/generalConstants";
import menuService from "../services/menuService";
import {
  addItemToOrder,
  removeSoldOutItems,
} from "../state/actions/orderActions";
import { RootState } from "../state/store/store";
import { MenuItem } from "../state/types/menuTypes";

export interface IAddItemToOrderProps {
  store: RootState;
  dispatchAction: Function;
  menuItem: MenuItem;
}

export interface IAddItemToOrderState {
  showMaxItemsAlertModal: boolean;
  showSoldOutModal: boolean;
  showRestrictedItemsModal: boolean;
  soldOutItem: ReadonlyArray<MenuItem>;
}

export const addItemToOrderFactory = (
  WrappedComponent: React.FunctionComponent<IMenuItemRowProps>
) => {
  return class AddItemToOrder extends React.Component<
    IAddItemToOrderProps,
    IAddItemToOrderState
  > {
    constructor(props: IAddItemToOrderProps) {
      super(props);
      this.addItemToOrder = this.addItemToOrder.bind(this);
      this.closeMaxItemsAlertModal = this.closeMaxItemsAlertModal.bind(this);
      this.closeSoldOutModal = this.closeSoldOutModal.bind(this);
      this.closeRestrictedItemsModal =
        this.closeRestrictedItemsModal.bind(this);
      this.state = {
        showMaxItemsAlertModal: false,
        showSoldOutModal: false,
        showRestrictedItemsModal: false,
        soldOutItem: [],
      };
    }

    get vendor() {
      return (this.props.store.vendorsByVenue.vendors[this.venueId] || []).find(
        (item) => item.id === this.vendorId
      );
    }

    get venueId(): number {
      try {
        return this.props.store.selectedVendor.data.venueId;
      } catch (error) {
        console.error("An venue should be selected");
        return 0;
      }
    }

    get vendorId(): number {
      try {
        return (
          //@ts-ignore - QFXFB-841
          this.props.store.placedOrder.data.vendorId ||
          this.props.store.selectedVendor.data.id
        );
      } catch (error) {
        console.error("An order should be configured or a vendor selected");
        return 0;
      }
    }

    get menuByVendor() {
      return this.props.store.menu.menuByVendor[this.vendorId];
    }

    isCurrentBurdenGreaterEqualMax(): boolean {
      // @ts-ignore - Project Upgrade
      if (this.vendor.vendorOrderConfiguration) {
        return (
          this.props.store.order.orderBurden >=
          // @ts-ignore - Project Upgrade
          this.vendor.vendorOrderConfiguration.maxBurdenPerOrder
        );
      }
      return false;
    }

    isCurrentItemBurdenGreaterEqualMax(item: MenuItem): boolean {
      // @ts-ignore - Project Upgrade
      if (this.vendor.vendorOrderConfiguration) {
        return (
          this.props.store.order.orderBurden + item.burden >
          // @ts-ignore - Project Upgrade
          this.vendor.vendorOrderConfiguration.maxBurdenPerOrder
        );
      }
      return false;
    }

    addItemToOrder(item: MenuItem, option?: string) {
      const count = menuService.countMenuItem(
        this.props.store.order.userOrder,
        item,
        option
      );
      // Burden validation
      if (
        this.isCurrentBurdenGreaterEqualMax() ||
        this.isCurrentItemBurdenGreaterEqualMax(item)
      ) {
        this.setState({ showMaxItemsAlertModal: true });
        return;
      }
      // Sold out items validation
      if (count >= item.currentInventory) {
        this.setState({
          showSoldOutModal: true,
          soldOutItem: [item],
        });
        return;
      }
      // Restricted Item validation
      if (
        item.isRestricted &&
        menuService.countRestrictedItems(this.props.store.order.userOrder) >=
          CONSTANTS.MAX_RESTRICTED_ITEMS
      ) {
        this.setState({ showRestrictedItemsModal: true });
        return;
      }
      if (count < CONSTANTS.MAX_MENU_ITEM_COUNT) {
        this.props.dispatchAction(addItemToOrder(item, option));
      }
    }

    closeMaxItemsAlertModal() {
      this.setState({ showMaxItemsAlertModal: false });
    }

    closeSoldOutModal(event: any) {
      event.preventDefault();
      this.setState({
        showSoldOutModal: false,
        soldOutItem: [],
      });
      const soldOutItems = menuService.getSoldOutItems(
        this.props.store.order.userOrder,
        this.menuByVendor
      );
      if (soldOutItems.length > 0) {
        soldOutItems.forEach((item) => {
          this.props.dispatchAction(removeSoldOutItems(item));
        });
      }
    }

    closeRestrictedItemsModal() {
      this.setState({ showRestrictedItemsModal: false });
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            addItemToOrder={this.addItemToOrder}
            menu={this.menuByVendor}
            menuItem={this.props.menuItem}
          />
          {this.state.showMaxItemsAlertModal && (
            <MenuMaxItemsAlertModal
              isOpen={this.state.showMaxItemsAlertModal}
              onOkClick={this.closeMaxItemsAlertModal}
              title={"That's all we can handle!"}
              text={`To serve you better, ${
                this.vendor && this.vendor.storeName
              } limits order sizes.`}
            />
          )}

          {this.state.showSoldOutModal && (
            <MenuSoldOutModal
              isOpen={this.state.showSoldOutModal}
              onClick={this.closeSoldOutModal}
              soldOutItems={this.state.soldOutItem}
            />
          )}

          {this.state.showRestrictedItemsModal && (
            <MenuRestrictedItemsModal
              isOpen={this.state.showRestrictedItemsModal}
              onClick={this.closeRestrictedItemsModal}
            />
          )}
        </React.Fragment>
      );
    }
  };
};

export default (
  WrappedComponent: React.FunctionComponent<IMenuItemRowProps>
) => {
  return addItemToOrderFactory(WrappedComponent);
};
