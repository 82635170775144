const dateTimeFormatService = {
  formatDateTime(dateString: string) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  },
  formatTimestamp(dateString: string) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return new Date(month + "/" + day + "/" + year).getTime();
  },
  /**
   * Format a count down wait time in milliseconds, in hrs mins, so it is easy to read.
   * @param {number} milliseconds  - The remaining milliseconds time to format.
   * @param {string} defaultOutput - The default output that will be returned if
   *                                 the provided milliseconds is invalid or equal/less than 0.
   * @return {string}              - The formatted time in hrs and mins or default output.
   */
  formatRemainingTime(milliseconds: number, defaultOutput?: string | number) {
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    const days = Math.floor(milliseconds / (1000 * 60 * 60) / 24);

    let output = defaultOutput || "";

    if (days > 0) {
      output = days + " day " + hours + " hrs " + minutes + " mins";
    } else if (hours > 0 && minutes <= 0) {
      output = hours + " hrs";
    } else if (hours > 0 && minutes > 0) {
      output = hours + " hrs " + minutes + " mins";
    } else if (minutes > 0) {
      output = minutes + " mins";
    }
    return output;
  },
};

function factoryDateTimeFormatService(): typeof dateTimeFormatService {
  return Object.create(dateTimeFormatService);
}

// Converts the milliseconds with whole minutes, so the time will not display seconds.
// e.g. 54 minutes, 39 seconds will just display 54 minutes.
export const withWholeMinutes = (millis: number) => {
  const wholeMinutes = Math.floor(millis / 60000);
  return wholeMinutes * 60000;
};

export default factoryDateTimeFormatService();
