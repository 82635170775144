/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import withData from "../../highOrderComponents/withData";
import { placeOrder } from "../../state/actions/placeOrderAction";
import { RootState } from "../../state/store/store";
import { OrderAttributes } from "../../state/types/orderTypes";

interface ITransitionValidatorProps {
  allowRedirect: (index: number, allow: boolean) => void;
  index: number;
  dispatchAction?: Function;
  store?: RootState;
}

class TransitionValidator extends React.Component<
  ITransitionValidatorProps,
  null
> {
  isDataUpToDate = false;

  constructor(props: ITransitionValidatorProps) {
    super(props);
    this.resetValidator();
  }

  get vendorId(): number {
    try {
      return (
        this.props.store?.selectedVendor.data.id ||
        Number.parseInt(process.env.REACT_APP_VENDOR_ID || "0")
      );
    } catch (error) {
      console.error(
        "The REACT_APP_VENDOR_ID should be defined or a vendor should be selected"
      );
      return 0;
    }
  }

  resetValidator() {
    this.isDataUpToDate = false;
  }

  componentDidMount() {
    // @ts-ignore - Project Upgrade
    const menuId = this.props.store?.menu.menuByVendor[this.vendorId].id;

    // Fetch URL query parameters
    const searchParams = new URLSearchParams(window.location.search);

    const attributes: OrderAttributes = searchParams.has("campaignId")
      ? { patron: { campaignId: searchParams.get("campaignId")! } }
      : {};

    // @ts-ignore - Project Upgrade
    this.props.dispatchAction(
      // @ts-ignore - Project Upgrade
      placeOrder(this.props.store.order, this.vendorId, menuId, attributes)
    );
  }

  componentWillReceiveProps(nextProps: ITransitionValidatorProps) {
    this.isDataUpToDate =
      this.isDataUpToDate ||
      (this.props.store?.placedOrder.fetchStatus === "fetching" &&
        nextProps.store?.placedOrder.fetchStatus === "success");
  }

  componentDidUpdate() {
    if (this.props.store?.placedOrder.fetchStatus === "failed") {
      this.resetValidator();
      this.props.allowRedirect(this.props.index, false);
    } else if (this.isDataUpToDate) {
      this.props.allowRedirect(this.props.index, true);
    }
  }

  render() {
    return null;
  }
}

export default withData()(TransitionValidator) as any;
