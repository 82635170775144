import {
  CHECK_NETWORK_STATUS,
  NetworkStatusActionType,
  NETWORK_IS_CONNECTED,
  NETWORK_IS_DISCONNECTED,
} from "../types/networkStatusTypes";

export const checkNetwork = (): NetworkStatusActionType => ({
  type: CHECK_NETWORK_STATUS,
});

export const networkConnected = (): NetworkStatusActionType => ({
  type: NETWORK_IS_CONNECTED,
});

export const networkDisconnected = (): NetworkStatusActionType => ({
  type: NETWORK_IS_DISCONNECTED,
});
