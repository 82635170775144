import React from "react";
import { FormattedNumber } from "react-intl";

export interface IOrserSummaryInfoProps {
  totalPayment: any;
  subtotal: any;
  chargeItems: any[];
}

export default (props: IOrserSummaryInfoProps) => (
  <div className="OrderSummaryInfo info-box info-bottom">
    {props.chargeItems.length ? (
      <table className="payment-table">
        <tbody>
          <tr>
            <td>sub total:</td>
            <td>
              {props.subtotal.OrderItem.currencySymbol}
              <FormattedNumber
                minimumFractionDigits={2}
                value={props.subtotal.OrderItem.amount || 0}
              />
            </td>
          </tr>
          {props.chargeItems.map((item) => (
            <tr key={item.name}>
              <td>{item.name}:</td>
              <td>
                {item.price.currencySymbol}
                <FormattedNumber
                  minimumFractionDigits={2}
                  value={item.price.amount}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : null}
    <div>
      Grand Total: &nbsp;
      <strong>
        {props.totalPayment.currencySymbol}
        <FormattedNumber
          minimumFractionDigits={2}
          value={props.totalPayment.amount || 0}
        />
      </strong>
    </div>
  </div>
);
