import React from "react";
import CONSTANTS from "../../../constants/generalConstants";

export interface IPlusMinusButtonProps {
  onMinusClick: () => void;
  onPlusClick: () => void;
  count: number;
  className?: string;
}

const PlusMinusButton = (props: IPlusMinusButtonProps) => (
  <div className={`input-group ${props.className}`}>
    <div className="input-group-btn">
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          props.onMinusClick();
        }}
        className={"button-number button-minus"}
      >
        -
      </button>
    </div>
    <div className={"input-number"}>{props.count}</div>
    <div className="input-group-btn">
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          props.count < CONSTANTS.MAX_MENU_ITEM_COUNT && props.onPlusClick();
        }}
        className={"button-number button-plus"}
      >
        +
      </button>
    </div>
  </div>
);

export default PlusMinusButton;
