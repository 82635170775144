export interface IGeolocation {
  longitude: number;
  latitude: number;
}

export interface IGeoLocationService {
  captureGeolocation: () => Promise<IGeolocation>;
  getLastLocation: () => IGeolocation;
  distanceToVendor: (vendor: any) => Promise<any>;
  distanceBetweenPoints: (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => number;
}

let lastLocation: IGeolocation = {
  latitude: 0,
  longitude: 0,
};

const geoLocationService: IGeoLocationService = {
  getLastLocation() {
    return lastLocation;
  },

  captureGeolocation(): Promise<IGeolocation> {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            /* eslint-disable @typescript-eslint/no-unused-expressions */
            (lastLocation = {
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
            }),
              resolve(lastLocation);
          },
          () => {
            (lastLocation = {
              longitude: 0,
              latitude: 0,
            }),
              /* eslint-enable @typescript-eslint/no-unused-expressions */
              resolve(lastLocation);
          },
          options
        );
      }
    });
  },

  distanceToVendor(vendor): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const distance = getDistanceFromLatLonInMetres(
            position.coords.latitude,
            position.coords.longitude,
            vendor.geoLocation.latitude,
            vendor.geoLocation.longitude
          );
          resolve(distance);
        });
      }
    });
  },

  // Function not used, is exposed here for future implementations
  distanceBetweenPoints(lat1, lon1, lat2, lon2): number {
    return getDistanceFromLatLonInMetres(lat1, lon1, lat2, lon2);
  },
};

// PRIVATE FUNCTIONS
/**
 * Get the distance between two spatial points using the Haversine Formula.
 * http://en.wikipedia.org/wiki/Haversine_formula.
 *
 */
// @ts-ignore - Project Upgrade
function getDistanceFromLatLonInMetres(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in metres
  return R * c * 1000;
}

/**
 * Convert degrees to radians.
 *
 */
// @ts-ignore - Project Upgrade
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

geoLocationService.captureGeolocation();

function factoryConsumerService(): IGeoLocationService {
  return Object.create(geoLocationService);
}

export default factoryConsumerService();
