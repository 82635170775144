import { FETCH_STATUS } from "../actions";

export const COMPLETE_PAYMENT = "COMPLETE_PAYMENT";
// TODO: Duplicate with orderTypes COMPLETE_PAYMENT_SUCCESS
export const COMPLETE_PAYMENT_SUCCESS = "COMPLETE_PAYMENT_SUCCESS";
export const COMPLETE_PAYMENT_ERROR = "COMPLETE_PAYMENT_ERROR";

export interface CompletePaymentAction {
  type: typeof COMPLETE_PAYMENT;
}

export interface CompletePaymentSuccessAction {
  type: typeof COMPLETE_PAYMENT_SUCCESS;
}

export interface CompletePaymentErrorAction {
  type: typeof COMPLETE_PAYMENT_ERROR;
  payload: { error: unknown };
}

export type PaymentActionType =
  | CompletePaymentAction
  | CompletePaymentSuccessAction
  | CompletePaymentErrorAction;

export interface PaymentState {
  fetchStatus: FETCH_STATUS;
  error: unknown;
}
