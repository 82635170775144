import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { env, routes } from "../app-constants";
import DiscussOrderModal from "../components/Modal/DiscussOrderModal";
import PaymentLoaderModal from "../components/Modal/PaymentLoaderModal";
import { INewCardFormState } from "../components/Payment/NewCardForm";
import orderService from "../services/orderService";
import paymentService from "../services/paymentService";
import { orderAmountCalculated } from "../state/actions/orderActions";
import {
  completePayment,
  completePaymentError,
  completePaymentSuccess,
} from "../state/actions/paymentActions";
import {
  loadOrder,
  waitForOrderStatus,
} from "../state/actions/placeOrderAction";
import { RootState } from "../state/store/store";
import withData from "./withData";

interface IPreparePaymentProps {
  store: RootState;
  dispatchAction: Function;
  history: any;
}

interface IPreparePaymentState {
  initiatedPayment: any;
  showPaymentLoaderModal: boolean;
  paymentLoaderText: string;
  serverNotification: { failed: boolean; orderId: string; paymentId: string };
}

// @ts-ignore - Project Upgrade
export default (WrappedComponent) => {
  class PreparePayment extends React.Component<
    IPreparePaymentProps,
    IPreparePaymentState
  > {
    constructor(props: IPreparePaymentProps) {
      super(props);
      this.onPayNowClick = this.onPayNowClick.bind(this);
      this.state = {
        initiatedPayment: {},
        showPaymentLoaderModal: false,
        paymentLoaderText: "",
        serverNotification: {
          failed: false,
          orderId: "",
          paymentId: "",
        },
      };
    }

    componentDidMount() {
      this.setState({
        showPaymentLoaderModal: true,
        paymentLoaderText: "Preparing Secure Payment",
      });
      // @ts-ignore - Project Upgrade
      const { id: orderId } = this.props.store.placedOrder.data;
      this.props.dispatchAction(
        // @ts-ignore - Project Upgrade
        loadOrder(this.props.store.placedOrder.data.vendorId, orderId)
      );
    }

    componentWillReceiveProps(nextProps: IPreparePaymentProps) {
      const isDataUpToDate =
        this.props.store.placedOrder.fetchStatus === "fetching" &&
        nextProps.store.placedOrder.fetchStatus === "success";

      const reInitiatePayment = nextProps.store.order.reInitiatePayment;

      if (nextProps.store.order.applyingCoupon) {
        if (nextProps.store.order.applyingCode === "") {
          this.setState({
            showPaymentLoaderModal: true,
            paymentLoaderText: "Clearing Coupon...",
          });
        } else {
          this.setState({
            showPaymentLoaderModal: true,
            paymentLoaderText: "Checking Coupon...",
          });
        }
      }

      if (
        this.props.store.order.applyingCoupon &&
        !nextProps.store.order.applyingCoupon
      ) {
        this.hidePaymentLoaderModal();
      }

      if (
        reInitiatePayment ||
        (isDataUpToDate &&
          // @ts-ignore - Project Upgrade
          nextProps.store.placedOrder.data.orderStatus === "Pending" &&
          !this.state.initiatedPayment.id)
      ) {
        this.loadPreparedPaymentData();
        this.props.dispatchAction(orderAmountCalculated());
      }

      if (
        // @ts-ignore - Project Upgrade
        nextProps.store.placedOrder.data.orderStatus ===
          "VendorSupportRequired" ||
        // @ts-ignore - Project Upgrade
        nextProps.store.placedOrder.data.orderStatus === "Cancelled"
      ) {
        this.hidePaymentLoaderModal();
        setTimeout(
          () => this.props.history.push("/order-flow/alert-order"),
          1500
        );
      }
    }

    async loadPreparedPaymentData(): Promise<any> {
      // @ts-ignore - Project Upgrade
      const { id: orderId, vendorId } = this.props.store.placedOrder.data;
      const { result } = await orderService.initiatePayment(vendorId, orderId);
      this.setState({ initiatedPayment: result });
      this.hidePaymentLoaderModal();
    }

    async onPayNowClick(formData: INewCardFormState) {
      try {
        this.setState({
          showPaymentLoaderModal: true,
          paymentLoaderText: "Secure Credit Card Payment",
        });
        this.props.dispatchAction(completePayment());
        const { initiatedPayment } = this.state;
        const { savedPaymentMethod = {} } = this.state.initiatedPayment;
        let creditCardData;

        if (savedPaymentMethod.maskedCreditCardNumber === formData.cardNumber) {
          creditCardData = {
            cardHolder: initiatedPayment.savedPaymentMethod.cardName,
            cardNumber:
              initiatedPayment.savedPaymentMethod.maskedCreditCardNumber,
            cardCvn: formData.cvn,
            cardExpiryMonth: initiatedPayment.savedPaymentMethod.expiryMonth,
            cardExpiryYear: initiatedPayment.savedPaymentMethod.expiryYear,
            isTokenPurchase: true,
          };
        } else {
          creditCardData = {
            cardHolder: formData.nameOncard,
            cardNumber: formData.cardNumber,
            cardCvn: formData.cvn,
            cardExpiryMonth: formData.expiryMonth,
            cardExpiryYear: formData.expiryYear,
          };
        }
        const payForOrderResponse = await paymentService.payForOrder(
          initiatedPayment,
          creditCardData,
          this.props.store.placedOrder.data,
          formData.isRememberCardChecked
        );

        if (
          payForOrderResponse.failed !== undefined &&
          payForOrderResponse.failed
        ) {
          this.setState({
            ...this.state,
            serverNotification: payForOrderResponse,
          });
          this.props.dispatchAction(
            completePaymentError(
              "Failed to contact DataPOS API after payment success"
            )
          );
        } else if (payForOrderResponse.result?.status === "Unsuccessful") {
          window.scrollTo(0, 0);
          this.hidePaymentLoaderModal();
          this.props.dispatchAction(
            completePaymentError({
              error: "Please check your credit card details.",
            })
          );
          throw new Error("Please check your credit card details.");
        } else {
          this.props.dispatchAction(
            waitForOrderStatus(
              // @ts-ignore - Project Upgrade
              this.props.store.placedOrder.data.vendorId,
              // @ts-ignore - Project Upgrade
              this.props.store.placedOrder.data.id,
              ["Waiting", "VendorSupportRequired"]
            )
          );
          this.props.dispatchAction(completePaymentSuccess());
        }
      } catch (error) {
        window.scrollTo(0, 0);
        this.hidePaymentLoaderModal();
        this.props.dispatchAction(completePaymentError(error));
        throw error;
      }
    }

    async hidePaymentLoaderModal() {
      // we have to add a delay, to prevent errors with the modal when is shown/hided fast.
      await new Promise((resolve) => setTimeout(resolve, 500));
      this.setState({
        showPaymentLoaderModal: false,
        paymentLoaderText: "",
      });
    }

    render() {
      const venueId = this.props.store.selectedVendor.data.venueId;

      return (
        <React.Fragment>
          <PaymentLoaderModal
            isOpen={
              this.state.showPaymentLoaderModal &&
              !this.state.serverNotification.failed
            }
            text={this.state.paymentLoaderText}
          />

          <WrappedComponent
            onPayNowClick={this.onPayNowClick}
            initiatedPayment={this.state.initiatedPayment}
            {...this.props}
          />

          <DiscussOrderModal
            showModal={
              !this.state.showPaymentLoaderModal &&
              // @ts-ignore - Project Upgrade
              this.props.store.placedOrder.data.orderStatus ===
                "VendorSupportRequired"
            }
          />

          <Modal
            isOpen={this.state.serverNotification.failed}
            className="network-disconnected Modal1"
          >
            <ModalHeader>Issues Occurred</ModalHeader>
            <ModalBody>
              <p>
                Your payment to {env.REACT_APP_VENUE_NAME} was a success, but
                the order did not go through. Please contact the store on{" "}
                <span className="d-inline-block">
                  {
                    this.props.store.vendorsByVenue.vendors[venueId][0]
                      .phoneNumber
                  }
                </span>
                .
              </p>
              <p>
                Tell them that you were ordering online but your order failed to
                go through. Quote the following information:
                <ul>
                  <li>
                    <b>Order identifer</b>:{" "}
                    {this.state.serverNotification.orderId}
                  </li>
                  <li>
                    <b>Payment identifer</b>:{" "}
                    {this.state.serverNotification.paymentId}
                  </li>
                </ul>
              </p>
            </ModalBody>
            <ModalFooter>
              <Link className="btn btn-primary" to={routes.START.PATH}>
                Start a new order
              </Link>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      );
    }
  }

  return withData()(PreparePayment);
};
