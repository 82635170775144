export type VendorGeo = {
  latitude: number;
  longitude: number;
};

export type Vendor = {
  id: number;
  storeName: string;
  vendorOrderConfiguration?: VendorOrderConfiguration;
  geoLocation: VendorGeo;
  phoneNumber: string;
  vendorDisplayAssets: VendorDisplayAssets;
  venueId: number;
  // Order estimate time to service for this vendor.
  tts: number;
  isOpen: boolean;
  isPaused: boolean;
  nextServiceTime?: number;
  locationDescription: string;
};

export type Venue = {
  adminEmail: string[];
  clientId: number;
  executiveEmails: string[];
  id: number;
  logoAssetId: number;
  patronWebAppUrl: string;
  smsProvider: string;
  title: string;
};

export type Money = {
  minorAmount: number;
  amount: number;
  currencyCode: string;
  currencySymbol: string;
};

export type ServiceMode = "DineIn" | "TakeAway" | "HomeDelivery";

export type VendorOrderConfiguration = {
  allowApplePay: boolean;
  allowGooglePay: boolean;
  isCouponPaymentAllowed: boolean;
  defaultOrderServiceMode: ServiceMode;
  availableServiceModes: ServiceMode[];
  deliveryMaxDistance: number;
  deliveryTimeBuffer: number;
  deliveryMinAmount: Money;
  preOrderBufferDuration?: number;
};

export type Location = {
  longitude: number;
  latitude: number;
};

export type DeliveryAddress = {
  place: GooglePlace;
  streetAddress: string;
  addressLocality: string;
  postalCode: string;
  addressRegion: string;
  addressCountry: string;
  addressDescription: string;
  location: Location;
};

export type VendorDisplayAssets = {
  locationImageAssetId: number;
  thumbnailAssetId: number;
};

// Sub set of Google address prediction response.
export type GoogleAddressPrediction = {
  description: string;
  place_id: string;
};

// Google place address component.
export type AddressComponent = {
  types: string[];
  long_name: string;
  short_name: string;
};

// Set sub of Google Place.
export type GooglePlace = {
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
  address_components: AddressComponent[];
};

export type GoogleDistanceMatrixEntry = {
  text: string;
  value: number;
};

export type GoogleDistanceMatrixRow = {
  elements: {
    distance: GoogleDistanceMatrixEntry;
    duration: GoogleDistanceMatrixEntry;
    status: string;
  }[];
};

export type GoogleDistanceMatrix = {
  rows: GoogleDistanceMatrixRow[];
};

/** The status of an order. See {@link https://wiki.agiledigital.com.au/display/APTY/DataPOS+Order+Status+Flow}. */
export enum OrderStatus {
  /** Order has been placed, but payment (confirmation) is not complete. Will progress to Paid or Cancelled. */
  Pending = "Pending",
  /** Order has been paid, but has not been placed with external system. Will progress to Waiting or VendorSupportRequired. */
  Paid = "Paid",
  /** Order is considered confirmed and has entered the queue. Will progress to PendingAlerted or Cancelled. */
  Waiting = "Waiting",
  /**
   * Customer should be alerted to pick up their order, but the alert has not been delivered.
   * Will progress to Alerted or Cancelled.
   *
   * For long-prep orders this will correspond to the state in which the order completed
   * preparation and the expediter manually alerted the order.
   */
  PendingAlerted = "PendingAlerted",
  /**
   * Customer has been alerted to pick up their order. Presumably, they are walking to the vendor.
   * Will progress to Serviced or Cancelled.
   */
  Alerted = "Alerted",
  /** Customer has received their food. Order is complete. */
  Serviced = "Serviced",
  /** Order has been cancelled. */
  Cancelled = "Cancelled",
  /**
   * Processing of the order has failed after payment has been taken. The order can not progress until the vendor
   * provides support to the customer.
   */
  VendorSupportRequired = "VendorSupportRequired",
  /**
   * Order has been submitted with a scheduled pickup time and has not yet been activated in the queue.
   */
  Scheduled = "Scheduled",
}
