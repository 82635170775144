import {
  COMPLETE_PAYMENT,
  COMPLETE_PAYMENT_ERROR,
  COMPLETE_PAYMENT_SUCCESS,
  PaymentActionType,
  PaymentState,
} from "../types/paymentTypes";

const defaultState: PaymentState = {
  error: "",
  fetchStatus: "",
};

export const paymentReducer = (
  state: PaymentState = defaultState,
  action: PaymentActionType
): PaymentState => {
  switch (action.type) {
    case COMPLETE_PAYMENT:
      return { error: "", fetchStatus: "fetching" };
    case COMPLETE_PAYMENT_SUCCESS:
      return { error: "", fetchStatus: "success" };
    case COMPLETE_PAYMENT_ERROR:
      return { ...state, error: action.payload.error, fetchStatus: "failed" };
    default:
      return state;
  }
};
