/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
/* eslint-disable sonarjs/no-useless-catch */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

axios.defaults.headers["Content-Type"] = "application/json";

export interface IRestFactory {
  get: (path: string, params?: any, isSecure?: boolean) => Promise<any>;
  post: (path: string, data: any, isSecure?: boolean) => Promise<any>;
  put: (path: string, data: any, isSecure?: boolean) => Promise<any>;
}

const restService: IRestFactory = {
  async get(path: string, params?: any, isSecure = false): Promise<any> {
    try {
      const axiosConfig: AxiosRequestConfig = {
        params,
        method: "get",
        url: path,
      };
      if (isSecure) {
        axiosConfig.headers = {
          ...axiosConfig.headers,
          Authorisation: "",
        };
      }
      const { data } = (await axios(axiosConfig)) as AxiosResponse;
      return data;
    } catch (error) {
      throw error;
    }
  },
  async post(path: string, data: any, isSecure = false): Promise<any> {
    try {
      const axiosConfig: AxiosRequestConfig = {
        data,
        method: "post",
        url: path,
      };
      if (isSecure) {
        axiosConfig.headers = {
          ...axiosConfig.headers,
          Authorisation: "",
        };
      }

      const { data: response } = (await axios(axiosConfig)) as AxiosResponse;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async put(path: string, data: any, isSecure = false): Promise<any> {
    try {
      const axiosConfig: AxiosRequestConfig = {
        data,
        method: "put",
        url: path,
      };
      if (isSecure) {
        axiosConfig.headers = {
          ...axiosConfig.headers,
          Authorisation: "",
        };
      }

      const { data: response } = (await axios(axiosConfig)) as AxiosResponse;
      return response;
    } catch (error) {
      throw error;
    }
  },
};

function factoryRestService(): IRestFactory {
  return Object.create(restService);
}

export default factoryRestService();
