import React from "react";
import { env } from "../../app-constants";
import withData from "../../highOrderComponents/withData";
import { RootState } from "../../state/store/store";
import Modal from "../Modal/Modal";

export interface INetworkErrorHandlerProps {
  store?: RootState;
}

export interface INetworkErrorHandlerState {
  showNetworkDisconnectedModal: boolean;
}

export class NetworkErrorHandler extends React.Component<
  INetworkErrorHandlerProps,
  INetworkErrorHandlerState
> {
  constructor(props: INetworkErrorHandlerProps) {
    super(props);
    this.state = {
      showNetworkDisconnectedModal: false,
    };
  }

  componentWillReceiveProps(nextProps: INetworkErrorHandlerProps) {
    if (nextProps?.store?.networkStatus.fetchStatus === "failed") {
      this.setState({ showNetworkDisconnectedModal: true });
    } else if (nextProps?.store?.networkStatus.fetchStatus === "success") {
      this.setState({ showNetworkDisconnectedModal: false });
    }
  }

  renderNetworDiconnectedModal() {
    return (
      // @ts-ignore - Project Upgrade
      <Modal
        className="network-disconnected"
        title="Connection troubles!"
        isOpen={this.state.showNetworkDisconnectedModal}
        content={
          <React.Fragment>
            <p className="alert-modal-text">
              {env.REACT_APP_VENUE_NAME
                ? `Having trouble connecting to ${env.REACT_APP_VENUE_NAME}.`
                : "`Having trouble connecting the kitchen."}
            </p>
            <p className="alert-modal-text">
              Please check your WiFi or 3G/4G network strength then refresh the
              page.
            </p>
          </React.Fragment>
        }
        actions={
          <a
            className="btn btn-primary"
            onClick={() =>
              this.setState({ showNetworkDisconnectedModal: false })
            }
          >
            Ok
          </a>
        }
      />
    );
  }

  render() {
    return this.renderNetworDiconnectedModal();
  }
}

export default withData()(NetworkErrorHandler);
