/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Link } from "react-router-dom";
import utils from "../../../services/utils";

export interface IConfirmOrderButtonProps {
  vendor: any;
  hasSelectedItems: any;
  isDigitalStoreOpen: boolean;
  onClick?: () => void;
  goTo: string;
  confirmText?: string;
  selectItemsText?: string;
  icon?: any;
  className?: string;
}

type OrderButtonProps = {
  buttonTxt?: string;
  isButtonDisabled: boolean;
  className?: string;
  onClick?: (isButtonDisabled: boolean) => void;
  goTo?: string;
};

/**
 * Renders an order progress button based on the provided props. E.g. Confirm Order or Select Items Above button.
 */
const OrderButton = ({
  buttonTxt,
  isButtonDisabled,
  className,
  onClick,
  goTo,
}: OrderButtonProps) => {
  const btnClasses = `btn ${
    isButtonDisabled ? "btn-secondary" : "btn-primary btn-arrow"
  } ${!utils.isNil(className) ? className : ""}`;

  return isButtonDisabled ? (
    <button
      type="button"
      onClick={() => onClick && onClick(isButtonDisabled)}
      className={btnClasses}
    >
      {buttonTxt}
    </button>
  ) : (
    <Link
      to={{ pathname: goTo ? goTo : "", search: window.location.search }}
      onClick={() => onClick && onClick(isButtonDisabled)}
      className={btnClasses}
    >
      {buttonTxt}
    </Link>
  );
};

/**
 * Renders confirm order button based on the vendor status or patron's item selection.
 */
const ConfirmOrderButton = ({
  onClick,
  confirmText,
  selectItemsText,
  goTo,
  isDigitalStoreOpen,
  hasSelectedItems,
  vendor,
}: IConfirmOrderButtonProps) => {
  /**
   * Custom onclick event when the order confirm button is clicked, e.g. show a modal about warnings.
   * @param isButtonDisabled to indicate whether the button is disabled.
   */
  const onButtonClick = (isButtonDisabled: boolean) => {
    if (!isButtonDisabled && onClick) {
      onClick();
    }
  };

  // The store allows placing order, and the store must not be paused..
  const vendorTakingOrders = isDigitalStoreOpen && !vendor.isPaused;

  // Order item selected and store is taking online orders, allow progress to next page.
  const allowProgressToOrderConfirm = hasSelectedItems && vendorTakingOrders;

  // Store is taking online orders, require patron to select order items before progress to next page.
  const requireItemSelected = !hasSelectedItems && vendorTakingOrders;

  // Store is open, but paused the online order, patron will not allow progress to next page.
  const vendorPaused = isDigitalStoreOpen && vendor.isPaused;

  if (allowProgressToOrderConfirm) {
    return (
      <OrderButton
        buttonTxt={confirmText || "Confirm my order"}
        isButtonDisabled={false}
        goTo={goTo}
        onClick={onButtonClick}
      />
    );
  }

  if (requireItemSelected) {
    return (
      <OrderButton
        buttonTxt={selectItemsText || "Select Items Above"}
        isButtonDisabled={true}
        onClick={onButtonClick}
      />
    );
  }

  if (vendorPaused) {
    return <OrderButton buttonTxt={"Back Soon"} isButtonDisabled={true} />;
  }

  return <OrderButton buttonTxt={"We are closed"} isButtonDisabled={true} />;
};

export default ConfirmOrderButton;
