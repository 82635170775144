import React from "react";
import { env } from "../app-constants";

export default () => (
  <div className="terms">
    <p>We care about your experience!</p>
    <p>
      Because of this, we want to create a lasting relationship with you, and so
      we have written these Terms and Conditions (T&amp;C’s) so that you know
      what to expect when ordering through our mobile ordering service. We have
      tried to keep these T&amp;C’s as casual as possible, avoiding legal jargon
      where we can. If anything sounds unfair, please drop us a line at DataPOS.
      We will work with the digital team to sort it out.
    </p>
    <p className="font-weight-bold">
      Please have a good read of these T&amp;C’s. By using the ordering
      application and by placing an order, you agree to accept these T&amp;C’s.
    </p>
    <p>
      We may from time to time amend, update or change this web site including
      these T&amp;C’s without prior notice. You will always find the latest
      version here, so keep checking from time to time.
    </p>
    <p className="font-weight-bold">
      These Terms and Conditions were last updated on 13 April 2020.
    </p>
    <ol className="root-list">
      <li>
        <p>
          <span className="font-weight-bold">About the ordering Service.</span>
          The mobile ordering web site (“Application”, “Service”) is licensed to{" "}
          {env.REACT_APP_ENTITY_NAME}
          ("{env.REACT_APP_VENUE_NAME}" “our,” “us,” or "we") (ABN:{" "}
          {env.REACT_APP_ABN}) by DataPOS Technologies Pty Ltd (DataPOS) (ABN 88
          609 658 460). We sell our products to you (“You”, “you”, “user”) via
          this Service and we maintain the information on the Application.
        </p>
      </li>
      <li>
        <span className="font-weight-bold">How to Place an Order.</span>
        <ol>
          <li>
            Make sure that you know where to pickup your order and that you are
            able to pick it up within the estimated or scheduled wait time.
          </li>
          <li> Choose and review what you want to eat.</li>
          <li>
            Let us know your phone number so we can send you a pickup alert
            notification.
          </li>
          <li>
            Enter your credit card details for secure payment through a trusted
            payment gateway.
          </li>
          <li>Wait for order acceptance and enter your email for a receipt.</li>
          <li>
            Make your way to the pickup location while youtrack your order
            progress on the wait screen.
          </li>
          <li>
            Ask us for your order and present us with your order ID after you
            receive the SMS notification alert.
          </li>
        </ol>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">
            Is your information secure and who sees your Credit Card details?
          </span>
          We do not personally see your credit card details and neither does
          DataPOS. We use a trusted third party payment gateway authorised by
          all the major Australian banks. See www.fatzebra.com.au for details on
          their security and compliance. All your data is encrypted using secure
          socket layer (SSL) technology. We do not personally store usable
          credit card information. Where a record is required or where you
          consent to saving credit card information, we entrust a third party
          payment gateway. Payment is made by requesting payment on your behalf
          to the third party payment gateway. A few of the first and last digits
          of your credit card may be requested from the payment gateway by
          DataPOS in order to provide you with receipt information that complies
          with our bank’s e-commerce regulations.
        </p>
      </li>
      <li>
        <span className="font-weight-bold">
          What information we collect about you.
        </span>
        <p>
          Your phone number, optionally your email, and details of food &amp;
          beverage orders only.
        </p>
        <p>
          We need these to complete your order purchase. We won’t contact you at
          other times, unless you ask to receive deals and offers by checking
          the unchecked checkbox when you supply your email.
        </p>
        <p>
          We do not personally store your data unless you have asked to
          participate in deals &amp; offers. Where additional service data is
          required to be collected and stored for the purpose of delivering the
          Service to you, we entrust the licensor of this Application, DataPOS.
        </p>
        <p>
          Five categories of information may be collected and stored by our
          technology partner, DataPOS in order to deliver this Service: a)
          Personal Information (phone and email), b) Technical Information (IP
          address, cookies, page requests), c) Activity History (purchasing
          history), d) Location Based Information, e) Credit Card Information
          (DataPOS does not collect or store your credit card information, we
          entrust a secure payment gateway, see 3) above. They may request a few
          digits of your card from the payment gateway to comply with banking
          requirements).
        </p>
        <p>
          DataPOS or {env.REACT_APP_VENUE_NAME} may need to contact you from
          time to time to assist us with fulfilling your orders or improving
          their Service.
        </p>
        <p>
          For a comprehensive list of data practices, please refer to DataPOS's
          Privacy Policy at{" "}
          <a
            href="https://datapos.com.au/privacy-policy"
            title="DataPOS Technologies privacy policy"
          >
            https://datapos.com.au/privacy-policy
          </a>
        </p>
        <p>
          Questions on security and privacy? Email{" "}
          <a href="mailto:privacy@datapos.com.au">privacy@datapos.com.au</a>
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Opting in and out.</span> You may
          opt out from the correlation of passive activity data by turning off
          cookies in your browser. Please note however that disabling cookies
          may result in a reduction of available functionality or Services
          including but not limited to the loss of the ability to place orders,
          or receive alerts, order confirmation receipts and other important
          communication required to fulfill your order.
        </p>
        <p>
          When you supply us with your email address you may be asked to opt in
          to receive marketing information by e-mail or other means. You will be
          given the opportunity to opt out from receiving further communications
          from us in accordance with this policy each time we send you
          information for which you have opted in. This comes in the form of an
          ‘unsubscribe’ link, generally in the footer and/or header of each
          email.
        </p>
        <p>
          If you receive any communication that sounds like it is from us but it
          is not, please contact DataPOS immediately.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Inventory Problems.</span> Even
          though we try our very best, sometimes it gets so busy that we may run
          out of ingredients before we can update our inventory. If there are
          any problems and you have already paid for your order, but we have run
          out of some ingredients to make your order, we will contact you via
          the phone number you provided to offer you something else, or a
          refund.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Technical Problems?</span> Please
          locate a manager in the venue and we will find your order. You will
          always be able to track your order using the app or web site. The web
          site’s URL link will also be in the email receipt sent to you. Make
          sure you are using the same device (smartphone or tablet) you used to
          order. If you don’t provide your email and you loose the order
          tracking link, come to the venue and wait for your pickup SMS. If you
          don’t receive your SMS, we will find your order.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Think you need a refund?</span>{" "}
          Unhappy with what you got or you simply didn’t get it? We are really
          sorry! Please talk a manager at the venue as soon as you can so we can
          fix it.
        </p>
        <p>
          <span className="font-weight-bold">Refund disclaimer:</span> Because
          it is expensive to provide refunds, we can’t give refunds if your
          order isn’t picked up within a reasonable time frame of receiving your
          pickup alert, or if you accidentally place an order, change your mind,
          or forget your order.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Are you under 18?</span> Sorry, but
          you’re not old enough to enter into contracts yet, so you’ll have to
          get a legal guardian to agree to this on your behalf.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">
            We need your real information.
          </span>{" "}
          We need to contact you so we can give you great service. You represent
          and warrant that all information that you provide in connection with
          the Application or the Service will be current, true, accurate,
          supportable and complete.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">
            Consent to Receive E-mail &amp; Text Messaging associated with the
            Service and Application.
          </span>
        </p>
        <p>
          <span className="text-uppercase">
            You Consent to Receive E-mail, Text Messages or Voice Calls from or
            On Behalf of Us, and Acknowledge and Agree That Your Primary Email
            Address And Other Information May Be Used by Us and Datapos for the
            Purpose Of Providing You with the Service and Application.
          </span>
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Intellectual Property.</span>{" "}
          Thinking of using anything from this web site or Application?
        </p>
        <p>
          All material contained within this web site or Application is the
          property of DataPOS Technologies Pty Ltd, its affiliates or us and
          protected by copyright. Except as permitted by relevant copyright
          legislation or with the prior written approval of DataPOS Technologies
          Pty Ltd or its affiliates, or us, the material may not be otherwise
          used, copied, reproduced, published, stored in a retrieval system,
          altered, transmitted, reverse engineered in any form or by any means
          in whole or in part.
        </p>
        <p>
          The trade marks appearing on this web site are primarily our trade
          marks, DataPOS's, or their affiliates. Other trade marks displayed on
          this web site are trade marks of their respective owners. The trade
          marks must not be used or modified in any way without the express
          written permission of us, DataPOS, orthe relevant affiliate.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">License Terms.</span> When you use
          this Application, it is not being sold to you, instead you are
          licensing it from DataPOS and DataPOS can remove that license from you
          if you do something bad.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">
            Restrictions on Use – what these bad things are.
          </span>
          This license does not allow you to use the Application on any device
          that you do not own or control, and you may not distribute or make the
          Application available over a network where it could be used by
          multiple devices at the same time. You may not rent, lease, lend,
          sell, redistribute, or sublicense the Application. You may not copy,
          decompile, reverse-engineer, disassemble, attempt to derive the source
          code of, modify, or create derivative works of the Application, any
          updates, or any part thereof (except as and only to the extent that
          any foregoing restriction is prohibited by applicable law or to the
          extent as may be permitted by the licensing terms governing use of any
          open- sourced components included with the Application). Any attempt
          to do so is a violation of the rights of DataPOS and us. If you breach
          this restriction, you may be subject to prosecution and damages. The
          terms of the license will govern any upgrades provided by DataPOS that
          replace and/or supplement the original Application, unless such
          upgrade is accompanied by a separate license in which case the terms
          of that license will govern.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">
            Are you thinking of buying liquor through the Application?
          </span>{" "}
          If you’re under 18, you agree not to purchase liquor! In general, we
          have to refuse to supply you liquor in accordance with the Responsible
          Services of Alcohol laws within your state or territory. <br />
          IT IS AN OFFENCE TO SUPPLY ALCOHOL TO A PERSON UNDER THE AGE OF 18
          YEARS PENALTIES APPLY
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">
            Menu, pricing and venue information.
          </span>{" "}
          We will attempt to keep all prices, venue opening times and item
          availability up to date. Sometimes, we may suddenly run out of key
          ingredients and we may not be able to update our systems in time.
          We’re really sorry, but this means we will have to contact you. We may
          also change prices and other details on the web site and we won’t get
          a chance to tell you about it before you see those changes on this web
          site.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Limitation of liability.</span> To
          the extent permitted by law, {env.REACT_APP_VENUE_NAME}'s liability in
          relation to any product purchased as a consequence of your use of this
          web site or Application is limited to the lesser of: (a) the
          replacement of any product purchased; and (b) the purchase price paid
          by you to {env.REACT_APP_VENUE_NAME} for the product.
        </p>
        <p>
          Neither DataPOS nor {env.REACT_APP_VENUE_NAME} will be liable for any
          direct, incidental, consequential or indirect damages, faults with the
          Service or Application, loss or corruption of data, loss of profits,
          goodwill, bargain or opportunity or loss of anticipated savings
          resulting from your access to, or use of, or inability to use this web
          site and the content, whether based on warranty, contract, tort,
          negligence or any other legal theory, and whether or not we know of
          the possibility of such damage. If a jurisdiction does not allow the
          exclusion or limitation of liability in accordance with this paragraph
          but allows a limitation of a certain maximum extent then our liability
          is limited to that extent.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Disclaimer.</span> Your use of the
          material contained on this web site or Application is at your sole
          risk. Any information and data is provided on an as is basis and
          excludes any warranties which may be excluded by law. We reserve the
          right to change any of the material on this web site or Application at
          any time.
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Modifications.</span> We may modify
          these terms from time to time. Keep checking this site for the latest
          T&amp;C’s.
        </p>
      </li>
      <li>
        <p className="font-weight-bold">
          We hope you enjoy using this Application!
        </p>
      </li>
    </ol>
  </div>
);
