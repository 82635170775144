import { FETCH_STATUS } from "../actions";

export const CREATE_CONSUMER = "CREATE_CONSUMER";
export const CONSUMER_CREATED_SUCCESS = "CONSUMER_CREATED_SUCCESS";
export const CONSUMER_CREATED_ERROR = "CONSUMER_CREATED_ERROR";
export const SET_MOBILE_PHONE = "SET_MOBILE_PHONE";
export const SET_EMAIL_ADDRESS = "SET_EMAIL_ADDRESS";
export const SET_FULL_NAME = "SET_FULL_NAME";
export const UPDATE_CONSUMER = "UPDATE_CONSUMER";
export const MIGRATE_CONSUMER = "MIGRATE_CONSUMER";
export const UPDATE_CONSUMER_SUCCESS = "UPDATE_CONSUMER_SUCCESS";
export const UPDATE_CONSUMER_ERROR = "UPDATE_CONSUMER_ERROR";
export const ISSUE_RECEIPT = "ISSUE_RECEIPT";
export const UPDATE_CONSUMER_AND_ISSUE_RECEIPT =
  "UPDATE_CONSUMER_AND_ISSUE_RECEIPT";

export interface CreateConsumerAction {
  type: typeof CREATE_CONSUMER;
  payload: { mobileNumber: string; emailAddress?: string; fullName?: string };
}

export interface ConsumerCreatedSuccessAction {
  type: typeof CONSUMER_CREATED_SUCCESS;
  payload: { consumer: ConsumerData };
}

export interface ConsumerCreatedErrorAction {
  type: typeof CONSUMER_CREATED_ERROR;
  payload: { error: unknown };
}

export interface SetMobilePhoneAction {
  type: typeof SET_MOBILE_PHONE;
  payload: { mobileNumber: string };
}

export interface SetEmailAddressAction {
  type: typeof SET_EMAIL_ADDRESS;
  payload: { emailAddress: string };
}

export interface SetFullNameAction {
  type: typeof SET_FULL_NAME;
  payload: { fullName: string };
}

export interface UpdateConsumerAction {
  type: typeof UPDATE_CONSUMER;
  payload: { consumer: ConsumerState };
}

export interface MigrateConsumerAction {
  type: typeof MIGRATE_CONSUMER;
  payload: { legacyConsumer: ConsumerData };
}

export interface UpdateConsumerSuccessAction {
  type: typeof UPDATE_CONSUMER_SUCCESS;
  payload: { consumer: ConsumerData };
}

export interface UpdateConsumerErrorAction {
  type: typeof UPDATE_CONSUMER_ERROR;
  payload: { error: unknown };
}

export interface IssueReceiptAction {
  type: typeof ISSUE_RECEIPT;
  payload: { orderId: number };
}

export interface UpdateConsumerAndIssueReceiptAction {
  type: typeof UPDATE_CONSUMER_AND_ISSUE_RECEIPT;
  payload: { consumer: ConsumerState; orderId: number };
}

export type ConsumerActionType =
  | CreateConsumerAction
  | ConsumerCreatedSuccessAction
  | ConsumerCreatedErrorAction
  | SetMobilePhoneAction
  | SetEmailAddressAction
  | SetFullNameAction
  | UpdateConsumerAction
  | MigrateConsumerAction
  | UpdateConsumerSuccessAction
  | UpdateConsumerErrorAction
  | IssueReceiptAction
  | UpdateConsumerAndIssueReceiptAction;

export interface ConsumerState {
  mobilePhoneFormInput: string;
  emailAddressFormInput: string;
  fullNameFormInput: string;
  data?: ConsumerData;
  fetchStatus: FETCH_STATUS;
  error: unknown;
}

export interface ConsumerData {
  contact?: ConsumerContact;
  name?: ConsumerName;
  hmacKey?: string;
  gatewayToken?: ConsumerGatewayToken;
  id?: number;
  metaData?: ConsumerMetaData;
}

interface ConsumerContact {
  mobile?: string;
  email?: string;
}

interface ConsumerName {
  title?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

interface ConsumerGatewayToken {
  id: string;
  tokenType: string; //TODO stronger typing
}

interface ConsumerMetaData {
  recordStatus: number;
  dateCreated: string;
  lastUpdated: string;
  version: number;
}
