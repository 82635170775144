import Utils from "../../services/utils";
import {
  FETCH_MENU,
  FETCH_MENU_FAILED,
  FETCH_MENU_SUCCEEDED,
  MenuActionType,
  MenuState,
} from "../types/menuTypes";

const defaultState: MenuState = {
  error: "",
  fetchStatus: "",
  menuByVendor: {},
};

export const menuReducer = (
  state: MenuState = defaultState,
  action: MenuActionType
): MenuState => {
  switch (action.type) {
    case FETCH_MENU:
      return { ...state, fetchStatus: "fetching" };
    case FETCH_MENU_SUCCEEDED:
      const menuItemsDecoded = Utils.decodeMenuItemName(
        action.payload.menu.items
      );
      const modifiersNameDecoded = Utils.decodeMenuItemName(
        action.payload.menu.modifiers ?? []
      );
      const modifierGroupsNameDecoded = Utils.decodeMenuItemName(
        action.payload.menu.modifierGroups ?? []
      );
      const menu = {
        ...action.payload.menu,
        items: menuItemsDecoded,
        modifiers: modifiersNameDecoded,
        modifierGroups: modifierGroupsNameDecoded,
      };
      return {
        ...state,
        fetchStatus: "success",
        menuByVendor: {
          ...state.menuByVendor,
          [action.payload.vendorId]: menu,
        },
      };
    case FETCH_MENU_FAILED:
      return { ...state, fetchStatus: "failed", error: action.payload.error };
    default:
      return state;
  }
};
