import React, { useCallback, useState } from "react";
import { Vendor } from "../../types";
import Asset from "../Asset/Asset";
import VendorLink from "./VendorLink";
import VendorInfo from "./VendorInfo";
import { DeliveryDetails } from "../../pages/Delivery/Delivery";
import { useDispatch } from "react-redux";
import { orderActions, vendorActions } from "../../state/actions";

export interface IVendorRowProps {
  vendor: Vendor;
  queryParams: string;
  deliveryPage: boolean;
  /** True if the user has entered their address for delivery (or we loaded a stored one). */
  haveDeliveryAddress?: boolean;
  deliveryDetails?: DeliveryDetails;
}

/**
 * Toggles the display of the map element and is used to display information about a specific vendor
 */
const VendorRow = ({
  vendor,
  queryParams,
  deliveryPage,
  haveDeliveryAddress = false,
  deliveryDetails,
}: IVendorRowProps) => {
  const dispatch = useDispatch();
  const [showMap, setShowMap] = useState(false);
  const toggleMapLocation = () => {
    setShowMap(!showMap);
  };

  const onVendorClick = useCallback(() => {
    dispatch(
      vendorActions.selectVendor(
        vendor.id,
        vendor.venueId,
        vendor.storeName,
        vendor.phoneNumber,
        vendor.locationDescription,
        vendor.vendorDisplayAssets
      )
    );
    const serviceMode = deliveryPage
      ? "HomeDelivery"
      : vendor.vendorOrderConfiguration?.defaultOrderServiceMode;
    if (serviceMode) {
      dispatch(orderActions.selectServiceMode(serviceMode));
    }
  }, [dispatch, vendor, deliveryPage]);

  const renderVendorRow = (loading: boolean) => {
    return (
      <div key={vendor.id} className={`vendor vendor-${vendor.id}`}>
        <div className="row content-row vertical-align">
          <VendorInfo
            vendor={vendor}
            onVendorClick={onVendorClick}
            queryParams={queryParams}
            deliveryPage={deliveryPage}
          />
          <div className="map-column">
            {vendor.vendorDisplayAssets &&
              vendor.vendorDisplayAssets.locationImageAssetId && (
                <button className="toggleMap" onClick={toggleMapLocation}>
                  <div className="mapIcon"></div>
                  <div className="h5">map</div>
                </button>
              )}
          </div>
          <VendorLink
            vendor={vendor}
            queryParams={queryParams}
            onVendorClick={onVendorClick}
            haveDeliveryAddress={haveDeliveryAddress}
            loading={loading}
            delivery={deliveryPage}
            deliveryTime={deliveryDetails?.deliveryTimeInMillis}
          />
        </div>
        {showMap && (
          <div className="row content-row vertical-align map-row">
            <Asset
              thumbnailAssetId={
                vendor.vendorDisplayAssets &&
                vendor.vendorDisplayAssets.locationImageAssetId
              }
            />
          </div>
        )}
      </div>
    );
  };

  const loading: boolean = deliveryDetails === undefined && haveDeliveryAddress;

  return (
    <div>
      {haveDeliveryAddress &&
      deliveryDetails !== undefined &&
      !deliveryDetails.deliveryPossible ? (
        <></>
      ) : (
        renderVendorRow(loading)
      )}
    </div>
  );
};

export default VendorRow;
