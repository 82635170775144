/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react";
import { RootState } from "../../state/store/store";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { env, routes } from "../../app-constants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useInterval } from "../timer/timer";
import { Link } from "react-router-dom";
import { resetOrderAction } from "../../state/actions";
import { OrderState } from "../../state/types/orderTypes";
import utils from "../../services/utils";

// 1 second interval check.
const SESSION_CHECK_INTERVAL = 1000;

/**
 * Modal displays information about order overriding your current order and
 * giving the option to continue it or start a new one.
 */
const OverrideOrderModal = ({
  show,
  vendorId,
}: {
  show: boolean;
  vendorId: number;
}) => {
  const dispatch = useDispatch();
  return (
    <Modal isOpen={show}>
      <ModalHeader>Existing Order Session</ModalHeader>
      <ModalBody>
        You have an existing order session on another Store.
      </ModalBody>
      <ModalFooter>
        <Link
          className="btn btn-primary btn-secondary"
          to={`${routes.ORDER_FLOW.PLACE_ORDER.PATH}?vendorId=${vendorId}`}
        >
          Continue Existing Order Session
        </Link>
        <button
          className="btn btn-primary"
          onClick={() => {
            dispatch(resetOrderAction(true));
          }}
        >
          Start New Order Session
        </button>
      </ModalFooter>
    </Modal>
  );
};

/**
 * Modal displays information about order session expiry and provide link to start again.
 */
const OrderSessionExpireModal = ({ show }: { show: boolean }) => (
  <Modal isOpen={show}>
    <ModalHeader>Order Session Expired</ModalHeader>
    <ModalBody>
      Your current order session expired. Please start again.
    </ModalBody>
    <ModalFooter>
      <Link className="btn btn-primary" to={routes.START.PATH}>
        Start Again
      </Link>
    </ModalFooter>
  </Modal>
);

/**
 * Checks whether an order is older than the maximum allowed age
 *
 * @param order the order to check
 * @return true if the order is older than the maximum allowed time
 */
export const isSessionExpired = (order: OrderState) => {
  const nowInMillis = moment().valueOf();

  return (
    !utils.isNil(order.orderSessionStartTime) &&
    nowInMillis - (order.orderSessionStartTime ?? 0) >
      env.REACT_APP_ORDER_SESSION_EXPIRY_TIME_MILLIS
  );
};

/**
 * Monitoring order session start time, and trigger order session expiry modal when order session expires.
 * There is an interval running in this component that checks the session time every "SESSION_CHECK_INTERVAL".
 * The expiry should only be valid if there is no order or order is in pending status.
 */
const OrderSession = () => {
  const dispatch = useDispatch();
  const order = useSelector((state: RootState) => state.order);
  const selectedVendor = useSelector(
    (state: RootState) => state.selectedVendor
  );
  const placeOrder = useSelector((state: RootState) => state.placedOrder.data);

  const [showOrderSessionExpireModal, setOrderSessionExpireModal] =
    useState(false);

  const [showOrderOverrideModal, setOrderOverrideModal] = useState(false);

  const checkSession = () => {
    // Resets the patron order session (clear selected pre-order time and any selected order items in order) when the session time expires.
    // Since there is no point to keep the patron session due to the menu could be outdated or the pre-order time becomes invalid.
    // Only resets when the order has not been placed, that is in Pending status or no status.
    if (
      isSessionExpired(order) &&
      !showOrderSessionExpireModal &&
      // @ts-ignore Project upgrade
      (!placeOrder.orderStatus || placeOrder.orderStatus === "Pending")
    ) {
      setOrderSessionExpireModal(true);
    } else if (
      showOrderSessionExpireModal &&
      utils.isNil(order.orderSessionStartTime)
    ) {
      setOrderSessionExpireModal(false);
    }

    // Presents the patron to continue an existing session or start a new session.
    // Only prompt if there is an active session, the selected vendor is different
    // from the one in the order, there are menu items selected on the order, and
    // the order has not been placed.
    const overridingExistingOrder =
      !showOrderSessionExpireModal &&
      selectedVendor.data.id !== 0 &&
      order.vendorId !== 0 &&
      order.vendorId !== selectedVendor.data.id &&
      // @ts-ignore Project upgrade
      (!placeOrder.orderStatus ||
        // @ts-ignore Project upgrade
        placeOrder.orderStatus === "Pending");
    setOrderOverrideModal(overridingExistingOrder);

    // If there are existing menu items then we should prompt the user, otherwise reset the order ourselves
    if (overridingExistingOrder && Object.keys(order.userOrder).length !== 0) {
      setOrderOverrideModal(true);
    } else if (overridingExistingOrder) {
      setOrderOverrideModal(false);
      dispatch(resetOrderAction(true));
    } else {
      setOrderOverrideModal(false);
    }
  };

  useInterval(checkSession, SESSION_CHECK_INTERVAL);

  return (
    <>
      <OverrideOrderModal
        show={showOrderOverrideModal}
        vendorId={order.vendorId}
      ></OverrideOrderModal>
      <OrderSessionExpireModal show={showOrderSessionExpireModal} />
    </>
  );
};

export default OrderSession;
