import React from "react";
import * as ReactDOM from "react-dom";
import {
  Modal as ReactstrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export interface IModalProps {
  title: string;
  content: any;
  children?: any;
  actions?: any;
  className?: string;
  isOpen: boolean;
  container?: Element;
}

export default class Modal extends React.Component<IModalProps, null> {
  render() {
    const props = this.props;
    const ModalComponent = (
      <ReactstrapModal isOpen={props.isOpen} className={props.className}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{props.content}</ModalBody>
        <ModalFooter>{props.actions}</ModalFooter>
      </ReactstrapModal>
    );

    if (props.container) {
      return ReactDOM.createPortal(ModalComponent, props.container);
    }
    return ModalComponent;
  }
}
