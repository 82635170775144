/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FETCH_STATUS } from "../../state/actions";
import { RootState } from "../../state/store/store";
import Spinner from "../Spinner/Spinner";
import utils from "../../services/utils";

/**
 * Renders loading spinner whenever a remote resource is currently in fetching status.
 * Spinner is dismissed if all resources are fetched.
 */
const LoadingIndicator: FunctionComponent<{
  // @ts-ignore Upgrade to React 18
  children?: any;
}> = (props) => {
  const state = useSelector((state: RootState) => state);

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    // Returns any state data that is currently fetching.
    const fetchingStateData = Object.keys(state).find((key: string) => {
      const stateKey = key as keyof RootState;
      const stateData = state[stateKey] as { fetchStatus: FETCH_STATUS };
      return stateData.fetchStatus === "fetching";
    });

    const fetchingOrderAmount =
      state.order.orderAmount.fetchStatus === "fetching";

    const vendorSelected = state.selectedVendor.data.id !== 0;

    setShowSpinner(
      vendorSelected && (!utils.isNil(fetchingStateData) || fetchingOrderAmount)
    );
  }, [setShowSpinner, state]);

  if (showSpinner) {
    return (
      <>
        {props.children}
        <div className="LoadingIndicator">
          <Spinner />
        </div>
      </>
    );
  }

  return <></>;
};

export default LoadingIndicator;
