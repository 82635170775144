import React from "react";
import { Link } from "react-router-dom";

export interface INavProps {
  goTo: string;
  title: string;
  search?: string;
  subtitle?: string;
  subtitleIcon?: any;
  className?: string;
}

export interface INavState {
  storeName: string;
}

const Nav = ({
  className,
  goTo,
  search,
  title,
  subtitle,
  subtitleIcon,
}: INavProps) => (
  <section className={`row ${className}`}>
    <h2 className="col-2">
      <div className="subheader">
        <Link
          className="back mt-auto mb-auto pull-left"
          to={{ pathname: goTo, search: search }}
        >
          &lt; Back
        </Link>
      </div>
    </h2>
    <h2 className="col-8">
      <div className="subheader">
        <span className="store">{title}</span>
        {subtitle && (
          <div className="store-name">
            {subtitleIcon}
            {subtitle}
          </div>
        )}
      </div>
    </h2>
  </section>
);

export default Nav;
