/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { env, routes } from "../../app-constants";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import Footer from "../../components/Footer/Footer";
import RefundDataPolicyTemplate from "../../templates/RefundAndDataPolicy";
import { RootState } from "../../state/store/store";
import Nav from "../../components/Nav/Nav";
import { FormattedNumber } from "react-intl";
import RefundDataPolicy from "../../components/RefundDataPolicy/RefundDataPolicy";
import contactFormEnhancer from "../../highOrderComponents/contactFormEnhancer";

interface IConfirmContactProps {
  store: RootState;
  dispatchAction: Function;
  onMobileNumberChanged: (mobileNumber: string) => void;
  onEmailAddressChanged: (emailAddress: string) => void;
  onFullNameChanged: (fullName: string) => void;
  onServiceModeChanged: (serviceMode: string) => void;
}

const ConfirmContact = (props: IConfirmContactProps) => {
  const getAvailableModes = () => {
    const selectedVendor = props.store.vendorsByVenue.vendors[
      props.store.selectedVendor.data.venueId
    ].find((item) => item.id === props.store.selectedVendor.data.id);

    let availableServiceModes: { label: string; value: string }[] = [];

    if (selectedVendor && selectedVendor.vendorOrderConfiguration) {
      // Home delivery will not present as an available service mode for selection, since it is preselected during Delivery page.
      availableServiceModes =
        selectedVendor.vendorOrderConfiguration.availableServiceModes
          .filter((mode) => mode !== "HomeDelivery")
          .map((mode: string) => ({
            label: mode,
            value: mode,
          }));
    }

    // Do not show any service mode for selection if it is a delivery order.
    if (
      props.store.order.orderConfiguration.selectedServiceMode ===
      "HomeDelivery"
    ) {
      return [];
    }

    return availableServiceModes;
  };

  const getSelectedServiceMode = (): string => {
    const selectedServiceMode =
      props.store.order.orderConfiguration.selectedServiceMode;
    if (selectedServiceMode === undefined) {
      return props.store.order.orderConfiguration.defaultOrderServiceMode;
    }

    return selectedServiceMode;
  };

  // @ts-ignore - Project Upgrade
  const total = props.store.order.orderAmount.paymentSummary.total;

  const mobileNumber =
    (props.store.consumer.data?.contact &&
      props.store.consumer.data.contact.mobile) ||
    "";

  const emailAddress =
    (props.store.consumer.data?.contact &&
      props.store.consumer.data.contact.email) ||
    "";

  const fullName =
    (props.store.consumer.data?.contact &&
      props.store.consumer.data.name?.fullName) ||
    "";

  const selectedServiceMode =
    props.store.order.orderConfiguration.selectedServiceMode;
  const deliveryAddress = props.store.order.deliveryAddress;

  return (
    <div className="ConfirmContact col-md-8 offset-md-2 d-flex flex-column">
      <Nav
        className="nav-header"
        goTo={routes.ORDER_FLOW.CONFIRM_ORDER.PATH}
        title="Enter Contact Details"
        subtitle={`Ordering from ${
          props.store.selectedVendor.data.storeName
            ? props.store.selectedVendor.data.storeName
            : env.REACT_APP_VENUE_NAME
        }`}
      />
      {deliveryAddress && selectedServiceMode === "HomeDelivery" ? (
        <div className="delivery-address-section">
          Deliver To: {deliveryAddress.addressDescription}
        </div>
      ) : (
        <></>
      )}
      {props.store.consumer.fetchStatus === "failed" &&
        props.store.consumer.mobilePhoneFormInput && (
          <div className="alert alert-danger" role="alert">
            {
              // @ts-ignore - Project Upgrade
              props.store.consumer.error?.response?.data ??
                "Error with user. Please try again later."
            }
          </div>
        )}

      <div className="payment-box">
        <div className="info-box">
          Grand Total:&nbsp;
          <strong>
            {total.currencySymbol}
            <FormattedNumber minimumFractionDigits={2} value={total.amount} />
          </strong>
        </div>
      </div>
      {
        <ContactInfo
          mobileNumber={mobileNumber}
          emailAddress={emailAddress}
          fullName={fullName}
          total={total}
          selectedServiceMode={getSelectedServiceMode()}
          availableServiceModes={getAvailableModes()}
          onMobileNumberChanged={props.onMobileNumberChanged}
          onEmailAddressChanged={props.onEmailAddressChanged}
          onFullNameChanged={props.onFullNameChanged}
          onServiceModeChanged={props.onServiceModeChanged}
        />
      }
      <footer className="footer">
        <RefundDataPolicy
          className="copyright"
          content={RefundDataPolicyTemplate}
          rejectRedirectTo={routes.START.PATH}
        />
      </footer>
      <Footer showBrand={false} />
    </div>
  );
};

export default contactFormEnhancer(ConfirmContact);
