import { RootState } from "../state/store/store";
import { PreloadedState } from "redux";

export interface ILocalStorageService {
  loadState: () => boolean | undefined | PreloadedState<RootState>;
  saveState: (state: RootState) => boolean;
}

const statKey = "state";

const localStorageService: ILocalStorageService = {
  loadState(): boolean | undefined | PreloadedState<RootState> {
    try {
      const state = localStorage.getItem(statKey);
      return state ? JSON.parse(state) : undefined;
    } catch (error) {
      return undefined;
    }
  },

  saveState(state: RootState): boolean {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(statKey, serializedState);
      return true;
    } catch (error) {
      return false;
    }
  },
};

function factoryLocalStorageService(): ILocalStorageService {
  return Object.create(localStorageService);
}

export default factoryLocalStorageService();
