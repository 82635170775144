/* eslint-disable @typescript-eslint/no-explicit-any */
import { env } from "../app-constants";
import { ConsumerState } from "../state/types/consumerTypes";
import restFactory from "./restFactory";

export interface IConsumerService {
  createConsumer: (
    mobile: string,
    email?: string,
    fullName?: string
  ) => Promise<any>;
  updateConsumer: (consumer: ConsumerState) => Promise<any>;
  issueReceipt: (orderId: number) => Promise<any>;
}

const consumerService: IConsumerService = {
  async createConsumer(mobile: string, email?: string, fullName?: string) {
    const consumer = {
      contact: { mobile, email },
      name: { fullName },
    };
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/consumer/create`,
      consumer
    );
  },

  async updateConsumer(consumer: ConsumerState): Promise<any> {
    const updatedConsumer = {
      name: { fullName: consumer.fullNameFormInput },
      contact: { email: consumer.emailAddressFormInput },
      id: consumer.data?.id,
      metaData: consumer.data?.metaData,
    };
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/current-consumer/update`,
      updatedConsumer,
      true
    );
  },

  async issueReceipt(orderId: number): Promise<any> {
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/current-consumer/issue-receipt`,
      { orderId },
      true
    );
  },
};

function factoryConsumerService(): IConsumerService {
  return Object.create(consumerService);
}

export default factoryConsumerService();
