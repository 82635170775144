import { all, takeEvery } from "redux-saga/effects";
import { env } from "../../app-constants";
import {
  FETCH_REQUIRED_VENUES_AND_VENDORS,
  FETCH_VENDORS_AND_MENU,
} from "../types/vendorTypes";
import { getMenuByVendorId } from "./menu";
import { getTimeSlotsByVendorId } from "./timeSlots";
import { getVendorByVenueId } from "./vendors";
import { getVenues } from "./venues";
import { Vendor, Venue } from "../../types";

// Vendors with time slots
function* getVendorsWithTimeSlots(action: any) {
  const vendors: Vendor[] = yield getVendorByVenueId(action);

  yield all(
    vendors.map((vendor: Vendor) =>
      getTimeSlotsByVendorId({
        type: "FETCH_PICKUP_TIME_SLOTS",
        payload: { vendorId: vendor.id },
      })
    )
  );
}
// -------------------------------------------------------------------------------------

// Venues and Vendors
function* getVenuesAndVendors() {
  const venues: Venue[] = yield getVenues();
  yield all(
    venues.map((venue: Venue) =>
      getVendorByVenueId({
        type: "FETCH_VENDORS_BY_VENUE",
        payload: { venueId: venue.id },
      })
    )
  );
}

// -------------------------------------------------------------------------------------

// Venues and Vendors
function* getRequiredVenuesAndVendors(action: any) {
  if (env.REACT_APP_MULTI_VENDOR_SUPPORT) {
    yield getVenuesAndVendors();
  } else {
    yield getVenues();
    yield getVendorsWithTimeSlots(action);
  }
}

export function* watchGetRequiredVenuesAndVendors() {
  yield takeEvery(
    FETCH_REQUIRED_VENUES_AND_VENDORS,
    getRequiredVenuesAndVendors
  );
}

// -------------------------------------------------------------------------------------

// Vendors and Menu's
function* getVendorWithMenu(action: any) {
  const vendors: Vendor[] = yield getVendorByVenueId(action);

  // We are only interested in load the menu of the selected vendor
  // Currently there is not endpoint to get a vendor by id
  const selectedVendor: Vendor | undefined = vendors.find(
    (vendor) => vendor.id === action.payload.vendorId
  );
  if (selectedVendor) {
    yield getMenuByVendorId({
      type: "FETCH_MENU",
      payload: { vendorId: selectedVendor.id },
    });
  }
}

export function* watchGetVendorsAndMenu() {
  yield takeEvery(FETCH_VENDORS_AND_MENU, getVendorWithMenu);
}
