import {
  CompletePaymentErrorAction,
  COMPLETE_PAYMENT,
  COMPLETE_PAYMENT_ERROR,
  COMPLETE_PAYMENT_SUCCESS,
  PaymentActionType,
} from "../types/paymentTypes";

export const completePayment = (): PaymentActionType => {
  return { type: COMPLETE_PAYMENT };
};

export const completePaymentSuccess = (): PaymentActionType => {
  return { type: COMPLETE_PAYMENT_SUCCESS };
};

export const completePaymentError = (
  error: CompletePaymentErrorAction["payload"]["error"]
): PaymentActionType => {
  return { payload: { error }, type: COMPLETE_PAYMENT_ERROR };
};
