import React from "react";
import utils from "../../services/utils";
import { updateUserAndIssueReceipt } from "../../state/actions/consumerActions";
import { RootState } from "../../state/store/store";
import { ConsumerData, ConsumerState } from "../../state/types/consumerTypes";
import Modal from "../Modal/Modal";

export interface EmailCaptureModalProps {
  consumer: ConsumerState;
  title?: string;
  onSendClick?: (email: string) => void;
  modalContent?: any;
  modalActions?: any;
  showInvitesCheckbox?: boolean;
  invitesText?: string;
  modalText?: string;

  dispatchAction?: Function;
  store?: RootState;
}

export interface EmailCaptureModalState {
  email: string;
  shouldShowModal: boolean;
}

export default class EmailCaptureModal extends React.Component<
  EmailCaptureModalProps,
  EmailCaptureModalState
> {
  constructor(props: EmailCaptureModalProps) {
    super(props);
    this.onSendClick = this.onSendClick.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      email: "",
      shouldShowModal: true,
    };
  }

  isEmalValid() {
    return utils.isEmailValid(this.state.email);
  }

  onClose() {
    this.setState({ shouldShowModal: false });
  }

  getConsumer(consumerData: ConsumerData | undefined) {
    if (consumerData) {
      const contact = { ...consumerData.contact, email: this.state.email };
      consumerData.contact = contact;
      return consumerData;
    } else {
      return { contact: { email: `${this.state.email}` } };
    }
  }

  onSendClick(event: any) {
    // Sends the event to custom EmailCaptureModals, like brodburger
    if (this.isEmalValid() && this.props.onSendClick) {
      this.props.onSendClick(this.state.email);
    }
    // @ts-ignore - Project Upgrade
    const orderId = this.props.store?.placedOrder.data.id;
    const consumer = this.getConsumer(this.props.consumer.data);

    // @ts-ignore - Project Upgrade
    this.props.dispatchAction(updateUserAndIssueReceipt(consumer, orderId));
  }

  onEmailChange(event: any) {
    this.setState({ email: event.currentTarget.value });
  }

  getDefaultContent(modalText: string) {
    return (
      <form name="emailForm">
        <span className="alert-modal-text">
          {modalText || "Email my receipt to:"}
        </span>
        <br />
        <br />
        <input
          onChange={this.onEmailChange}
          className="form-control"
          name="emailAddress"
          type="email"
          placeholder="Enter Email Address"
          required
        />
        <div className="field checkbox">
          <input id="checkbox-join-club" type="checkbox" />
          <label htmlFor="checkbox-join-club">{this.props.invitesText}</label>
        </div>
      </form>
    );
  }

  getDefaultActions() {
    return (
      <React.Fragment>
        <a onClick={this.onClose} className="btn btn-primary left">
          Close
        </a>
        <button
          onClick={this.onSendClick}
          className={`btn btn-primary ${!this.isEmalValid() ? "disabled" : ""}`}
        >
          <i className="fas fa-paper-plane"></i>&nbsp;Send
        </button>
      </React.Fragment>
    );
  }

  render() {
    return (
      // @ts-ignore - Project Upgrade
      <Modal
        className="EmailCaptureModal alert-modal"
        isOpen={
          !this.props.consumer.data?.contact?.email &&
          this.state.shouldShowModal
        }
        title={this.props.title || "Order is confirmed!"}
        content={
          this.props.modalContent ||
          // @ts-ignore - Project Upgrade
          this.getDefaultContent(this.props.modalText)
        }
        actions={this.props.modalActions || this.getDefaultActions()}
      ></Modal>
    );
  }
}
