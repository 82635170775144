import moment from "moment";
import dateTimeFormatService from "./dateTimeFormatService";
import restFactory from "./restFactory";
import { Vendor } from "../types";
import { env } from "../app-constants";

export interface IVendorDiscoveryService {
  getVenues: () => Promise<any>;
  getVendorByVenueId: (venueId: string | number) => Promise<any>;
  isDigitalStoreOpen: (vendor: any, order?: any) => boolean;
  getAvailableScheduledPickupTimeSlots: (vendorId: number) => Promise<any>;
  getOpenVendors: (vendorsByVenue: any) => any[];
  getVendorsWithNextTimeService: (vendorsByVenue: any) => any[];
  remainingTimeUntilStoreOpen: (vendor: any) => string;
  isVendorAcceptingCurrentOrder: (vendor: any, pickedTime?: boolean) => boolean;
}

const vendorDiscoveryService: IVendorDiscoveryService = {
  async getVenues(): Promise<any> {
    return await restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/client/${env.REACT_APP_CLIENT_ID}/venues`
    );
  },

  getVendorByVenueId(venueId: string | number): Promise<any> {
    return restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/venue/${venueId}/vendors`
    );
  },

  async getAvailableScheduledPickupTimeSlots(vendorId: number): Promise<any> {
    return restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}/availableScheduledPickupTimeSlots`
    );
  },

  /**
   * Determines if the vendor/store remotely accepting orders.
   * This means if the vendor is eligible to accept orders and effect the Place Order page Confirm Order Button behaviour.
   *
   * Vendor has an isOpen status indicates if it accepts any order to be placed in the queue (has active queue).
   * The next service time attribute of the vendor indicates if the vendor actually open right now (start trading).
   *
   * In summary the vendor is open and allow patron to progress to next flow to order if:
   * - vendor is open, and
   * - now is passed next service time (plus allowed preOrderBufferDuration) if next service time is set, or
   * - vendor is open and this is a scheduled order.
   *
   * @param vendor with open status and next service time.
   * @param order contains the information about if the order is scheduled or ASAP.
   */
  isDigitalStoreOpen(vendor?: Vendor, order?: any) {
    const preOrderBufferDuration =
      vendor?.vendorOrderConfiguration?.preOrderBufferDuration || 0;

    // Vendor can start accepting orders before the trading, for pre-orders and order monitoring from events.
    const vendorAcceptingOrders = vendor && vendor.isOpen;

    if (vendor && vendor.nextServiceTime) {
      // Vendor is actually trading, based on the open hour's next service time.
      const vendorIsTrading =
        moment(vendor.nextServiceTime).valueOf() <
        moment().valueOf() + preOrderBufferDuration;

      // If the order is scheduled, we allow the order through as long as vendor is accepting orders.
      const orderIsScheduled = order && order.pickedTime;

      /* FIXME We hack this check until "kitchen/queue is being monitored" 
         can be decoupled from "online ordering is open".
         
         FIXME The same hack is applied at vendor-status-detect-click.directive.js 
         for scheduled orders handling.
      */
      return (
        (vendorAcceptingOrders && vendorIsTrading) ||
        (vendorAcceptingOrders && orderIsScheduled)
      );
    }

    return vendorAcceptingOrders;
  },

  getOpenVendors(vendorsByVenue: any): any[] {
    return Object.keys(vendorsByVenue)
      .reduce(
        (acc: any[], curr: string) => [...acc, ...vendorsByVenue[curr]],
        []
      )
      .filter((vendor: any) => {
        return !vendor.nextServiceTime && vendor.isOpen;
      });
  },

  getVendorsWithNextTimeService(vendorsByVenue: any): any[] {
    return Object.keys(vendorsByVenue)
      .reduce(
        (acc: any[], curr: string) => [...acc, ...vendorsByVenue[curr]],
        []
      )
      .filter((vendor: any) => {
        return vendor.nextServiceTime;
      });
  },

  remainingTimeUntilStoreOpen(vendor): string {
    if (vendor && vendor.nextServiceTime) {
      return (
        dateTimeFormatService.formatRemainingTime(
          vendor.nextServiceTime - new Date().valueOf(),
          "very soon"
        ) + ""
      );
    }
    return "unknown";
  },

  isVendorAcceptingCurrentOrder(vendor: any, pickedTime?: boolean): boolean {
    if (vendor && vendor.isOpen && !vendor.isPaused && pickedTime) {
      return true;
    }
    if (this.isDigitalStoreOpen(vendor) && !vendor.isPaused) {
      return true;
    }
    return false;
  },
};

function factoryvendorDiscoveryService(): IVendorDiscoveryService {
  return Object.create(vendorDiscoveryService);
}

export default factoryvendorDiscoveryService();
