import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../app-constants";
import Footer from "../../components/Footer/Footer";
import OrderSummaryInfo from "../../components/Order/OrderSummaryInfo";
import OrderSummaryList from "../../components/Order/OrderSummaryList";
import Asset from "../../components/Asset/Asset";
import { RootState } from "../../state/store/store";
import withData from "../../highOrderComponents/withData";
import { Vendor } from "../../types";

interface IAlertOrderProps {
  store: RootState;
}

class AlertOrder extends React.Component<IAlertOrderProps, any> {
  Vendor(): Vendor | undefined {
    const vendorsList = this.props.store.vendorsByVenue.vendors;
    let allVendors: Vendor[] = [];
    let i: any;
    let thisVendor: Vendor;
    for (i in vendorsList) {
      allVendors = allVendors.concat(vendorsList[i].flat());
    }

    for (thisVendor of allVendors) {
      //@ts-ignore - QFXFB-841
      if (thisVendor.id === this.props.store.placedOrder.data.vendorId)
        return thisVendor;
    }
  }

  render() {
    const placedOrderData = this.props.store.placedOrder.data;
    const { paymentSummary } = this.props.store.order.orderAmount;
    const vendor = this.Vendor();

    const selectedServiceMode =
      this.props.store.order.orderConfiguration.selectedServiceMode;

    return (
      <div className="alert-order col-md-8 offset-md-2">
        <div className="see-map">See Map Below</div>
        <div className="info-box">
          {
            // This has to be here for the ts-ignore to work
            // @ts-ignore - Project Upgrade
            placedOrderData.orderStatus === "Cancelled" ||
            // @ts-ignore - Project Upgrade
            placedOrderData.orderStatus === "VendorSupportRequired" ? (
              <span>
                There is a problem with your order. Please quote your order
                number&nbsp;
                <span>
                  {
                    // This has to be here for the ts-ignore to work
                    // @ts-ignore - Project Upgrade
                    placedOrderData.claimTicket
                  }
                </span>{" "}
                in store.
              </span>
            ) : (
              <span>
                {selectedServiceMode === "HomeDelivery" ? (
                  <>You ordered from</>
                ) : (
                  <>Pick up now from</>
                )}
              </span>
            )
          }
        </div>

        {
          // This has to be here for the ts-ignore to work
          // @ts-ignore - Project Update
          vendor.vendorDisplayAssets.locationImageAssetId ? (
            <div className="map-box no-round">
              <Asset
                thumbnailAssetId={
                  // This has to be here for the ts-ignore to work
                  // @ts-ignore - Project Upgrade
                  vendor.vendorDisplayAssets.locationImageAssetId
                }
              />
              <div className="address">
                {
                  // This has to be here for the ts-ignore to work
                  // @ts-ignore - Project Update
                  vendor.locationDescription
                }
              </div>
            </div>
          ) : (
            <div className="no-map address">
              {
                // This has to be here for the ts-ignore to work
                // @ts-ignore - Project Update
                vendor.locationDescription
              }
            </div>
          )
        }

        <div className="info-box">
          Order no.{" "}
          <span className="number">
            {
              // This has to be here for the ts-ignore to work
              // @ts-ignore - Project Upgrade
              placedOrderData.claimTicket
            }
          </span>
        </div>
        <div className="notice notice-indent">Thank you</div>

        <div className="summary-container">
          <OrderSummaryList
            showAddRemoveItemsBtn={false}
            items={
              // This has to be here for the ts-ignore to work
              // @ts-ignore - Project Upgrade
              paymentSummary.items.filter(
                // @ts-ignore - Project Upgrade
                (item) => item.itemType !== "ServiceCharge"
              )
            }
            totalPayment={
              // This has to be here for the ts-ignore to work
              // @ts-ignore - Project Upgrade
              paymentSummary.total
            }
          />
          <OrderSummaryInfo
            chargeItems={
              // This has to be here for the ts-ignore to work
              // @ts-ignore - Project Upgrade
              paymentSummary.items.filter(
                // @ts-ignore - Project Upgrade
                (item) => item.itemType === "ServiceCharge"
              )
            }
            totalPayment={
              // This has to be here for the ts-ignore to work
              // @ts-ignore Project Upgrade
              paymentSummary.total
            }
            subtotal={
              // This has to be here for the ts-ignore to work
              // @ts-ignore - Project Upgrade
              paymentSummary.subTotals
            }
          />
        </div>

        <Link to={routes.START.PATH} className="btn btn-primary btn-arrow">
          Start new order
        </Link>

        <Footer showBrand={false} />
      </div>
    );
  }
}

export default withData()(AlertOrder);
