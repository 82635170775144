import {
  FetchMenuAction,
  FETCH_MENU,
  MenuActionType,
} from "../types/menuTypes";

export const loadMenu = (
  vendorId: FetchMenuAction["payload"]["vendorId"]
): MenuActionType => ({
  type: FETCH_MENU,
  payload: { vendorId },
});
