/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import * as ReactDOM from "react-dom";
import Utils from "../../services/utils";

export interface IScrollToMenuItemProps {
  userOrder: any;
  refs: { [key: string]: React.RefObject<HTMLDivElement> };
  scrollOffSet?: number;
  // @ts-ignore Upgrade to React 18
  children?: any;
}

export default class ScrollToMenuItem extends React.Component<
  IScrollToMenuItemProps,
  any
> {
  // @ts-ignore - Project Upgrade
  smoothScrollClear: Function;

  componentDidMount() {
    const itemNames = Object.keys(this.props.userOrder);
    if (itemNames.length) {
      const lastItemSelected = itemNames[itemNames.length - 1];

      const itemRef = this.props.refs[lastItemSelected];
      if (itemRef) {
        const elementScrollTo = ReactDOM.findDOMNode(itemRef.current);
        if (elementScrollTo) {
          this.smoothScrollClear = Utils.smoothScroll(
            elementScrollTo,
            this.props.scrollOffSet
          );
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.smoothScrollClear) {
      this.smoothScrollClear();
    }
  }

  render() {
    return this.props.children;
  }
}
