import { all } from "redux-saga/effects";
import {
  watchGetRequiredVenuesAndVendors,
  watchGetVendorsAndMenu,
} from "./composedSagas";
import { consumerRootSaga } from "./consumerSaga";
import { couponRootSaga } from "./couponSaga";
import { menuRootSaga } from "./menu";
import { networkStatusRootSaga } from "./networkStatus";
import { orderRootSaga } from "./orderSaga";
import { placeOrderRootSaga } from "./placeOrderSaga";
import { timeSlotsRootSaga } from "./timeSlots";
import { vendorsRootSaga } from "./vendors";
import { venuesRootSaga } from "./venues";

export default function* rootSaga() {
  yield all([
    consumerRootSaga(),
    couponRootSaga(),
    menuRootSaga(),
    networkStatusRootSaga(),
    orderRootSaga(),
    placeOrderRootSaga(),
    timeSlotsRootSaga(),
    vendorsRootSaga(),
    venuesRootSaga(),
    watchGetVendorsAndMenu(),
    watchGetRequiredVenuesAndVendors(),
  ]);
}
