import React from "react";
import Utils from "../../services/utils";
import { ConsumerState } from "../../state/types/consumerTypes";
import EmailCaptureModal from "../WaitOrder/EmailCaptureModal";

export interface EmailCaptureModalProps {
  consumer: ConsumerState;
  onSendClick: (email: string) => void;
}

export interface EmailCaptureModalState {
  email: string;
  shouldShowModal: boolean;
}

export default class EmailModal extends React.Component<
  EmailCaptureModalProps,
  EmailCaptureModalState
> {
  constructor(props: EmailCaptureModalProps) {
    super(props);
    this.onSendClick = this.onSendClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.state = {
      email: "",
      shouldShowModal: true,
    };
  }

  isEmailValid() {
    return this.state.email !== "" && Utils.isEmailValid(this.state.email);
  }

  onSendClick(event: any) {
    this.isEmailValid() && this.props.onSendClick(this.state.email);
  }

  onCloseClick() {
    this.setState({ shouldShowModal: false });
  }

  onEmailChange(event: any) {
    this.setState({ email: event.currentTarget.value });
  }

  getModalContent() {
    return (
      <form name="emailForm">
        <span className="alert-modal-text">Where to send your receipt?</span>
        <br />
        <br />
        <input
          className="form-control"
          name="emailAddress"
          type="email"
          onChange={this.onEmailChange}
          placeholder="Enter Email Address"
          required
        />
      </form>
    );
  }

  getModalActions() {
    return (
      <React.Fragment>
        <button onClick={this.onCloseClick} className="btn btn-secondary">
          Close
        </button>
        <button
          className={`btn btn-primary ${
            !this.isEmailValid() ? "disabled" : ""
          }`}
          onClick={this.onSendClick}
        >
          <i className="fas fa-paper-plane"></i>&nbsp;Send
        </button>
      </React.Fragment>
    );
  }

  render() {
    return this.state.shouldShowModal ? (
      <EmailCaptureModal
        onSendClick={this.onSendClick}
        title="Order is confirmed!"
        consumer={this.props.consumer}
        modalContent={this.getModalContent()}
        modalActions={this.getModalActions()}
        {...this.props}
      />
    ) : null;
  }
}
