import React from "react";
import withData from "../../highOrderComponents/withData";
import { alertOrder } from "../../state/actions/placeOrderAction";
import { RootState } from "../../state/store/store";

interface IAlertOrderTransitionProps {
  allowRedirect: (index: number, allow: boolean) => void;
  index: number;
  dispatchAction?: Function;
  store?: RootState;
}

class AlertOrderTransition extends React.Component<
  IAlertOrderTransitionProps,
  null
> {
  isDataUpToDate = false;

  constructor(props: IAlertOrderTransitionProps) {
    super(props);
    this.resetValidator();
  }

  get vendor() {
    return this.props.store?.vendorsByVenue.vendors[
      this.props.store?.selectedVendor.data.venueId || 0
    ].find(
      // @ts-ignore - Project Upgrade
      (item) => item.id === this.vendorId
    );
  }

  get venueId(): number {
    try {
      return this.props.store?.selectedVendor.data.venueId || 0;
    } catch (error) {
      console.error("A vendor should be selected");
      return 0;
    }
  }

  get vendorId(): number {
    try {
      return this.props.store?.selectedVendor.data.id || 0;
    } catch (error) {
      console.error("A vendor should be selected");
      return 0;
    }
  }

  resetValidator() {
    this.isDataUpToDate = false;
  }

  componentDidMount() {
    // @ts-ignore - Project Upgrade
    const { orderStatus } = this.props.store?.placedOrder.data;
    if (orderStatus === "PendingAlerted") {
      // @ts-ignore - Project Upgrade
      this.props.dispatchAction(
        // @ts-ignore - Project Upgrade
        alertOrder(this.props.store?.placedOrder.data.id, this.vendor.id)
      );
    }
    // If the order was cancelled, we allow the redirect to show the alert-order
    // page with indications to proceed
    if (orderStatus === "Cancelled" || orderStatus === "Serviced") {
      this.props.allowRedirect(this.props.index, true);
    }
  }

  componentWillReceiveProps(nextProps: IAlertOrderTransitionProps) {
    this.isDataUpToDate =
      this.isDataUpToDate ||
      (this.props.store?.placedOrder.fetchStatus === "fetching" &&
        nextProps.store?.placedOrder.fetchStatus === "success");
  }

  componentDidUpdate() {
    if (this.isDataUpToDate && this.props.store?.payment.error !== "") {
      this.resetValidator();
      this.props.allowRedirect(this.props.index, false);
    }
    if (
      this.isDataUpToDate &&
      // @ts-ignore - Project Upgrade
      (this.props.store?.placedOrder.data.orderStatus === "Alerted" ||
        // @ts-ignore - Project Upgrade
        this.props.store?.placedOrder.data.orderStatus === "Cancelled")
    ) {
      this.props.allowRedirect(this.props.index, true);
    }
  }

  render() {
    return null;
  }
}

export default withData()(AlertOrderTransition) as any;
