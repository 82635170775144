import React from "react";

export default () => (
  <div id="followingBallsG">
    <div id="followingBallsG_1" className="followingBallsG"></div>
    <div id="followingBallsG_2" className="followingBallsG"></div>
    <div id="followingBallsG_3" className="followingBallsG"></div>
    <div id="followingBallsG_4" className="followingBallsG"></div>
  </div>
);
