import { all, call, put, takeEvery } from "redux-saga/effects";
import menuService from "../../services/menuService";
import {
  FetchMenuAction,
  FETCH_MENU,
  FETCH_MENU_FAILED,
  FETCH_MENU_SUCCEEDED,
} from "../types/menuTypes";

export function* getMenuByVendorId({ payload }: FetchMenuAction) {
  try {
    const { result } = yield call(
      menuService.getMenuByVendorId,
      payload.vendorId
    );
    yield put({
      type: FETCH_MENU_SUCCEEDED,
      payload: {
        ...payload,
        menu: result,
      },
    });
  } catch (error) {
    console.error(error);
    yield put({ type: FETCH_MENU_FAILED, payload: { error } });
  }
}

export function* menuRootSaga() {
  yield all([takeEvery(FETCH_MENU, getMenuByVendorId)]);
}
