import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import CONSTANTS from "../../constants/generalConstants";
import couponService from "../../services/couponService";
import orderService from "../../services/orderService";
import { waitOrderResolved } from "../actions/placeOrderAction";
import { RootState } from "../store/store";
import {
  AlertOrderAction,
  ALERT_ORDER,
  ALERT_ORDER_ERROR,
  ALERT_ORDER_SUCCESS,
  LoadOrderAction,
  LOAD_ORDER,
  LOAD_ORDER_ERROR,
  LOAD_ORDER_SUCCESS,
  ORDER_RESOLVED_ERROR,
  ORDER_RESOLVED_SUCCESS,
  PlaceOrderAction,
  PLACE_ORDER,
  PLACE_ORDER_ERROR,
  PLACE_ORDER_SUCCESS,
  PurchaseWithCouponAction,
  PURCHASE_WITH_COUPON,
  PURCHASE_WITH_COUPON_ERROR,
  PURCHASE_WITH_COUPON_SUCCESS,
  WaitOrderResolvedAction,
  WaitStatusAction,
  WAIT_ORDER_RESOLVED,
  WAIT_STATUS,
} from "../types/placeOrderTypes";

export function* placeOrder({ payload }: PlaceOrderAction) {
  try {
    const deliveryAddress = yield select(
      (state: RootState) => state.order.deliveryAddress
    );
    const venueId = yield select(
      (state: RootState) => state.selectedVendor.data.venueId
    );
    const vendorId = yield select(
      (state: RootState) => state.selectedVendor.data.id
    );
    const { result } = yield call(
      orderService.placeOrder,
      payload.order,
      venueId,
      vendorId,
      payload.menuId,
      payload.attributes,
      deliveryAddress
    );

    yield put({
      type: PLACE_ORDER_SUCCESS,
      payload: { placedOrder: result },
    });
  } catch (error) {
    yield put({
      type: PLACE_ORDER_ERROR,
      payload: { error },
    });
  }
}

export function* purchaseWithCoupon({ payload }: PurchaseWithCouponAction) {
  try {
    const { result } = yield call(
      couponService.purchase,
      payload.orderId,
      payload.couponData
    );
    yield put({
      type: PURCHASE_WITH_COUPON_SUCCESS,
      payload: { order: result },
    });

    yield put(waitOrderResolved(payload.vendorId, payload.orderId));
  } catch (error) {
    // Delay to show the modal animation for at least one second
    yield delay(1000);
    yield put({
      type: PURCHASE_WITH_COUPON_ERROR,
      payload: { error },
    });
  }
}

export function* loadOrder({ payload }: LoadOrderAction) {
  try {
    const { result } = yield call(
      orderService.loadOrder,
      payload.vendorId,
      payload.orderId
    );

    yield put({
      type: LOAD_ORDER_SUCCESS,
      payload: { order: result },
    });
  } catch (error) {
    yield put({
      type: LOAD_ORDER_ERROR,
      payload: { error },
    });
  }
}

export function* waitForOrderResolved({ payload }: WaitOrderResolvedAction) {
  try {
    const maximumRetries = 200;
    let order;
    let retry = 0;
    let orderStatus = "";

    while (
      retry < maximumRetries &&
      orderStatus !== "Waiting" &&
      orderStatus !== "Scheduled" &&
      orderStatus !== "VendorSupportRequired"
    ) {
      order = yield call(
        orderService.loadOrder,
        payload.vendorId,
        payload.orderId
      );
      retry += 1;
      orderStatus = order.result.orderStatus;
      yield delay(1000);
    }

    if (retry === 200) {
      throw new Error("Order not resolved, max retries reached");
    }

    yield put({
      type: ORDER_RESOLVED_SUCCESS,
      payload: { order: order.result },
    });
  } catch (error) {
    yield put({
      type: ORDER_RESOLVED_ERROR,
      payload: { error },
    });
  }
}

export function* waitForOrderStatus({ payload }: WaitStatusAction) {
  while (true) {
    try {
      const order = yield call(
        orderService.loadOrder,
        payload.vendorId,
        payload.orderId
      );

      const orderStatus = order && order.result.orderStatus;
      yield put({
        type: ORDER_RESOLVED_SUCCESS,
        payload: { order: order && order.result },
      });

      /*
        Break before the delay to prevent a user-controlled race condition that occurs towards the end of the order-flow process.
        When the app is waiting for the order to be bumped through by the pos app, this loop is running to check for updates to the order.
        However, the loop continues to run once or twice after the order is confirmed by the user: "Picking up now!".
        If we didn't break here and called delay again before exiting the loop, the user could quickly click through to start a new order, and reach the start page during the delay.
        Then ORDER_RESOLVED_SUCCESS would be dispatched below this loop when the delay finished, AFTER the reset functions on the start page had run.
        Then the "placedOrder" in the store would be re-populated with the old data (persisting as the payload of the action) and the user would be
        redirected to the alert-order page because the app would think they already have a confirmed and placed order in storage.

        This prevents the race even if the request made above is slow because the user won't be shown the "Picking up now!" button until after the
        request finishes and we dispatch the new order status.

        See QFXFB-814 for more details.
      */
      if (payload.status.includes(orderStatus)) {
        break;
      }

      yield delay(CONSTANTS.DEFAULT_DELAY);
    } catch (error) {
      yield put({
        type: ORDER_RESOLVED_ERROR,
        payload: { error },
      });
      // We want to continue polling if the error is a Network Error, otherwise
      // we assume the error can not be recovered from so we should stop polling
      if ((error as any)?.message !== "Network Error") {
        break;
      }
      yield delay(CONSTANTS.DEFAULT_DELAY);
    }
  }
}

export function* alertOrder({ payload }: AlertOrderAction) {
  try {
    const order = yield call(
      // @ts-ignore - Project Upgrade
      orderService.alertOrder,
      payload.orderId,
      payload.vendorId
    );

    yield put({
      type: ALERT_ORDER_SUCCESS,
      payload: { order: order.result },
    });
  } catch (error) {
    yield put({
      type: ALERT_ORDER_ERROR,
      payload: { error },
    });
  }
}

export function* placeOrderRootSaga() {
  yield all([
    takeEvery(PLACE_ORDER, placeOrder),
    takeEvery(PURCHASE_WITH_COUPON, purchaseWithCoupon),
    takeEvery(LOAD_ORDER, loadOrder),
    takeEvery(WAIT_ORDER_RESOLVED, waitForOrderResolved),
    takeEvery(WAIT_STATUS, waitForOrderStatus),
    takeEvery(ALERT_ORDER, alertOrder),
  ]);
}
