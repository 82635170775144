import React from "react";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";

export interface ActionBtnProps {
  className?: string;
  goTo?: string;
  search?: string;
  text: any;
  isBusy?: boolean;
  iconBefore?: any;
  iconAfter?: any;
  noticeTextOnly?: boolean;
  // @ts-ignore - Project Upgrade
  onClick?: (event) => void;
}

export default (props: ActionBtnProps) =>
  props.noticeTextOnly ? (
    <div className="notice-container">
      {props.iconBefore ? props.iconBefore : null}
      {props.text}
      {props.iconAfter ? props.iconAfter : null}
    </div>
  ) : props.goTo ? (
    <Link
      to={{ pathname: props.goTo, search: props.search }}
      className={props.className}
    >
      {props.isBusy ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {props.iconBefore ? props.iconBefore : null}
          {props.text}
          {props.iconAfter ? props.iconAfter : null}
        </React.Fragment>
      )}
    </Link>
  ) : (
    <a onClick={props.onClick} className={props.className}>
      {props.isBusy ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {props.iconBefore ? props.iconBefore : null}
          {props.text}
          {props.iconAfter ? props.iconAfter : null}
        </React.Fragment>
      )}
    </a>
  );
