/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RESET_ORDER_STATE } from "../types/placeOrderTypes";
import * as consumerActions from "./consumerActions";
import * as menuActions from "./menuActions";
import * as checkNetworkActions from "./networkStatusActions";
import * as orderActions from "./orderActions";
import * as placedOrderActions from "./placeOrderAction";
import * as vendorActions from "./vendorActions";
export type FETCH_STATUS = "fetching" | "success" | "failed" | "";

export type RootActionType = typeof RESET_ORDER_STATE;

const resetOrderAction = (
  resetLastOrder = false
  // @ts-ignore - Project Upgrade
): { type: RootActionType; payload: { resetLastOrder } } => ({
  type: RESET_ORDER_STATE,
  payload: { resetLastOrder },
});

export {
  resetOrderAction,
  checkNetworkActions,
  vendorActions,
  orderActions,
  menuActions,
  consumerActions,
  placedOrderActions,
};
