import { OrderStatus } from "../types";

/**
 * Statuses that indicate the user has paid for their {@link PlaceOrderState.data placed order}, but
 * the order isn't finished yet. That is, the order isn't ready or alerted, the vendor hasn't
 * cancelled it and it hasn't been marked as {@link OrderStatus.VendorSupportRequired}.
 *
 * See {@link https://wiki.agiledigital.com.au/display/APTY/DataPOS+Order+Status+Flow}.
 */
export const orderPaidUnfinishedStatuses: OrderStatus[] = [
  OrderStatus.Paid,
  OrderStatus.Scheduled,
  OrderStatus.Waiting,
];

/**
 * Statuses that indicate the user's {@link PlaceOrderState.data placed order} is finished. This
 * means either
 *  - the order was cancelled or failed, or
 *  - the order is ready to be alerted, has been alerted or has been received.
 *
 * See {@link https://wiki.agiledigital.com.au/display/APTY/DataPOS+Order+Status+Flow}.
 */
export const orderFinishedStatuses: OrderStatus[] = [
  OrderStatus.PendingAlerted,
  OrderStatus.Alerted,
  OrderStatus.Serviced,
  OrderStatus.Cancelled,
  OrderStatus.VendorSupportRequired,
];
