import React from "react";
import { Link, Redirect } from "react-router-dom";
import withData from "../../highOrderComponents/withData";
import { getCoupon, setCouponCode } from "../../state/actions/couponActions";
import { purchaseWithCoupon } from "../../state/actions/placeOrderAction";
import { RootState } from "../../state/store/store";
import DiscussOrderModal from "../Modal/DiscussOrderModal";
import Modal from "../Modal/Modal";
import PaymentLoaderModal from "../Modal/PaymentLoaderModal";
import { IRoutItem } from "../Router/Router";

interface ICouponValidatorProps {
  redirectTo: string;
  allowRedirect: (index: number, allow: boolean) => void;
  index: number;
  nextRoute: IRoutItem;
  dispatchAction?: Function;
  store?: RootState;
}

interface ICouponValidatorState {
  showCouponModal: boolean;
  showPaymentLoader: boolean;
  couponError: string;
  verification: string;
  allowRedirectToWaitOrder: boolean;
}

class CouponValidator extends React.Component<
  ICouponValidatorProps,
  ICouponValidatorState
> {
  isDataUpToDate = false;

  constructor(props: ICouponValidatorProps) {
    super(props);
    this.purchase = this.purchase.bind(this);
    this.continue = this.continue.bind(this);
    this.onCouponCodeChange = this.onCouponCodeChange.bind(this);

    this.resetValidator();
    this.state = {
      showCouponModal: false,
      showPaymentLoader: false,
      // @ts-ignore - Project Upgrade
      couponError: undefined,
      verification: "",
      allowRedirectToWaitOrder: false,
    };
  }

  get vendor() {
    return this.props.store?.vendorsByVenue.vendors[this.venueId].find(
      // @ts-ignore - Project Upgrade
      (item) => item.id === this.vendorId
    );
  }

  get venueId(): number {
    try {
      return this.props.store?.selectedVendor.data.venueId || 0;
    } catch (error) {
      console.error(
        "The REACT_APP_VENUE_ID should be defined or a vendor should be selected"
      );
      return 0;
    }
  }

  get vendorId(): number {
    try {
      return (
        this.props.store?.selectedVendor.data.id ||
        Number.parseInt(process.env.REACT_APP_VENDOR_ID || "0")
      );
    } catch (error) {
      console.error(
        "The REACT_APP_VENDOR_ID should be defined or a vendor should be selected"
      );
      return 0;
    }
  }

  resetValidator() {
    this.isDataUpToDate = false;
  }

  componentDidMount() {
    if (
      // @ts-ignore - Project Upgrade
      this.vendor.vendorOrderConfiguration &&
      // @ts-ignore - Project Upgrade
      this.vendor.vendorOrderConfiguration.isCouponPaymentAllowed
    ) {
      // @ts-ignore - Project Upgrade
      this.props.dispatchAction(
        // @ts-ignore - Project Upgrade
        getCoupon(this.props.store?.placedOrder.data.id)
      );
    } else {
      this.props.allowRedirect(this.props.index, true);
    }
  }

  componentWillReceiveProps(nextProps: ICouponValidatorProps) {
    this.isDataUpToDate =
      this.isDataUpToDate ||
      (this.props.store?.coupon.fetchStatus === "fetching" &&
        nextProps.store?.coupon.fetchStatus === "success");

    if (nextProps.store?.coupon.fetchStatus === "failed") {
      // If there is no coupon, we allow the redirect to the next state
      nextProps.allowRedirect(this.props.index, true);
      this.setState({ showCouponModal: false });
    } else if (this.isDataUpToDate) {
      // When a coupon exists, we show the modal
      this.setState({ showCouponModal: true });
    }
    this.handlePurchaseWithCoupon(nextProps);
  }

  handlePurchaseWithCoupon(nextProps: ICouponValidatorProps) {
    if (nextProps.store?.placedOrder.fetchStatus === "failed") {
      this.setState({
        showPaymentLoader: false,
        couponError:
          // @ts-ignore - Project Upgrade
          nextProps.store?.placedOrder.error.response.data.messages[0].message,
      });
    }

    // @ts-ignore - Project Upgrade
    const orderStatus = nextProps.store?.placedOrder.data.orderStatus;
    if (orderStatus === "Waiting" || orderStatus === "Scheduled") {
      this.setState({
        showPaymentLoader: false,
        showCouponModal: false,
        allowRedirectToWaitOrder: true,
      });
    } else if (orderStatus === "VendorSupportRequired") {
      this.setState({
        showPaymentLoader: false,
        showCouponModal: false,
        allowRedirectToWaitOrder: false,
      });
    }
  }

  purchase() {
    if (this.state.verification) {
      this.setState({ showPaymentLoader: true });
      // @ts-ignore - Project Upgrade
      this.props.dispatchAction(
        purchaseWithCoupon(
          this.state.verification,
          // @ts-ignore - Project Upgrade
          this.props.store?.placedOrder.data.id,
          // @ts-ignore - Project Upgrade
          this.props.store?.placedOrder.data.vendorId,
          this.props.store?.coupon.data
        )
      );
    }
  }

  continue() {
    // If the user wants to pay by card instead of using the coupon,
    // we allow the redirect to the next state
    this.setState({ showCouponModal: false });
    this.props.allowRedirect(this.props.index, true);
    this.resetValidator();
  }

  onCouponCodeChange(event: any) {
    // @ts-ignore - Project Upgrade
    this.props.dispatchAction(setCouponCode(event.currentTarget.value));
    this.setState({
      verification: event.currentTarget.value,
    });
  }

  couponModal(showModal: boolean) {
    return (
      // @ts-ignore - Project Upgrade
      <Modal
        className="coupon-modal alert-modal"
        isOpen={showModal}
        title={"Please enter coupon code"}
        content={
          <React.Fragment>
            <p className="alert-modal-text">
              {"You are eligible to use a coupon."}
            </p>
            <input
              value={this.state.verification}
              onChange={this.onCouponCodeChange}
              className="form-control"
              placeholder="Coupon Code"
            />
            {this.state.couponError ? (
              <p
                style={{ color: "#811E09", margin: "10px" }}
                className="alert-modal-text"
              >
                <b>{this.state.couponError}</b>
              </p>
            ) : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Link
              className="btn btn-primary"
              onClick={this.continue}
              // @ts-ignore - Project Upgrade
              to={this.props.nextRoute.path}
            >
              Use card
            </Link>
            <button
              className={`btn btn-primary ${
                !this.state.verification ? "disabled" : ""
              }`}
              onClick={this.purchase}
            >
              Use coupon
            </button>
          </React.Fragment>
        }
      ></Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.couponModal(this.state.showCouponModal)}
        <PaymentLoaderModal
          isOpen={this.state.showPaymentLoader}
          text={"Sending order to the kitchen..."}
        />

        {this.state.allowRedirectToWaitOrder && (
          <Redirect to={this.props.redirectTo} push />
        )}
        <DiscussOrderModal
          showModal={
            !this.state.showPaymentLoader &&
            // @ts-ignore - Project Upgrade
            this.props.store?.placedOrder.data.orderStatus ===
              "VendorSupportRequired"
          }
        />
      </React.Fragment>
    );
  }
}

export default withData()(CouponValidator) as any;
