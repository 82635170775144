import React from "react";
import { Route, RouteComponentProps, StaticContext } from "react-router";
import { AnyAction, Store } from "redux";
import storeFactory from "../../state/store/store";
import TransitionValidator from "../TransitionValidators/TransitionValidator";
import { getRedirectElement } from "./OrderStatusRedirection";
import { IRoutItem } from "./Router";

const getRenderFragment = (
  props: RouteComponentProps<any, StaticContext, unknown>,
  store: Store<any, AnyAction>,
  route: IRoutItem
): JSX.Element => {
  let displayedElement: JSX.Element;
  const redirectionElement = getRedirectElement(props.location.pathname, store);

  if (redirectionElement.props.to !== undefined) {
    displayedElement = redirectionElement;
  } else {
    if (route.transitionValidators && route.transitionValidators.length) {
      displayedElement = (
        <TransitionValidator route={route} routeProps={{ ...props }} />
      );
    } else {
      // @ts-ignore - Project Upgrade
      displayedElement = <route.component {...props} routes={route.routes} />;
    }
  }

  return <React.Fragment>{displayedElement}</React.Fragment>;
};

const SubRoutes = (route: IRoutItem) => {
  const store = storeFactory.getStore();

  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={(props) => getRenderFragment(props, store, route)}
    />
  );
};

export default SubRoutes;
