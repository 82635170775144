import {
  FETCHING_VENUES,
  FETCH_VENUES_FAILED,
  FETCH_VENUES_SUCCEEDED,
  VenueActionType,
  VenuesState,
} from "../types/venueTypes";

const defaultVenuesState: VenuesState = {
  fetchStatus: "",
  list: [],
  error: "",
};

export const venuesReducer = (
  state: VenuesState = defaultVenuesState,
  action: VenueActionType
): VenuesState => {
  switch (action.type) {
    case FETCHING_VENUES:
      return {
        ...state,
        fetchStatus: "fetching",
      };
    case FETCH_VENUES_SUCCEEDED:
      return {
        ...state,
        fetchStatus: "success",
        list: action.payload.venues,
      };
    case FETCH_VENUES_FAILED:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
      };
    default:
      return state;
  }
};
