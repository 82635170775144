import React from "react";
import { Link } from "react-router-dom";
import { RootState } from "../../state/store/store";
import { routes } from "../../app-constants";
import RefundDataPolicyTemplate from "../../templates/RefundAndDataPolicy";
import Nav from "../../components/Nav/Nav";
import CardNoticeError from "../../components/Cards/CardNoticeError";
import PaymentCardForm, {
  ICardFormData,
} from "../../components/Payment/PaymentCardForm";
import { FormattedNumber } from "react-intl";
import RefundDataPolicy from "../../components/RefundDataPolicy/RefundDataPolicy";
import withPreparePayment from "../../highOrderComponents/withPreparePayment";
import { selectedVendor } from "../../state/selectors/vendor";
import Asset from "../../components/Asset/Asset";

interface IPaymentProps {
  store: RootState;
  onPayNowClick: (formData: ICardFormData) => void;
  initiatedPayment: any;
}

interface IPaymentState {
  isFormValid: boolean;
  isCardWithErrors: boolean;
}

class Payment extends React.Component<IPaymentProps, IPaymentState> {
  // @ts-ignore - Project Upgrade
  formData: ICardFormData;

  constructor(props: IPaymentProps) {
    super(props);
    this.isFormValid = this.isFormValid.bind(this);
    this.onPayNowClick = this.onPayNowClick.bind(this);
    this.state = {
      isFormValid: false,
      isCardWithErrors: false,
    };
  }

  isFormValid(formData: ICardFormData, isValid: boolean) {
    this.setState({ isFormValid: isValid });
    this.formData = formData;
  }

  async onPayNowClick() {
    try {
      this.setState({ isCardWithErrors: false });
      await this.props.onPayNowClick(this.formData);
    } catch (error) {
      this.setState({ isCardWithErrors: true });
    }
  }

  render() {
    // @ts-ignore - Project Upgrade
    const total = this.props.store.order.orderAmount.paymentSummary.total;

    const vendor = selectedVendor(
      this.props.store.vendorsByVenue,
      this.props.store.selectedVendor
    );

    return (
      <div className="Payment col-md-8 offset-md-2 d-flex flex-column">
        <Nav
          className="nav-header"
          goTo={routes.ORDER_FLOW.CONFIRM_CONTACT.PATH}
          title="Enter Payment Details"
        />
        {vendor ? (
          <div className="store-column">
            {vendor.vendorDisplayAssets.thumbnailAssetId && (
              <div className="vendor-row">
                <Asset
                  thumbnailAssetId={vendor.vendorDisplayAssets.thumbnailAssetId}
                />
                <div className="vendor-name">{vendor.storeName}</div>
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
        <div className="payment-box">
          <div className="info-box">
            Grand Total:&nbsp;
            <strong>
              {total.currencySymbol}
              <FormattedNumber minimumFractionDigits={2} value={total.amount} />
            </strong>
          </div>
        </div>
        <div className="details">
          {this.state.isCardWithErrors && <CardNoticeError />}
          <PaymentCardForm
            store={this.props.store}
            initiatedPayment={this.props.initiatedPayment}
            isFormValid={this.isFormValid}
          />
          <div className="form-notice">
            Card details are always encrypted and sent securely for bank
            clearance.
          </div>
        </div>
        <div className="fixed-btn-row">
          {!this.state.isFormValid ? (
            <button type="button" className="btn btn-secondary disabled">
              <span>Enter card details</span>
            </button>
          ) : (
            <Link
              id="pay-by-card-btn"
              className="btn btn-primary btn-arrow"
              onClick={this.onPayNowClick}
              to={routes.ORDER_FLOW.WAIT_ORDER.PATH}
            >
              Pay by Card Now
            </Link>
          )}
          <footer className="footer">
            <RefundDataPolicy
              className="copyright"
              content={RefundDataPolicyTemplate}
              rejectRedirectTo={routes.START.PATH}
            />
          </footer>
        </div>
      </div>
    );
  }
}

export default withPreparePayment(Payment);
