import React from "react";
import { Redirect } from "react-router";
import { Prompt } from "react-router-dom";
import utils from "../../services/utils";
import { IRoutItem } from "../Router/Router";

export interface ITransitionValidator {
  redirectTo?: string;
  validator: React.ComponentType<any>;
}

export interface ITransitionValidatorProps {
  route: IRoutItem;
  routeProps: any;
}

export interface ITransitionValidatorState {
  triggerValidation: boolean;
}

export default class TransitionValidator extends React.Component<
  ITransitionValidatorProps,
  ITransitionValidatorState
> {
  nextLocation: string;
  isTransitionAllowed: boolean[];

  constructor(props: ITransitionValidatorProps) {
    super(props);
    this.allowRedirect = this.allowRedirect.bind(this);

    const numberOfValidators = this.props.route.transitionValidators?.length;
    this.nextLocation = "";
    this.isTransitionAllowed = Array(numberOfValidators).fill(undefined);
    this.state = {
      triggerValidation: false,
    };
  }

  isLocationNext(): boolean {
    // @ts-ignore - Project Upgrade
    return (
      this.props.route.nextRoute &&
      this.props.route.nextRoute.path === this.nextLocation
    );
  }

  resetState() {
    const numberOfValidators = this.props.route.transitionValidators?.length;
    this.nextLocation = "";
    this.setState({ triggerValidation: false });
    this.isTransitionAllowed = Array(numberOfValidators).fill(undefined);
  }

  allowRedirect(index: number, allow: boolean) {
    this.isTransitionAllowed[index] = allow;
    this.forceUpdate();
    if (!allow) {
      // Stops the validations because there was a validation with error
      // So we need to handle this error before continuing
      this.resetState();
    }
  }

  shouldBlockTransition() {
    // Returns an empty prompt, this is to block the transition while we validate
    // the store status.
    const isTransitionAllowed = !!this.isTransitionAllowed.find(
      (item) => !item
    );
    return (
      <Prompt
        when={
          !isTransitionAllowed &&
          !this.state.triggerValidation &&
          (!this.nextLocation || this.isLocationNext())
        }
        message={(location: any) => {
          this.nextLocation = location.pathname;
          this.setState({
            // @ts-ignore - Project Upgrade
            triggerValidation:
              this.props.route.nextRoute &&
              this.nextLocation === this.props.route.nextRoute.path,
          });
          return ``;
        }}
      />
    );
  }

  render() {
    const indexOfValidatorToExecute = this.isTransitionAllowed.findIndex(
      (item) => !item
    );
    const Validator =
      // @ts-ignore - Project Upgrade
      this.props.route.transitionValidators[indexOfValidatorToExecute];
    const allowTransition =
      this.isTransitionAllowed.filter((item) => item).length ===
      this.props.route.transitionValidators?.length;

    const allowRedirect = !this.isLocationNext() || allowTransition;

    // Removes the vendorId query parameter if redirected, since we need to remove it after the QR code scan to load the vendor menu.
    const updatedQueryParams = utils.removeQueryParameter(
      "vendorId",
      this.props.routeProps.location.search
    );
    const redirectPath = `${this.nextLocation}?${updatedQueryParams}`;

    return (
      <React.Fragment>
        <this.props.route.component
          {...this.props.routeProps}
          // @ts-ignore Project upgrade
          routes={this.props.route.routes}
        />
        {this.shouldBlockTransition()}
        {this.state.triggerValidation && Validator && (
          <Validator.validator
            index={indexOfValidatorToExecute}
            allowRedirect={this.allowRedirect}
            nextRoute={this.props.route.nextRoute}
            {...Validator}
          />
        )}

        {this.nextLocation && allowRedirect && (
          <Redirect to={redirectPath} push />
        )}
      </React.Fragment>
    );
  }
}
