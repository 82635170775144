/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { env } from "../app-constants";
import restFactory from "./restFactory";

export interface ICouponService {
  getCoupon: (orderId: number) => Promise<any>;
  purchase: (orderId: number, couponData: any) => Promise<any>;
  setOrderCoupon: (orderId: number, couponCode?: string) => Promise<any>;
  generateOrderInvoice: (orderId: number) => Promise<any>;
}

const couponService: ICouponService = {
  async getCoupon(orderId: number) {
    return await restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/current-consumer/order/${orderId}/coupon`,
      undefined,
      true
    );
  },

  async purchase(orderId: number, couponData: any) {
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/current-consumer/order/${orderId}/coupon/purchase`,
      couponData,
      true
    );
  },

  async setOrderCoupon(orderId: number, couponCode?: string) {
    return await restFactory.put(
      `${env.REACT_APP_SERVER_URL}${
        env.REACT_APP_API_VERSION
      }/current-consumer/order/${orderId}/coupon?${
        // @ts-ignore - Project Upgrade
        couponCode === "" || couponCode === undefined
          ? ""
          : `couponCode=${encodeURI(couponCode)}`
      }`,
      undefined,
      true
    );
  },

  async generateOrderInvoice(orderId: number) {
    return await restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/current-consumer/order/${orderId}/invoice`,
      undefined,
      true
    );
  },
};

function factoryCouponService(): ICouponService {
  return Object.create(couponService);
}

export default factoryCouponService();
