/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
import React from "react";
import utils from "../../services/utils";
import CreditCardIcon from "../CreditCard/CreditCardIcon";
import { ICardFormData } from "./PaymentCardForm";

export type INewCardFormState = ICardFormData;

export interface INewCardFormProps {
  isFormValid: (formData: INewCardFormState, isValid: boolean) => void;
}

export default class PayWithNewCardForm extends React.Component<
  INewCardFormProps,
  INewCardFormState
> {
  constructor(props: any) {
    super(props);
    this.toggleRememberCard = this.toggleRememberCard.bind(this);
    this.onNameOnCardChange = this.onNameOnCardChange.bind(this);
    this.onCardNumberChanged = this.onCardNumberChanged.bind(this);
    this.onCvnrChanged = this.onCvnrChanged.bind(this);
    this.onExpiryMonth = this.onExpiryMonth.bind(this);
    this.onExpiryYear = this.onExpiryYear.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      isRememberCardChecked: true,
      nameOncard: "",
      cardNumber: "",
      cvn: "",
      expiryMonth: "",
      expiryYear: "",
    };
    this.props.isFormValid(this.state, this.isFormValid);
  }

  get isFormValid() {
    return (
      /^(?!\s*$).+/.test(this.state.nameOncard) &&
      /^\d{13,16}$/.test(this.state.cardNumber) &&
      /^\d{3,}$/.test(this.state.cvn) &&
      this.state.nameOncard !== "" &&
      this.state.expiryMonth !== "" &&
      this.state.expiryYear !== ""
    );
  }

  toggleRememberCard(event: any) {
    this.setState(
      (prevState: INewCardFormState) => {
        return {
          isRememberCardChecked: !prevState.isRememberCardChecked,
        };
      },
      () => {
        this.props.isFormValid(this.state, this.isFormValid);
      }
    );
  }

  onChange(formInput: string, value: string | boolean) {
    this.setState(
      // @ts-ignore
      {
        [formInput]: value,
      },
      () => {
        this.props.isFormValid(this.state, this.isFormValid);
      }
    );
  }

  onNameOnCardChange(event: any) {
    this.onChange("nameOncard", event.currentTarget.value);
  }

  onCardNumberChanged(event: any) {
    this.onChange("cardNumber", event.currentTarget.value);
  }
  onCvnrChanged(event: any) {
    const cvnValue = event.currentTarget.value;
    if (cvnValue.length <= 4) {
      this.onChange("cvn", event.currentTarget.value);
    }
  }

  onExpiryMonth(event: any) {
    this.onChange("expiryMonth", event.currentTarget.value);
  }

  onExpiryYear(event: any) {
    this.onChange("expiryYear", event.currentTarget.value);
  }

  render() {
    return (
      <React.Fragment>
        <div className="field checkbox checkbox-confirm payment-option">
          <input
            checked={this.state.isRememberCardChecked}
            onChange={this.toggleRememberCard}
            type="checkbox"
            name="creditCardCheckbox"
            id="payment"
          />
          <label htmlFor="payment">
            Securely <strong>remember this card</strong>
          </label>
        </div>
        <div className="field">
          <label htmlFor="creditCardHolder">Name on card</label>
          <input
            type="text"
            value={this.state.nameOncard}
            onChange={this.onNameOnCardChange}
            id="creditCardHolder"
            name="creditCardHolder"
            className="form-control"
            autoComplete="cc-name"
            x-autocompletetype="cc-full-name"
            required
          />
        </div>
        <div className="field field-protected">
          <label htmlFor="cardNumber">Card number</label>
          <input
            type="tel"
            value={this.state.cardNumber}
            onChange={this.onCardNumberChanged}
            id="cardNumber"
            name="cardNumber"
            className="form-control"
            autoComplete="cc-number"
            x-autocompletetype="cc-number"
            required
          />
          <div className="icons-card">
            <CreditCardIcon creditCardNumber={this.state.cardNumber} />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <label htmlFor="creditCardCvn">CVN/CCV</label>
            <input
              type="tel"
              value={this.state.cvn}
              onChange={this.onCvnrChanged}
              name="creditCardCvn"
              className="form-control"
              id="creditCardCvn"
              autoComplete="cc-csc"
              x-autocompletetype="cc-csc"
              maxLength={4}
              required
            />
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col">
                <label>Expiry date</label>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <select
                  id="ccmonth"
                  onChange={this.onExpiryMonth}
                  value={this.state.expiryMonth}
                  name="ccmonth"
                  className="form-control"
                  x-autocompletetype="cc-exp-month"
                  required
                >
                  <option value={""}></option>
                  {utils.monthsInYear().map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.id}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6" style={{ paddingLeft: "5px" }}>
                <select
                  id="ccyear"
                  onChange={this.onExpiryYear}
                  value={this.state.expiryYear}
                  name="ccyear"
                  className="form-control"
                  x-autocompletetype="cc-exp-year"
                  required
                >
                  <option value={""}></option>
                  {utils.generateFutureYears(20).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
