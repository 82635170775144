import React from "react";
import * as ReactDOM from "react-dom";
import { Redirect } from "react-router";
import { routes } from "./app-constants";
import Router, { IRoutItem } from "./components/Router/Router";
import Start from "./pages/Start/Start";
import OrderFlow from "./components/OrderFlow/OrderFlow";
import PlaceOrder from "./pages/PlaceOrder/PlaceOrder";
import MenuTransitionValidator from "./components/TransitionValidators/MenuTransitionValidator";
import ConfirmOrder from "./pages/ConfirmOrder/ConfirmOrder";
import ConfirmContact from "./pages/ConfirmContact/ConfirmContact";
import CouponValidator from "./components/TransitionValidators/CouponValidator";
import CreateConsumer from "./components/TransitionValidators/CreateConsumer";
import PlaceOrderValidator from "./components/TransitionValidators/PlaceOrder";
import WaitOrderValidation from "./components/TransitionValidators/WaitOrderValidation";
import AlertOrderTransition from "./components/TransitionValidators/AlertOrderTransition";
import Payment from "./pages/Payment/Payment";
import WaitOrder from "./pages/WaitOrder/WaitOrder";
import AlertOrder from "./pages/AlertOrder/AlertOrder";
import { unregister } from "./serviceWorker";
import Delivery from "./pages/Delivery/Delivery";
import VendorStatusTransitionValidator from "./components/TransitionValidators/VendorStatusTransitionValidator";
import DiscoverVendor from "./pages/DiscoverVendor/DiscoverVendor";
import localStorageService from "./services/localStorageService";

const routesConfig: IRoutItem[] = [
  {
    path: routes.START.PATH,
    component: Start,
  },
  {
    path: routes.ORDER_FLOW.PATH,
    component: OrderFlow,
    routes: [
      {
        path: routes.ORDER_FLOW.DELIVERY.PATH,
        exact: true,
        component: Delivery,
        step: 0,
      },
      {
        path: routes.ORDER_FLOW.DISCOVER_VENDOR.PATH,
        exact: true,
        component: DiscoverVendor,
        step: 0,
      },
      {
        path: routes.ORDER_FLOW.PLACE_ORDER.PATH,
        exact: true,
        component: PlaceOrder,
        step: 0,
        transitionValidators: [
          {
            redirectTo: routes.ORDER_FLOW.PLACE_ORDER.PATH,
            validator: MenuTransitionValidator,
          },
          {
            redirectTo: routes.START.PATH,
            validator: VendorStatusTransitionValidator,
          },
        ],
      },
      {
        path: routes.ORDER_FLOW.CONFIRM_ORDER.PATH,
        exact: true,
        component: ConfirmOrder,
        step: 0,
        transitionValidators: [
          {
            redirectTo: routes.ORDER_FLOW.PLACE_ORDER.PATH,
            validator: MenuTransitionValidator,
          },
          {
            redirectTo: routes.START.PATH,
            validator: VendorStatusTransitionValidator,
          },
        ],
      },
      {
        path: routes.ORDER_FLOW.CONFIRM_CONTACT.PATH,
        exact: true,
        component: ConfirmContact,
        step: 0,
        transitionValidators: [
          {
            redirectTo: routes.ORDER_FLOW.PLACE_ORDER.PATH,
            validator: MenuTransitionValidator,
          },
          {
            validator: CreateConsumer,
          },
          {
            validator: PlaceOrderValidator,
          },
          {
            redirectTo: routes.ORDER_FLOW.WAIT_ORDER.PATH,
            validator: CouponValidator,
          },
          {
            redirectTo: routes.START.PATH,
            validator: VendorStatusTransitionValidator,
          },
        ],
      },
      {
        path: routes.ORDER_FLOW.PAYMENT.PATH,
        exact: true,
        component: Payment,
        step: 1,
        transitionValidators: [
          {
            validator: WaitOrderValidation,
          },
          {
            redirectTo: routes.START.PATH,
            validator: VendorStatusTransitionValidator,
          },
        ],
      },
      {
        path: routes.ORDER_FLOW.WAIT_ORDER.PATH,
        exact: true,
        component: WaitOrder,
        step: 2,
        transitionValidators: [
          {
            validator: AlertOrderTransition,
          },
        ],
      },
      {
        path: routes.ORDER_FLOW.ALERT_ORDER.PATH,
        exact: true,
        step: 3,
        component: AlertOrder,
      },
    ],
  },
  {
    path: "/",
    exact: true,
    component: () => (
      <Redirect push to={routes.START.PATH + window.location.search} />
    ),
  },
  {
    component: () => (
      <Redirect push to={routes.START.PATH + window.location.search} />
    ),
  },
];

const main = () => {
  ReactDOM.render(
    <Router routes={routesConfig} localStorageService={localStorageService} />,
    document.getElementById("container")
  );

  unregister();
};

export default main;
