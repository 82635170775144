import { Vendor } from "../../types";
import { FETCH_STATUS } from "../actions";

export const FETCH_MENU = "FETCH_MENU";
export const FETCH_MENU_SUCCEEDED = "FETCH_MENU_SUCCEEDED";
export const FETCH_MENU_FAILED = "FETCH_MENU_FAILED";

export interface FetchMenuAction {
  type: typeof FETCH_MENU;
  payload: { vendorId: number };
}

export interface FetchMenuSucceededAction {
  type: typeof FETCH_MENU_SUCCEEDED;
  payload: {
    vendorId: string | number;
    menu: {
      items: ReadonlyArray<MenuItem>;
      modifiers: ReadonlyArray<Modifier>;
      modifierGroups: ReadonlyArray<ModifierGroup>;
    };
  };
}

export interface FetchMenuFailedAction {
  type: typeof FETCH_MENU_FAILED;
  payload: { error: unknown };
}

export type MenuActionType =
  | FetchMenuAction
  | FetchMenuSucceededAction
  | FetchMenuFailedAction;

export interface MenuState {
  menuByVendor: Record<number, Menu>;
  fetchStatus: FETCH_STATUS;
  error: unknown;
}

export interface Menu extends WithMetaData {
  id: number;
  items: MenuItem[];
  modifierGroups: ReadonlyArray<ModifierGroup>;
  modifiers: ReadonlyArray<Modifier>;
  name: string;
  vendor: Vendor;
  vendorId: number;
  version?: string;
  lastChanged?: string;
}

interface WithMetaData {
  metaData: MetaData;
}
export interface MetaData {
  recordStatus: number;
  dateCreated: string;
  lastUpdated: string;
  version: number;
}

export interface MenuItem extends WithMetaData {
  id: number;
  name: string;
  description?: string;
  itemType: string;
  price: Price;
  isRestricted: boolean;
  isGstIncluded: boolean;
  currentInventory: number;
  posMenuItemId?: number;
  visible: boolean;
  displayOrder: number;
  burden: number;
  modifierGroupsIds: ReadonlyArray<number>;
  posMenuItemVisible: boolean;
  displayAssetId?: number;
  posMenuItemName?: string;
  posMenuItemType?: string;
  availability?: Availability;
  variantName?: string;
  itemOptions?: string; // Depricated - use modifierGroupsIds instead
}

export interface ModifierGroup extends WithMetaData {
  defaultModifierId?: number;
  displayOrder: number;
  id: number;
  maximumChoices: number;
  minimumChoices: number;
  modifierIds: ReadonlyArray<number>;
  name: string;
  selectionRequiredText?: string;
  visible: boolean;
  pos?: PosModifierGroup;
}

export interface PosModifierGroup {
  id: number;
  name: string;
  visible: boolean;
}

export interface Modifier extends WithMetaData {
  displayOrder: number;
  id: number;
  name: string;
  price: Price;
  visible: boolean;
  pos?: PosModifier;
}

export interface PosModifier {
  id: number;
  name: string;
  visible: boolean;
}

export interface Price {
  amount: number;
  currencyCode: string;
  currencySymbol: string;
  minorAmount: number;
}

/*
 * We generally expect the keys to be the days of the week:
 * Monday
 * Tuesday
 * Wednesday
 * Thursday
 * Friday
 * Saturday
 * Sunday
 *
 */
export type Availability = Record<string, ReadonlyArray<ReadonlyArray<string>>>;
