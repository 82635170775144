import React, { useState } from "react";
import { Link } from "react-router-dom";
import { env, routes } from "../../app-constants";
import TermsAnConditions from "../../templates/TermsAndConditions";
import Modal from "../Modal/Modal";

export interface IFooterProps {
  showBrand?: boolean;
  className?: string;
}

const modalActions = (
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
) => (
  <>
    <Link
      to={routes.START.PATH}
      className="btn btn-secondary"
      onClick={() => setShowModal(false)}
    >
      Reject
    </Link>
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => setShowModal(false)}
    >
      <i className="far fa-thumbs-up"></i> Accept
    </button>
  </>
);

export const Footer: React.FunctionComponent<IFooterProps> = ({
  className,
  showBrand,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <footer className={`footer ${className ?? ""}`}>
      <div className="copyright">
        By using this service you agree to our&nbsp;
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          terms and conditions
        </button>
        <Modal
          isOpen={showModal}
          className="terms"
          title={`${env.REACT_APP_VENUE_NAME} Terms and Conditions`}
          content={<TermsAnConditions />}
          actions={modalActions(setShowModal)}
          // @ts-ignore - Project Upgrade
          container={document.getElementById("modal-container")}
        />
      </div>
    </footer>
  );
};

export default Footer;
