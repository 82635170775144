/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/ban-types */
import * as he from "he";
import React, { useState, useRef, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import withAddItemToOrder from "../../highOrderComponents/withAddItemToOrder";
import menuService from "../../services/menuService";
import { orderActions } from "../../state/actions";
import { RootState } from "../../state/store/store";
import Asset from "../Asset/Asset";
import PlusMinusButton from "../Button/PlusMinusButton/PlusMinusButton";
import MenuItemOptions from "./MenuItemOptions";
import MenuSelectedItemOptions from "./MenuSelectedItemOptions";
import { Menu, MenuItem } from "../../state/types/menuTypes";
import { OrderItemOption } from "../../state/types/orderTypes";
import { useDispatch, useSelector } from "react-redux";

export type AddSubtractOrderItemType = "add" | "rest";
export interface IMenuItemRowProps {
  menu: Menu;
  menuItem: MenuItem;
  addItemToOrder: (item: MenuItem, option?: string) => void;
}

const smoothScrollToTop = (ref: HTMLElement, offset: number) => {
  window.scrollTo({
    behavior: "smooth",
    top:
      ref.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
};

const MenuItemRow: React.FunctionComponent<IMenuItemRowProps> = ({
  menu,
  menuItem,
  addItemToOrder,
}: IMenuItemRowProps) => {
  const store = useSelector((state: RootState) => state);
  const dispatchAction = useDispatch();

  const [showOptions, setShowOptions] = useState(false);
  // TODO - type this.
  const [selectedOption, setSelectedOption] = useState<{}[]>([]);
  const [itemAdded, setItemAdded] = useState(false);

  const menuItemAddToOrderButtonRef = useRef<HTMLButtonElement>(null);
  const menuItemRef = useRef<HTMLDivElement>(null);

  const order = store.order.userOrder;
  const isSelected = !!(
    order[menuItem.name] && Object.keys(order[menuItem.name]).length
  );
  const orderItemCount = menuService.countMenuItem(
    store.order.userOrder,
    menuItem
  );
  const menuItemHasVisibleModifiers = menuService.menuItemHasVisibleModifiers(
    menuItem,
    menu.modifierGroups,
    menu.modifiers
  );

  // Auto scroll to add to order button when the showOptions is true.
  // So that the "Add to order" is always visible on screen and customer won't forget to click it.
  useEffect(() => {
    const currentAddToOrderButtonRef = menuItemAddToOrderButtonRef.current;

    if (
      showOptions &&
      menuItemAddToOrderButtonRef !== null &&
      currentAddToOrderButtonRef !== null
    ) {
      smoothScrollToTop(currentAddToOrderButtonRef, 150);
    }
  }, [showOptions, menuItemAddToOrderButtonRef]);

  useEffect(() => {
    const currentMenuItemRef = menuItemRef.current;

    const animationDelayForScrollInMilliseconds = 500;

    if (itemAdded && currentMenuItemRef !== null) {
      // Once item options are selected and item is added to the order, the options will be collapsed.
      // This will cause a change of height for the element, so to wait for the height to be adjusted,
      // it needs to have slight delay before triggering the scroll to the options button, so we can re-focus
      // the selected menu item on the screen for the patron to see.
      setTimeout(() => {
        smoothScrollToTop(currentMenuItemRef, 150);
      }, animationDelayForScrollInMilliseconds);
    }
  }, [itemAdded, menuItemRef]);

  const renderSelectedOptions = (menuItem: MenuItem) => {
    const options = store.order.userOrder[menuItem.name] || {};
    return Object.keys(options).map((optionName: string) => {
      const option = options[optionName];
      return (
        menuItem.visible && (
          <MenuSelectedItemOptions
            key={option.name}
            name={menuItem.name}
            option={option}
            shouldScroll={itemAdded}
            onAddRestItem={(action: AddSubtractOrderItemType) =>
              onAddRestItem(action, menuItem, option)
            }
          />
        )
      );
    });
  };

  const onAddToOrderClick = (item: MenuItem, selectedOptions: any) => {
    setShowOptions(!showOptions);
    addItemToOrder(item, selectedOptions);
    setItemAdded(true);
  };

  const onAddRestItem = (
    action: AddSubtractOrderItemType,
    item: MenuItem,
    option: OrderItemOption
  ) => {
    if (action === "add") {
      addItemToOrder(item, option.name);
    } else {
      dispatchAction(orderActions.subtractItemFromOrder(item, option.name));
    }
  };

  const onClickSelectOptions = () => {
    if (menuItemHasVisibleModifiers) {
      setShowOptions(!showOptions);
      setItemAdded(false);
    }
  };

  return menuService.menuItemDisplayed(
    menuItem,
    menu.modifierGroups,
    menu.modifiers
  ) ? (
    <div
      className={`order-item ${isSelected ? "selected" : ""}`}
      ref={menuItemRef}
    >
      <div className="MenuItem item-top clearfix">
        <div className="item-info">
          {menuItem.displayAssetId && (
            <div className="thumbnail-image">
              <Asset
                thumbnailAssetId={menuItem.displayAssetId}
                transform={"100x"}
              />
            </div>
          )}
          <div
            className={`item-card${
              !menuItem.displayAssetId ? " menu-item-is-missing-image" : ""
            }${!menuItemHasVisibleModifiers ? " no-modifiers" : ""}`}
          >
            <div
              className={orderItemCount > 0 ? "item-title red" : "item-title"}
            >
              {menuItem.name}
            </div>
            <>
              {menuItem.description && (
                <div className="item-description">
                  {he.decode(menuItem.description || "")}
                </div>
              )}
            </>
            <div className="item-price">
              {menuItem.price.currencySymbol}
              <FormattedNumber
                minimumFractionDigits={2}
                value={menuItem.price.amount}
              />
            </div>
          </div>
          <div className="item-selection">
            {menuItemHasVisibleModifiers && menuItem.currentInventory > 0 && (
              <button
                className="btn btn-default btn-options"
                onClick={onClickSelectOptions}
              >
                {showOptions ? "Cancel" : "Options"}
              </button>
            )}
            {menuItem.currentInventory <= 0 && (
              <span className="sold-message">Sold Out</span>
            )}
            {menuItem.currentInventory > 0 && !menuItemHasVisibleModifiers && (
              <PlusMinusButton
                className="plus-minus-btn"
                count={orderItemCount}
                onMinusClick={() =>
                  dispatchAction(orderActions.subtractItemFromOrder(menuItem))
                }
                onPlusClick={() => addItemToOrder(menuItem)}
              />
            )}
          </div>
        </div>
        {showOptions && (
          <div className="item-desc">
            <MenuItemOptions
              onAddToOrderClick={(selectedOption: {}[]) =>
                onAddToOrderClick(menuItem, selectedOption)
              }
              onOptionAdded={setSelectedOption}
              modifierGroupIds={menuItem.modifierGroupsIds}
              selectedOption={selectedOption}
              menu={menu}
              menuItem={menuItem}
              menuItemAddToOrderButtonRef={menuItemAddToOrderButtonRef}
            />
          </div>
        )}
      </div>
      {menuItemHasVisibleModifiers && renderSelectedOptions(menuItem)}
    </div>
  ) : (
    <></>
  );
};

export default withAddItemToOrder(MenuItemRow);
