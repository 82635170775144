import {
  ConsumerActionType,
  ConsumerState,
  CONSUMER_CREATED_ERROR,
  CONSUMER_CREATED_SUCCESS,
  CREATE_CONSUMER,
  MIGRATE_CONSUMER,
  SET_MOBILE_PHONE,
  UPDATE_CONSUMER,
  UPDATE_CONSUMER_ERROR,
  UPDATE_CONSUMER_SUCCESS,
  SET_EMAIL_ADDRESS,
  SET_FULL_NAME,
} from "../types/consumerTypes";

const defaultState: ConsumerState = {
  mobilePhoneFormInput: "",
  emailAddressFormInput: "",
  fullNameFormInput: "",
  data: {},
  fetchStatus: "",
  error: "",
};

export const consumerReducer = (
  state: ConsumerState = defaultState,
  action: ConsumerActionType
): ConsumerState => {
  switch (action.type) {
    case SET_MOBILE_PHONE:
      return {
        ...state,
        fetchStatus: "",
        mobilePhoneFormInput: action.payload.mobileNumber,
      };
    case SET_EMAIL_ADDRESS:
      return {
        ...state,
        fetchStatus: "",
        emailAddressFormInput: action.payload.emailAddress,
      };
    case SET_FULL_NAME:
      return {
        ...state,
        fetchStatus: "",
        fullNameFormInput: action.payload.fullName,
      };
    case CREATE_CONSUMER:
      return { ...state, fetchStatus: "fetching" };
    case CONSUMER_CREATED_ERROR:
      return { ...state, error: action.payload.error, fetchStatus: "failed" };
    case UPDATE_CONSUMER:
      return { ...state, fetchStatus: "fetching" };
    case CONSUMER_CREATED_SUCCESS:
    case UPDATE_CONSUMER_SUCCESS:
      return {
        ...state,
        error: "",
        fetchStatus: "success",
        data: action.payload.consumer,
      };
    case UPDATE_CONSUMER_ERROR:
      return { ...state, error: action.payload.error, fetchStatus: "failed" };
    case MIGRATE_CONSUMER:
      return {
        ...state,
        fetchStatus: "success",
        data: action.payload.legacyConsumer,
      };
    default:
      return state;
  }
};
