/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import he from "he";
import { IRoutItem } from "../components/Router/Router";
import queryString from "query-string";

export interface IUtilsService {
  isNil: (value: unknown) => boolean;
  throttle: (fn: Function, throttleTimeMs: number) => void;
  isDeviceMobile: () => boolean;
  generateFutureYears: (numYears: number) => string[];
  monthsInYear: () => { name: string; id: string }[];
  isEmailValid: (email: string) => boolean;
  getCurrentRouteStep: (currentPath: string, routes: IRoutItem[]) => number;
  smoothScroll: (elementScrollTo: any, restToOffset?: number) => Function;
  decodeMenuItemName(items: ReadonlyArray<unknown>): ReadonlyArray<unknown>;
  removeQueryParameter(parameterKey: string, queryParams: string): string;
}

let throttling = false;

const utilsService: IUtilsService = {
  isNil(value: unknown): value is null | undefined {
    return value === null || value === undefined;
  },

  throttle(fn: Function, throttleTimeMs: number) {
    if (throttling) {
      return;
    }

    throttling = true;

    setTimeout(() => {
      fn();

      throttling = false;
    }, throttleTimeMs);
  },

  isDeviceMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  },

  generateFutureYears(numYears: number): string[] {
    const thisYear = new Date().getFullYear();
    const futureYears = [];
    for (let i = 0; i < numYears; i += 1) {
      futureYears.push(thisYear + i);
    }
    // @ts-ignore - Project Upgrade
    return futureYears;
  },

  monthsInYear(): { name: string; id: string }[] {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames.map((monthName: string) => {
      let id = (monthNames.indexOf(monthName) + 1).toString();
      id = id.length < 2 ? "0" + id : id;
      return {
        id,
        name: monthName,
      };
    });
  },

  isEmailValid(email: string) {
    /* tslint:disable */
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
    /* tslint:enable */
  },

  getCurrentRouteStep(currentPath: string, routes: IRoutItem[]): number {
    const currLocation = currentPath;
    const currRoute = routes.find((item) => item.path === currLocation);

    return (currRoute && currRoute.step) || 0;
  },

  smoothScroll(elementScrollTo: any, restToOffset = 120): Function {
    const scrollToPos = elementScrollTo.offsetTop - restToOffset;
    let currScrollPosition = window.scrollY;
    // @ts-ignore - Project Upgrade
    let interval;

    if (currScrollPosition > scrollToPos) {
      // Scroll Up
      interval = setInterval(() => {
        const step =
          Math.round(
            ((currScrollPosition - scrollToPos) / scrollToPos) * 100
          ) || 1;
        currScrollPosition =
          document.documentElement.scrollTop - (step <= scrollToPos ? step : 1);
        if (currScrollPosition < scrollToPos || step < 0) {
          // @ts-ignore - Project Upgrade
          clearInterval(interval);
        }
        window.scrollTo(
          0,
          currScrollPosition < scrollToPos ? scrollToPos : currScrollPosition
        );
      }, 0);
    } else if (currScrollPosition < scrollToPos) {
      // Scroll Down
      interval = setInterval(() => {
        const step =
          Math.round(
            ((scrollToPos - currScrollPosition) / scrollToPos) * 100
          ) || 1;
        currScrollPosition += step <= scrollToPos ? step : 1;
        if (currScrollPosition > scrollToPos || step < 0) {
          // @ts-ignore - Project Upgrade
          clearInterval(interval);
        }
        window.scrollTo(
          0,
          currScrollPosition > scrollToPos ? scrollToPos : currScrollPosition
        );
      }, 0);
    }
    // @ts-ignore - Project Upgrade
    return () => clearInterval(interval);
  },

  decodeMenuItemName(items: any[]): any[] {
    return items.map((item) => ({
      ...item,
      name: he.decode(item.name),
    }));
  },

  /**
   * Removes a specific query parameter matches the provided key.
   * @param paramKey key of the parameter to remove.
   * @param queryParams current query parameters as in format of `?a=b&b=a`.
   * @returns query parameters with the specific key removed.
   */
  removeQueryParameter(paramKey, queryParams) {
    const parsedQueryParams = queryString.parse(queryParams);
    const updatedQueryParams = {
      ...parsedQueryParams,
      [paramKey]: undefined,
    };
    return queryString.stringify(updatedQueryParams);
  },
};

function factoryUtilsService(): IUtilsService {
  return Object.create(utilsService);
}

export default factoryUtilsService();
