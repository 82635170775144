import React from "react";
import prettyMs from "pretty-ms";
import { withWholeMinutes } from "../../services/dateTimeFormatService";

export interface IEstimatedWaitTimeProps {
  secsToServiceEstimate: number;
}

export default (props: IEstimatedWaitTimeProps) => {
  const secsToServiceEstimate = props.secsToServiceEstimate || 0;
  const minWait = prettyMs(
    withWholeMinutes((secsToServiceEstimate * 1000) / 1.1)
  );
  const maxWait = prettyMs(
    withWholeMinutes(secsToServiceEstimate * 1000 * 1.1)
  );

  return (
    <div className="estimatedWaitTime">
      <span>
        <i className="clock"></i>
        {props.secsToServiceEstimate > 66 && minWait === maxWait
          ? `~${maxWait}`
          : ""}
        {props.secsToServiceEstimate > 66 && minWait !== maxWait
          ? `${minWait} - ${maxWait}`
          : ""}

        {props.secsToServiceEstimate <= 66 ? "Very Soon!" : ""}
      </span>
    </div>
  );
};
