import { FETCH_STATUS } from "../actions";
import { DeliveryAddress, ServiceMode } from "../../types";
import { MenuItem, Modifier } from "./menuTypes";

export const SET_PICK_TIME = "SET_PICK_TIME";
export const ADD_ITEM_TO_ORDER = "ADD_ITEM_TO_ORDER";
export const REMOVE_ITEM_FROM_ORDER = "REMOVE_ITEM_FROM_ORDER";
export const SET_ORDER_CONFIGURATION = "SET_ORDER_CONFIGURATION";
export const APPLYING_COUPON = "APPLYING_COUPON";
export const SET_ORDER_COUPON = "SET_ORDER_COUPON";
export const ORDER_COUPON_APPLY_SUCCESS = "ORDER_COUPON_APPLY_SUCCESS";
export const ORDER_COUPON_APPLY_FAILED = "ORDER_COUPON_APPLY_FAILED";
export const ORDER_AMOUNT_RECALCULATED = "ORDER_AMOUNT_RECALCULATED";
export const CALCULATE_ORDER_AMOUNT = "CALCULATE_ORDER_AMOUNT";
export const FETCHING_ORDER_AMOUNT = "FETCHING_ORDER_AMOUNT";
export const FETCH_ORDER_AMOUNT_SUCCESS = "FETCH_ORDER_AMOUNT_SUCCESS";
export const FETCH_ORDER_AMOUNT_ERROR = "FETCH_ORDER_AMOUNT_ERROR";
export const REMOVE_SOLD_OUT_ITEMS = "REMOVE_SOLD_OUT_ITEMS";
export const SELECT_SERVICE_MODE = "SELECT_SERVICE_MODE";
export const COMPLETE_PAYMENT_SUCCESS = "COMPLETE_PAYMENT_SUCCESS";
export const REORDER_LAST_ORDER = "REORDER_LAST_ORDER";
export const REORDER_SUCCESS = "REORDER_SUCCESS";
export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS";
export const CLEAR_DELIVERY_ADDRESS = "CLEAR_DELIVERY_ADDRESS";
export const START_ORDER_SESSION = "START_ORDER_SESSION";

export interface SetPickTimeAction {
  type: typeof SET_PICK_TIME;
  payload: { pickedTime: string };
}

export interface AddItemToOrderAction {
  type: typeof ADD_ITEM_TO_ORDER;
  payload: { item: MenuItem; selectedOption: string };
}

export interface RemoveItemFromOrderAction {
  type: typeof REMOVE_ITEM_FROM_ORDER;
  payload: { item: MenuItem; selectedOption: string };
}

export interface SetOrderConfigurationAction {
  type: typeof SET_ORDER_CONFIGURATION;
  payload: { orderConfig: OrderConfiguration };
}

export interface ApplyingCouponAction {
  type: typeof APPLYING_COUPON;
  payload: { couponCode: string };
}

export interface SetOrderCouponAction {
  type: typeof SET_ORDER_COUPON;
  payload: {
    vendorId: number;
    orderId: number;
    couponCode: string;
    order: OrderState;
  };
}

export interface OrderCouponApplySuccessAction {
  type: typeof ORDER_COUPON_APPLY_SUCCESS;
  payload: { appliedCoupon: string; updatedInvoice: unknown };
}

export interface OrderCouponApplyFailedAction {
  type: typeof ORDER_COUPON_APPLY_FAILED;
  // TODO: Not compliant with Agile Digital Redux configurations
  payload: string;
}

export interface OrderAmountRecalculatedAction {
  type: typeof ORDER_AMOUNT_RECALCULATED;
}

export interface CalculateOrderAmountAction {
  type: typeof CALCULATE_ORDER_AMOUNT;
  payload: {
    vendorId: number;
    order: OrderState;
  };
}

export interface FetchingOrderAmountAction {
  type: typeof FETCHING_ORDER_AMOUNT;
  payload: { vendorId: number; order: unknown };
}

export interface FetchingOrderAmountSuccessAction {
  type: typeof FETCH_ORDER_AMOUNT_SUCCESS;
  payload: { paymentSummary: { items: ReadonlyArray<unknown> } };
}

export interface FetchingOrderAmountErrorAction {
  type: typeof FETCH_ORDER_AMOUNT_ERROR;
  payload: { error: unknown };
}

export interface RemoveSoldOutItemsAction {
  type: typeof REMOVE_SOLD_OUT_ITEMS;
  payload: { item: MenuItem };
}

export interface SelectServiceModeAction {
  type: typeof SELECT_SERVICE_MODE;
  payload: { serviceMode: ServiceMode };
}

export interface CompletePaymentSuccessAction {
  type: typeof COMPLETE_PAYMENT_SUCCESS;
}

export interface ReorderLastOrderAction {
  type: typeof REORDER_LAST_ORDER;
  payload: { vendorId: number };
}

export interface ReorderSuccessAction {
  type: typeof REORDER_SUCCESS;
  payload: { lastOrder: OrderState["userOrder"] };
}

export interface SetDeliveryAddressAction {
  type: typeof SET_DELIVERY_ADDRESS;
  payload: { deliveryAddress: DeliveryAddress };
}

export interface ClearDeliveryAddressAction {
  type: typeof CLEAR_DELIVERY_ADDRESS;
}

export interface StartOrderSessionAction {
  type: typeof START_ORDER_SESSION;
  payload: {
    startTime: number;
    vendorId: number;
  };
}

export type OrderActionType =
  | SetPickTimeAction
  | AddItemToOrderAction
  | RemoveItemFromOrderAction
  | SetOrderConfigurationAction
  | ApplyingCouponAction
  | SetOrderCouponAction
  | OrderCouponApplySuccessAction
  | OrderCouponApplyFailedAction
  | OrderAmountRecalculatedAction
  | CalculateOrderAmountAction
  | FetchingOrderAmountAction
  | FetchingOrderAmountSuccessAction
  | FetchingOrderAmountErrorAction
  | RemoveSoldOutItemsAction
  | SelectServiceModeAction
  | CompletePaymentSuccessAction
  | ReorderLastOrderAction
  | ReorderSuccessAction
  | SetDeliveryAddressAction
  | ClearDeliveryAddressAction
  | StartOrderSessionAction;

export interface OrderItemOption {
  id: number;
  name: string;
  count: number;
  price: any;
  itemType: string;
  isRestricted: boolean;
  /*  Modifiers are optional as they may not exist in specific situations e.g.
   *  Two vendors have a menu item with the same name (one with modifies, one without)
   *  If a user chooses the item that has not got modifiers, then switches to the vendor
   *  with modifiers then the modifiers won't exist.
   */
  modifiers?: Modifier[];
}

export interface OrderConfiguration {
  defaultOrderServiceMode: ServiceMode;
  selectedServiceMode?: ServiceMode;
}

export interface OrderAmount {
  fetchStatus: FETCH_STATUS;
  paymentSummary: unknown;
  error: unknown;
}

export interface OrderAttributes {
  patron?: {
    campaignId?: string;
  };
}

export interface OrderState {
  pickedTime: string;
  // The user order is an object of objects, first with the menu item name
  // second the selected option, if the menu item doesn't have options, the
  // selected option will be 'default'
  userOrder: Record<string, Record<string, OrderItemOption>>;
  lastOrder?: Record<string, Record<string, OrderItemOption>>;
  orderBurden: number;
  orderConfiguration: OrderConfiguration;
  orderAmount: OrderAmount;
  vendorId: number;
  vendorConfirmed: boolean;
  applyingCoupon?: boolean;
  reInitiatePayment?: boolean;
  appliedCoupon?: string;
  applyingCode?: string;
  couponError?: string;
  deliveryAddress?: DeliveryAddress;
  // Indicates the time the patron first visit the menu (place order) page.
  // We use this time to determine if we should expire a session to make sure patron always
  // sees the latest menu and clear any selected schedule time of the order.
  orderSessionStartTime?: number;
}
