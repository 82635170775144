import React from "react";

export default () => (
  <div className="Spinner">
    <div className="sk-circle">
      {Array(12)
        .fill("")
        .map((item: any, index: number) => (
          <div key={index} className={`sk-circle${index + 1} sk-child`}></div>
        ))}
    </div>
  </div>
);
