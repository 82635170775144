import { env } from "../app-constants";
import restFactory from "./restFactory";

export interface INetworkService {
  checkNetworkStatus: () => Promise<any>;
}

const networkService: INetworkService = {
  async checkNetworkStatus(): Promise<any> {
    return restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/time`
    );
  },
};

function factoryNetworkService(): INetworkService {
  return Object.create(networkService);
}

export default factoryNetworkService();
