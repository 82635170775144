import {
  ConsumerActionType,
  ConsumerState,
  CreateConsumerAction,
  CREATE_CONSUMER,
  IssueReceiptAction,
  ISSUE_RECEIPT,
  ConsumerData,
  MIGRATE_CONSUMER,
  SetMobilePhoneAction,
  SET_MOBILE_PHONE,
  UPDATE_CONSUMER,
  UPDATE_CONSUMER_AND_ISSUE_RECEIPT,
  SetEmailAddressAction,
  SET_EMAIL_ADDRESS,
  SetFullNameAction,
  SET_FULL_NAME,
} from "../types/consumerTypes";

export const setMobilePhone = (
  mobileNumber: SetMobilePhoneAction["payload"]["mobileNumber"]
): ConsumerActionType => ({
  type: SET_MOBILE_PHONE,
  payload: { mobileNumber },
});

export const setEmailAddress = (
  emailAddress: SetEmailAddressAction["payload"]["emailAddress"]
): ConsumerActionType => ({
  type: SET_EMAIL_ADDRESS,
  payload: { emailAddress },
});

export const SetFullName = (
  fullName: SetFullNameAction["payload"]["fullName"]
): ConsumerActionType => ({
  type: SET_FULL_NAME,
  payload: { fullName },
});

export const createConsumer = (
  mobileNumber: CreateConsumerAction["payload"]["mobileNumber"],
  emailAddress?: CreateConsumerAction["payload"]["emailAddress"],
  fullName?: CreateConsumerAction["payload"]["fullName"]
): ConsumerActionType => ({
  type: CREATE_CONSUMER,
  payload: { mobileNumber, emailAddress, fullName },
});

export const updateConsumer = (
  consumer: ConsumerState
): ConsumerActionType => ({
  type: UPDATE_CONSUMER,
  payload: { consumer },
});

export const issueReceipt = (
  orderId: IssueReceiptAction["payload"]["orderId"]
): ConsumerActionType => ({
  type: ISSUE_RECEIPT,
  payload: { orderId },
});

export const updateUserAndIssueReceipt = (
  consumer: ConsumerState,
  orderId: number
): ConsumerActionType => ({
  type: UPDATE_CONSUMER_AND_ISSUE_RECEIPT,
  payload: { consumer, orderId },
});

//Used to Migrate the Consumer data from the Legacy Brod app
export const migrateConsumer = (
  legacyConsumer: ConsumerData
): ConsumerActionType => ({
  type: MIGRATE_CONSUMER,
  payload: { legacyConsumer },
});
