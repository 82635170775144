/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import prettyMs from "pretty-ms";
import Nav from "../../components/Nav/Nav";
import { env, routes } from "../../app-constants";
import { RootState } from "../../state/store/store";
import {
  DeliveryAddress,
  GoogleAddressPrediction,
  GoogleDistanceMatrix,
  GooglePlace,
  Location,
  Vendor,
  VendorGeo,
  Venue,
} from "../../types";
import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { orderActions, vendorActions } from "../../state/actions";
import withData from "../../highOrderComponents/withData";
import VendorRow from "../../components/VendorList/VendorRow";
import { discoveredVendors } from "../../state/selectors/vendor";
import { withWholeMinutes } from "../../services/dateTimeFormatService";
import { PlaceOrderButton } from "./PlaceOrderButton";
import { AddressInput } from "./AddressInput";
import moment from "moment";

const getGooglePlaceDetails = (placeId: string) => {
  // @ts-ignore
  const placeService = new window.google.maps.places.PlacesService(
    document.createElement("div")
  );

  return new Promise<GooglePlace>((resolve) => {
    placeService.getDetails(
      {
        placeId: placeId,
        fields: ["address_components", "geometry"],
      },
      (place: GooglePlace) => {
        return resolve(place);
      }
    );
  });
};

const getGoogleDistanceMatrix = (
  location: Location,
  vendorLocation?: VendorGeo
) => {
  // @ts-ignore
  const distanceMatrixService = new window.google.maps.DistanceMatrixService();

  if (vendorLocation) {
    return new Promise<{ matrix: GoogleDistanceMatrix; status: string }>(
      (resolve) => {
        distanceMatrixService.getDistanceMatrix(
          {
            origins: [
              { lat: vendorLocation.latitude, lng: vendorLocation.longitude },
            ],
            destinations: [{ lat: location.latitude, lng: location.longitude }],
            travelMode: "DRIVING",
          },
          (matrix: GoogleDistanceMatrix, status: string) => {
            return resolve({
              matrix,
              status,
            });
          }
        );
      }
    );
  } else {
    return Promise.resolve(undefined);
  }
};

export type DeliveryDetails = {
  /** True if the vendor will deliver to the user's selected delivery address. */
  deliveryPossible: boolean;
  /** An error message. Set if we failed to load the delivery details. */
  error?: string;
  /** The estimated time it will take to drive from the store to the delivery address. */
  deliveryTimeInMillis?: number;
};

/**
 * Determines the validity of the selected address, that is it has to be under the configured driving distance.
 * @return validity of the address and the estimated delivery time based on the distance matrix driving time.
 */
const loadDeliveryDetails = async (
  location: Location,
  vendor: Vendor
): Promise<DeliveryDetails> => {
  const distanceResponse = await getGoogleDistanceMatrix(
    location,
    vendor.geoLocation
  );

  // If we cannot get distance matrix for driving direction, return error.
  if (
    !distanceResponse ||
    !distanceResponse.matrix ||
    distanceResponse.matrix.rows.length === 0 ||
    distanceResponse.status !== "OK"
  ) {
    return {
      deliveryPossible: false,
      error: "Cannot get driving direction. Please try again.",
    };
  }

  const { matrix } = distanceResponse;

  // Always access first element as we only calculate one set of distance.
  // Sample response from distance matrix:
  // @see https://developers.google.com/maps/documentation/distance-matrix/start#sample-request
  if (
    matrix.rows[0].elements.length > 0 &&
    matrix.rows[0].elements[0].status === "OK"
  ) {
    const drivingDistanceInMeters = matrix.rows[0].elements[0].distance.value;
    const drivingTimeInSeconds = matrix.rows[0].elements[0].duration.value;
    const deliveryTimeInMillis = withWholeMinutes(
      (vendor?.tts || 0) +
        drivingTimeInSeconds * 1000 +
        (vendor.vendorOrderConfiguration?.deliveryTimeBuffer || 0)
    );

    if (
      drivingDistanceInMeters >
      (vendor.vendorOrderConfiguration?.deliveryMaxDistance || 0) * 1000
    ) {
      return {
        deliveryPossible: false,
        error:
          "Sorry, we can't deliver to your address. Please try our pick up option instead.",
      };
    }

    return {
      deliveryPossible: true,
      deliveryTimeInMillis,
    };
  } else {
    return {
      deliveryPossible: false,
      error:
        "Sorry, we can't deliver to your address. Please try our pick up option instead.",
    };
  }
};

/** Load the Google Maps API script. */
const loadGoogleMapsScript = (onLoad: () => void): void => {
  const script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.setAttribute(
    "src",
    `https://maps.googleapis.com/maps/api/js?key=${env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
  );
  script.async = true;
  script.onload = onLoad;
  document.head.appendChild(script);
};

/**
 * We have to do a clean up to remove all Google API scripts tags when we navigate away (unmount).
 * Because the react-google-places-autocomplete only clears main Google API script tag, not the ones
 * auto loaded, e.g. common.js etc.
 */
const cleanUpGoogleScriptTags = (): void => {
  const scriptElements = document.getElementsByTagName("script");

  const googleMapApiScripts = Array.from(scriptElements).filter((script) =>
    script.src.includes("maps.googleapis")
  );

  googleMapApiScripts.forEach((script) => {
    script.remove();
  });

  // Remove any Google API lib reference.
  // @ts-ignore
  window.google = undefined;
};

/**
 * For each vendor, look up whether they will deliver to the patron's selected address and how long
 * it would take them to deliver to it.
 */
const loadDeliveryDetailsForVendors = async (
  selectedDeliveryAddressLocation: Location,
  vendors: readonly Vendor[]
): Promise<Record<number, DeliveryDetails>> => {
  const detailsArray: {
    vendorId: number;
    details: DeliveryDetails;
  }[] = await Promise.all(
    vendors.map(async (vendor) => {
      const details = await loadDeliveryDetails(
        selectedDeliveryAddressLocation,
        vendor
      );
      const vendorId = vendor.id;
      return { vendorId, details };
    })
  );

  // Convert from
  // [ { vendorId: 123, details: {...} }, { vendorId: 456, details: {...} } ]
  // to
  // { 123: {...}, 456: {...} }
  return detailsArray.reduce(
    (acc, { vendorId, details }) => ({
      ...acc,
      [vendorId]: details,
    }),
    {}
  );
};

/**
 * @param addressPrediction The address in the autocomplete menu that the user selected after
 *                          entering their address.
 * @return The matching address in a structured format, or an error message.
 */
const fetchDeliveryAddress = async (
  addressPrediction: GoogleAddressPrediction
): Promise<DeliveryAddress | string> => {
  // Fetches delivery place details, so we can use more detailed address components.
  const place = await getGooglePlaceDetails(addressPrediction.place_id);

  // Prediction description is a string with ',' to separate address parts.
  // e.g. 100 London Street, Canberra, ACT.
  const addressParts = addressPrediction.description.split(",");

  // First part of the address prediction is the street address, Google Places API will not return unit from the address
  // components or formatted_address, so we make sure we extract everything before the first ',' in address description.
  // e.g. 23/100 London Street, Canberra, ACT.
  // @see https://developers.google.com/places/web-service/details#PlaceDetailsResponses for place response example.
  const streetAddress = addressParts[0];
  const addressLocality = place.address_components.find((c) =>
    c.types.includes("locality")
  );
  const postalCode = place.address_components.find((c) =>
    c.types.includes("postal_code")
  );
  const addressRegion = place.address_components.find((c) =>
    c.types.includes("administrative_area_level_1")
  );
  const addressCountry = place.address_components.find((c) =>
    c.types.includes("country")
  );
  const location = {
    longitude: place.geometry.location.lng(),
    latitude: place.geometry.location.lat(),
  };

  // We want to hide the country part of the address description.
  // The reason for using description is the Google API response for structured address do not contain unit information,
  // so instead of 100/230 Street name, it will just be 230 Street name.
  // The description will have format as "100 London Street, Canberra, ACT, Australia", so the last part will be truncated.
  const addressPartsToDisplay = addressParts.splice(0, addressParts.length - 1);
  const description = addressPartsToDisplay.join(", ");

  if (!addressLocality || !postalCode || !addressRegion || !addressCountry) {
    // Terminate the function if address is not valid, so no additional Google API calls (distance matrix) wasted.
    return "Cannot find your address. Please try again.";
  }

  // Construct the selected address and return it.
  return {
    place,
    streetAddress: streetAddress,
    addressLocality: addressLocality.long_name,
    postalCode: postalCode.long_name,
    addressRegion: addressRegion.long_name,
    addressCountry: addressCountry.long_name,
    addressDescription: description,
    location: location,
  };
};

const vendorsTakingDeliveryOrders = (
  venues: ReadonlyArray<Venue>,
  vendorsByVenue: Record<number, ReadonlyArray<Vendor>>
) =>
  discoveredVendors(venues, vendorsByVenue).filter(
    (vendor) =>
      !!vendor &&
      (vendor.nextServiceTime !== undefined || vendor.isOpen) &&
      vendor.vendorOrderConfiguration?.availableServiceModes.includes(
        "HomeDelivery"
      )
  );

/**
 * @return A `VendorRow` element for each vendor that's available for the patron to select for
 *         delivery. If the patron hasn't entered their address yet or the delivery details are
 *         still loading (i.e. `deliveryDetailsByVendorId` is undefined), returns a row for every
 *         vendor. (The rows will show "enter deliver address" or loading spinners.)
 */
const vendorRowsAvailableForDelivery = (
  deliveryVendors: Vendor[],
  queryParams: string,
  selectedDeliveryAddress: DeliveryAddress | undefined,
  deliveryDetailsByVendorId: Record<number, DeliveryDetails> | undefined
) => {
  // Show all of the vendors if the user hasn't entered their address yet. Otherwise, only show the
  // vendors that will deliver to the user's address.
  const vendorsToShow =
    !selectedDeliveryAddress || !deliveryDetailsByVendorId
      ? deliveryVendors
      : deliveryVendors.filter(
          (vendor) =>
            // Also show the vendor if we haven't loaded the delivery details for it yet.
            !deliveryDetailsByVendorId[vendor.id] ||
            deliveryDetailsByVendorId[vendor.id].deliveryPossible
        );

  return vendorsToShow.map((vendor) => (
    <VendorRow
      key={vendor.id}
      vendor={vendor}
      queryParams={queryParams}
      deliveryPage={true}
      haveDeliveryAddress={selectedDeliveryAddress !== undefined}
      deliveryDetails={deliveryDetailsByVendorId?.[vendor.id]}
    />
  ));
};

/**
 * The list of vendors for the user to choose from, or a message to explain why we're not showing
 * it.
 */
const VendorSelection = (props: {
  noVendorsTakingDeliveryOrders: boolean;
  vendorRows: JSX.Element[];
}): JSX.Element => (
  <>
    <div className="header-row">
      <div className="header-cols">Store</div>
      <div className="header-cols">Location</div>
    </div>
    {props.noVendorsTakingDeliveryOrders ? (
      <div className="vendor-unavailable">
        None of our outlets are currently available for ordering. Please try
        later!
      </div>
    ) : (
      <div className="vendor-selection">
        {props.vendorRows.length !== 0 ? (
          props.vendorRows
        ) : (
          <div key="NoVendor" className="vendor">
            <div className="row content-row vertical-align">
              <div className="headerText">
                Sorry, none of our stores are currently available for home
                delivery to your location.
              </div>
              <div className="bodyText">
                {
                  "We may be too far away from you, however we'd love to take your takeaway order!"
                }
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </>
);

const AddressInputSection = (props: {
  googleScriptLoaded: boolean;
  addressDescription: string;
  selectedDeliveryAddress: DeliveryAddress | undefined;
  timeToService: number | undefined;
  pickedTime: string | undefined;
  handleAddressSelected: (
    addressPrediction: GoogleAddressPrediction
  ) => Promise<void>;
  clearAddress: () => void;
}) => (
  <div className="address-input-section">
    {
      // Only show the estimated delivery time when TTS is available and the order is not scheduled
      props.timeToService &&
      env.REACT_APP_SHOW_TIME_TO_SERVICE &&
      !props.pickedTime &&
      !env.REACT_APP_MULTI_VENDOR_SUPPORT ? (
        <div className="time-to-service">
          <span>
            Delivery to this address <br />~
            {prettyMs(props.timeToService, { verbose: true })}
          </span>
        </div>
      ) : (
        <></>
      )
    }
    {props.googleScriptLoaded ? (
      <AddressInput
        {...{
          addressDescription: props.addressDescription,
          handleAddressSelected: props.handleAddressSelected,
          clearAddress: props.clearAddress,
          selectedDeliveryAddress: props.selectedDeliveryAddress,
        }}
      />
    ) : (
      <></>
    )}
  </div>
);

/**
 * Responsible for rendering home delivery page and handling home delivery address for the next order.
 */
const Delivery = () => {
  const dispatch = useDispatch();
  const venues = useSelector((state: RootState) => state.venues.list);
  const vendorsByVenue = useSelector(
    (state: RootState) => state.vendorsByVenue.vendors
  );
  const order = useSelector((state: RootState) => state.order);

  const [addressDescription, setAddressDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [timeToService, setTimeToService] = useState<number | undefined>(
    undefined
  );
  const [deliveryDetailsByVendorId, setDeliveryDetails] = useState<
    Record<number, DeliveryDetails> | undefined
  >(undefined);
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState<boolean>(false);

  const selectedDeliveryAddress = order.deliveryAddress;
  const pickedTime = order.pickedTime;
  const deliveryVendors = vendorsTakingDeliveryOrders(venues, vendorsByVenue);
  const queryParams = useLocation().search;

  useEffect(() => {
    // Clean up after this effect, similar to componentWillUnmount.
    return function cleanup() {
      cleanUpGoogleScriptTags();
    };
  }, []);

  // Calculate the validity of the selected address for each vendor.
  useEffect(() => {
    if (googleScriptLoaded && selectedDeliveryAddress) {
      loadDeliveryDetailsForVendors(
        selectedDeliveryAddress.location,
        vendorsTakingDeliveryOrders(venues, vendorsByVenue)
      ).then((details) => setDeliveryDetails(details));
    } else {
      setDeliveryDetails(undefined);
    }
  }, [selectedDeliveryAddress, googleScriptLoaded, vendorsByVenue, venues]);

  // We need to load the main google API script once every time we visit this page.
  // Because react-google-places-autocomplete cannot handle reloading the script.
  useEffect(() => {
    if (!googleScriptLoaded) {
      loadGoogleMapsScript(() => setGoogleScriptLoaded(true));
    }
  }, [googleScriptLoaded]);

  // Make sure the address field is updated
  useEffect(() => {
    if (selectedDeliveryAddress) {
      setAddressDescription(selectedDeliveryAddress.addressDescription);
    }
  }, [selectedDeliveryAddress]);

  /**
   * Extracts address parts for order delivery address and validate the address to see if it is eligible for delivery.
   * @param addressPrediction from Google autocomplete.
   */
  const handleAddressSelected = async (
    addressPrediction: GoogleAddressPrediction
  ) => {
    // Reset error message.
    setErrorMessage(undefined);
    setDeliveryDetails(undefined);

    const selectedAddressOrError = await fetchDeliveryAddress(
      addressPrediction
    );

    // Set the selected address to global state.
    if (typeof selectedAddressOrError === "string") {
      setErrorMessage(selectedAddressOrError);
    } else {
      dispatch(orderActions.setDeliveryAddress(selectedAddressOrError));
    }
  };

  const clearAddress = () => {
    setErrorMessage(undefined);
    setTimeToService(undefined);
    dispatch(orderActions.clearDeliveryAddress());
    setAddressDescription("");
  };

  const vendorRows = vendorRowsAvailableForDelivery(
    deliveryVendors,
    queryParams,
    selectedDeliveryAddress,
    deliveryDetailsByVendorId
  );

  return (
    <div className="delivery-page col-md-8 offset-md-2 d-flex flex-column">
      <Nav
        className="nav-header"
        goTo={routes.START.PATH}
        search={queryParams}
        title="Home Delivery"
      />

      {env.REACT_APP_MULTI_VENDOR_SUPPORT ? (
        <VendorSelection
          noVendorsTakingDeliveryOrders={deliveryVendors.length === 0}
          vendorRows={vendorRows}
        />
      ) : (
        <></>
      )}

      {pickedTime ? (
        <div className="time-to-service">
          <span>
            Preferred Deliver Time {moment(pickedTime).format("h:mm a")}
          </span>
        </div>
      ) : (
        <></>
      )}

      <AddressInputSection
        googleScriptLoaded={googleScriptLoaded}
        addressDescription={addressDescription}
        selectedDeliveryAddress={selectedDeliveryAddress}
        timeToService={timeToService}
        handleAddressSelected={handleAddressSelected}
        clearAddress={clearAddress}
        pickedTime={pickedTime}
      />

      {!env.REACT_APP_MULTI_VENDOR_SUPPORT && errorMessage ? (
        <div className="error-notice">
          <span>{errorMessage}</span>
        </div>
      ) : (
        <></>
      )}

      {env.REACT_APP_MULTI_VENDOR_SUPPORT ? (
        <Footer className="mt-auto" />
      ) : (
        <PlaceOrderButton
          orderState={order}
          vendorsTakingDeliveryOrders={deliveryVendors}
          deliveryDetailsByVendorId={deliveryDetailsByVendorId}
          queryParams={queryParams}
          onClick={() =>
            dispatch(orderActions.selectServiceMode("HomeDelivery"))
          }
        />
      )}
    </div>
  );
};

export default withData({
  resolver: vendorActions.loadRequiredVenuesAndVendors,
  params: [env.REACT_APP_VENUE_ID],
})(Delivery);
