import React from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "../../state/types/menuTypes";
import Modal from "./Modal";

export interface IMenuSoldOutModalProps {
  soldOutItems: ReadonlyArray<MenuItem>;
  goTo?: string;
  isOpen: boolean;
  onClick: (event: any) => void;
}

export default (props: IMenuSoldOutModalProps) => (
  // @ts-ignore - Project Upgrade
  <Modal
    className="alert-modal"
    isOpen={props.isOpen}
    title="We've sold out!"
    content={
      <React.Fragment>
        <p className="alert-modal-text">
          We apologise that the below items are no longer available for your
          order!
        </p>
        <ul>
          {props.soldOutItems.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
      </React.Fragment>
    }
    actions={
      props.goTo ? (
        <Link
          onClick={props.onClick}
          className="btn btn-primary"
          to={props.goTo}
        >
          <i className="fas fa-check"></i>
          Ok
        </Link>
      ) : (
        <a href="#" onClick={props.onClick} className="btn btn-primary">
          <i className="fas fa-check"></i>
          Ok
        </a>
      )
    }
  ></Modal>
);
