/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { OrderItemOption } from "../../state/types/orderTypes";
import MenuGroupType from "./MenuGroupType";
import { Menu, MenuItem } from "../../state/types/menuTypes";
import { RootState } from "../../state/store/store";

export interface IMenuListProps {
  store: RootState;
  dispatchAction: Function;
  groupedMenuItems: ReadonlyArray<MenuItem>;
  userOrder: Record<string, Record<string, OrderItemOption>>;
  menu: Menu;
}

const MenuList = ({
  store,
  dispatchAction,
  userOrder,
  menu,
  groupedMenuItems,
}: IMenuListProps) => (
  <div className="order-items">
    {groupedMenuItems.map((item) => (
      // @ts-ignore - Project Upgrade
      <MenuGroupType
        store={store}
        dispatchAction={dispatchAction}
        userOrder={userOrder}
        key={item.name}
        title={item.name}
        menu={menu}
        // @ts-ignore - Project Upgrade
        list={item.menuItems.filter((item) => item.visible)}
      />
    ))}
  </div>
);

export default MenuList;
