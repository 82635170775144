import { all, call, put, takeEvery } from "redux-saga/effects";
import networkService from "../../services/networkService";
import {
  CHECK_NETWORK_STATUS,
  NETWORK_IS_CONNECTED,
  NETWORK_IS_DISCONNECTED,
} from "../types/networkStatusTypes";

export function* checkNetworkStatus() {
  try {
    yield call(networkService.checkNetworkStatus);
    yield put({ type: NETWORK_IS_CONNECTED });
  } catch (error) {
    yield put({ type: NETWORK_IS_DISCONNECTED });
  }
}

export function* networkStatusRootSaga() {
  yield all([takeEvery(CHECK_NETWORK_STATUS, checkNetworkStatus)]);
}
