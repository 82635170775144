/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { env, routes } from "../../app-constants";
import { orderFinishedStatuses } from "../../app-constants/order";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/Nav/Nav";
import { isSessionExpired } from "../../components/OrderSession/OrderSession";
import { useInterval } from "../../components/timer/timer";
import VendorRow from "../../components/VendorList/VendorRow";
import withData from "../../highOrderComponents/withData";
import { useQueryParams } from "../../hooks/useQueryParams";
import utils from "../../services/utils";
import { resetOrderAction, vendorActions } from "../../state/actions";
import { discoveredVendors } from "../../state/selectors/vendor";
import { RootState } from "../../state/store/store";
import { Vendor } from "../../types";

/**
 * Renders an list of vendors, sorted by id.
 */
const VendorList: React.FC<{
  vendors: Vendor[];
  rawQueryParams: string;
}> = ({ vendors, rawQueryParams }) => (
  <>
    {vendors
      .sort((v1, v2) => v1.id - v2.id)
      .map((vendor) => (
        <VendorRow
          key={vendor.id}
          vendor={vendor}
          queryParams={rawQueryParams}
          deliveryPage={false}
        />
      ))}
  </>
);

/**
 * Dropdown toggle for a list of non-primary vendors.
 */
const VendorDropdown: React.FC<{
  vendors: Vendor[];
  queryParams: URLSearchParams;
}> = ({ vendors, queryParams }) => {
  const [dropdownIsOpen, toggleDropdown] = useState<boolean>(false);

  return vendors.length !== 0 ? (
    <>
      <button
        type="button"
        className="btn btn-primary btn-vendor-dropdown"
        onClick={() => toggleDropdown(!dropdownIsOpen)}
      >
        {dropdownIsOpen ? "Show Less" : "Show More"}
      </button>
      {dropdownIsOpen ? (
        <div className="vendor-selection">
          <VendorList
            vendors={vendors}
            rawQueryParams={queryParams.toString()}
          />
        </div>
      ) : null}
    </>
  ) : null;
};

/**
 * When a primary vendor is specified via Query params, move all other vendors
 * to a dropdown.
 */
const VendorDisplay: React.FC<{
  vendors: Vendor[];
  queryParams: URLSearchParams;
}> = ({ vendors, queryParams }) => {
  const dispatch = useDispatch();
  const queryVendorId = queryParams.get("vendorId");
  const order = useSelector((state: RootState) => state.order);
  const placedOrder = useSelector((state: RootState) => state.placedOrder);
  const [primaryVendorId, setPrimaryVendorId] = useState<number>(0);
  const primaryVendor = vendors.find((vendor) => vendor.id === primaryVendorId);

  const history = useHistory();

  useEffect(() => {
    if (queryVendorId) {
      setPrimaryVendorId(parseInt(queryVendorId));
      // Clean up vendor id in query parameter since it only used for QR code.
      const updatedQueryParams = utils.removeQueryParameter(
        "vendorId",
        queryParams.toString()
      );

      history.replace({ search: updatedQueryParams });
      if (
        isSessionExpired(order) ||
        // @ts-ignore - Project Upgrade
        orderFinishedStatuses.includes(placedOrder.data.orderStatus)
      ) {
        dispatch(resetOrderAction(true));
      }
    }
  }, [
    dispatch,
    history,
    order,
    placedOrder,
    queryVendorId,
    queryParams,
    vendors,
  ]);

  return vendors.length !== 0 ? (
    primaryVendor !== undefined ? (
      <>
        <div className="vendor-selection">
          <VendorRow
            key={primaryVendor.id}
            vendor={primaryVendor}
            queryParams={queryParams.toString()}
            deliveryPage={false}
          />
        </div>
        <VendorDropdown
          vendors={vendors.filter((vendor) => vendor.id !== primaryVendor.id)}
          queryParams={queryParams}
        />
      </>
    ) : (
      <div className="vendor-selection">
        <VendorList vendors={vendors} rawQueryParams={queryParams.toString()} />
      </div>
    )
  ) : (
    <div className="vendor-unavailable">
      None of our outlets are currently available for ordering. Please try
      later!
    </div>
  );
};

/**
 * Responsible for rendering discover Vendor page and handling vendor selection for the next order.
 */
export const DiscoverVendor = () => {
  const dispatch = useDispatch();
  const vendorRefresh = 30000;
  const venues = useSelector((state: RootState) => state.venues.list);
  const vendorsByVenue = useSelector(
    (state: RootState) => state.vendorsByVenue.vendors
  );
  const allVendors = discoveredVendors(venues, vendorsByVenue);
  const vendors = allVendors
    ? allVendors.filter(
        (f) => !!f && (f.nextServiceTime !== undefined || f.isOpen === true)
      )
    : [];

  const refreshVendors = () => {
    dispatch(
      vendorActions.loadRequiredVenuesAndVendors(env.REACT_APP_VENUE_ID)
    );
  };

  useInterval(refreshVendors, vendorRefresh);

  const queryParams = useQueryParams();

  return (
    <div className="discover-vendor-page col-md-8 offset-md-2 d-flex flex-column">
      <Nav
        className="nav-header"
        goTo={routes.START.PATH}
        search={queryParams.toString()}
        title="Select Your Option Below"
      />
      <div className="header-row">
        <div className="header-cols">Store</div>
        <div className="header-cols">Location</div>
      </div>
      <VendorDisplay vendors={vendors} queryParams={queryParams} />
      <Footer />
    </div>
  );
};

export default withData({
  resolver: vendorActions.loadRequiredVenuesAndVendors,
  params: [env.REACT_APP_VENUE_ID],
})(DiscoverVendor);
