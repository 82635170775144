import {
  AddItemToOrderAction,
  ADD_ITEM_TO_ORDER,
  CalculateOrderAmountAction,
  CALCULATE_ORDER_AMOUNT,
  OrderActionType,
  ORDER_AMOUNT_RECALCULATED,
  RemoveItemFromOrderAction,
  RemoveSoldOutItemsAction,
  REMOVE_ITEM_FROM_ORDER,
  REMOVE_SOLD_OUT_ITEMS,
  ReorderLastOrderAction,
  REORDER_LAST_ORDER,
  SelectServiceModeAction,
  SELECT_SERVICE_MODE,
  SetOrderConfigurationAction,
  SetOrderCouponAction,
  SetPickTimeAction,
  SET_ORDER_CONFIGURATION,
  SET_ORDER_COUPON,
  SET_PICK_TIME,
  SET_DELIVERY_ADDRESS,
  CLEAR_DELIVERY_ADDRESS,
  START_ORDER_SESSION,
  StartOrderSessionAction,
} from "../types/orderTypes";
import { DeliveryAddress } from "../../types";
import { CONFIRM_VENDOR } from "../types/placeOrderTypes";

export const defaultMenuItemOption = "default";

export const pickedTime = (
  pickedTime: SetPickTimeAction["payload"]["pickedTime"]
): OrderActionType => ({
  type: SET_PICK_TIME,
  payload: { pickedTime },
});

export const addItemToOrder = (
  item: AddItemToOrderAction["payload"]["item"],
  selectedOption: AddItemToOrderAction["payload"]["selectedOption"] = defaultMenuItemOption
): OrderActionType => ({
  payload: { item, selectedOption },
  type: ADD_ITEM_TO_ORDER,
});

export const subtractItemFromOrder = (
  item: RemoveItemFromOrderAction["payload"]["item"],
  selectedOption: RemoveItemFromOrderAction["payload"]["selectedOption"] = defaultMenuItemOption
): OrderActionType => ({
  payload: { item, selectedOption },
  type: REMOVE_ITEM_FROM_ORDER,
});

export const setOrderConfiguration = (
  orderConfig: SetOrderConfigurationAction["payload"]["orderConfig"]
): OrderActionType => ({
  payload: { orderConfig },
  type: SET_ORDER_CONFIGURATION,
});

export const calculateOrderAmount = (
  vendorId: CalculateOrderAmountAction["payload"]["vendorId"],
  order: CalculateOrderAmountAction["payload"]["order"]
): OrderActionType => ({
  payload: { vendorId, order },
  type: CALCULATE_ORDER_AMOUNT,
});

export const removeSoldOutItems = (
  item: RemoveSoldOutItemsAction["payload"]["item"]
): OrderActionType => ({ payload: { item }, type: REMOVE_SOLD_OUT_ITEMS });

export const selectServiceMode = (
  serviceMode: SelectServiceModeAction["payload"]["serviceMode"]
): OrderActionType => ({ payload: { serviceMode }, type: SELECT_SERVICE_MODE });

export const reorderLastOrder = (
  vendorId: ReorderLastOrderAction["payload"]["vendorId"]
): OrderActionType => ({ payload: { vendorId }, type: REORDER_LAST_ORDER });

export const setOrderCoupon = (
  vendorId: SetOrderCouponAction["payload"]["vendorId"],
  orderId: SetOrderCouponAction["payload"]["orderId"],
  couponCode: SetOrderCouponAction["payload"]["couponCode"],
  order: SetOrderCouponAction["payload"]["order"]
): OrderActionType => ({
  payload: { vendorId, orderId, couponCode, order },
  type: SET_ORDER_COUPON,
});

export const orderAmountCalculated = (): OrderActionType => ({
  type: ORDER_AMOUNT_RECALCULATED,
});

export const setDeliveryAddress = (deliveryAddress: DeliveryAddress) => ({
  type: SET_DELIVERY_ADDRESS,
  payload: {
    deliveryAddress: deliveryAddress,
  },
});

export const clearDeliveryAddress = () => ({
  type: CLEAR_DELIVERY_ADDRESS,
});

/**
 * Starts the ordering session with specific time in millis.
 * @param startTime time of the session start in epoch millis.
 * @param vendorId the id of the vendor the order is placed against.
 */
export const startOrderSession = (
  startTime: number,
  vendorId: number
): StartOrderSessionAction => ({
  type: START_ORDER_SESSION,
  payload: {
    startTime,
    vendorId,
  },
});

export const confirmVendor = () => ({ type: CONFIRM_VENDOR });
