/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Utils from "../../services/utils";
import {
  ADD_ITEM_TO_ORDER,
  APPLYING_COUPON,
  COMPLETE_PAYMENT_SUCCESS,
  FETCHING_ORDER_AMOUNT,
  FETCH_ORDER_AMOUNT_ERROR,
  FETCH_ORDER_AMOUNT_SUCCESS,
  OrderActionType,
  OrderConfiguration,
  OrderState,
  ORDER_AMOUNT_RECALCULATED,
  ORDER_COUPON_APPLY_FAILED,
  ORDER_COUPON_APPLY_SUCCESS,
  REMOVE_ITEM_FROM_ORDER,
  REMOVE_SOLD_OUT_ITEMS,
  REORDER_SUCCESS,
  SELECT_SERVICE_MODE,
  SET_ORDER_CONFIGURATION,
  SET_PICK_TIME,
  SET_DELIVERY_ADDRESS,
  CLEAR_DELIVERY_ADDRESS,
  START_ORDER_SESSION,
} from "../types/orderTypes";
import {
  CONFIRM_VENDOR,
  PlaceOrderActionType,
  RESET_ORDER_STATE,
} from "../types/placeOrderTypes";

export const generateOptionKey = (selectedOption: any, key: string) =>
  selectedOption.reduce(
    // @ts-ignore - Project Upgrade
    (s, o) => o["groupId"] + "-" + s + "-" + o[key],
    ""
  );

export const defaultState: OrderState = {
  pickedTime: "",
  userOrder: {},
  lastOrder: {},
  orderBurden: 0,
  vendorId: 0,
  vendorConfirmed: false,
  reInitiatePayment: false,
  deliveryAddress: undefined,
  orderConfiguration: {
    defaultOrderServiceMode: "DineIn",
  },
  orderAmount: {
    error: "",
    fetchStatus: "",
    paymentSummary: {
      items: [],
      subTotals: {},
      total: {},
    },
  },
  orderSessionStartTime: undefined,
};

export const orderReducer = (
  state: OrderState = defaultState,
  action: OrderActionType | PlaceOrderActionType
): OrderState => {
  switch (action.type) {
    case SET_PICK_TIME:
      return { ...state, pickedTime: action.payload.pickedTime };
    case ADD_ITEM_TO_ORDER:
      return handleAddItemOption(
        state,
        action.payload.item,
        action.payload.selectedOption
      );
    case REMOVE_ITEM_FROM_ORDER:
      return handleRestItemOption(
        state,
        action.payload.item,
        action.payload.selectedOption
      );
    case SET_ORDER_CONFIGURATION:
      return setOrderConfiguration(state, action.payload.orderConfig);
    case FETCHING_ORDER_AMOUNT:
      return {
        ...state,
        orderAmount: {
          ...state.orderAmount,
          fetchStatus: "fetching",
        },
      };
    case FETCH_ORDER_AMOUNT_SUCCESS:
      const menuItemsDecoded = Utils.decodeMenuItemName(
        action.payload.paymentSummary.items
      );
      const paymentSummary = {
        ...action.payload.paymentSummary,
        items: menuItemsDecoded,
      };
      return {
        ...state,
        orderAmount: {
          paymentSummary,
          fetchStatus: "success",
          error: "",
        },
      };
    case APPLYING_COUPON:
      return {
        ...state,
        applyingCoupon: true,
        applyingCode: action.payload.couponCode,
        couponError: undefined,
      };
    case ORDER_COUPON_APPLY_SUCCESS:
      return {
        ...state,
        reInitiatePayment: true,
        appliedCoupon: action.payload.appliedCoupon,
        orderAmount: {
          paymentSummary: action.payload.updatedInvoice,
          fetchStatus: "success",
          error: "",
        },
        couponError: undefined,
        applyingCoupon: false,
      };
    case ORDER_COUPON_APPLY_FAILED:
      return {
        ...state,
        reInitiatePayment: false,
        appliedCoupon: undefined,
        couponError: action.payload,
        applyingCoupon: false,
      };
    // Make sure coupon is cleared on new pending order creation.
    case "PLACE_ORDER":
      return {
        ...state,
        couponError: undefined,
        reInitiatePayment: false,
        appliedCoupon: undefined,
      };
    case ORDER_AMOUNT_RECALCULATED:
      return {
        ...state,
        reInitiatePayment: false,
      };
    case FETCH_ORDER_AMOUNT_ERROR:
      return {
        ...state,
        orderAmount: {
          ...state.orderAmount,
          fetchStatus: "failed",
          error: action.payload.error,
        },
      };
    case REMOVE_SOLD_OUT_ITEMS:
      return removeSoldOutItems(state, action.payload.item);
    case SELECT_SERVICE_MODE:
      return {
        ...state,
        orderConfiguration: {
          ...state.orderConfiguration,
          selectedServiceMode: action.payload.serviceMode,
        },
      };
    case COMPLETE_PAYMENT_SUCCESS:
      return { ...state, lastOrder: state.userOrder };
    case REORDER_SUCCESS:
      return { ...state, userOrder: action.payload.lastOrder };
    case RESET_ORDER_STATE:
      return { ...defaultState, deliveryAddress: state.deliveryAddress };
    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload.deliveryAddress,
      };
    case CLEAR_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: undefined };
    case START_ORDER_SESSION:
      return {
        ...state,
        orderSessionStartTime: action.payload.startTime,
        vendorId: action.payload.vendorId,
      };
    case CONFIRM_VENDOR:
      return {
        ...state,
        vendorConfirmed: true,
      };
    default:
      return state;
  }
};

function handleAddItemOption(
  state: OrderState,
  item: any,
  selectedOption: any
): OrderState {
  if (typeof selectedOption === "string") {
    const prevSelectedItem = state.userOrder[item.name] || {};
    // When item to add does not exists we need to add it to the array
    if (!prevSelectedItem[selectedOption]) {
      return {
        ...state,
        orderBurden: state.orderBurden + (item.burden || 1),
        userOrder: {
          ...state.userOrder,
          [item.name]: {
            ...prevSelectedItem,
            [selectedOption]: {
              id: item.id,
              count: 1,
              price: item.price,
              name: selectedOption,
              itemType: item.itemType,
              burden: item.burden,
              isRestricted: item.isRestricted,
            },
          },
        },
      };
    }

    // When item to add exists we need to increment the counter
    return {
      ...state,
      orderBurden: state.orderBurden + (item.burden || 1),
      userOrder: {
        ...state.userOrder,
        [item.name]: {
          ...prevSelectedItem,
          [selectedOption]: {
            ...prevSelectedItem[selectedOption],
            count: prevSelectedItem[selectedOption].count + 1,
          },
        },
      },
    };
  }

  // Handling if the selected options are array of modifiers.
  const selectedModifiers = selectedOption;
  const prevSelectedItem = state.userOrder[item.name] || {};
  const optionKey = generateOptionKey(selectedModifiers, "id");
  if (prevSelectedItem[optionKey]) {
    return {
      ...state,
      orderBurden: state.orderBurden + (item.burden || 1),
      userOrder: {
        ...state.userOrder,
        [item.name]: {
          ...prevSelectedItem,
          [optionKey]: {
            ...prevSelectedItem[optionKey],
            count: prevSelectedItem[optionKey].count + 1,
          },
        },
      },
    };
  }

  return {
    ...state,
    orderBurden: state.orderBurden + (item.burden || 1),
    userOrder: {
      ...state.userOrder,
      [item.name]: {
        ...prevSelectedItem,
        [optionKey]: {
          id: item.id,
          count: 1,
          price: item.price,
          name: optionKey,
          modifiers: selectedModifiers,
          itemType: item.itemType,
          burden: item.burden,
          isRestricted: item.isRestricted,
        },
      },
    },
  };
}

function handleRestItemOption(
  state: OrderState,
  item: any,
  selectedOption: string
): OrderState {
  const prevSelectedItem = { ...state.userOrder[item.name] };
  const optionToEdit = prevSelectedItem[selectedOption];

  if (!optionToEdit) {
    return state;
  }
  // If the item to rest is the last, here we check if we can remove the
  // object from the user order
  if (optionToEdit.count === 1) {
    delete prevSelectedItem[selectedOption];
    const newState = {
      ...state,
      orderBurden: Math.max(0, state.orderBurden - (item.burden || 1)),
      userOrder: { ...state.userOrder },
    };
    if (Object.keys(prevSelectedItem).length) {
      newState.userOrder = {
        ...newState.userOrder,
        [item.name]: {
          ...prevSelectedItem,
        },
      };
    } else {
      delete newState.userOrder[item.name];
    }
    return newState;
  }
  return {
    ...state,
    orderBurden: Math.max(0, state.orderBurden - (item.burden || 1)),
    userOrder: {
      ...state.userOrder,
      [item.name]: {
        ...prevSelectedItem,
        [selectedOption]: {
          ...prevSelectedItem[selectedOption],
          count: prevSelectedItem[selectedOption].count - 1,
        },
      },
    },
  };
}

function setOrderConfiguration(
  state: OrderState,
  orderConfiguration: OrderConfiguration
): OrderState {
  return {
    ...state,
    orderConfiguration,
  };
}

function removeSoldOutItems(state: OrderState, item: any): OrderState {
  const itemInOrder = state.userOrder[item.name];
  const options = Object.keys(itemInOrder);
  const count = options.reduce((acc: number, option: string) => {
    return itemInOrder[option].count + acc;
    // tslint:disable-next-line
  }, 0);
  if (item.currentInventory === 0 || item.currentInventory < count) {
    const difference = Math.abs(item.currentInventory - count);
    let newState = { ...state };
    const currOptionIndex = 0;
    const optionToSubtract = options[currOptionIndex];
    // tslint:disable-next-line
    for (let i = 0; i < difference; i++) {
      newState = handleRestItemOption(newState, item, optionToSubtract);
    }
    return { ...newState };
  }
  return { ...state };
}
