import {
  CHECK_NETWORK_STATUS,
  NetworkStatusActionType,
  NetworkStatusState,
  NETWORK_IS_CONNECTED,
  NETWORK_IS_DISCONNECTED,
} from "../types/networkStatusTypes";

const defaultState: NetworkStatusState = {
  fetchStatus: "",
};

export const networkStatusReducer = (
  state: NetworkStatusState = defaultState,
  action: NetworkStatusActionType
): NetworkStatusState => {
  switch (action.type) {
    case CHECK_NETWORK_STATUS:
      return {
        fetchStatus: "fetching",
      };
    case NETWORK_IS_CONNECTED:
      return {
        fetchStatus: "success",
      };
    case NETWORK_IS_DISCONNECTED:
      return {
        fetchStatus: "failed",
      };
    default:
      return state;
  }
};
