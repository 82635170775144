/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { IntlProvider } from "react-intl";
import { ConnectedComponent, Provider } from "react-redux";
import { Redirect, RouteComponentProps, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { PreloadedState, Store } from "redux";
import { ILocalStorageService } from "../../services/localStorageService";
import { FETCH_STATUS } from "../../state/actions";
import storeFactory, { RootState } from "../../state/store/store";
import NetworkErrorHandler from "../Error/NetworkErrorHandler";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import OrderSession from "../OrderSession/OrderSession";
import ScrollToTop from "../ScrollTo/ScrollToTop";
import { ITransitionValidator } from "../TransitionValidators/TransitionValidator";
import RouteWithSubRoutes from "./SubRoutes";
import utils from "../../services/utils";

export interface IRoutItem {
  path?: string;
  exact?: boolean;
  component:
    | ConnectedComponent<any, never>
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  step?: number;
  routes?: IRoutItem[];
  transitionValidators?: ITransitionValidator[];
  nextRoute?: IRoutItem;
}

export interface IRouterProps {
  routes: IRoutItem[];
  localStorageService: ILocalStorageService;
}

/**
 * If any of the state data items were being fetched when the state was last
 * saved, that fetch must have been interrupted e.g. by the user closing the
 * tab. This function updates the `fetchStatus` of those items to `'failed'`.
 *
 * The main purpose of this is so the page knows not to show the loading
 * spinner.
 *
 * @param state The state loaded from Local Storage.
 */
const stateWithFetchStatusesResolved = (
  state: boolean | undefined | PreloadedState<RootState>
): PreloadedState<RootState> | undefined => {
  if (!state || typeof state !== "object") {
    return undefined;
  }

  const resolveFetchStatus = <T extends object>(stateData: T): T => {
    if (
      stateData &&
      "fetchStatus" in stateData &&
      (stateData as T & { fetchStatus: FETCH_STATUS }).fetchStatus ===
        "fetching"
    ) {
      return { ...stateData, fetchStatus: "failed" };
    }

    return stateData;
  };

  // Map over the state object.
  const stateWithTopLevelItemsResolved: PreloadedState<RootState> =
    Object.entries(state).reduce(
      (acc, [key, stateData]) => ({
        ...acc,
        [key]: resolveFetchStatus(stateData ?? {}),
      }),
      {}
    );

  if (
    stateWithTopLevelItemsResolved.order?.orderAmount.fetchStatus === "fetching"
  ) {
    // Change order.orderAmount.fetchStatus from 'fetching' to 'failed'.
    // order.orderAmount is a special case because it's the only object in the
    // state that has a fetchStatus property but isn't at the top level.
    return {
      ...stateWithTopLevelItemsResolved,
      order: {
        ...stateWithTopLevelItemsResolved.order,
        orderAmount: {
          ...stateWithTopLevelItemsResolved.order.orderAmount,
          fetchStatus: "failed",
        },
      },
    };
  } else {
    return stateWithTopLevelItemsResolved;
  }
};

export interface IRouterState {
  hasError: boolean;
}

export default class Router extends React.Component<
  IRouterProps,
  IRouterState
> {
  store: Store<RootState>;

  constructor(props: IRouterProps) {
    super(props);
    this.state = {
      hasError: false,
    };

    const persistedStore = props.localStorageService.loadState();
    this.store = storeFactory
      .createStore(stateWithFetchStatusesResolved(persistedStore))
      .getStore();
  }

  componentDidMount() {
    this.store.subscribe(() => {
      utils.throttle(() => {
        this.props.localStorageService.saveState(this.store.getState());
      }, 1000);
    });
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    // If there is an unhandled error in the app when
    // running in production we redirect the user to the start page
    if (process.env.NODE_ENV === "production" && this.state.hasError) {
      return (
        <Provider store={this.store}>
          <BrowserRouter forceRefresh={true}>
            <Redirect to={"/"} />
          </BrowserRouter>
        </Provider>
      );
    }
    return (
      <IntlProvider locale="en">
        <Provider store={this.store}>
          <BrowserRouter
            getUserConfirmation={(message, callback) => callback(false)}
          >
            <>
              <OrderSession />
              <ScrollToTop>
                <Switch>
                  {this.props.routes.map((route, i) => (
                    <RouteWithSubRoutes
                      key={i}
                      {...Object.assign(
                        {
                          nextRoute:
                            i < this.props.routes.length - 1
                              ? this.props.routes[i + 1]
                              : null,
                        },
                        route
                      )}
                    />
                  ))}
                </Switch>
                <LoadingIndicator />
                {/* @ts-ignore Project upgrade */}
                <NetworkErrorHandler />
              </ScrollToTop>
            </>
          </BrowserRouter>
        </Provider>
      </IntlProvider>
    );
  }
}
