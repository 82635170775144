import moment from "moment";
import React from "react";

export interface IModalTimePickerContentProps {
  timeSlots: readonly string[];
  onChange: (newValue: string) => void;
  timeFormat: string;
}

/**
 * Renders a time pick control that is suitable in modal content.
 */
const ModalTimePickerContent = ({
  timeSlots,
  onChange,
  timeFormat,
}: IModalTimePickerContentProps) => (
  <select className="form-control" onChange={(e) => onChange(e.target.value)}>
    <option>Select a time</option>
    {timeSlots.map((value: string) => {
      const option = moment(value).format(timeFormat);
      return (
        <option key={option} value={value}>
          {moment(value).format(timeFormat)}
        </option>
      );
    })}
  </select>
);

export default ModalTimePickerContent;
