/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { createRef } from "react";
import MenuItemRow from "./MenuItemRow";
import ScrollToMenuItem from "../ScrollTo/ScrollToMenuItem";
import { AllHtmlEntities } from "html-entities";
import { Menu, MenuItem } from "../../state/types/menuTypes";
import { RootState } from "../../state/store/store";

export interface IMenuGroupTypeProps {
  store: RootState;
  dispatchAction: Function;
  title: string;
  list: MenuItem[];
  userOrder: any;
  menu: Menu;
}

export default class MenuGroupType extends React.Component<
  IMenuGroupTypeProps,
  null
> {
  render() {
    const { title, list } = this.props;
    const responsiveWidth = 600;
    const refs: {
      [key: string]: React.RefObject<HTMLDivElement>;
    } = list.reduce((acc, item) => ({ ...acc, [item.name]: createRef() }), {});

    return (
      <div className="item-group">
        <h4>
          <span>{AllHtmlEntities.decode(title)}</span>
        </h4>
        <ScrollToMenuItem
          scrollOffSet={window.innerWidth < responsiveWidth ? 70 : 100}
          userOrder={this.props.userOrder}
          refs={refs}
        >
          {list.map((item) => (
            <div ref={refs[item.name]} key={item.id}>
              <MenuItemRow
                key={item.id}
                menuItem={item}
                store={this.props.store}
                dispatchAction={this.props.dispatchAction}
              />
            </div>
          ))}
        </ScrollToMenuItem>
      </div>
    );
  }
}
