import { all, call, put, takeEvery } from "redux-saga/effects";
import vendorService from "../../services/vendorService";
import {
  FETCHING_VENUES,
  FETCH_VENUES,
  FETCH_VENUES_FAILED,
  FETCH_VENUES_SUCCEEDED,
} from "../types/venueTypes";

export function* getVenues() {
  try {
    yield put({ type: FETCHING_VENUES });
    const { result } = yield call(vendorService.getVenues);

    yield put({
      type: FETCH_VENUES_SUCCEEDED,
      payload: { venues: result },
    });
    return result;
  } catch (error) {
    yield put({
      type: FETCH_VENUES_FAILED,
      payload: { error: error.message },
    });
  }
}

export function* venuesRootSaga() {
  yield all([takeEvery(FETCH_VENUES, getVenues)]);
}
