import { Venue } from "../../types";
import { FETCH_STATUS } from "../actions";

export const FETCH_VENUES = "FETCH_VENUES";
export const FETCHING_VENUES = "FETCHING_VENUES";
export const FETCH_VENUES_SUCCEEDED = "FETCH_VENUES_SUCCEEDED";
export const FETCH_VENUES_FAILED = "FETCH_VENUES_FAILED";

export interface FetchVenuesAction {
  type: typeof FETCHING_VENUES;
}

export interface FetchingVenuesAction {
  type: typeof FETCHING_VENUES;
}

export interface FetchVenuesSucceededAction {
  type: typeof FETCH_VENUES_SUCCEEDED;
  payload: { venues: ReadonlyArray<Venue> };
}

export interface FetchVenuesFailedAction {
  type: typeof FETCH_VENUES_FAILED;
  payload: { error: unknown };
}

export type VenueActionType =
  | FetchVenuesAction
  | FetchingVenuesAction
  | FetchVenuesSucceededAction
  | FetchVenuesFailedAction;

export interface VenuesState {
  fetchStatus: FETCH_STATUS;
  list: ReadonlyArray<Venue>;
  error: unknown;
}
