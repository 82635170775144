import { all, call, put, takeEvery } from "redux-saga/effects";
import vendorService from "../../services/vendorService";
import {
  FetchPickupTimeSlotsAction,
  FETCH_PICKUP_TIME_SLOTS,
  FETCH_PICKUP_TIME_SLOTS_FAILED,
  FETCH_PICKUP_TIME_SLOTS_SUCCEEDED,
} from "../types/vendorTypes";

export function* getTimeSlotsByVendorId({
  payload,
}: FetchPickupTimeSlotsAction) {
  try {
    const { result } = yield call(
      vendorService.getAvailableScheduledPickupTimeSlots,
      payload.vendorId
    );

    yield put({
      type: FETCH_PICKUP_TIME_SLOTS_SUCCEEDED,
      payload: {
        ...payload,
        timeSlots: result,
      },
    });
  } catch (error) {
    yield put({
      type: FETCH_PICKUP_TIME_SLOTS_FAILED,
      payload: { error },
    });
  }
}

export function* timeSlotsRootSaga() {
  yield all([takeEvery(FETCH_PICKUP_TIME_SLOTS, getTimeSlotsByVendorId)]);
}
