import React, { useEffect, useState } from "react";
import prettyMs from "pretty-ms";
import { env, routes } from "../../app-constants";
import Footer from "../../components/Footer/Footer";
import {
  resetOrderAction,
  vendorActions,
  orderActions,
} from "../../state/actions";
import ActionButton from "../../components/Button/ActionButton/ActionButton";
import withData from "../../highOrderComponents/withData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store/store";
import { Vendor, ServiceMode } from "../../types";
import vendorService from "../../services/vendorService";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ModalTimePickerContent from "../../components/Modal/TimePickerModal/TimePickerModalContent";
import { Link, useHistory, useLocation } from "react-router-dom";
import { discoveredVendors } from "../../state/selectors/vendor";

/**
 * Props for TimeSlotsModal.
 */
type TimeSlotsModalProps = {
  showModal: boolean;
  toggleModal: (visible: boolean, serviceMode?: ServiceMode) => void;
  timeSlots: readonly string[];
  selectedServiceMode?: ServiceMode;
};

/**
 * Time slots selection modal for pre-order.
 * Patron selects an available time slot and direct to either place order page or delivery page depending on selected service mode.
 */
const TimeSlotsModal = ({
  showModal,
  toggleModal,
  timeSlots,
  selectedServiceMode,
}: TimeSlotsModalProps) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [pickedTime, setPickedTime] = useState<string | undefined>(undefined);

  const onTimeChange = (newValue: string) => {
    setPickedTime(newValue);
  };

  const queryParams = useLocation().search;

  const navigate = (pathname: string) => {
    history.push({
      pathname: pathname,
      search: queryParams,
    });
  };

  const selectTimeAndContinue = () => {
    if (pickedTime) {
      dispatch(orderActions.pickedTime(pickedTime));

      if (selectedServiceMode === "HomeDelivery") {
        navigate(routes.ORDER_FLOW.DELIVERY.PATH);
      } else {
        navigate(routes.ORDER_FLOW.PLACE_ORDER.PATH);
      }
    }
    // Button is disabled and nothing will happen if the no time is picked.
  };

  return (
    <Modal isOpen={showModal}>
      <ModalHeader>
        {selectedServiceMode === "HomeDelivery"
          ? "Select preferred deliver time"
          : "Select preferred pickup time"}
      </ModalHeader>
      <ModalBody>
        <Link
          className="btn btn-primary"
          to={{
            pathname:
              selectedServiceMode === "HomeDelivery"
                ? routes.ORDER_FLOW.DELIVERY.PATH
                : routes.ORDER_FLOW.PLACE_ORDER.PATH,
            search: queryParams,
          }}
        >
          {selectedServiceMode === "HomeDelivery" ? (
            <i className="fas fa-truck float-left"></i>
          ) : (
            <i className="fas fa-utensils float-left"></i>
          )}
          ASAP
        </Link>
        <br />
        <br />
        <button
          disabled={pickedTime === "" || pickedTime === undefined}
          className="btn btn-primary"
          onClick={selectTimeAndContinue}
        >
          <i className="far fa-clock float-left"></i>
          {selectedServiceMode === "HomeDelivery"
            ? "Deliver Later"
            : "Pickup Later"}
        </button>
        <br />
        <br />
        <ModalTimePickerContent
          timeFormat={"h:mm a"}
          timeSlots={timeSlots}
          onChange={onTimeChange}
        />
      </ModalBody>
      <button
        type="button"
        className="btn"
        onClick={() => toggleModal(false, selectedServiceMode)}
      >
        Cancel
      </button>
    </Modal>
  );
};

const Start = () => {
  const dispatch = useDispatch();

  const [showModal, setModalVisibility] = useState(false);

  // Local state holds current selected service mode for time schedule pickup modal content rendering.
  const [selectedServiceMode, setSelectedServiceMode] = useState<
    ServiceMode | undefined
  >(undefined);

  const vendorsByVenue = useSelector(
    (state: RootState) => state.vendorsByVenue
  );

  const timeSlots = useSelector(
    (state: RootState) =>
      state.pickupTimeSlotsByVendor.timeSlots[env.REACT_APP_VENDOR_ID]
  );

  const allVenues = useSelector((state: RootState) => state.venues.list);

  const allDeliveryVendors = discoveredVendors(
    allVenues,
    vendorsByVenue.vendors
  ).filter(
    (f) =>
      !!f &&
      f.vendorOrderConfiguration?.availableServiceModes.includes("HomeDelivery")
  );

  const vendors = vendorsByVenue.vendors[env.REACT_APP_VENUE_ID];

  const vendor =
    vendors &&
    vendors.find((vendor: Vendor) => vendor.id === env.REACT_APP_VENDOR_ID);

  const isDigitalStoreOpen = vendorService.isDigitalStoreOpen(vendor);

  const isScheduledOrderEnabled =
    vendor &&
    vendor.isOpen &&
    !vendor.isPaused &&
    timeSlots !== undefined &&
    timeSlots.length > 0;

  const queryParams = useLocation().search;

  const showDeliveryBtn = env.REACT_APP_MULTI_VENDOR_SUPPORT
    ? allDeliveryVendors.length > 0
    : vendor?.vendorOrderConfiguration?.availableServiceModes.includes(
        "HomeDelivery"
      );

  // This is similar to componentDidMount, componentDidUpdate and componentWillUnmount.
  useEffect(() => {
    dispatch(resetOrderAction(true));

    if (!env.REACT_APP_MULTI_VENDOR_SUPPORT && vendor !== undefined) {
      dispatch(
        vendorActions.selectVendor(
          env.REACT_APP_VENDOR_ID,
          env.REACT_APP_VENUE_ID,
          env.REACT_APP_VENUE_NAME,
          vendor.phoneNumber,
          vendor.locationDescription
        )
      );
    }

    document.body.className = `home ${document.body.className}`;

    // Clean up after this effect, similar to componentWillUnmount.
    return function cleanup() {
      const bodyClass = document.body.className;
      document.body.className = bodyClass.replace("home", "");
    };
  }, [dispatch]);

  const toggleModal = (visible: boolean, serviceMode?: ServiceMode) => {
    setModalVisibility(visible);
    setSelectedServiceMode(serviceMode);
  };

  return (
    <div className="Start d-flex flex-column mobile-wrapper m-auto">
      <header className="header-logo">
        <div className="row clearfix header-background m-auto">
          <div className="logo-background d-flex ">
            <div className="logo w-100 h-100" />
          </div>
        </div>
      </header>
      <div>
        <h1 className="site-title">{env.REACT_APP_VENUE_NAME}</h1>
      </div>
      {vendor && !env.REACT_APP_MULTI_VENDOR_SUPPORT ? (
        <TimeSlotsModal
          showModal={showModal}
          toggleModal={toggleModal}
          selectedServiceMode={selectedServiceMode}
          timeSlots={timeSlots}
        />
      ) : (
        <></>
      )}
      {vendor?.tts &&
      isDigitalStoreOpen &&
      env.REACT_APP_SHOW_TIME_TO_SERVICE &&
      !env.REACT_APP_MULTI_VENDOR_SUPPORT ? (
        <div className="time-to-service">
          <span>
            Estimated pickup <br />~{prettyMs(vendor?.tts, { verbose: true })}
          </span>
        </div>
      ) : (
        <></>
      )}
      <div className="action-buttons">
        <section className="actions">
          {isScheduledOrderEnabled && !env.REACT_APP_MULTI_VENDOR_SUPPORT ? (
            <>
              <button
                type="button"
                className="btn btn-primary col-md-6 offset-md-3"
                // TakeAway and DineIn renders same time pickup modal content.
                onClick={() => toggleModal(true, "TakeAway")}
              >
                Order & Collect
              </button>
              <br />
              <br />
              {vendor?.vendorOrderConfiguration?.availableServiceModes.includes(
                "HomeDelivery"
              ) ? (
                <button
                  type="button"
                  className="btn btn-primary col-md-6 offset-md-3"
                  onClick={() => toggleModal(true, "HomeDelivery")}
                >
                  Order Home Delivery
                </button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <ActionButton
                text="Order &amp; Collect"
                className="btn btn-primary col-md-6 offset-md-3"
                goTo={
                  env.REACT_APP_MULTI_VENDOR_SUPPORT
                    ? routes.ORDER_FLOW.DISCOVER_VENDOR.PATH
                    : routes.ORDER_FLOW.PLACE_ORDER.PATH
                }
                search={queryParams}
              />
              {showDeliveryBtn ? (
                <>
                  <br />
                  <br />
                  <ActionButton
                    text="Order Home Delivery"
                    className="btn btn-primary col-md-6 offset-md-3"
                    goTo={routes.ORDER_FLOW.DELIVERY.PATH}
                    search={queryParams}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <Footer showBrand={true} />
        </section>
        <div className="powered_by">
          <a
            href="https://datapos.com.au"
            target="_blank"
            rel="noopener noreferrer"
            title="DataPOS website (https://datapos.com.au)."
          >
            Powered by DataPOS
          </a>
        </div>
      </div>
    </div>
  );
};

export default withData({
  resolver: vendorActions.loadRequiredVenuesAndVendors,
  params: [env.REACT_APP_VENUE_ID],
})(Start);
