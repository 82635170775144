/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment, { now } from "moment";
import React from "react";
import Asset from "../../components/Asset/Asset";
import EmailCaptureModal from "../../components/EmailCapture/EmailCaptureModal";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import DiscussOrderModal from "../../components/Modal/DiscussOrderModal";
import EstimatedWaitTime from "../../components/WaitOrder/EstimatedWaitTime";
import withData from "../../highOrderComponents/withData";
import withWatchForOrderResolved from "../../highOrderComponents/withWatchForOrderResolved";
import { consumerActions } from "../../state/actions";
import { RootState } from "../../state/store/store";
import { Vendor } from "../../types";
import OrderAlertedModal from "./OrderAlertedModal";

interface IWaitOrderProps {
  store: RootState;
  dispatchAction: Function;
  showPickUpModal: boolean;
  showOrderCancelledModal: boolean;
}

class WaitOrder extends React.Component<IWaitOrderProps, null> {
  readonly numOfBlinkImages = 8;
  initialSecsToServiceEstimate = 0;

  constructor(props: IWaitOrderProps) {
    super(props);
    this.sendEmailClick = this.sendEmailClick.bind(this);
    this.initialSecsToServiceEstimate =
      // @ts-ignore - Project Upgrade
      props.store.placedOrder.data.secsToServiceEstimate;
  }

  // @ts-ignore - Project Upgrade
  renderHeaderInfoMessage(placedOrderData) {
    return (
      <div className="info-box">
        {placedOrderData.positionInQueue ? (
          <span>
            You are <span>No. {placedOrderData.positionInQueue}</span> in the
            queue
          </span>
        ) : null}
      </div>
    );
  }

  renderBlinkImages(secsToServiceEstimate: number) {
    const blinkClass = "blink-image";
    const percentage =
      100 -
      (secsToServiceEstimate * 100) / (this.initialSecsToServiceEstimate || 1);
    const numOfImagesToRender = Math.ceil(
      percentage / (100 / this.numOfBlinkImages)
    );

    return Array(numOfImagesToRender || 1)
      .fill("")
      .map((item, index) => (
        <img
          alt="Blink"
          key={index}
          className={`part\
       ${this.shouldBlinkImage(index + 1, percentage) ? blinkClass : ""}`}
          src={`../../static/images/loader-${index + 1}.png`}
        />
      ));
  }

  // @ts-ignore - Project Upgrade
  shouldBlinkImage(imageNumber: number, currWaitPercentage): boolean {
    const percentagePerImage = 100 / this.numOfBlinkImages;
    return (
      currWaitPercentage < 100 &&
      percentagePerImage * imageNumber >= currWaitPercentage
    );
  }

  sendEmailClick(email: string) {
    // @ts-ignore - Project Upgrade
    const orderId = this.props.store.placedOrder.data.id;
    const consumer = this.props.store.consumer.data ?? {};

    if (consumer.contact?.mobile !== undefined) {
      this.props.dispatchAction(
        consumerActions.updateUserAndIssueReceipt(
          // @ts-ignore - Project Upgrade
          {
            data: consumer,
            emailAddressFormInput: email,
            fullNameFormInput: consumer.name?.fullName ?? "",
            mobilePhoneFormInput: consumer.contact?.mobile,
          },
          orderId
        )
      );
    }
  }

  Vendor(): Vendor | undefined {
    const vendorsList = this.props.store.vendorsByVenue.vendors;
    let allVendors: Vendor[] = [];
    let i: any;
    let thisVendor: Vendor;
    for (i in vendorsList) {
      allVendors = allVendors.concat(vendorsList[i].flat());
    }

    for (thisVendor of allVendors) {
      //@ts-ignore - QFXFB-841
      if (thisVendor.id === this.props.store.placedOrder.data.vendorId)
        return thisVendor;
    }
  }

  percentComplete() {
    if (
      //@ts-ignore
      this.props.store.placedOrder.data.timing.timeQueued &&
      //@ts-ignore
      this.props.store.placedOrder.data.timing.timeServiceEstimated
    ) {
      const TimeQueued: Date = new Date(
        //@ts-ignore
        this.props.store.placedOrder.data.timing.timeQueued
      );
      const tts: Date = new Date(
        //@ts-ignore
        this.props.store.placedOrder.data.timing.timeServiceEstimated
      );
      const waited = now() - TimeQueued.getTime();
      const total = tts.getTime() - TimeQueued.getTime();

      return Math.min((waited / total) * 100, 100);
    } else {
      return 0;
    }
  }

  orderStatusImage() {
    const percentComplete = this.percentComplete();
    const indicators: any[] = [];
    indicators.push(<div className="indicater indicate10" key="10"></div>);
    if (percentComplete > 13) {
      indicators.push(<div className="indicater indicate20" key="20"></div>);
    } else {
      indicators.push(
        <div className="indicater indicate20 fade" key="20"></div>
      );
    }
    if (percentComplete > 27) {
      indicators.push(<div className="indicater indicate30" key="30"></div>);
    } else if (percentComplete > 13) {
      indicators.push(
        <div className="indicater indicate30 fade" key="30"></div>
      );
    }
    if (percentComplete > 40) {
      indicators.push(<div className="indicater indicate40" key="40"></div>);
    } else if (percentComplete > 27) {
      indicators.push(
        <div className="indicater indicate40 fade" key="40"></div>
      );
    }
    if (percentComplete > 56) {
      indicators.push(<div className="indicater indicate50" key="50"></div>);
    } else if (percentComplete > 40) {
      indicators.push(
        <div className="indicater indicate50 fade" key="50"></div>
      );
    }
    if (percentComplete > 70) {
      indicators.push(<div className="indicater indicate60" key="60"></div>);
    } else if (percentComplete > 56) {
      indicators.push(
        <div className="indicater indicate60 fade" key="60"></div>
      );
    }
    if (percentComplete > 83) {
      indicators.push(<div className="indicater indicate70" key="70"></div>);
    } else if (percentComplete > 70) {
      indicators.push(
        <div className="indicater indicate70 fade" key="70"></div>
      );
    }
    if (percentComplete > 92) {
      indicators.push(<div className="indicater indicate100" key="100"></div>);
    } else if (percentComplete > 83) {
      indicators.push(
        <div className="indicater indicate100 fade" key="100"></div>
      );
    }
    return indicators;
  }

  render() {
    const placedOrderData = this.props.store.placedOrder.data;
    const consumer = this.props.store.consumer.data;
    const vendor = this.Vendor();

    const selectedServiceMode =
      this.props.store.order.orderConfiguration.selectedServiceMode;

    if (
      // @ts-ignore - Project Upgrade
      (!vendor || !placedOrderData.positionInQueue) &&
      // @ts-ignore - Project Upgrade
      placedOrderData.orderStatus === "Waiting"
    ) {
      return <LoadingIndicator />;
    }

    return (
      <div className="WaitOrder col-md-8 offset-md-2">
        <h2>Wait and keep checking screen</h2>
        {
          // @ts-ignore - Project Upgrade
          placedOrderData.requestedServiceTime ? (
            <div className="info-box">
              <span>Order Scheduled</span>
            </div>
          ) : (
            this.renderHeaderInfoMessage(placedOrderData)
          )
        }

        <div className="order-info">
          {
            // @ts-ignore - Project Upgrade
            placedOrderData.requestedServiceTime ? (
              <>
                <div className="notice">
                  {selectedServiceMode === "HomeDelivery" ? (
                    <>Your order is scheduled for Delivery at:</>
                  ) : (
                    <>Your order is scheduled for Pick Up at:</>
                  )}
                </div>
                <div className="waiting-time">
                  {
                    // @ts-ignore - Project Upgrade
                    moment(placedOrderData.requestedServiceTime).format(
                      "h:mm a"
                    )
                  }
                </div>
              </>
            ) : (
              <>
                <div className="notice">
                  {
                    // This needs to be here so ts-ignore works
                    // @ts-ignore - Project Upgrade
                    placedOrderData.positionInQueue ||
                    // @ts-ignore - Project Upgrade
                    placedOrderData.orderStatus === "Scheduled" ? (
                      <div>
                        {selectedServiceMode === "HomeDelivery" ? (
                          <>
                            This screen will alert you when your order is on its
                            way:
                          </>
                        ) : (
                          <>This screen will alert you for order pick up in:</>
                        )}
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </div>
                <div className={"order-loader"}>
                  {
                    // Same images are stacked to compensate the pixel gap between the image loader parts for high resolution screens
                    this.orderStatusImage()
                  }
                  {this.orderStatusImage()}
                  {this.orderStatusImage()}
                </div>
                <div className="waiting-time">
                  <EstimatedWaitTime
                    secsToServiceEstimate={
                      // @ts-ignore - Project Upgrade
                      placedOrderData.secsToServiceEstimate
                    }
                  />
                </div>
                {selectedServiceMode === "HomeDelivery" ? (
                  <p>An alert will notify you when your order is on its way</p>
                ) : (
                  <p>
                    An alert will guide you to pick up your order from&nbsp;
                    <span className="font-weight-bold text-nowrap">
                      {
                        // @ts-ignore - Project Upgrade
                        vendor.storeName
                      }
                    </span>
                  </p>
                )}
              </>
            )
          }
          <div className="info-box">
            ORDER NO.{" "}
            <b>
              <span>
                {
                  //
                  // @ts-ignore - Project Upgrade
                  placedOrderData.claimTicket
                }
              </span>
            </b>
          </div>
          {consumer?.contact?.email ? (
            <div className="email-info">
              A receipt has been emailed to{" "}
              <span>{consumer.contact.email}</span>
            </div>
          ) : null}

          <div className="map-box">
            {
              //
              // @ts-ignore - Project Upgrade
              vendor.vendorDisplayAssets.locationImageAssetId ? (
                <Asset
                  thumbnailAssetId={
                    // @ts-ignore - Project Upgrade
                    vendor.vendorDisplayAssets.locationImageAssetId
                  }
                />
              ) : null
            }
            <div className="address">
              {
                //
                // @ts-ignore - Project Upgrade
                vendor.locationDescription
              }
            </div>
          </div>
        </div>
        <div className="problem-info">
          Problem? Contact{" "}
          <span>
            {
              //
              // @ts-ignore - Project Upgrade
              vendor.storeName
            }
          </span>
          {
            //
            // @ts-ignore - Project Upgrade
            vendor.locationDescription && (
              <span>
                {" "}
                -{" "}
                <span>
                  {
                    //
                    // @ts-ignore - Project Upgrade
                    vendor.locationDescription
                  }
                </span>
              </span>
            )
          }
          {
            //
            // @ts-ignore - Project Upgrade
            vendor.phoneNumber && (
              <a
                className="phone-link"
                href={`tel:${
                  //
                  // @ts-ignore - Project Upgrade
                  vendor.phoneNumber
                }`}
              >
                {
                  //
                  // @ts-ignore - Project Upgrade
                  vendor.phoneNumber
                }
              </a>
            )
          }{" "}
          for help.
        </div>
        <OrderAlertedModal
          showModal={this.props.showPickUpModal}
          serviceMode={selectedServiceMode}
        />
        <DiscussOrderModal showModal={this.props.showOrderCancelledModal} />
        <EmailCaptureModal
          consumer={this.props.store.consumer}
          onSendClick={this.sendEmailClick}
          {...this.props}
        />
      </div>
    );
  }
}

export default withData()(withWatchForOrderResolved(WaitOrder));
