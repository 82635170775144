/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { routes } from "../../app-constants";
import cardMastercard from "../../static/images/card_mastercard.jpg";
import cardVisa from "../../static/images/card_visa.jpg";
import { RootState } from "../../state/store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearPlacedOrder,
  placeOrder,
  waitForOrderStatus,
} from "../../state/actions/placeOrderAction";
import { OrderAttributes } from "../../state/types/orderTypes";
import paymentService from "../../services/paymentService";
import { WAIT_ORDER_URL } from "../../app-constants/Routes";
import {
  createConsumer,
  updateConsumer,
} from "../../state/actions/consumerActions";
import Utils from "../../services/utils";
import GooglePayButton from "@google-pay/button-react";
import { env } from "../../app-constants";
import PaymentLoaderModal from "../Modal/PaymentLoaderModal";

interface IContactInfoProps {
  availableServiceModes: { label: string; value: string }[];
  selectedServiceMode: string;
  total: any;
  mobileNumber: string;
  emailAddress: string;
  fullName: string;
  onMobileNumberChanged: (mobileNumber: string) => void;
  onEmailAddressChanged: (emailAddress: string) => void;
  onFullNameChanged: (fullName: string) => void;
  onServiceModeChanged: (serviceMode: string) => void;
}

const placedOrderStatuses = [
  "Scheduled",
  "Waiting",
  "Serviced",
  "PendingAlerted",
  "Alerted",
  "Cancelled",
  "VendorSupportRequired",
];

// eslint-disable-next-line sonarjs/cognitive-complexity
const ContactInfo = (props: IContactInfoProps) => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);

  const [applePaySessionActive, setApplePaySessionActive] = useState(false);

  const [paymentError, setPaymentError] = useState(false);

  const [emailError, setEmailError] = useState(false);

  const [paying, setPaying] = useState(false);

  const [applePayLocalSession, setApplePayLocalSession] =
    useState<ApplePaySession>();

  const [googlePaymentData, setGooglePaymentData] =
    useState<google.payments.api.PaymentData>();

  const order = useSelector((state: RootState) => state.order);

  const menu = useSelector((state: RootState) => state.menu);

  const vendor = useSelector((state: RootState) => state.selectedVendor);

  const vendorOrderConfiguration = useSelector((state: RootState) =>
    state.vendorsByVenue.vendors[vendor.data.venueId].find(
      (venueVendor) => venueVendor.id === vendor.data.id
    )
  );

  const consumer = useSelector((state: RootState) => state.consumer);

  const placedOrder = useSelector((state: RootState) => state.placedOrder);

  const dispatch = useDispatch();

  const applePaySession: unknown | undefined = window.ApplePaySession;

  if (
    applePaySession !== undefined &&
    !isApplePayAvailable &&
    vendorOrderConfiguration?.vendorOrderConfiguration?.allowApplePay
  ) {
    setIsApplePayAvailable(true);
  }

  const [selectedServiceMode, setSelectedServiceMode] = useState(
    props.selectedServiceMode
  );

  const [mobileNumber, setMobileNumber] = useState(props.mobileNumber || "");

  const [emailAddress, setEmailAddress] = useState(props.emailAddress || "");

  const [fullName, setFullName] = useState(props.fullName || "");

  const [isOrderWaiting, setIsOrderWaiting] = useState(false);

  const [isMobileNumberValid, setIsMobileNumberValid] = useState(
    !!props.mobileNumber
  );

  const createOrder = () => {
    // Fetch URL query parameters
    const searchParams = new URLSearchParams(window.location.search);

    const attributes: OrderAttributes = searchParams.has("campaignId")
      ? { patron: { campaignId: searchParams.get("campaignId")! } }
      : {};

    dispatch(
      placeOrder(
        order,
        vendor.data.id,
        menu.menuByVendor[vendor.data.id].id,
        attributes
      )
    );
  };

  const resetAllPaymentData = () => {
    // Reset Apple Pay related payment data/session.
    setApplePaySessionActive(false);
    setApplePayLocalSession(undefined);

    // Reset Google Pay related payment data/session.
    setGooglePaymentData(undefined);
    setPaymentError(true);

    setPaying(false);

    // Clear the cached Pending Order.
    dispatch(clearPlacedOrder());
  };

  // Reset the Placed Order cache on load.
  // A new Pending Order is always created from any of the wallet payment, or when progressed to credit card form page.
  // This is done so that we don't have inconsistent order data when the order is updated and the placed order (Pending Order)
  // cannot be updated from the remote server (DataPOS API).
  useEffect(() => {
    dispatch(clearPlacedOrder());
  }, []);

  // Effect that handles the Pending order creation and the Apple Pay session lifecycle based on the consumer (patron) data.
  useEffect(() => {
    // If an Apple Pay session is created (payment sheet is up).
    if (applePayLocalSession !== undefined) {
      // Create a new Pending order if the consumer data is available.
      if (consumer.fetchStatus === "success") {
        // This will initiate Apple Pay flow when the Pending order is created (placedOrder.fetchStatus is success).
        createOrder();
      }

      // Otherwise, clear the Apple Pay session and reset with error.
      if (consumer.fetchStatus === "failed") {
        resetAllPaymentData();
      }
    }
  }, [consumer, applePayLocalSession]);

  // Effect that handles the Pending order creation and the Google Pay session lifecycle based on the consumer (patron) data.
  useEffect(() => {
    // If the Google Pay payment data is available (payment authorized by patron).
    if (googlePaymentData !== undefined) {
      // Create a new Pending order if the consumer data is available.
      if (consumer.fetchStatus === "success") {
        // This will initiate Google Pay flow when the Pending order is created (placedOrder.fetchStatus is success).
        createOrder();
      }

      // Otherwise, clear the Google Pay session and reset with error.
      if (consumer.fetchStatus === "failed") {
        resetAllPaymentData();
      }
    }
  }, [consumer, googlePaymentData]);

  // Effect that handles the current order status and redirect the patron to appropriate page.
  useEffect(() => {
    // @ts-ignore
    if (placedOrderStatuses.includes(placedOrder.data.orderStatus)) {
      setIsOrderWaiting(true);
    }
  }, [placedOrder]);

  // Effect that handles the Apple Pay flow.
  useEffect(() => {
    // After an order is placed and success, with an active Apple Pay session (Apple Pay button clicked) and a local Apple Pay session up (payment sheet up).
    // Proceed with Apple Pay payment flow.
    if (
      applePaySessionActive &&
      placedOrder.fetchStatus === "success" &&
      applePayLocalSession !== undefined
    ) {
      // Validate merchant with DataPOS remote service, and obtain a validation with purchase token
      // that will be used for payment authorization.
      // This is the step where the patron sees the payment sheet with "processing" spinner.
      applePayLocalSession.onvalidatemerchant = (
        event: ApplePayJS.ApplePayValidateMerchantEvent
      ) => {
        paymentService
          // @ts-ignore
          .getApplePaySession(placedOrder.data.id, event.validationURL)
          .then((response) => {
            applePayLocalSession.completeMerchantValidation(response.result);
          })
          .catch(() => {
            setPaymentError(true);
          });
      };

      // On cancelling the payment, reset all sessions to prepare for next payment attempt.
      applePayLocalSession.oncancel = () => {
        resetAllPaymentData();
      };

      // Patron authorizes the payment with the purchase token after the initial merchant validation.
      // This is the step when patron uses either passcode, face ID or finger print to authorize the payment.
      applePayLocalSession.onpaymentauthorized = (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        setPaying(true);

        paymentService
          .purchaseWithWallet(
            // @ts-ignore
            placedOrder.data.id,
            // @ts-ignore
            order.orderAmount.paymentSummary.total.minorAmount,
            "APPLEPAYWEB",
            event.payment.token
          )
          .then((response) => {
            if (response.result.status === "Successful") {
              applePayLocalSession.completePayment({ status: 0 });

              setApplePaySessionActive(false);

              setApplePayLocalSession(undefined);

              dispatch(
                // @ts-ignore
                waitForOrderStatus(vendor.data.id, placedOrder.data.id, [
                  // Break if order is in waiting status.
                  "Waiting",
                ])
              );
            } else {
              applePayLocalSession.completePayment({ status: 1 });

              resetAllPaymentData();
            }
          })
          .catch(() => {
            applePayLocalSession.completePayment({ status: 1 });

            resetAllPaymentData();
          });
      };

      applePayLocalSession.begin();
    }
  }, [placedOrder, applePaySessionActive, googlePaymentData]);

  // Effect that handles Google Pay flow.
  useEffect(() => {
    // When Google Pay is authorized, the Google Payment Data will be set and initiate the purchase with payment gateway via DataPOS.
    if (
      placedOrder.fetchStatus === "success" &&
      googlePaymentData !== undefined
    ) {
      setPaying(true);

      paymentService
        .purchaseWithWallet(
          // @ts-ignore
          placedOrder.data.id,
          // @ts-ignore
          order.orderAmount.paymentSummary.total.minorAmount,
          "GOOGLE",
          JSON.parse(googlePaymentData.paymentMethodData.tokenizationData.token)
        )
        .then((response) => {
          if (response.result.status === "Successful") {
            setGooglePaymentData(undefined);

            dispatch(
              waitForOrderStatus(
                vendor.data.id,
                // @ts-ignore
                placedOrder.data.id,
                placedOrderStatuses
              )
            );
          } else {
            resetAllPaymentData();
          }
        })
        .catch(() => {
          resetAllPaymentData();
        });
    }
  }, [placedOrder, googlePaymentData]);

  const onServiceModeSelected = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedServiceMode(event.currentTarget.value);
    props.onServiceModeChanged(event.currentTarget.value);
  };

  const onMobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isMobileNumberValid = /^\+?\d{10,}$/.test(event.currentTarget.value);
    if (isMobileNumberValid) {
      props.onMobileNumberChanged(event.currentTarget.value);
    }

    setMobileNumber(event.currentTarget.value);
    setIsMobileNumberValid(isMobileNumberValid);
  };

  const onEmailAddressChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.currentTarget.value;

    if (Utils.isEmailValid(inputValue) || inputValue === "") {
      setEmailError(false);

      props.onEmailAddressChanged(inputValue);
    } else {
      setEmailError(true);
    }

    setEmailAddress(event.currentTarget.value);
  };

  const onFullNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onFullNameChanged(event.currentTarget.value);

    setFullName(event.currentTarget.value);
  };

  const createOrUpdateConsumer = () => {
    const hasExistingConsumer = consumer.data?.id !== undefined;

    const mobileNumberProvided = consumer.mobilePhoneFormInput !== "";

    const mobileNumberUpdatedOrConsumerError =
      consumer.data?.contact?.mobile !== consumer.mobilePhoneFormInput ||
      consumer.error !== "";

    // If there is no existing consumer or consumer mobile phone is changed, create new consumer.
    // We treat new mobile number as a new consumer.
    if (
      !hasExistingConsumer ||
      (mobileNumberUpdatedOrConsumerError && mobileNumberProvided)
    ) {
      dispatch(
        createConsumer(
          consumer.mobilePhoneFormInput,
          consumer.emailAddressFormInput,
          consumer.fullNameFormInput
        )
      );
    } else {
      // Always try to update the consumer data if not creating new one.
      // Upon consumer data updated, the place order will be triggered since the consumer fetch status turns to success.
      dispatch(updateConsumer(consumer));
    }
  };

  const purchaseWithApplePay = () => {
    setApplePaySessionActive(true);

    setPaymentError(false);

    const applePayPaymentRequest = {
      countryCode: "AU",
      currencyCode: "AUD",
      supportedNetworks: ["visa", "masterCard"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: vendor.data.storeName,
        // @ts-ignore
        amount: order.orderAmount.paymentSummary.total.amount,
      },
    };

    const session = new window.ApplePaySession(3, applePayPaymentRequest);

    setApplePayLocalSession(session);

    createOrUpdateConsumer();
  };

  return isOrderWaiting ? (
    <Redirect to={WAIT_ORDER_URL} />
  ) : (
    <>
      <PaymentLoaderModal text="Secure Payment" isOpen={paying} />
      <div className="details">
        {paymentError ? (
          <label className="warning">
            Error Processing payment. Please try again later or use an
            alternative form of payment.
          </label>
        ) : (
          <></>
        )}
        {props.availableServiceModes.length > 1 && (
          <div className="field">
            {props.availableServiceModes.map((item: any) => (
              <div className="custom-radio" key={item.value}>
                <label htmlFor={item.value}>
                  <input
                    checked={selectedServiceMode === item.value}
                    value={item.value}
                    onChange={onServiceModeSelected}
                    id={item.value}
                    type="radio"
                    className="option-input"
                  />
                  <span style={{ textTransform: "capitalize" }}>
                    {item.label}
                  </span>
                  <span></span>
                </label>
              </div>
            ))}
          </div>
        )}
        <div className="field field-phone">
          <label>Enter mobile number for order alert*</label>
          <input
            id="phone"
            className="form-control"
            onChange={onMobileNumberChange}
            name="mobileNumber"
            placeholder="Mobile phone #*"
            type="tel"
            value={mobileNumber}
          />
        </div>
        <div className="field field-phone">
          <label>Enter email address for receipt</label>
          <input
            id="email"
            className="form-control"
            onChange={onEmailAddressChanged}
            name="emailAddress"
            placeholder="Email address"
            type="email"
            value={emailAddress}
          />
          {emailError ? (
            <label className="warning">Please enter a valid email</label>
          ) : (
            <></>
          )}
        </div>
        <div className="field field-phone">
          <label>Enter your full name</label>
          <input
            id="fullName"
            className="form-control"
            onChange={onFullNameChanged}
            name="fullName"
            placeholder="Full name"
            type="fullName"
            value={fullName}
          />
        </div>
        {isMobileNumberValid && !emailError ? <label>Pay With: </label> : <></>}
        <div className="fixed-btn-row">
          {isApplePayAvailable && isMobileNumberValid && !emailError ? (
            <button
              className="apple-pay-button apple-pay-button-black"
              onClick={purchaseWithApplePay}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="fixed-btn-row">
          {vendorOrderConfiguration?.vendorOrderConfiguration
            ?.allowGooglePay === true &&
          isMobileNumberValid &&
          !emailError ? (
            <GooglePayButton
              style={{
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              buttonSizeMode="fill"
              buttonColor="black"
              buttonType="plain"
              environment={
                env.REACT_APP_GPAY_PRODUCTION ? "PRODUCTION" : "TEST"
              }
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: "CARD",
                    parameters: {
                      allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                      allowedCardNetworks: ["MASTERCARD", "VISA"],
                    },
                    tokenizationSpecification: {
                      type: "PAYMENT_GATEWAY",
                      parameters: {
                        gateway: "fatzebra",
                        gatewayMerchantId:
                          env.REACT_APP_GPAY_GATEWAY_MERCHANT_ID,
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: env.REACT_APP_GPAY_MERCHANT_ID,
                  merchantName: vendor.data.storeName,
                },
                transactionInfo: {
                  totalPriceStatus: "FINAL",
                  totalPriceLabel: "Total",
                  totalPrice:
                    // @ts-ignore
                    order.orderAmount.paymentSummary.total.amount?.toString(),
                  currencyCode: "AUD",
                  countryCode: "AU",
                },
              }}
              onLoadPaymentData={(paymentRequest) => {
                // Create or update consumer action turns the consumer fetch status to "fetching".
                // This call will make sure no createOrder() is called until consumer data is updated/created.
                createOrUpdateConsumer();
                setGooglePaymentData(paymentRequest);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="fixed-btn-row">
          {!isMobileNumberValid ? (
            <button type="button" className="btn btn-secondary disabled ">
              Enter phone number above
            </button>
          ) : emailError ? (
            <button type="button" className="btn btn-secondary disabled ">
              Enter email address above
            </button>
          ) : (
            <Link
              id="pay-by-card-btn"
              className="btn btn-primary btn-payment"
              to={routes.ORDER_FLOW.PAYMENT.PATH}
            >
              <img src={cardMastercard} width={30} alt="Mastercard" />{" "}
              <img src={cardVisa} width={30} alt="Visa" />
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
