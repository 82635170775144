import {
  CouponActionType,
  CouponState,
  COUPON_LOADED_ERROR,
  COUPON_LOADED_SUCCESS,
  LOAD_COUPON,
  SET_COUPON_CODE,
} from "../types/couponTypes";

const defaultState: CouponState = {
  data: {},
  fetchStatus: "",
  error: "",
};

export const couponReducer = (
  state: CouponState = defaultState,
  action: CouponActionType
): CouponState => {
  switch (action.type) {
    case LOAD_COUPON:
      return { ...state, fetchStatus: "fetching" };
    case COUPON_LOADED_SUCCESS:
      return {
        ...state,
        error: "",
        fetchStatus: "success",
        data: action.payload.coupon,
      };
    case COUPON_LOADED_ERROR:
      return { ...state, error: action.payload.error, fetchStatus: "failed" };
    case SET_COUPON_CODE:
      return {
        ...state,
        //@ts-ignore - Project Upgrade
        data: { ...state.data, verification: action.payload.verification },
      };
    default:
      return state;
  }
};
