import {
  FETCHING_VENDORS_BY_VENUE,
  FETCH_PICKUP_TIME_SLOTS,
  FETCH_PICKUP_TIME_SLOTS_FAILED,
  FETCH_PICKUP_TIME_SLOTS_SUCCEEDED,
  FETCH_VENDORS_BY_VENUE_FAILED,
  FETCH_VENDORS_BY_VENUE_SUCCEEDED,
  RESET_SELECTED_VENDOR,
  RESET_VENUES_AND_VENDORS,
  SelectedVendorState,
  SELECT_VENDOR,
  SELECT_VENDOR_ERROR,
  SELECT_VENDOR_SUCCESS,
  TimeSlotState,
  VendorActionType,
  VendorState,
} from "../types/vendorTypes";

const defaultVendorsByVenueState: VendorState = {
  fetchStatus: "",
  vendors: {},
  error: "",
};

export const vendorsByVenueListReducer = (
  state: VendorState = defaultVendorsByVenueState,
  action: VendorActionType
): VendorState => {
  switch (action.type) {
    case FETCHING_VENDORS_BY_VENUE:
      return {
        ...state,
        fetchStatus: "fetching",
      };
    case FETCH_VENDORS_BY_VENUE_SUCCEEDED:
      return {
        ...state,
        fetchStatus: "success",
        vendors: {
          ...state.vendors,
          [action.payload.venueId]: action.payload.vendors,
        },
      };
    case FETCH_VENDORS_BY_VENUE_FAILED:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
      };
    case RESET_VENUES_AND_VENDORS:
      return defaultVendorsByVenueState;
    default:
      return state;
  }
};

const defaultPickUpTimeSlotsSlotState: TimeSlotState = {
  fetchStatus: "",
  timeSlots: {},
  error: "",
};

export const pickUpTimeSlotsReducer = (
  state: TimeSlotState = defaultPickUpTimeSlotsSlotState,
  action: VendorActionType
): TimeSlotState => {
  switch (action.type) {
    case FETCH_PICKUP_TIME_SLOTS:
      return { ...state, fetchStatus: "fetching" };
    case FETCH_PICKUP_TIME_SLOTS_SUCCEEDED:
      return {
        ...state,
        fetchStatus: "success",
        timeSlots: {
          ...state.timeSlots,
          [action.payload.vendorId]: action.payload.timeSlots,
        },
      };
    case FETCH_PICKUP_TIME_SLOTS_FAILED:
      return {
        ...state,
        fetchStatus: "failed",
        timeSlots: {
          ...state.timeSlots,
        },
        error: action.payload.error,
      };
    default:
      return state;
  }
};

const defaultSelectedVendorState: SelectedVendorState = {
  allowSelect: false,
  data: {
    id: 0,
    venueId: 0,
    storeName: "",
    phoneNumber: "",
    location: "",
  },
};

export const selectedVendorReducer = (
  state: SelectedVendorState = defaultSelectedVendorState,
  action: VendorActionType
): SelectedVendorState => {
  switch (action.type) {
    case SELECT_VENDOR:
      return {
        ...state,
        allowSelect: false,
      };
    case SELECT_VENDOR_SUCCESS:
      return {
        data: {
          id: action.payload.id,
          venueId: action.payload.venueId,
          storeName: action.payload.storeName,
          phoneNumber: action.payload.phoneNumber,
          location: action.payload.location,
          displayAssets: action.payload.displayAssets,
        },
        allowSelect: true,
      };
    case SELECT_VENDOR_ERROR:
      return {
        data: {
          id: action.payload.id,
          venueId: action.payload.venueId,
          storeName: action.payload.storeName,
          phoneNumber: action.payload.phoneNumber,
          location: action.payload.location,
          displayAssets: action.payload.displayAssets,
        },
        allowSelect: false,
      };
    case RESET_SELECTED_VENDOR:
      return defaultSelectedVendorState;
    default:
      return state;
  }
};
