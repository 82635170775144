/* eslint-disable sonarjs/cognitive-complexity */
import queryString from "query-string";
import React from "react";
import { Redirect } from "react-router";
import { Store } from "redux";
import { env } from "../../app-constants";
import {
  orderFinishedStatuses,
  orderPaidUnfinishedStatuses,
} from "../../app-constants/order";
import {
  ALERT_ORDER_URL,
  allowedCompletedRoutes,
  DELIVERY_URL,
  DISCOVER_VENDOR_URL,
  pathsShouldHaveSelectedVendor,
  pathsShouldHaveUserContact,
  pathsShouldHaveUserOrder,
  pathsShouldHaveUserPayment,
  PAYMENT_URL,
  PLACE_ORDER_URL,
  START_URL,
  WAIT_ORDER_URL,
} from "../../app-constants/Routes";
import {
  discoveredVendors,
  selectedVendor,
} from "../../state/selectors/vendor";
import { RootState } from "../../state/store/store";
import { UrlPaths } from "../../state/types/pathTypes";
import { OrderStatus } from "../../types";

const homeDeliveryAvailable = (storeState: RootState): boolean => {
  if (storeState.venues?.list && storeState.vendorsByVenue?.vendors) {
    const allDeliveryVendors = discoveredVendors(
      storeState.venues.list,
      storeState.vendorsByVenue.vendors
    ).filter(
      (f) =>
        !!f &&
        f.vendorOrderConfiguration?.availableServiceModes.includes(
          "HomeDelivery"
        )
    );
    return allDeliveryVendors.length !== 0;
  }
  return !!selectedVendor(
    storeState.vendorsByVenue,
    storeState.selectedVendor
  )?.vendorOrderConfiguration?.availableServiceModes?.includes("HomeDelivery");
};

/**
 * The vendor is selected when there is a selected vendor in the store or a query parameter of vendor id passed for vendor selection.
 */
const hasSelectedVendor = (
  storeState: RootState,
  parsedQueryParams: { vendorId: string }
): boolean => {
  return (
    storeState.selectedVendor.data.id !== 0 ||
    parsedQueryParams.vendorId !== undefined
  );
};

const redirectURLPath = (
  pathName: string,
  store: Store
): UrlPaths | undefined => {
  const storeState: RootState = store.getState();
  const hasUserOrder =
    Object.keys(storeState.order.userOrder ? storeState.order.userOrder : [])
      .length > 0;
  const queryParams = window.location.search;
  const parsedQueryParams = queryString.parse(queryParams) as {
    vendorId: string;
  };

  // If a vendor is not selected
  if (
    !hasSelectedVendor(storeState, parsedQueryParams) &&
    pathsShouldHaveSelectedVendor.includes(pathName) &&
    !hasUserOrder
  ) {
    return env.REACT_APP_MULTI_VENDOR_SUPPORT ? DISCOVER_VENDOR_URL : START_URL;
  }

  // Redirect back to the Discover Vendor page if home delivery is not enabled for vendor.
  if (
    pathName === DELIVERY_URL &&
    !homeDeliveryAvailable(storeState) &&
    !hasUserOrder
  ) {
    return DISCOVER_VENDOR_URL;
  }

  // Redirect back to the Start page if vendor discovery is not enabled for vendor.
  if (
    pathName === DISCOVER_VENDOR_URL &&
    !env.REACT_APP_MULTI_VENDOR_SUPPORT &&
    !hasUserOrder
  ) {
    return START_URL;
  }

  // If we should have an order, but don't
  if (pathsShouldHaveUserOrder.includes(pathName) && !hasUserOrder) {
    return DISCOVER_VENDOR_URL;
  }

  const hasUserContact =
    (storeState.consumer.data as any)?.contact !== undefined;

  // If we should have contact info, but don't
  if (pathsShouldHaveUserContact.includes(pathName) && !hasUserContact) {
    return PLACE_ORDER_URL;
  }

  const orderStatus: OrderStatus | "" | undefined = (
    storeState.placedOrder.data as any
  )?.orderStatus;
  const hasPaidUnfinishedOrder =
    !!orderStatus && orderPaidUnfinishedStatuses.includes(orderStatus);
  const hasFinishedOrder =
    !!orderStatus && orderFinishedStatuses.includes(orderStatus);

  // If we have a pending or empty order status then our payment is not yet done
  if (
    pathsShouldHaveUserPayment.includes(pathName) &&
    !hasPaidUnfinishedOrder &&
    !hasFinishedOrder
  ) {
    return PAYMENT_URL;
  }

  // If the user has placed and paid for an order and it's not in a finished state
  if (pathName !== WAIT_ORDER_URL && hasPaidUnfinishedOrder) {
    return WAIT_ORDER_URL;
  }

  const directVendorAfterFinishedOrder =
    (pathName === PLACE_ORDER_URL || pathName === DISCOVER_VENDOR_URL) &&
    parsedQueryParams.vendorId !== undefined &&
    hasFinishedOrder;

  // If the user has placed an order and it's in a finished state
  // Don't redirect if we are on the Place Order page and we got there via
  // a query parameter
  if (
    !allowedCompletedRoutes.includes(pathName) &&
    hasFinishedOrder &&
    !directVendorAfterFinishedOrder
  ) {
    return ALERT_ORDER_URL;
  }

  // No need to redirect.
  return undefined;
};

export const getRedirectElement = (
  pathName: string,
  store: Store
): JSX.Element => {
  const redirectURL = redirectURLPath(pathName, store);
  let redirectElement: JSX.Element = <></>;
  if (redirectURL !== undefined) {
    redirectElement = <Redirect to={redirectURL} />;
  }
  return redirectElement;
};
