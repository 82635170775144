import { useEffect, useRef } from "react";

/**
 * Custom hook that sets up an JS interval and clears it while the component use it unmount.
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback to be called when the interval runs.
 * @param delay of the interval
 */
export const useInterval = (callback: Function, delay: number) => {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current && savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};
