import React from "react";

export interface ICardNoticeProps {
  text?: string;
}

export default (props: ICardNoticeProps) => (
  <div className="card-notice error">
    <span>{props.text || "Please check credit card details."}</span>
  </div>
);
