/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as he from "he";
import React from "react";
import { FormattedNumber } from "react-intl";
import { Menu, MenuItem } from "../../state/types/menuTypes";
import MissingOptionModal from "../Modal/MissingOptionModal";

export interface IMenuItemOptionsProps {
  modifierGroupIds: ReadonlyArray<number>;
  selectedOption: {}[];
  onOptionAdded: (option: {}[]) => void;
  onAddToOrderClick: (selectedOption: {}[]) => void;
  menu: Menu;
  menuItem: MenuItem;
  menuItemAddToOrderButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
}

export const uniqueModifierKey = (m: any) => m.id + "-" + m.groupId;

export default class MenuItemOptions extends React.Component<
  IMenuItemOptionsProps,
  any
> {
  readonly btnSelectedClass = "btn-selected";
  readonly btnWhiteClass = "btn-white";

  selectedOptions: {}[];

  constructor(props: IMenuItemOptionsProps) {
    super(props);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.onAddToOrderClick = this.onAddToOrderClick.bind(this);
    this.selectedOptions = props.selectedOption;

    this.state = {
      defaultOption: {},
    };
  }

  componentDidMount() {
    const modifierGroupsDerived = this.deriveModifierGroups(
      this.props.modifierGroupIds,
      this.props.menu
    );
    this.selectedOptions = [];

    // @ts-ignore - Project Upgrade
    const defaultOption = modifierGroupsDerived.reduce((acc, mg) => {
      // @ts-ignore - Project Upgrade
      const defaultModifier = mg.modifiers.find(
        // @ts-ignore
        (m) => m.id === mg.defaultModifierId
      );

      if (defaultModifier !== undefined) {
        return {
          ...acc,
          [mg.id]: {
            // @ts-ignore - Project Upgrade
            id: defaultModifier.id,
            // @ts-ignore - Project Upgrade
            name: defaultModifier.name,
            // @ts-ignore - Project Upgrade
            groupName: mg.name,
            // @ts-ignore - Project Upgrade
            groupId: mg.id,
            // @ts-ignore - Project Upgrade
            price: defaultModifier.price,
          },
        };
      }

      return {
        ...acc,
        [mg.id]: {
          id: null,
          price: {},
        },
      };
    }, {});

    this.setState({
      defaultOption: defaultOption,
      validations: {},
    });

    Object.keys(defaultOption).forEach((modifierGroupId) => {
      // @ts-ignore - Project Upgrade
      this.setSelectedOption(modifierGroupId, defaultOption[modifierGroupId]);
    });
  }

  noSelectionOption(modifierGroup: any) {
    if (modifierGroup.minimumChoices > 0 && modifierGroup.defaultModifierId) {
      return null;
    } else {
      return (
        // @ts-ignore - Project Upgrade
        <option key="no-selection" value={null}>
          {he.decode(modifierGroup.selectionRequiredText || "Select")}
        </option>
      );
    }
  }

  // @ts-ignore - Project Upgrade
  deriveModifierGroups(modifierGroupIds, menu) {
    // @ts-ignore - Project Upgrade
    const modifierGroups = menu.modifierGroups.filter((mg) =>
      modifierGroupIds.includes(mg.id)
    );

    return modifierGroups.map((mg: any) => {
      // @ts-ignore - Project Upgrade
      const modifiers = mg.modifierIds.map((modifierId) =>
        // @ts-ignore - Project Upgrade
        menu.modifiers.find((m) => m.id === modifierId)
      );

      // @ts-ignore - Project Upgrade
      const derivedModifiers = modifiers.map((m) => ({
        ...m,
        groupName: mg.name,
        groupId: mg.id,
      }));

      return {
        // @ts-ignore - Project Upgrade
        id: mg.id,
        // @ts-ignore - Project Upgrade
        name: mg.name,
        // @ts-ignore - Project Upgrade
        modifiers: derivedModifiers,
        // @ts-ignore - Project Upgrade
        ...mg,
      };
    });
  }

  setSelectedOption(modifierGroupId: any, modifier: any) {
    const modifierGroup = this.props.menu.modifierGroups.find(
      (mg: any) => mg.id === modifierGroupId
    );

    const maximumChoices = modifierGroup ? modifierGroup.maximumChoices : 0;

    const selectedModifiers = this.selectedOptions.filter(
      (m: any) => m.groupId === modifierGroupId
    );

    const isNotDuplicateModifier = !this.selectedOptions
      .map((o) => uniqueModifierKey(o))
      .includes(uniqueModifierKey(modifier));

    if (selectedModifiers.length === maximumChoices && maximumChoices > 0) {
      // Allow de-selection of the same modifier;
      if (!isNotDuplicateModifier) {
        this.selectedOptions = this.selectedOptions.filter(
          // @ts-ignore - Project Upgrade
          (m) => m.id !== modifier.id || m.groupId !== modifier.groupId
        );

        this.props.onOptionAdded(this.selectedOptions);
      }

      // Do not allow new modifier to be added if maximum choice reached.
      // Patron has to deselect one of the modifier to select new one again.
      return;
    }

    if (isNotDuplicateModifier) {
      const selectedOptions = this.selectedOptions;
      if (modifier.id) {
        this.selectedOptions = [...selectedOptions, modifier];
      }
      this.props.onOptionAdded(this.selectedOptions);
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        // @ts-ignore - Project Upgrade
        (m) => m.id !== modifier.id || m.groupId !== modifier.groupId
      );

      this.props.onOptionAdded(this.selectedOptions);
    }
  }

  onAddToOrderClick(selectedModifiers: {}[]) {
    const modifierGroupsDerived = this.deriveModifierGroups(
      this.props.modifierGroupIds,
      this.props.menu
    );

    const requiredModifierGroups = modifierGroupsDerived.filter(
      // @ts-ignore - Project Upgrade
      (mg) => mg.minimumChoices > 0
    );

    // @ts-ignore - Project Upgrade
    const requiredGroupIds = requiredModifierGroups.map((mg) => mg.id);

    // @ts-ignore - Project Upgrade
    const selectedGroupIds = selectedModifiers.map((m) => m.groupId);

    const missingGroupIds = requiredGroupIds.filter(
      // @ts-ignore - Project Upgrade
      (id) => !selectedGroupIds.includes(id)
    );

    if (missingGroupIds.length === 0) {
      this.props.onAddToOrderClick(selectedModifiers);
    } else {
      this.setState({
        missingGroupIds: missingGroupIds,
      });
    }
  }

  readonly btnOrangeClass = "btn-orange-toggle";

  renderOptionButton(
    modifierGroup: any,
    isSelected: boolean,
    onClick: (option: string, value: any) => void
  ) {
    const selectedModifierInGroup = this.selectedOptions.find(
      // @ts-ignore - Project Upgrade
      (m) => m.groupId === modifierGroup.id
    );

    const selectedModifier =
      selectedModifierInGroup || this.state.defaultOption[modifierGroup.id];

    return (
      <div className="col modifier-container" key={modifierGroup.id}>
        {selectedModifier ? (
          <div>
            <label>
              {modifierGroup.name}
              {modifierGroup.minimumChoices > 0 ? "*" : ""}
              {modifierGroup.maximumChoices > 0
                ? ` (max choice: ${modifierGroup.maximumChoices})`
                : ""}
            </label>
            {modifierGroup.modifiers
              // @ts-ignore - Project Upgrade
              .sort((a, b) => a.displayOrder - b.displayOrder)
              // @ts-ignore - Project Upgrade
              .filter((modifier) => modifier.visible)
              // @ts-ignore - Project Upgrade
              .map((modifier) => (
                <button
                  key={modifier.id}
                  className={`btn-default btn-modifier ${
                    this.selectedOptions
                      .map((o) => uniqueModifierKey(o))
                      .includes(uniqueModifierKey(modifier))
                      ? this.btnOrangeClass
                      : this.btnWhiteClass
                  }`}
                  onClick={() => onClick(modifierGroup.id, modifier)}
                >
                  {modifier.name + " "}
                  {modifier.price.amount > 0 ? (
                    <span>
                      {modifier.price.currencySymbol}
                      <FormattedNumber
                        minimumFractionDigits={2}
                        value={modifier.price.amount}
                      />
                    </span>
                  ) : null}{" "}
                </button>
              ))}
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const { selectedOption } = this.props;
    const modifierGroupsDerived = this.deriveModifierGroups(
      this.props.modifierGroupIds,
      this.props.menu
    );

    const missingModifierGroups = this.props.menu.modifierGroups.filter(
      (group) => this.state.missingGroupIds?.includes(group.id)
    );

    return (
      <div className="item-extras fast-transform">
        <div className="row-finish">
          <button
            ref={this.props.menuItemAddToOrderButtonRef}
            onClick={() => this.onAddToOrderClick(selectedOption)}
            className="btn btn-primary btn-done"
          >
            Add to Order
          </button>
        </div>
        <div className="item-extra">
          <div className="menu-modifier row">
            {modifierGroupsDerived
              .filter(
                // @ts-ignore - Project Upgrade
                (mg) =>
                  // @ts-ignore - Project Upgrade
                  mg.visible &&
                  // @ts-ignore - Project Upgrade
                  mg.modifiers.filter((m) => m.visible).length > 0
              )
              // @ts-ignore - Project Upgrade
              .sort((mg1, mg2) => mg1.displayOrder - mg2.displayOrder)
              .map((modifierGroup: any) =>
                this.renderOptionButton(
                  modifierGroup,
                  true,
                  this.setSelectedOption
                )
              )}
          </div>
        </div>
        {missingModifierGroups?.length > 0 ? (
          <MissingOptionModal
            onDismiss={() => this.setState({ missingGroupIds: [] })}
            missingModifierGroups={missingModifierGroups}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
