/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { env } from "../app-constants";
import geoLocationService from "../services/geoLocationService";
import { OrderAttributes, OrderState } from "../state/types/orderTypes";
import { DeliveryAddress } from "../types";
import restFactory from "./restFactory";
import vendorService from "./vendorService";

export interface IOrderRequest {
  menuId: number;
  vendorId: number;
  consumerId: number;
  orderItems: any;
  geoLocation: { latitude: number; longitude: number };
  orderOrigin: string;
  serviceMode: string;
  couponCode: string;
}

export interface IOrderModifier {
  id: number;
  name: string;
  quantity: number;
}

const orderOrigin = "QhopperMobile";

export interface IOrderService {
  calculateOrderAmount: (
    vendorId: number,
    order: OrderState,
    couponCode?: string,
    selectedServiceMode?: string
  ) => Promise<any>;

  placeOrder: (
    order: any,
    venueId: number,
    vendorId: number,
    menuId: number,
    attributes: OrderAttributes,
    deliveryAddress?: DeliveryAddress
  ) => Promise<any>;
  loadOrder: (vendorId: number, orderId: number) => Promise<any>;
  initiatePayment: (vendorId: number, orderId: number) => Promise<any>;
  purchase: (
    orderId: number,
    paymentId: number,
    purchaseType: string,
    data: any
  ) => Promise<any>;
  alertOrder: (orderId: number, vendorId: string) => Promise<any>;
}

const modifiersToSubmit = (itemOption: any, quantity: number) =>
  itemOption["modifiers"]?.map((m: IOrderModifier) => ({
    quantity,
    modifierId: m.id,
    name: m.name,
  }));

const orderService: IOrderService = {
  async calculateOrderAmount(
    vendorId: number,
    order: OrderState,
    couponCode?: string,
    selectedServiceMode?: string
  ): Promise<any> {
    const geoLocation = await geoLocationService.getLastLocation();
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}/calculate-order-payment`,
      parseSelectedOrderItemsToSend(
        order,
        vendorId,
        geoLocation,
        // @ts-ignore - Project Upgrade
        couponCode,
        selectedServiceMode
      )
    );
  },

  async placeOrder(
    order: OrderState,
    venueId: number,
    vendorId: number,
    menuId: number,
    attributes: OrderAttributes,
    deliveryAddress?: DeliveryAddress
  ): Promise<any> {
    const orderItems: any[] = [];
    for (const [name, option] of Object.entries(order.userOrder)) {
      Object.keys(option).forEach((item: string) => {
        const modifiers = modifiersToSubmit(option[item], option[item].count);
        orderItems.push({
          name,
          modifiers,
          menuItemId: option[item].id,
          note:
            option[item].name !== "default" && modifiers.length === 0
              ? option[item].name
              : "",
          quantity: option[item].count,
        });
      });
    }

    const response = await vendorService.getVendorByVenueId(venueId || 0);
    const selectedVendor = response.result.find((r: any) => r.id === vendorId);
    const vendorSetDefaultServiceMode = selectedVendor.vendorOrderConfiguration
      ? selectedVendor.vendorOrderConfiguration.defaultOrderServiceMode
      : "NotSpecified";
    const geoLocation = await geoLocationService.captureGeolocation();

    let orderSubmission: any = {
      orderOrigin,
      vendorId,
      menuId,
      orderItems,
      geoLocation,
      consumerId: -1,
      serviceMode:
        order.orderConfiguration.selectedServiceMode ||
        vendorSetDefaultServiceMode,
      attributes,
    };

    if (order.pickedTime) {
      orderSubmission = {
        ...orderSubmission,
        requestedServiceTime: order.pickedTime,
      };
    }

    if (
      deliveryAddress &&
      (order.orderConfiguration.selectedServiceMode ||
        vendorSetDefaultServiceMode) === "HomeDelivery"
    ) {
      orderSubmission = {
        ...orderSubmission,
        deliveryAddress,
      };
    }

    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}/place-order`,
      orderSubmission,
      true
    );
  },

  async loadOrder(vendorId: number, orderId: number) {
    return await restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}/order/${orderId}`,
      undefined,
      true
    );
  },

  async initiatePayment(vendorId: number, orderId: number) {
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}/order/${orderId}/initiate-payment`,
      { orderId, vendorId },
      true
    );
  },

  async purchase(
    orderId: number,
    paymentId: number,
    purchaseType: string,
    data: any
  ) {
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/order/${orderId}` +
        `/payment/${paymentId}/purchase/${purchaseType}`,
      data
    );
  },

  async alertOrder(orderId: number, vendorId: string) {
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}` +
        `/order/${orderId}/alerted`,
      {
        orderId,
        vendorId,
        timeAlerted: new Date().toISOString(),
      },
      true
    );
  },
};

function parseSelectedOrderItemsToSend(
  order: OrderState,
  vendorId: number,
  geoLocation: { latitude: number; longitude: number },
  couponCode: string,
  selectedServiceMode?: string
): IOrderRequest {
  const orderItems: any[] = [];
  for (const [name, option] of Object.entries(order.userOrder)) {
    Object.keys(option).forEach((itemName: any) => {
      // @ts-ignore - Project Upgrade
      const modifiers = option[itemName]["modifiers"]?.map((m) => m["id"]);

      let note =
        option[itemName].name !== "default" ? option[itemName].name : "";

      if (modifiers && modifiers.length > 0) {
        // @ts-ignore - Project Upgrade
        note = undefined;
      }

      orderItems.push({
        name,
        note,
        itemType: option[itemName].itemType,
        menuItemId: option[itemName].id,
        quantity: option[itemName].count,
        modifiers: modifiersToSubmit(option[itemName], option[itemName].count),
      });
    });
  }

  return {
    orderItems,
    vendorId,
    orderOrigin,
    geoLocation,
    couponCode,
    menuId: 1,
    consumerId: 1,
    serviceMode:
      selectedServiceMode || order.orderConfiguration.defaultOrderServiceMode,
  };
}

function factoryOrderServiceService(): IOrderService {
  return Object.create(orderService);
}

export default factoryOrderServiceService();
