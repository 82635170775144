/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { Moment } from "moment";
import { env } from "../app-constants";
import { defaultMenuItemOption } from "../state/actions/orderActions";
import { MenuItem, Modifier, ModifierGroup } from "../state/types/menuTypes";
import { OrderItemOption } from "../state/types/orderTypes";
import restFactory from "./restFactory";

export interface IMenuService {
  getMenuByVendorId(vendorId: number): Promise<any>;
  retrieveMenuItems(
    menuItems: MenuItem[],
    availableAt?: Moment
  ): ReadonlyArray<MenuItem>;
  availableMenuItems(
    menuItems: ReadonlyArray<MenuItem>,
    availableAt: Moment
  ): MenuItem[];
  groupAndMapMenuItems(menuItems: ReadonlyArray<MenuItem>): any;
  countMenuItem(
    userOrder: Record<string, Record<string, OrderItemOption>>,
    menuItem: MenuItem,
    option?: string
  ): number;
  countRestrictedItems(
    userOrder: Record<string, Record<string, OrderItemOption>>
  ): number;
  getSoldOutItems(
    userOrder: Record<string, Record<string, OrderItemOption>>,
    menuByVendor: any
  ): any[];
  menuItemDisplayed(
    menuItem: MenuItem,
    modifierGroups: ReadonlyArray<ModifierGroup>,
    modifiers: ReadonlyArray<Modifier>
  ): boolean;
  menuItemHasVisibleModifiers(
    menuItem: MenuItem,
    modifierGroups: ReadonlyArray<ModifierGroup>,
    modifiers: ReadonlyArray<Modifier>
  ): boolean;
}

const vendorMenuService: IMenuService = {
  async getMenuByVendorId(vendorId: number): Promise<any> {
    return restFactory.get(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}/current-menu`
    );
  },

  retrieveMenuItems(menuItems, availableAt = moment()) {
    return this.availableMenuItems(menuItems, availableAt)
      .sort((m1, m2) => m1.displayOrder - m2.displayOrder)
      .map((menuItem) => ({
        ...menuItem,
        itemType: menuItem.itemType.toLowerCase(),
      }));
  },

  // Was copied from the old angular app
  availableMenuItems(menuItems, availableAt) {
    // availableAt could be "today", the below comment assumes "today".
    const dayOfWeekToday = availableAt.format("dddd");

    return menuItems.map((menuItem) => {
      if (
        menuItem.availability !== undefined &&
        Object.keys(menuItem.availability).length > 0
      ) {
        const availabilityToday = menuItem.availability[dayOfWeekToday];

        // If there is availability set for today, check the availability time of the menu item for today.
        if (availabilityToday !== undefined && availabilityToday.length > 0) {
          // Availability times are in format of e.g. [[08:00, 12:00], [14:00, 17:00]].
          // The interval is in format of e.g. [08:00, 12:00].
          const availableTime = availabilityToday.find((interval) => {
            const startTime = availableAt
              .clone()
              .hour(parseInt(interval[0].split(":")[0]))
              .minute(parseInt(interval[0].split(":")[1]));

            const endTime = availableAt
              .clone()
              .hour(parseInt(interval[1].split(":")[0]))
              .minute(parseInt(interval[1].split(":")[1]));

            // @see https://momentjs.com/docs/#/query/is-between/
            return availableAt.isBetween(startTime, endTime, undefined, "[]");
          });

          // Original menu item is returned if item available or make it invisible to the menu.
          // The unavailable menu items need to be kept in the list in order to display menu
          // item unavailable alert when placing order.
          if (availableTime !== undefined) {
            return menuItem;
          }

          return { ...menuItem, visible: false };
        }

        // If there is available settings for the menu, and there is no today's availability set, the menu item is available.
        return menuItem;
      }
      // The menu item is always available if there is no availability configured at all.
      return menuItem;
    });
  },

  groupAndMapMenuItems(menuItems) {
    const emptyGroup: Record<string, MenuItem[]> = {};

    const groupedMenuItems = menuItems.reduce(
      (acc, item) => ({
        ...acc,
        [item.itemType]: [...(acc[item.itemType] ?? []), item],
      }),
      emptyGroup
    );

    return Object.keys(groupedMenuItems).map((key) => {
      let displayOrder = 999;

      const items = groupedMenuItems[key];

      const maxDisplayOrderItemInGroup = items.sort(
        (i1, i2) => i2.displayOrder - i1.displayOrder
      )[0];

      if (maxDisplayOrderItemInGroup !== undefined) {
        displayOrder = maxDisplayOrderItemInGroup.displayOrder;
      }

      const visibleItems = items
        .sort((i1, i2) => i1.displayOrder - i2.displayOrder)
        .filter((item) => item.visible);

      return {
        displayOrder,
        name: key,
        menuItems: items,
        visible: visibleItems !== undefined && visibleItems.length > 0,
      };
    });
  },

  countMenuItem(
    userOrder: Record<string, Record<string, OrderItemOption>>,
    menuItem: MenuItem,
    option?: string
  ): number {
    if (!option || option !== defaultMenuItemOption) {
      // Here we count all options selected in one item menu
      return countAllOptionsInMenuItem(userOrder, menuItem);
    }
    if (
      option === defaultMenuItemOption &&
      userOrder[menuItem.name] &&
      userOrder[menuItem.name][option]
    ) {
      return userOrder[menuItem.name][option].count;
    }
    return 0;
  },

  countRestrictedItems(
    userOrder: Record<string, Record<string, OrderItemOption>>
  ): number {
    const items = Object.keys(userOrder);
    let count = 0;
    items.forEach((itemName: string) => {
      const options = Object.keys(userOrder[itemName]);
      count = options.reduce((acc: number, curr: string) => {
        return userOrder[itemName][curr].isRestricted
          ? acc + userOrder[itemName][curr].count
          : acc;
      }, count);
    });
    return count;
  },

  getSoldOutItems(
    userOrder: Record<string, Record<string, OrderItemOption>>,
    menuByVendor: any
  ): any[] {
    if (userOrder && menuByVendor) {
      const menuItemNames = Object.keys(userOrder).map((item) =>
        item.toLowerCase()
      );
      return menuByVendor.items.filter(
        // @ts-ignore - Project Upgrade
        (item) =>
          menuItemNames.includes(item.name.toLowerCase()) &&
          isAvailableInCurrentInventory(userOrder, item)
      );
    }
    return [];
  },

  /**
   * Returns whether the given menu item should be displayed
   * If the Menu item has a modifier group:
   *  - That is required (minimum choices > 0) AND
   *    - maximum choices is 0 OR
   *    - minimum choices is less or equal to maximum choices
   *   AND
   *    - That is not visible OR
   *    - That does not have enough visible modifiers to meet the minimum choices
   * Then we shouldn't display it
   *
   * @param menuItem A menu item
   * @param modifierGroups A list of modifier groups
   * @param modifiers A list of modifiers
   * @returns If the menu item should be displayed
   */
  menuItemDisplayed(
    menuItem: MenuItem,
    modifierGroups: ReadonlyArray<ModifierGroup>,
    modifiers: ReadonlyArray<Modifier>
  ): boolean {
    return !modifierGroups.some(
      (modifierGroup) =>
        menuItem.modifierGroupsIds.includes(modifierGroup.id) &&
        modifierGroup.minimumChoices > 0 &&
        (modifierGroup.maximumChoices === 0 ||
          modifierGroup.minimumChoices <= modifierGroup.maximumChoices) &&
        (!modifierGroup.visible ||
          modifierGroup.modifierIds.filter((modifierId) =>
            modifiers.find(
              (modifier) =>
                modifier.id === modifierId && modifier.visible === true
            )
          ).length < modifierGroup.minimumChoices)
    );
  },

  /**
   * Returns true if the menu item has any visible modifiers:
   *   If there is a modifier group on our item
   *   And that modifier group is visible
   *   And that modifier group has at least 1 visible modifier
   *
   * @param menuItem A menu item
   * @param modifierGroups A list of modifier groups
   * @param modifiers A list of modifiers
   * @returns If the modifier list has visible modifiers
   */
  menuItemHasVisibleModifiers(
    menuItem: MenuItem,
    modifierGroups: ReadonlyArray<ModifierGroup>,
    modifiers: ReadonlyArray<Modifier>
  ): boolean {
    return (
      modifierGroups.filter(
        (modifierGroup) =>
          menuItem.modifierGroupsIds.includes(modifierGroup.id) &&
          modifierGroup.visible &&
          modifierGroup.modifierIds.some((modifierId) =>
            modifiers.find(
              (modifier) => modifier.id === modifierId && modifier.visible
            )
          )
      ).length > 0
    );
  },
};

// Private functions
function isAvailableInCurrentInventory(
  userOrder: Record<string, Record<string, OrderItemOption>>,
  item: MenuItem
): boolean {
  return (
    item.currentInventory === 0 ||
    item.currentInventory < vendorMenuService.countMenuItem(userOrder, item)
  );
}

function countAllOptionsInMenuItem(
  userOrder: Record<string, Record<string, OrderItemOption>>,
  menuItem: MenuItem
): number {
  if (Object.keys(userOrder).length && menuItem && userOrder[menuItem.name]) {
    const options = Object.keys(userOrder[menuItem.name]);
    return options.reduce((acc: number, current: string) => {
      return userOrder[menuItem.name][current].count + acc;
    }, 0);
  }
  return 0;
}

// End private functions

function factoryVendorMenuService(): IMenuService {
  return Object.create(vendorMenuService);
}

export default factoryVendorMenuService();
