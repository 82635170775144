import React from "react";
import Modal from "../Modal/Modal";

interface ICouponModalProps {
  showModal: boolean;
  toggleModal: () => void;
  validateCoupon: (couponCode: string) => void;
}

interface ICouponModalState {
  couponCode: string;
}

export default class CouponModal extends React.Component<
  ICouponModalProps,
  ICouponModalState
> {
  constructor(props: ICouponModalProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.validateCoupon = this.validateCoupon.bind(this);
    this.state = {
      couponCode: "",
    };
  }

  cancel() {
    this.props.toggleModal();
    this.setState({
      couponCode: "",
    });
  }

  validateCoupon() {
    this.props.validateCoupon(this.state.couponCode);
    this.props.toggleModal();
    this.setState({
      couponCode: "",
    });
  }

  onChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({
      couponCode: event.currentTarget.value,
    });
  }

  render() {
    const { couponCode } = this.state;
    return (
      // @ts-ignore - Project Upgrade
      <Modal
        className="alert-modal"
        isOpen={this.props.showModal}
        title={"Coupon Code"}
        content={
          <React.Fragment>
            <label htmlFor="couponCode">Enter your coupon code</label>
            <div className="d-flex align-items-center">
              <input
                type="text"
                value={couponCode}
                onChange={this.onChange}
                name="couponCode"
                className="form-control"
                id="couponCode"
                autoComplete="coupon-code"
                x-autocompletetype="coupon-code"
                placeholder="Coupon Code"
              />
            </div>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <button
              className={`btn btn-primary ${couponCode ? "" : "disabled"}`}
              onClick={this.validateCoupon}
            >
              Apply Code
            </button>
            <button className="btn btn-primary" onClick={this.cancel}>
              Cancel
            </button>
          </React.Fragment>
        }
      />
    );
  }
}
