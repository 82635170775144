/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import jsonp from "jsonp";
import { env } from "../app-constants";
import restFactory from "./restFactory";

export enum FAT_ZEBRA_RESPONSE_CODE {
  SUCCESS = 1,
  DECLINED = 2,
  SYSTEM_ERROR_DECLINED = 96,
  VALIDATION_ERROR = 97,
  INVALID_TOKEN = 98,
  INVALID_VERIFICATION = 99,
  GATEWAY_ERROR = 999,
}

export const PAYMENT_PROCESS_FAIL_MESSAGE = `Temporary problem, you <b>haven't</b> been charged for this order.\
  Please check your card details below.`;

export const PAYMENT_PROCESS_FALLBACK_MESSAGE = `Unknown problem.\
  Please check your card details and try again, or contact the venue if the problem persists.`;

export const fatZebraResponseCodeMapping = {
  // Payment successful.
  [FAT_ZEBRA_RESPONSE_CODE.SUCCESS]: "Successful",

  // Credit card declined,
  [FAT_ZEBRA_RESPONSE_CODE.DECLINED]:
    "Your card has been declined by the bank, please check the details below.",

  // System error declined,
  [FAT_ZEBRA_RESPONSE_CODE.SYSTEM_ERROR_DECLINED]:
    "The payment should be attempted again. If it still cannot be processed, try again later.",

  // Validation error, e.g. card number is invalid.
  [FAT_ZEBRA_RESPONSE_CODE.VALIDATION_ERROR]:
    "Please check credit card details.",

  // Invalid card token (for saved card).
  [FAT_ZEBRA_RESPONSE_CODE.INVALID_TOKEN]: "Sorry, please switch to new card.",

  // Invalid verification.
  [FAT_ZEBRA_RESPONSE_CODE.INVALID_VERIFICATION]: PAYMENT_PROCESS_FAIL_MESSAGE,

  // Gateway Error - Fat Zebra server is busted.
  [FAT_ZEBRA_RESPONSE_CODE.GATEWAY_ERROR]: PAYMENT_PROCESS_FAIL_MESSAGE,
};

const retryAttemptsDataposApiUpdate = 3;

export interface IFatZebraTransactionService {
  completePayment: (
    payment: any,
    creditCardData: any,
    order: any,
    savePaymentMethod: any
  ) => Promise<any>;
}

/**
 * Retries async function for a step amount of times where the Promise rejects.
 * Either will error out at the retry limit or return a resolved result from the
 * function
 *
 * @param retries amount of attempts to execute the function
 * @param fn async runnable function
 * @returns Result of executing function or a failed object
 */
export const retryAsync =
  <T>(retries: number) =>
  async (fn: () => T): Promise<T | { failed: true }> => {
    try {
      return await fn();
    } catch (e) {
      if (retries > 1) {
        return retryAsync<T>(retries - 1)(fn);
      } else {
        return { failed: true };
      }
    }
  };

const fatZebraTransactionService: IFatZebraTransactionService = {
  async completePayment(
    payment,
    creditCardData,
    order,
    savePaymentMethod
  ): Promise<any> {
    if (creditCardData.isTokenPurchase) {
      try {
        const purchaseWithSavedTokenResponse =
          await paymentService.purchaseWithSavedToken(
            order.id,
            payment.id,
            creditCardData.cardCvn
          );

        return handleTokenPurchaseSuccess(purchaseWithSavedTokenResponse);
      } catch (error) {
        throw await handleTokenPurchaseFailure(
          // @ts-ignore
          error.status,
          order,
          payment,
          savePaymentMethod
        );
      }
    } else {
      const fatZebraResponse = await sendDataToFatZebra(
        order,
        payment,
        creditCardData,
        savePaymentMethod
      );
      const result = await paymentService.updatePayment(
        order.vendorId,
        payment.orderId,
        payment.id,
        fatZebraResponse,
        savePaymentMethod
      );

      return result.failed === undefined && result.failed
        ? result.result
        : result;
    }
  },
};

// @ts-ignore - Project Upgrade
function sendDataToFatZebra(order, payment, creditCardData, savePaymentMethod) {
  return new Promise((resolve, reject) => {
    const params = `currency=${order.totalPrice.currencyCode}&\
verification=${payment.accessCode}&\
amount=${order.totalPrice.minorAmount}&\
return_path=http://example.com&\
cvv=${creditCardData.cardCvn}&\
reference=${payment.reference}&\
format=json&\
callback=JSON_CALLBACK&\
card_holder=${creditCardData.cardHolder}&\
card_number=${creditCardData.cardNumber}&\
expiry_month=${creditCardData.cardExpiryMonth}&\
expiry_year=${creditCardData.cardExpiryYear}`;

    jsonp(payment.formActionUrl + "?" + params.trim(), (error, data) => {
      if (!error) {
        try {
          const responseSuccess = validateFatZebraPaymentGatewayResponse(data);
          resolve(responseSuccess);
        } catch (error) {
          reject(error);
        }
      } else {
        fatZebraPaymentGatewayFailure(error, order, payment, savePaymentMethod);
      }
    });
  });
}

// @ts-ignore - Project Upgrade
function validateFatZebraPaymentGatewayResponse(response) {
  const gatewayResponseCode = response.r;
  // A validation error is not interesting to the backend (contrast with
  // a declined payment) so they are not round tripped. Instead
  // a payment response matching the structure of a backend response is returned directly.
  if (gatewayResponseCode === FAT_ZEBRA_RESPONSE_CODE.VALIDATION_ERROR) {
    throw {
      message:
        // @ts-ignore - Project Upgrade
        fatZebraResponseCodeMapping[gatewayResponseCode] ??
        PAYMENT_PROCESS_FALLBACK_MESSAGE,
      status: "Unsuccessful",
    };
  }
  return response;
}

async function fatZebraPaymentGatewayFailure(
  // @ts-ignore - Project Upgrade
  errorStatus,
  // @ts-ignore - Project Upgrade
  order,
  // @ts-ignore - Project Upgrade
  payment,
  // @ts-ignore - Project Upgrade
  savePaymentMethod
) {
  try {
    const data = {
      savePaymentMethod,
      responseCode: FAT_ZEBRA_RESPONSE_CODE.GATEWAY_ERROR.toString(),
      transactionStatus: false,
      messages: [
        "Verifying payment with gateway status [" + errorStatus + "].",
      ],
    };
    const { result: verifiedPayment } = await paymentService.verifyPayment(
      order.id,
      payment.id,
      savePaymentMethod,
      data
    );

    if (verifiedPayment.status === "Successful") {
      return verifiedPayment;
    }
    throw { message: PAYMENT_PROCESS_FAIL_MESSAGE, payment: verifiedPayment };
  } catch (error) {
    return error;
  }
}

// @ts-ignore - Project Upgrade
function handleTokenPurchaseSuccess(response) {
  const updatedPayment = response.result;

  if (updatedPayment.status === "Successful") {
    return updatedPayment;
  }

  // The error message displayed for patron is abstracted based on the remote error message.
  // Patron should only know:
  //  # A card is declined, so they need to switch card.
  //  # Invalid card details provided, so they need to amend the card entry.
  // Payment failed with Declined error.
  if (
    updatedPayment.errors &&
    updatedPayment.errors !== undefined &&
    updatedPayment.errors !== null &&
    updatedPayment.errors.length > 0 &&
    updatedPayment.errors[0].message === "Declined"
  ) {
    throw {
      message: fatZebraResponseCodeMapping[FAT_ZEBRA_RESPONSE_CODE.DECLINED],
      payment: updatedPayment,
    };
  } else {
    // Any other errors will display as card validation error message in order to abstract
    // the rejection to the patron.
    throw {
      message:
        fatZebraResponseCodeMapping[FAT_ZEBRA_RESPONSE_CODE.VALIDATION_ERROR],
    };
  }
}

async function handleTokenPurchaseFailure(
  // @ts-ignore - Project Upgrade
  errorStatus,
  // @ts-ignore - Project Upgrade
  order,
  // @ts-ignore - Project Upgrade
  payment,
  // @ts-ignore - Project Upgrade
  savePaymentMethod
) {
  try {
    const data = {
      // This is not a Fat Zebra response code, instead it means the token purchase
      // failed by Qhopper server.
      savePaymentMethod,
      responseCode: "-1",
      transactionStatus: false,
      messages: [
        "Verifying payment with token purchase status [" + errorStatus + "].",
      ],
    };
    const { result: verifiedPayment } = await paymentService.verifyPayment(
      order.id,
      payment.id,
      savePaymentMethod,
      data
    );
    if (verifiedPayment.status === "Successful") {
      return verifiedPayment;
    }

    throw { message: PAYMENT_PROCESS_FAIL_MESSAGE, payment: verifiedPayment };
  } catch (error) {
    return error;
  }
}

function factoryFatZebraTransactionService(): IFatZebraTransactionService {
  return Object.create(fatZebraTransactionService);
}

const fatZebraService = factoryFatZebraTransactionService();

export interface IPaymentService {
  payForOrder: (
    initiatedPayment: any,
    creditCardData: any,
    order: any,
    savePaymentMethod: boolean
  ) => Promise<any>;
  updatePayment: (
    vendorId: number,
    orderId: number,
    paymentId: number,
    fatZebraResponse: any,
    savePaymentMethod: boolean
  ) => Promise<any>;
  purchaseWithSavedToken: (
    orderId: number,
    paymentId: number,
    cardCvn: number
  ) => Promise<any>;
  verifyPayment: (
    orderId: number,
    paymentId: number,
    savePaymentMethod: boolean,
    data: any
  ) => Promise<any>;
  getApplePaySession: (orderId: number, validationUrl: string) => Promise<any>;
  purchaseWithWallet: (
    orderId: number,
    minorAmount: number,
    walletType: "APPLEPAYWEB" | "GOOGLE",
    purchaseToken: unknown
  ) => Promise<any>;
}

const paymentService: IPaymentService = {
  async payForOrder(
    initiatedPayment: any,
    creditCardData: any,
    order: any,
    savePaymentMethod = false
  ) {
    return await fatZebraService.completePayment(
      initiatedPayment,
      creditCardData,
      order,
      savePaymentMethod
    );
  },

  async updatePayment(
    vendorId: number,
    orderId: number,
    paymentId: number,
    fatZebraResponse: any,
    savePaymentMethod: boolean
  ): Promise<any> {
    let messages = [""];
    if (
      fatZebraResponse.errors &&
      // @ts-ignore - Project Upgrade
      !fatZebraResponseCodeMapping[fatZebraResponse.r]
    ) {
      messages = fatZebraResponse.errors.filter((e: any) => e !== null);
    } else {
      messages = [
        // @ts-ignore - Project Upgrade
        fatZebraResponseCodeMapping[fatZebraResponse.r] ??
          PAYMENT_PROCESS_FALLBACK_MESSAGE,
      ];
    }

    // Convert the Fat Zebra gateway response into a more portable structure and
    // remove any information that should not be transmitted back to the server.
    const paymentStatusUpdate: any = {
      savePaymentMethod,
      messages,
      responseCode: fatZebraResponse.r.toString(),
      transactionStatus: fatZebraResponse.successful || false,
      transactionId: fatZebraResponse.id,
      verification: fatZebraResponse.v,
    };

    // Fat Zebra will not always allocate a token, so only create the payment
    // gateway agnostic token if one is provided.
    if (fatZebraResponse.token) {
      paymentStatusUpdate.token = {
        id: fatZebraResponse.token,
        tokenType: "Fat Zebra",
      };
    }

    const result = await retryAsync(retryAttemptsDataposApiUpdate)(async () =>
      restFactory.post(
        `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/${vendorId}/order/${orderId}` +
          `/payment/${paymentId}/update`,
        paymentStatusUpdate,
        true
      )
    );

    // @ts-ignore
    if (result.failed !== undefined && result.failed) {
      // @ts-ignore
      return { ...result, orderId, paymentId: fatZebraResponse.id };
    }

    return result;
  },

  async purchaseWithSavedToken(
    orderId: number,
    paymentId: number,
    cardCvn: number
  ): Promise<any> {
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/order/${orderId}/payment/${paymentId}/purchase/token`,
      { cvv: cardCvn },
      true
    );
  },

  async verifyPayment(
    orderId: number,
    paymentId: number,
    savePaymentMethod: boolean,
    data: any
  ): Promise<any> {
    return await restFactory.post(
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/order/${orderId}/payment/${paymentId}/verify`,
      data,
      true
    );
  },

  async getApplePaySession(orderId: number, validationUrl: string) {
    return await restFactory.post(
      // @ts-ignore
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/order/${orderId}/payment/apple-pay/session`,
      { validationUrl },
      true
    );
  },

  async purchaseWithWallet(
    orderId: number,
    minorAmount: number,
    walletType: "APPLEPAYWEB" | "GOOGLE",
    purchaseToken: unknown
  ) {
    const googlePayBody = {
      amount: minorAmount,
      walletType,
      googlePayPurchaseToken: purchaseToken,
    };
    const applePayBody = {
      amount: minorAmount,
      walletType,
      applePayPurchaseToken: purchaseToken,
    };
    return await restFactory.post(
      // @ts-ignore
      `${env.REACT_APP_SERVER_URL}${env.REACT_APP_API_VERSION}/vendor/order/${orderId}/purchase/wallet`,
      walletType === "APPLEPAYWEB" ? applePayBody : googlePayBody,
      true
    );
  },
};

function factoryPaymentService(): IPaymentService {
  return Object.create(paymentService);
}

export default factoryPaymentService();
