import React, { useState, useEffect } from "react";
import { RootState } from "../../state/store/store";
import VendorStatusModal from "../Modal/VendorStatusModal";
import { useSelector, useDispatch } from "react-redux";
import { Vendor } from "../../types";
import { vendorActions } from "../../state/actions";

export interface ITransitionValidator {
  storeClosedModal?: React.ComponentType<any>;
  storePausedModal?: React.ComponentType<any>;
  redirectTo: string;
}

interface ITransitionValidatorProps {
  allowRedirect: (index: number, allow: boolean) => void;
  index: number;
  redirectTo: string;
  dispatchAction?: Function;
  store?: RootState;
}

const VendorStatusTransitionValidator = ({
  allowRedirect,
  index,
  redirectTo,
}: ITransitionValidatorProps) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const vendorId = state.selectedVendor.data.id;
  const venueId = state.selectedVendor.data.venueId;
  const vendor = state.vendorsByVenue.vendors[venueId].find(
    (vendor: Vendor) => vendor.id === vendorId
  );

  const vendorFetchStatus = useSelector(
    (state: RootState) => state.vendorsByVenue.fetchStatus
  );

  const [vendorFetchStarted, setVendorFetchStarted] = useState(false);

  // This is similar to componentDidMount, componentDidUpdate.
  useEffect(() => {
    dispatch(vendorActions.loadVendorsByVenue(venueId));
  }, [dispatch, venueId]);

  useEffect(() => {
    if (vendorFetchStatus === "fetching") {
      setVendorFetchStarted(true);
    }

    // Only allow transition if:
    // - vendor status re-fetched for new status
    // - vendor new status is not closed and not paused.
    if (
      vendor?.isOpen &&
      !vendor.isPaused &&
      vendorFetchStatus === "success" &&
      vendorFetchStarted
    ) {
      allowRedirect(index, true);
    }

    if (vendorFetchStatus === "failed") {
      allowRedirect(index, false);
    }
  }, [vendor, vendorFetchStatus, vendorFetchStarted, index, allowRedirect]);

  return (
    <>
      {vendor?.isPaused ? (
        <VendorStatusModal
          title="Back Soon!"
          text="We're taking a quick break, we'll be back soon!"
          goTo={redirectTo}
          isOpen={true}
        />
      ) : (
        <></>
      )}
      {!vendor?.isOpen && !vendor?.isPaused ? (
        <VendorStatusModal
          title="We have closed"
          text="We're closed at the moment, come back soon!"
          goTo={redirectTo}
          isOpen={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default VendorStatusTransitionValidator;
