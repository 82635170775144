import React from "react";
import { connect } from "react-redux";
import geoLocationService from "../services/geoLocationService";
import { RootState } from "../state/store/store";

interface WrapperProps {
  dispatchAction: Function;
  store: RootState;
}

/**
 * The purpose of this HOC is to get data and pass it down to WrappedComponent.
 * This component receives a list of actions to dispatch in parallel with their payload
 * { resolver: actionFunctionCreator, params: [REACT_APP_VENUE_ID] },
 */
export default (...actions: any[]) => {
  function mapStateToProps(state: RootState) {
    return { store: { ...state } };
  }

  function mapDispatchToProps(dispatch: Function) {
    return {
      dispatchAction: dispatch,
    };
  }

  // @ts-ignore - Project Upgrade
  return (WrappedComponent) => {
    const Wrapper = class extends React.Component<WrapperProps, null> {
      componentDidMount() {
        // captureGeolocation called here to ask for user permission when is a new user
        geoLocationService.captureGeolocation();
        actions.forEach((action: any) =>
          this.props.dispatchAction(action.resolver(...action.params))
        );
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    };
    // @ts-ignore - Project Upgrade
    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
  };
};
