import React from "react";
import withData from "../../highOrderComponents/withData";
import menuService from "../../services/menuService";
import { loadMenu } from "../../state/actions/menuActions";
import { removeSoldOutItems } from "../../state/actions/orderActions";
import { RootState } from "../../state/store/store";
import MenuSoldOutModal from "../Modal/MenuSoldOutModal";

interface ITransitionValidatorProps {
  allowRedirect: (index: number, allow: boolean) => void;
  redirectTo: string;
  index: number;
  dispatchAction: Function;
  store: RootState;
}

interface ITransitionValidatorState {
  closeModal: boolean;
}

class TransitionValidator extends React.Component<
  ITransitionValidatorProps,
  ITransitionValidatorState
> {
  isDataUpToDate = false;
  isTransitionAllowed = false;

  constructor(props: ITransitionValidatorProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.resetValidator();
  }

  get menu() {
    return this.props.store?.menu.menuByVendor[this.vendorId];
  }

  get userOrder() {
    return this.props.store?.order.userOrder;
  }

  get menuByVendor() {
    return this.props.store?.menu.menuByVendor[this.vendorId];
  }

  get vendorId(): number {
    try {
      return (
        this.props.store?.selectedVendor.data.id ||
        Number.parseInt(process.env.REACT_APP_VENDOR_ID || "0")
      );
    } catch (error) {
      console.error(
        "The REACT_APP_VENDOR_ID should be defined or a vendor should be selected"
      );
      return 0;
    }
  }

  resetValidator() {
    this.isDataUpToDate = false;
    this.isTransitionAllowed = false;
    this.state = {
      closeModal: false,
    };
  }

  componentDidMount() {
    // @ts-ignore - Project Upgrade
    this.props.dispatchAction(loadMenu(this.vendorId));
  }

  componentWillReceiveProps(nextProps: ITransitionValidatorProps) {
    this.isDataUpToDate =
      this.isDataUpToDate ||
      (this.props.store?.menu.fetchStatus === "fetching" &&
        (nextProps.store?.menu.fetchStatus === "success" ||
          nextProps.store?.menu.fetchStatus === "failed"));
  }

  componentDidUpdate() {
    if (
      this.isDataUpToDate &&
      this.menu &&
      menuService.getSoldOutItems(this.userOrder, this.menuByVendor).length > 0
    ) {
      this.isTransitionAllowed = false;
    } else if (this.isDataUpToDate) {
      this.isTransitionAllowed = true;
    }
    if (this.isDataUpToDate && this.isTransitionAllowed) {
      this.props.allowRedirect(this.props.index, true);
    }
    if (
      this.isDataUpToDate &&
      this.props.store?.menu.fetchStatus === "failed"
    ) {
      this.props.allowRedirect(this.props.index, false);
    }
  }

  closeModal() {
    this.setState({
      closeModal: true,
    });
    this.resetValidator();
    const soldOutItems = menuService.getSoldOutItems(
      this.userOrder,
      this.menuByVendor
    );
    if (soldOutItems.length > 0) {
      soldOutItems.forEach((item) => {
        // @ts-ignore - Project Upgrade
        this.props.dispatchAction(removeSoldOutItems(item));
      });
    }
  }

  defaultMenuOutdatedModal() {
    return (
      <MenuSoldOutModal
        onClick={this.closeModal}
        soldOutItems={menuService.getSoldOutItems(
          this.userOrder,
          this.menuByVendor
        )}
        goTo={this.props.redirectTo}
        isOpen={!this.state.closeModal}
      />
    );
  }

  getModalToRender() {
    let ModalToShow = null;
    if (
      this.isDataUpToDate &&
      this.menu &&
      menuService.getSoldOutItems(this.userOrder, this.menuByVendor).length > 0
    ) {
      ModalToShow = this.defaultMenuOutdatedModal();
    }
    return ModalToShow;
  }

  render() {
    const ModalToShow = this.getModalToRender();
    return <React.Fragment>{ModalToShow}</React.Fragment>;
  }
}

export default withData()(TransitionValidator) as any;
