import React from "react";
import { Vendor } from "../../types";
import Asset from "../Asset/Asset";
import { Link } from "react-router-dom";
import { routes } from "../../app-constants/Routes";

export interface IVendorInfoProps {
  /** Is this component is on the delivery page? If true, it won't be clickable. */
  deliveryPage: boolean;
  vendor: Vendor;
  /** Ignored if `deliveryPage` is true. */
  onVendorClick: () => void;
  queryParams: string;
}

/** Displays the vendor's name and icon. */
const VendorInfo = (props: IVendorInfoProps) => {
  const renderVendorInfo = () => {
    return (
      <div>
        {props.vendor.vendorDisplayAssets &&
          props.vendor.vendorDisplayAssets.thumbnailAssetId && (
            <div className="row">
              <div className="thumbnail-image">
                <Asset
                  thumbnailAssetId={
                    props.vendor.vendorDisplayAssets.thumbnailAssetId
                  }
                />
              </div>
            </div>
          )}
        <div className="row">
          <div className="option-name">{props.vendor.storeName}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="store-column">
      {!props.deliveryPage ? (
        <Link
          to={{
            pathname: routes.ORDER_FLOW.PLACE_ORDER.PATH,
            search: props.queryParams,
          }}
          onClick={props.onVendorClick}
        >
          {renderVendorInfo()}
        </Link>
      ) : (
        <>{renderVendorInfo()}</>
      )}
    </div>
  );
};

export default VendorInfo;
