import { env } from "../../app-constants";
import { Vendor, Venue } from "../../types";
import { SelectedVendorState, VendorState } from "../types/vendorTypes";

/**
 * @return If {@link REACT_APP_MULTI_VENDOR_SUPPORT} is enabled, the {@link Vendor} chosen by the
 *         user, or `undefined` when they haven't chosen yet. Otherwise, the `Vendor` configured by
 *         {@link REACT_APP_VENUE_ID} and {@link REACT_APP_VENDOR_ID}.
 */
export const selectedVendor = (
  vendorsByVenue: VendorState,
  selectedVendor: SelectedVendorState
): Vendor | undefined =>
  (vendorsByVenue.vendors[selectedVendor.data.venueId] || []).find(
    (item) => item.id === selectedVendor.data.id
  );

/**
 * Discovered vendors for the client or client's specified venue.
 * @param venues for the client.
 * @param vendorsByVenue vendors grouped by venue id.
 * @returns vendors that are discovered.
 */
export const discoveredVendors = (
  venues: readonly Venue[],
  vendorsByVenue: Record<number, readonly Vendor[]>
) => {
  const discoverVenueId = env.REACT_APP_DISCOVER_VENUE_ID;
  if (discoverVenueId) {
    return vendorsByVenue[discoverVenueId];
  }

  return venues
    .flatMap((venue) => vendorsByVenue[venue.id])
    .filter((venue) => venue !== undefined);
};
