import React from "react";
import Modal from "./Modal";

export interface IMenuMaxItemsAlertModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onOkClick: () => void;
}

export default (props: IMenuMaxItemsAlertModalProps) => (
  // @ts-ignore - Project Upgrade
  <Modal
    className="alert-modal menu-item-limit"
    isOpen={props.isOpen}
    title={props.title}
    content={<p className="alert-modal-text">{props.text}</p>}
    actions={
      <a className="btn btn-primary btn-red" onClick={props.onOkClick}>
        <i className="fas fa-check"></i>
        Ok
      </a>
    }
  ></Modal>
);
