import { FETCH_STATUS } from "../actions";

export const LOAD_COUPON = "LOAD_COUPON";
export const COUPON_LOADED_SUCCESS = "COUPON_LOADED_SUCCESS";
export const COUPON_LOADED_ERROR = "COUPON_LOADED_ERROR";
export const SET_COUPON_CODE = "SET_COUPON_CODE";

export interface LoadCouponAction {
  type: typeof LOAD_COUPON;
  payload: { orderId: number };
}

export interface CouponLoadedSuccessAction {
  type: typeof COUPON_LOADED_SUCCESS;
  payload: { coupon: unknown };
}

export interface CouponLoadedErrorAction {
  type: typeof COUPON_LOADED_ERROR;
  payload: { error: unknown };
}

export interface SetCouponCodeAction {
  type: typeof SET_COUPON_CODE;
  payload: { verification: string };
}

export type CouponActionType =
  | LoadCouponAction
  | CouponLoadedSuccessAction
  | CouponLoadedErrorAction
  | SetCouponCodeAction;

export interface CouponState {
  data: unknown;
  fetchStatus: FETCH_STATUS;
  error: unknown;
}
