import React from "react";
import Modal from "./Modal";

export interface IMenuRestrictedItemsModalProps {
  isOpen: boolean;
  onClick: () => void;
}

export default (props: IMenuRestrictedItemsModalProps) => (
  // @ts-ignore - Project Upgrade
  <Modal
    className="alert-modal"
    isOpen={props.isOpen}
    title="6 Drink Maximum"
    content={
      <React.Fragment>
        <p className="alert-modal-text">
          Sorry, there is a limit of 6 alcoholic drinks per order.
        </p>
      </React.Fragment>
    }
    actions={
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          props.onClick();
        }}
        className="btn btn-primary"
      >
        <i className="fas fa-check"></i>
        Ok
      </a>
    }
  ></Modal>
);
