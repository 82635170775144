import { all, call, put, takeEvery } from "redux-saga/effects";
import consumerService from "../../services/consumerService";
import {
  CONSUMER_CREATED_ERROR,
  CONSUMER_CREATED_SUCCESS,
  CreateConsumerAction,
  CREATE_CONSUMER,
  IssueReceiptAction,
  ISSUE_RECEIPT,
  UpdateConsumerAction,
  UpdateConsumerAndIssueReceiptAction,
  UPDATE_CONSUMER,
  UPDATE_CONSUMER_AND_ISSUE_RECEIPT,
  UPDATE_CONSUMER_ERROR,
  UPDATE_CONSUMER_SUCCESS,
} from "../types/consumerTypes";

export function* createConsumer({
  payload: { mobileNumber, emailAddress, fullName },
}: CreateConsumerAction) {
  try {
    const { result } = yield call(
      consumerService.createConsumer,
      mobileNumber,
      emailAddress,
      fullName
    );
    yield put({
      type: CONSUMER_CREATED_SUCCESS,
      payload: { consumer: result },
    });
  } catch (error) {
    yield put({
      type: CONSUMER_CREATED_ERROR,
      payload: { error },
    });
  }
}

export function* updateConsumer({
  payload: { consumer },
}: UpdateConsumerAction) {
  try {
    const { result } = yield call(consumerService.updateConsumer, consumer);
    yield put({
      type: UPDATE_CONSUMER_SUCCESS,
      payload: { consumer: result },
    });
  } catch (error) {
    yield put({
      type: UPDATE_CONSUMER_ERROR,
      payload: { error },
    });
  }
}

export function* issueReceipt({ payload: { orderId } }: IssueReceiptAction) {
  try {
    yield call(consumerService.issueReceipt, orderId);
  } catch (error) {
    console.error(error);
  }
}

export function* updateConsumerAndIssueReceipt({
  payload,
}: UpdateConsumerAndIssueReceiptAction) {
  try {
    yield updateConsumer({ payload, type: "UPDATE_CONSUMER" });
    yield issueReceipt({ payload, type: "ISSUE_RECEIPT" });
  } catch (error) {
    console.error(error);
  }
}

export function* consumerRootSaga() {
  yield all([
    takeEvery(CREATE_CONSUMER, createConsumer),
    takeEvery(UPDATE_CONSUMER, updateConsumer),
    takeEvery(ISSUE_RECEIPT, issueReceipt),
    takeEvery(UPDATE_CONSUMER_AND_ISSUE_RECEIPT, updateConsumerAndIssueReceipt),
  ]);
}
