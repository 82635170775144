/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-find-dom-node */
import * as he from "he";
import React, { useEffect, useRef } from "react";
import * as ReactDOM from "react-dom";
import { FormattedNumber } from "react-intl";
import Utils from "../../services/utils";
import { Modifier } from "../../state/types/menuTypes";
import { OrderItemOption } from "../../state/types/orderTypes";
import PlusMinusButton from "../Button/PlusMinusButton/PlusMinusButton";
import { AddSubtractOrderItemType } from "./MenuItemRow";
import { uniqueModifierKey } from "./MenuItemOptions";

export const renderSeparator = (index: number, options: any[]) => {
  if (index < options.length - 1 && index !== options.length - 2) {
    return ", ";
  }

  if (index === options.length - 2) {
    return " & ";
  }

  return "";
};

const ModifierPrice = ({ modifiers }: { modifiers: Modifier[] }) => {
  const totalExtraPrice = modifiers.reduce((acc, m) => acc + m.price.amount, 0);

  const hasPrice = modifiers[0];

  return (
    <>
      {hasPrice && totalExtraPrice > 0 && (
        <>
          <br />
          <span className="extra-price">
            + {hasPrice.price.currencySymbol}
            <FormattedNumber
              minimumFractionDigits={2}
              value={totalExtraPrice}
            />
          </span>
        </>
      )}
    </>
  );
};

export interface IMenuSelectedItemProps {
  name: string;
  option: OrderItemOption;
  onAddRestItem: (action: AddSubtractOrderItemType) => void;
  shouldScroll: boolean;
}

interface PlusMinusButtonProps {
  option: OrderItemOption;
  onAddRestItem: (action: AddSubtractOrderItemType) => void;
}

interface ParsedOptionNameProps {
  modifiers: Modifier[];
}

const PlusMinusButtons = ({ option, onAddRestItem }: PlusMinusButtonProps) => (
  <PlusMinusButton
    className="plus-minus-btn"
    count={option.count}
    onMinusClick={() => onAddRestItem("rest")}
    onPlusClick={() => onAddRestItem("add")}
  />
);

const ParsedOptionName = ({ modifiers }: ParsedOptionNameProps) => (
  <>
    {modifiers.map((m, index) => (
      <span className="selected-option" key={uniqueModifierKey(m)}>
        {he.decode(m.name)}
        {renderSeparator(index, Object.keys(modifiers))}
      </span>
    ))}
  </>
);

const MenuSelectedItemOption = ({
  shouldScroll,
  name,
  option,
  onAddRestItem,
}: IMenuSelectedItemProps) => {
  const itemRef = useRef(null);

  useEffect(() => {
    if (shouldScroll) {
      const elementScrollTo = ReactDOM.findDOMNode(itemRef.current);
      Utils.smoothScroll(elementScrollTo);
    }
  }, [shouldScroll]);

  return (
    <div className="selected-item-box clearfix" ref={itemRef}>
      <div className="selected-item item-info">
        <span className="select-item-title">
          {name}
          {option.modifiers && <ModifierPrice modifiers={option.modifiers} />}
        </span>
        <br />
        <div className="selected-item-options">
          {option.modifiers && (
            <ParsedOptionName modifiers={option.modifiers} />
          )}
        </div>
      </div>
      <div className="item-selection">
        <PlusMinusButtons option={option} onAddRestItem={onAddRestItem} />
      </div>
    </div>
  );
};

export default MenuSelectedItemOption;
