/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Modal from "./Modal";
import { ModifierGroup } from "../../state/types/menuTypes";

type MissingOptionModalProps = {
  missingModifierGroups: ModifierGroup[];
  onDismiss: () => void;
};

const MissingOptionModal = ({
  onDismiss,
  missingModifierGroups,
}: MissingOptionModalProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    // @ts-ignore
    <Modal
      className="alert-modal"
      isOpen={isOpen}
      title="Missing options"
      content={
        <React.Fragment>
          <p className="alert-modal-text">
            Please select the following required option
          </p>
          <ul style={{ fontSize: "16px" }}>
            {missingModifierGroups.map((group) => (
              <li key={`missing-modifier-${group.id}`}>{group.name}</li>
            ))}
          </ul>
        </React.Fragment>
      }
      actions={
        <button
          onClick={() => {
            setIsOpen(!isOpen);
            onDismiss();
          }}
          className="btn btn-primary btn-done"
        >
          Ok
        </button>
      }
    ></Modal>
  );
};

export default MissingOptionModal;
