import React from "react";
import withData from "../../highOrderComponents/withData";
import { loadOrder } from "../../state/actions/placeOrderAction";
import { RootState } from "../../state/store/store";

interface IWaitOrderValidationProps {
  allowRedirect: (index: number, allow: boolean) => void;
  index: number;
  dispatchAction?: Function;
  store?: RootState;
}

class WaitOrderValidation extends React.Component<
  IWaitOrderValidationProps,
  null
> {
  isDataUpToDate = false;

  constructor(props: IWaitOrderValidationProps) {
    super(props);
    this.resetValidator();
  }

  resetValidator() {
    this.isDataUpToDate = false;
  }

  componentDidMount() {
    // @ts-ignore - Project Upgrade
    const { id: orderId } = this.props.store?.placedOrder.data;
    // @ts-ignore - Project Upgrade
    this.props.dispatchAction(
      // @ts-ignore - Project Upgrade
      loadOrder(this.props.store.placedOrder.data.vendorId, orderId)
    );
  }

  componentWillReceiveProps(nextProps: IWaitOrderValidationProps) {
    this.isDataUpToDate =
      this.isDataUpToDate ||
      (this.props.store?.placedOrder.fetchStatus === "fetching" &&
        (nextProps.store?.placedOrder.fetchStatus === "success" ||
          nextProps.store?.placedOrder.fetchStatus === "failed"));
  }

  componentDidUpdate() {
    if (
      this.isDataUpToDate &&
      (this.props.store?.payment.error !== "" ||
        this.props.store?.placedOrder.fetchStatus === "failed")
    ) {
      this.resetValidator();
      this.props.allowRedirect(this.props.index, false);
    }
    if (
      this.isDataUpToDate &&
      // @ts-ignore - Project Upgrade
      (this.props.store?.placedOrder.data.orderStatus === "Waiting" ||
        // @ts-ignore - Project Upgrade
        this.props.store?.placedOrder.data.orderStatus === "Scheduled")
    ) {
      this.props.allowRedirect(this.props.index, true);
    }
  }

  render() {
    return null;
  }
}

export default withData()(WaitOrderValidation) as any;
