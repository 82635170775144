import {
  AlertOrderAction,
  ALERT_ORDER,
  LoadOrderAction,
  LOAD_ORDER,
  PlaceOrderAction,
  PlaceOrderActionType,
  PLACE_ORDER,
  PurchaseWithCouponAction,
  PURCHASE_WITH_COUPON,
  WaitOrderResolvedAction,
  WaitStatusAction,
  WAIT_ORDER_RESOLVED,
  WAIT_STATUS,
  PLACE_ORDER_WITH_APPLE_PAY,
  CLEAR_PLACED_ORDER,
} from "../types/placeOrderTypes";

export const placeOrder = (
  order: PlaceOrderAction["payload"]["order"],
  vendorId: PlaceOrderAction["payload"]["vendorId"],
  menuId: PlaceOrderAction["payload"]["menuId"],
  attributes: PlaceOrderAction["payload"]["attributes"]
): PlaceOrderActionType => ({
  payload: { order, vendorId, menuId, attributes },
  type: PLACE_ORDER,
});

export const purchaseWithCoupon = (
  verification: PurchaseWithCouponAction["payload"]["verification"],
  orderId: PurchaseWithCouponAction["payload"]["orderId"],
  vendorId: PurchaseWithCouponAction["payload"]["vendorId"],
  couponData: PurchaseWithCouponAction["payload"]["couponData"]
): PlaceOrderActionType => ({
  type: PURCHASE_WITH_COUPON,
  payload: {
    verification,
    orderId,
    vendorId,
    couponData: { ...(couponData as object), couponCode: verification },
  },
});

export const loadOrder = (
  vendorId: LoadOrderAction["payload"]["vendorId"],
  orderId: LoadOrderAction["payload"]["orderId"]
): PlaceOrderActionType => ({
  type: LOAD_ORDER,
  payload: { vendorId, orderId },
});

export const waitOrderResolved = (
  vendorId: WaitOrderResolvedAction["payload"]["vendorId"],
  orderId: WaitOrderResolvedAction["payload"]["orderId"]
): PlaceOrderActionType => ({
  type: WAIT_ORDER_RESOLVED,
  payload: { vendorId, orderId },
});

export const waitForOrderStatus = (
  vendorId: WaitStatusAction["payload"]["vendorId"],
  orderId: WaitStatusAction["payload"]["orderId"],
  status: WaitStatusAction["payload"]["status"]
): PlaceOrderActionType => ({
  type: WAIT_STATUS,
  payload: { vendorId, orderId, status },
});

export const alertOrder = (
  orderId: AlertOrderAction["payload"]["orderId"],
  vendorId: AlertOrderAction["payload"]["vendorId"]
): PlaceOrderActionType => ({
  type: ALERT_ORDER,
  payload: { orderId, vendorId },
});

export const placeOrderWithApplePay = (
  order: PlaceOrderAction["payload"]["order"],
  vendorId: PlaceOrderAction["payload"]["vendorId"],
  menuId: PlaceOrderAction["payload"]["menuId"],
  attributes: PlaceOrderAction["payload"]["attributes"]
): PlaceOrderActionType => ({
  payload: { order, vendorId, menuId, attributes },
  type: PLACE_ORDER_WITH_APPLE_PAY,
});

export const clearPlacedOrder = (): PlaceOrderActionType => ({
  type: CLEAR_PLACED_ORDER,
  payload: {},
});
