import React from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";

export interface IVendorStatusModalProps {
  title: string;
  text: string;
  goTo: string;
  isOpen: boolean;
}

export default (props: IVendorStatusModalProps) => (
  // @ts-ignore - Project Upgrade
  <Modal
    className="alert-modal"
    isOpen={props.isOpen}
    title={props.title}
    content={<p className="alert-modal-text">{props.text}</p>}
    actions={
      <Link className="btn btn-primary btn-red" to={props.goTo}>
        Ok
      </Link>
    }
  ></Modal>
);
