import he from "he";
import { all, call, put, takeEvery } from "redux-saga/effects";
import vendorService from "../../services/vendorService";
import {
  FETCHING_VENDORS_BY_VENUE,
  FetchVendorsByVenueAction,
  FETCH_VENDORS_BY_VENUE,
  FETCH_VENDORS_BY_VENUE_FAILED,
  FETCH_VENDORS_BY_VENUE_SUCCEEDED,
  SelectVendorAction,
  SELECT_VENDOR,
  SELECT_VENDOR_SUCCESS,
} from "../types/vendorTypes";

export function* getVendorByVenueId({ payload }: FetchVendorsByVenueAction) {
  try {
    yield put({
      type: FETCHING_VENDORS_BY_VENUE,
      payload: { venueId: payload.venueId },
    });

    const { result } = yield call(
      vendorService.getVendorByVenueId,
      payload.venueId
    );
    yield put({
      type: FETCH_VENDORS_BY_VENUE_SUCCEEDED,
      payload: {
        venueId: payload.venueId,
        // @ts-ignore - Project Upgrade
        vendors: result.map((vendor) => ({
          ...vendor,
          storeName: he.decode(vendor.storeName),
        })),
      },
    });
    return result;
  } catch (error) {
    console.error(error);

    yield put({
      type: FETCH_VENDORS_BY_VENUE_FAILED,
      payload: { error, venueId: payload.venueId },
    });
  }
}

export function* selectVendor({ payload }: SelectVendorAction) {
  try {
    yield put({ payload, type: SELECT_VENDOR_SUCCESS });
  } catch (error) {
    // TODO: Safe error management
  }
}

export function* vendorsRootSaga() {
  yield all([
    takeEvery(FETCH_VENDORS_BY_VENUE, getVendorByVenueId),
    takeEvery(SELECT_VENDOR, selectVendor),
  ]);
}
