import { FETCH_STATUS } from "../actions";
import { Vendor, VendorDisplayAssets } from "../../types";

export const FETCH_VENDORS_BY_VENUE = "FETCH_VENDORS_BY_VENUE";
export const FETCHING_VENDORS_BY_VENUE = "FETCHING_VENDORS_BY_VENUE";
export const FETCH_VENDORS_BY_VENUE_SUCCEEDED =
  "FETCH_VENDORS_BY_VENUE_SUCCEEDED";
export const FETCH_VENDORS_BY_VENUE_FAILED = "FETCH_VENDORS_BY_VENUE_FAILED";
export const FETCH_PICKUP_TIME_SLOTS = "FETCH_PICKUP_TIME_SLOTS";
export const FETCH_PICKUP_TIME_SLOTS_SUCCEEDED =
  "FETCH_PICKUP_TIME_SLOTS_SUCCEEDED";
export const FETCH_PICKUP_TIME_SLOTS_FAILED = "FETCH_PICKUP_TIME_SLOTS_FAILED";
export const FETCH_REQUIRED_VENUES_AND_VENDORS =
  "FETCH_REQUIRED_VENUES_AND_VENDORS";
export const FETCH_VENDORS_AND_MENU = "FETCH_VENDORS_AND_MENU";
export const SELECT_VENDOR = "SELECT_VENDOR";
export const SELECT_VENDOR_SUCCESS = "SELECT_VENDOR_SUCCESS";
export const SELECT_VENDOR_ERROR = "SELECT_VENDOR_ERROR";
export const RESET_SELECTED_VENDOR = "RESET_SELECTED_VENDOR";
export const RESET_VENUES_AND_VENDORS = "RESET_VENUES_AND_VENDORS";

export interface FetchVendorsByVenueAction {
  type: typeof FETCH_VENDORS_BY_VENUE;
  payload: { venueId: number };
}

export interface FetchingVendorsByVenueAction {
  type: typeof FETCHING_VENDORS_BY_VENUE;
}

export interface FetchVendorsByVenueSucceededAction {
  type: typeof FETCH_VENDORS_BY_VENUE_SUCCEEDED;
  payload: { venueId: number; vendors: ReadonlyArray<Vendor> };
}

export interface FetchVendorsByVenueFailedAction {
  type: typeof FETCH_VENDORS_BY_VENUE_FAILED;
  payload: { error: unknown };
}

export interface FetchPickupTimeSlotsAction {
  type: typeof FETCH_PICKUP_TIME_SLOTS;
  payload: { vendorId: number };
}

export interface FetchPickupTimeSlotsSucceededAction {
  type: typeof FETCH_PICKUP_TIME_SLOTS_SUCCEEDED;
  payload: { vendorId: number; timeSlots: ReadonlyArray<string> };
}

export interface FetchPickupTimeSlotsFailedAction {
  type: typeof FETCH_PICKUP_TIME_SLOTS_FAILED;
  payload: { error: unknown };
}

export interface FetchRequiredVenuesAndVendorsAction {
  type: typeof FETCH_REQUIRED_VENUES_AND_VENDORS;
  payload: { venueId: number };
}

export interface FetchVendorsAndMenuAction {
  type: typeof FETCH_VENDORS_AND_MENU;
  payload: { venueId: number; vendorId: number };
}

export interface SelectVendorAction {
  type: typeof SELECT_VENDOR;
  payload: {
    id: number;
    venueId: number;
    storeName: string;
    phoneNumber: string;
    location: string;
    displayAssets?: VendorDisplayAssets;
  };
}

export interface SelectVendorSuccessAction {
  type: typeof SELECT_VENDOR_SUCCESS;
  payload: {
    id: number;
    venueId: number;
    storeName: string;
    phoneNumber: string;
    location: string;
    displayAssets?: VendorDisplayAssets;
  };
}

export interface SelectVendorFailedAction {
  type: typeof SELECT_VENDOR_ERROR;
  payload: {
    id: number;
    venueId: number;
    storeName: string;
    phoneNumber: string;
    location: string;
    displayAssets?: VendorDisplayAssets;
  };
}

export interface ResetSelectedVendorAction {
  type: typeof RESET_SELECTED_VENDOR;
}

export interface ResetVenuesAndVendorsAction {
  type: typeof RESET_VENUES_AND_VENDORS;
}

export type VendorActionType =
  | FetchVendorsByVenueAction
  | FetchingVendorsByVenueAction
  | FetchVendorsByVenueSucceededAction
  | FetchVendorsByVenueFailedAction
  | FetchPickupTimeSlotsAction
  | FetchPickupTimeSlotsSucceededAction
  | FetchPickupTimeSlotsFailedAction
  | FetchVendorsAndMenuAction
  | SelectVendorAction
  | SelectVendorSuccessAction
  | SelectVendorFailedAction
  | ResetSelectedVendorAction
  | ResetVenuesAndVendorsAction
  | FetchRequiredVenuesAndVendorsAction;

export interface VendorState {
  fetchStatus: FETCH_STATUS;
  vendors: Record<number, ReadonlyArray<Vendor>>;
  error: unknown;
}

export interface TimeSlotState {
  fetchStatus: FETCH_STATUS;
  timeSlots: Record<number, ReadonlyArray<string>>;
  error: unknown;
}

export interface SelectedVendorState {
  data: {
    id: number;
    venueId: number;
    storeName: string;
    phoneNumber: string;
    location: string;
    displayAssets?: VendorDisplayAssets;
  };
  allowSelect: boolean;
}
