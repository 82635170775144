/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from "react";
import {
  SetFullName,
  migrateConsumer,
  setEmailAddress,
  setMobilePhone,
} from "../state/actions/consumerActions";
import { selectServiceMode } from "../state/actions/orderActions";
import { RootState } from "../state/store/store";
import withData from "./withData";
import { ServiceMode } from "../types";
import { ConsumerData } from "../state/types/consumerTypes";

interface IContactFormEnhancerProps {
  store: RootState;
  dispatchAction: Function;
}

// @ts-ignore - Project Upgrade
export default (WrappedComponent) => {
  const ContactFormEnhancer = (props: IContactFormEnhancerProps) => {
    useEffect(() => {
      const savedMobileNumber = props.store.consumer.data?.contact?.mobile;
      if (savedMobileNumber !== undefined) {
        props.dispatchAction(setMobilePhone(savedMobileNumber));
      }

      const savedEmail = props.store.consumer.data?.contact?.email;
      if (savedEmail !== undefined) {
        props.dispatchAction(setEmailAddress(savedEmail));
      }

      const savedFullName = props.store.consumer.data?.name?.fullName;
      if (savedFullName !== undefined) {
        props.dispatchAction(SetFullName(savedFullName));
      }

      //Used to migrate consumers from the old Angular App to this app
      const legacyConsumer = localStorage.getItem("consumer");
      if (legacyConsumer) {
        localStorage.removeItem("consumer");
        const legacyConsumerState: ConsumerData = JSON.parse(legacyConsumer);
        props.dispatchAction(migrateConsumer(legacyConsumerState));
        if (legacyConsumerState.contact?.mobile) {
          const savedPhoneNumber = legacyConsumerState.contact.mobile;
          props.dispatchAction(setMobilePhone(savedPhoneNumber));
        }
      }
    }, []);

    const onMobileNumberChanged = (mobileNumber: string) => {
      props.dispatchAction(setMobilePhone(mobileNumber));
    };

    const onEmailAddressChanged = (emailAddress: string) => {
      props.dispatchAction(setEmailAddress(emailAddress));
    };

    const onFullNameChanged = (fullName: string) => {
      props.dispatchAction(SetFullName(fullName));
    };

    const onServiceModeChanged = (serviceMode: ServiceMode) => {
      props.dispatchAction(selectServiceMode(serviceMode));
    };

    return (
      <WrappedComponent
        onMobileNumberChanged={onMobileNumberChanged}
        onServiceModeChanged={onServiceModeChanged}
        onEmailAddressChanged={onEmailAddressChanged}
        onFullNameChanged={onFullNameChanged}
        {...props}
      />
    );
  };

  return withData()(ContactFormEnhancer);
};
