import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../app-constants";
import { FormattedNumber } from "react-intl";

interface IOrderSummaryProps {
  totalPayment: any;
  items: any[];
  showAddRemoveItemsBtn: boolean;
}

interface IOrderSummaryState {
  shouldRedirect: boolean;
}

export default class OrderSummary extends React.Component<
  IOrderSummaryProps,
  IOrderSummaryState
> {
  constructor(props: IOrderSummaryProps) {
    super(props);
    this.state = {
      shouldRedirect: false,
    };
  }

  getSummaryModifier(item: any, parent: any) {
    if (item.parentItemId === parent.id) {
      return (
        <div key={`${item.id}-${item.parentItemId}-${parent.id}`}>
          - {item.name} {item.perItemPrice.currencySymbol}
          <FormattedNumber
            minimumFractionDigits={2}
            value={item.perItemPrice.amount || 0}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  getSummaryItem(item: any, index: number) {
    if (item.parentItemId == null) {
      return (
        <tr key={item.name + index} style={{ lineHeight: "normal" }}>
          <td className="width-1">
            <div className="item-title">{item.name}</div>
            {this.props.items.map((modifier: any, index: number) =>
              this.getSummaryModifier(modifier, item)
            )}
          </td>
          <td className="width-2">
            <span className="amount">{item.quantity}</span>
          </td>
          <td className="width-3">
            <span className="item-price">
              {item.perItemPrice.currencySymbol}
              <FormattedNumber
                minimumFractionDigits={2}
                value={item.perItemPrice.amount || 0}
              />
            </span>
          </td>
        </tr>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <table className="table table-summary">
        <thead>
          <tr>
            <th className="width-1 first">Item</th>
            <th className="width-2">Qty</th>
            <th className="width-3 last">Price</th>
          </tr>
        </thead>
        <tbody>
          {this.props.items.map((item: any, index: number) =>
            this.getSummaryItem(item, index)
          )}
          {this.props.showAddRemoveItemsBtn ? (
            <tr>
              <td colSpan={3} className="width-4 pl-0 pr-0">
                <Link
                  className="btn border border-dark w-100"
                  to={routes.ORDER_FLOW.PLACE_ORDER.PATH}
                >
                  Add or remove items
                </Link>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
  }
}
