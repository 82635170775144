/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import { waitForOrderStatus } from "../state/actions/placeOrderAction";
import { RootState } from "../state/store/store";
import withData from "./withData";

interface IWaitForOrderResolvedProps {
  store: RootState;
  dispatchAction: Function;
}

interface IWaitForOrderResolvedState {
  showPickUpModal: boolean;
  showOrderCancelledModal: boolean;
}

// @ts-ignore - Project Upgrade
export default (WrappedComponent) => {
  class WaitForOrderResolved extends React.Component<
    IWaitForOrderResolvedProps,
    IWaitForOrderResolvedState
  > {
    constructor(props: IWaitForOrderResolvedProps) {
      super(props);
      this.state = {
        showPickUpModal: false,
        showOrderCancelledModal: false,
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IWaitForOrderResolvedProps) {
      if (
        // @ts-ignore - Project Upgrade
        nextProps.store.placedOrder.data.orderStatus === "PendingAlerted" ||
        // @ts-ignore - Project Upgrade
        nextProps.store.placedOrder.data.orderStatus === "Serviced"
      ) {
        this.setState({ showPickUpModal: true });
      }

      // @ts-ignore - Project Upgrade
      if (nextProps.store.placedOrder.data.orderStatus === "Cancelled") {
        this.setState({ showOrderCancelledModal: true });
      }

      if (
        // @ts-ignore
        (nextProps.store.placedOrder.error?.length ?? 0) > 0
      ) {
        // The order cancelled modal should not be used for network errors.
        // Instead, refer to /components/Error/NetworkErrorHandler.tsx
        (nextProps.store.placedOrder.error as any)?.message !==
          "Network Error" && this.setState({ showOrderCancelledModal: true });
      }
    }

    componentDidMount() {
      // @ts-ignore - Project Upgrade
      const { id: orderId, vendorId } = this.props.store.placedOrder.data;
      this.props.dispatchAction(
        waitForOrderStatus(vendorId, orderId, [
          "PendingAlerted",
          "Cancelled",
          "Alerted",
          "Serviced",
        ])
      );
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            showPickUpModal={this.state.showPickUpModal}
            showOrderCancelledModal={this.state.showOrderCancelledModal}
            {...this.props}
          />
        </React.Fragment>
      );
    }
  }

  return withData()(WaitForOrderResolved);
};
