import { VendorDisplayAssets } from "../../types";
import {
  FetchPickupTimeSlotsAction,
  FetchVendorsAndMenuAction,
  FetchVendorsByVenueAction,
  FETCH_PICKUP_TIME_SLOTS,
  FETCH_VENDORS_AND_MENU,
  RESET_SELECTED_VENDOR,
  SelectVendorAction,
  SELECT_VENDOR,
  RESET_VENUES_AND_VENDORS,
  VendorActionType,
  FETCH_REQUIRED_VENUES_AND_VENDORS,
  FetchRequiredVenuesAndVendorsAction,
} from "../types/vendorTypes";

export const loadVendorsByVenue = (
  venueId: FetchVendorsByVenueAction["payload"]["venueId"]
): VendorActionType => ({
  payload: { venueId },
  type: "FETCH_VENDORS_BY_VENUE",
});

export const loadPikUpTimeSlots = (
  vendorId: FetchPickupTimeSlotsAction["payload"]["vendorId"]
): VendorActionType => ({
  payload: { vendorId },
  type: FETCH_PICKUP_TIME_SLOTS,
});

export const loadRequiredVenuesAndVendors = (
  venueId: FetchRequiredVenuesAndVendorsAction["payload"]["venueId"]
): VendorActionType => ({
  payload: { venueId },
  type: FETCH_REQUIRED_VENUES_AND_VENDORS,
});

export const loadVendorAndMenu = (
  venueId: FetchVendorsAndMenuAction["payload"]["venueId"],
  vendorId: FetchVendorsAndMenuAction["payload"]["vendorId"]
): VendorActionType => ({
  payload: { venueId, vendorId },
  type: FETCH_VENDORS_AND_MENU,
});

export const selectVendor = (
  id: SelectVendorAction["payload"]["id"],
  venueId: SelectVendorAction["payload"]["venueId"],
  storeName: SelectVendorAction["payload"]["storeName"],
  phoneNumber: SelectVendorAction["payload"]["phoneNumber"],
  location: SelectVendorAction["payload"]["location"],
  displayAssets?: VendorDisplayAssets
): VendorActionType => ({
  payload: { id, venueId, storeName, phoneNumber, location, displayAssets },
  type: SELECT_VENDOR,
});

export const resetSelectedVendor = (): VendorActionType => ({
  type: RESET_SELECTED_VENDOR,
});

export const resetVenuesAndVendors = (): VendorActionType => ({
  type: RESET_VENUES_AND_VENDORS,
});
