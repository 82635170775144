import React from "react";
import { env } from "../app-constants";

export default () => (
  <div className="refund">
    <h5>{env.REACT_APP_VENUE_NAME}</h5>
    <h5>Last updated 13 April 2020</h5>
    <ol className="root-list">
      <li>
        <p className="m-0">
          <span className="font-weight-bold">Acceptance.</span> By placing an
          order you accept our {env.REACT_APP_VENUE_NAME} Terms and Conditions
          and Privacy Policy.
        </p>
        <ul>
          <li>
            Ensure that your phone number and/or email address are correct.
          </li>
          <li>Get to your pickup location by your estimated pickup time.</li>
          <li>
            Refunds not given for no shows, change of mind, accidental orders or
            late pickup.
          </li>
        </ul>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">Privacy.</span> Your phone number
          and email is only used by us and DataPOS to complete your order
          purchase unless you consent to receiving marketing information by
          signing up to our mailing list.
        </p>
        <p>
          For comprehensive data practices by DataPOS, refer to the Privacy
          Policy at{" "}
          <a
            href="https://datapos.com.au/privacy-policy"
            title="DataPos privacy policy"
          >
            https://datapos.com.au/privacy-policy
          </a>
          .
        </p>
      </li>
      <li>
        <p>
          <span className="font-weight-bold">
            Payment Confirmation &amp; Refunds.
          </span>{" "}
          Once your payment is confirmed, you will be taken to an order
          confirmation and wait screen (order tracking screen). Enter your email
          there to receive your payment confirmation.
        </p>
        <p>
          If you do not receive an email or SMS pickup alert with your payment
          confirmation and you believe there has been a mistake with your order,
          contact us immediately in person or by phone so that we can retrieve
          your order confirmation.
        </p>
        <p>
          Refunds will not be given if you accidentally place an order, change
          your mind, forget to pickup your order or do not pickup your order
          within a reasonable time.
        </p>
      </li>
    </ol>
  </div>
);
