export interface IConstants {
  DEFAULT_DELAY: number;
  MAX_MENU_ITEM_COUNT: number;
  MAX_RESTRICTED_ITEMS: number;
}

const constants = {
  DEFAULT_DELAY: 5000,
  MAX_MENU_ITEM_COUNT: 99,
  MAX_RESTRICTED_ITEMS: 6,
};

function factoryConstants(): IConstants {
  return Object.create(constants);
}

export default factoryConstants();
