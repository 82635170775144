import { FETCH_STATUS } from "../actions";

export const CHECK_NETWORK_STATUS = "CHECK_NETWORK_STATUS";
export const NETWORK_IS_CONNECTED = "NETWORK_IS_CONNECTED";
export const NETWORK_IS_DISCONNECTED = "NETWORK_IS_DISCONNECTED";

export interface CheckNetworkStatusAction {
  type: typeof CHECK_NETWORK_STATUS;
}

export interface NetworkIsConnectedAction {
  type: typeof NETWORK_IS_CONNECTED;
}

export interface NetworkIsDisconnected {
  type: typeof NETWORK_IS_DISCONNECTED;
}

export type NetworkStatusActionType =
  | CheckNetworkStatusAction
  | NetworkIsConnectedAction
  | NetworkIsDisconnected;

export interface NetworkStatusState {
  fetchStatus: FETCH_STATUS;
}
