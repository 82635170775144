export const START_URL = "/start";
export const DELIVERY_URL = "/order-flow/delivery";
export const DISCOVER_VENDOR_URL = "/order-flow/discover-vendor";
export const PLACE_ORDER_URL = "/order-flow/place-order";
export const CONFIRM_ORDER_URL = "/order-flow/confirm-order";
export const CONFIRM_CONTACT_URL = "/order-flow/confirm-contact";
export const PAYMENT_URL = "/order-flow/payment";
export const WAIT_ORDER_URL = "/order-flow/wait-order";
export const ALERT_ORDER_URL = "/order-flow/alert-order";

export const routes = {
  START: {
    PATH: START_URL,
  },
  ORDER_FLOW: {
    PATH: "/order-flow",
    DISCOVER_VENDOR: {
      PATH: DISCOVER_VENDOR_URL,
      STEP: 0,
    },
    DELIVERY: {
      PATH: DELIVERY_URL,
      STEP: 0,
    },
    PLACE_ORDER: {
      PATH: PLACE_ORDER_URL,
      STEP: 0,
    },
    CONFIRM_ORDER: {
      PATH: CONFIRM_ORDER_URL,
      STEP: 0,
    },
    CONFIRM_CONTACT: {
      PATH: CONFIRM_CONTACT_URL,
      STEP: 0,
    },
    PAYMENT: {
      PATH: PAYMENT_URL,
      STEP: 1,
    },
    WAIT_ORDER: {
      PATH: WAIT_ORDER_URL,
      STEP: 2,
    },
    ALERT_ORDER: {
      PATH: ALERT_ORDER_URL,
      STEP: 3,
    },
  },
};

/* URL paths that are valid when the user has a completed (picked up/alerted) order. 
  Other paths in this state will be redirected to one of these. */
export const allowedCompletedRoutes = [
  routes.START.PATH,
  routes.ORDER_FLOW.ALERT_ORDER.PATH,
];

/* URL paths that are valid when the user has created an order. 
  Other paths in this state will be redirected to one of these. */
export const pathsShouldHaveUserOrder = [
  routes.ORDER_FLOW.CONFIRM_ORDER.PATH,
  routes.ORDER_FLOW.CONFIRM_CONTACT.PATH,
  routes.ORDER_FLOW.PAYMENT.PATH,
  routes.ORDER_FLOW.WAIT_ORDER.PATH,
  routes.ORDER_FLOW.ALERT_ORDER.PATH,
];

/* URL paths that are valid when the user has inputted their contact details.
  Other paths in this state will be redirected to one of these. */
export const pathsShouldHaveUserContact = [
  routes.ORDER_FLOW.PAYMENT.PATH,
  routes.ORDER_FLOW.WAIT_ORDER.PATH,
  routes.ORDER_FLOW.ALERT_ORDER.PATH,
];

/* URL paths that are valid when the order is paid for but not ready.
  Other paths in this state will be redirected to one of these. */
export const pathsShouldHaveUserPayment = [
  routes.ORDER_FLOW.WAIT_ORDER.PATH,
  routes.ORDER_FLOW.ALERT_ORDER.PATH,
];

/* URL paths that are valid when the order is paid for but not ready.
  Other paths in this state will be redirected to one of these. */
export const pathsShouldHaveSelectedVendor = [
  routes.ORDER_FLOW.PLACE_ORDER.PATH,
  routes.ORDER_FLOW.CONFIRM_ORDER.PATH,
  routes.ORDER_FLOW.CONFIRM_CONTACT.PATH,
  routes.ORDER_FLOW.PAYMENT.PATH,
  routes.ORDER_FLOW.WAIT_ORDER.PATH,
  routes.ORDER_FLOW.ALERT_ORDER.PATH,
];
