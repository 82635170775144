import {
  CouponActionType,
  LoadCouponAction,
  LOAD_COUPON,
  SetCouponCodeAction,
  SET_COUPON_CODE,
} from "../types/couponTypes";

export const getCoupon = (
  orderId: LoadCouponAction["payload"]["orderId"]
): CouponActionType => ({
  type: LOAD_COUPON,
  payload: { orderId },
});

export const setCouponCode = (
  verification: SetCouponCodeAction["payload"]["verification"]
): CouponActionType => ({
  type: SET_COUPON_CODE,
  payload: { verification },
});
