import React from "react";

const defaultSteps = ["Order", "Pay", "Track", "Pick Up"];

export interface IStepsProps {
  currStepIndex: number;
  overrideSteps?: string[];
  children?: any;
  className?: string;
}

export default (props: IStepsProps) => {
  function getStepClassStyle(index: number): string {
    let stepClass = "";
    if (index === props.currStepIndex) {
      stepClass = "active";
    } else if (index < props.currStepIndex) {
      stepClass = "passed";
    }
    return stepClass;
  }

  // @ts-ignore - Project Upgrade
  function getStepList(overrideSteps) {
    const steps = overrideSteps || defaultSteps;
    return steps.map((step: string, index: number) => {
      return (
        <div
          key={index}
          className={`step step-${index + 1} ${getStepClassStyle(index)}`}
        >
          <span>{step}</span>
        </div>
      );
    });
  }

  return (
    <header className={`OrderSteps ${props.className ? props.className : ""}`}>
      <div className="row clearfix">
        <div className="steps clearfix">{getStepList(props.overrideSteps)}</div>
      </div>
      {props.children}
    </header>
  );
};
