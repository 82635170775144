import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../app-constants";
import Modal from "../../components/Modal/Modal";
import { ServiceMode } from "../../types";

export interface IOrderAlertedModalProps {
  showModal: boolean;
  serviceMode: ServiceMode | undefined;
}

export default (props: IOrderAlertedModalProps) => (
  // @ts-ignore - Project Upgrade
  <Modal
    className="alert-modal pick-now"
    isOpen={props.showModal}
    title={
      props.serviceMode === "HomeDelivery" ? "We're on our way" : "Pick up now"
    }
    content={
      <p className="alert-modal-text">
        {props.serviceMode === "HomeDelivery" ? (
          <>Your order is with our driver and is on its way.</>
        ) : (
          <>
            <strong>Press button below</strong> for your pick up instructions.
          </>
        )}
      </p>
    }
    actions={
      <Link
        to={routes.ORDER_FLOW.ALERT_ORDER.PATH}
        className="btn btn-primary btn-arrow"
      >
        {props.serviceMode === "HomeDelivery" ? (
          <>Enjoy!</>
        ) : (
          <>I am coming now!</>
        )}
      </Link>
    }
  />
);
