import React from "react";
import withData from "../../highOrderComponents/withData";
import {
  createConsumer,
  updateConsumer,
} from "../../state/actions/consumerActions";
import { RootState } from "../../state/store/store";

interface ITransitionValidatorProps {
  allowRedirect: (index: number, allow: boolean) => void;
  index: number;
  dispatchAction?: Function;
  store?: RootState;
}

class TransitionValidator extends React.Component<
  ITransitionValidatorProps,
  null
> {
  isDataUpToDate = false;

  constructor(props: ITransitionValidatorProps) {
    super(props);
    this.resetValidator();
  }

  resetValidator() {
    this.isDataUpToDate = false;
  }

  componentDidMount() {
    // Creates a new user if there is no saved mobile number or if it is invalid
    // or if it is different

    if (
      (this.props.store?.consumer.data?.contact?.mobile !==
        this.props.store?.consumer.mobilePhoneFormInput ||
        this.props.store?.consumer.error !== "") &&
      this.props.store?.consumer.mobilePhoneFormInput !== "" &&
      this.props.store?.consumer.mobilePhoneFormInput !== undefined
    ) {
      // @ts-ignore - Project Upgrade
      this.props.dispatchAction(
        createConsumer(
          this.props.store?.consumer.mobilePhoneFormInput,
          this.props.store?.consumer.emailAddressFormInput,
          this.props.store?.consumer.fullNameFormInput
        )
      );
    } else if (
      this.props.store?.consumer.data?.contact?.email !==
        this.props.store?.consumer.emailAddressFormInput ||
      this.props.store?.consumer.data?.name?.fullName !==
        this.props.store?.consumer.fullNameFormInput
    ) {
      // @ts-ignore - Project Upgrade
      this.props.dispatchAction(updateConsumer(this.props.store?.consumer));
    } else {
      this.props.allowRedirect(this.props.index, true);
    }
  }

  componentWillReceiveProps(nextProps: ITransitionValidatorProps) {
    this.isDataUpToDate =
      this.isDataUpToDate ||
      (this.props.store?.consumer.fetchStatus === "fetching" &&
        nextProps.store?.consumer.fetchStatus === "success");
  }

  componentDidUpdate() {
    if (this.props.store?.consumer.fetchStatus === "failed") {
      this.resetValidator();
      this.props.allowRedirect(this.props.index, false);
    } else if (this.isDataUpToDate) {
      this.props.allowRedirect(this.props.index, true);
    }
  }

  render() {
    return null;
  }
}

export default withData()(TransitionValidator) as any;
